import { useContext, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigationType, useSearchParams } from 'react-router-dom';

import { SideNav } from 'components/SideNav/SideNav';

import {
  CANCELLATIONS_ROUTE,
  COLLECTIONS_ROUTE,
  ANALYTICS_ROUTE,
  CONTACT_ROUTE,
  FAQ_ROUTE,
  GET_STARTED_ROUTE,
  OVERVIEW_ROUTE,
  SESSIONS_ROUTE,
  LOGIN_ROUTE,
  COMPARISON_ROUTE,
  SYSTEM_STATUS_ROUTE,
  CUSTOMERS_ROUTE,
  ADMIN_PANEL_ROUTE,
  LIVEBOARDS_ROUTE,
  CLIENT_ROUTE,
  USER_GROUP_ROUTE,
} from 'constants/routes';
import AppContext from 'contexts/app/AppContext';
import { SessionContextProvider } from 'contexts/session/SessionContextProvider';
import UserContext from 'contexts/user/UserContext';

import { getStoredAuthToken } from 'services/authToken';
import utils from 'services/utils';

import { Permissions } from 'types/v3';
import Analytics from 'views/Analytics/Analytics';
import { CancellationsPage } from 'views/Cancellations/CancellationsPage';
import { CollectionInfoPage } from 'views/Collections/CollectionInfoPage/CollectionInfoPage';
import { Collections } from 'views/Collections/Collections';
import { ComparisonPage } from 'views/Comparison/ComparisonPage';
import { AdminPanel } from 'views/Config/AdminPanel/AdminPanel';
import ClientConfig from 'views/Config/CustomerConfig/ClientConfig/ClientConfig';
import CustomerConfig from 'views/Config/CustomerConfig/CustomerConfig';
import { UserGroupConfig } from 'views/Config/CustomerConfig/UserGroupConfig/UserGroupConfig';
import { LiveboardConfig } from 'views/Config/LiveboardConfig/LiveboardConfig';
import { Contact } from 'views/Contact/Contact';
import FAQ from 'views/FAQ';
import { GetStarted } from 'views/GetStarted/GetStarted';
import { InsurancePageWrapper } from 'views/InsurancePage/InsurancePageWrapper';
import LoginLoader from 'views/Login/comp/LoginLoader';
import { OverviewPage } from 'views/Overview/OverviewPage';
import { PensionPage } from 'views/PensionPage/PensionPage';
import { SessionPage } from 'views/Session/SessionPage';
import { SessionsPage } from 'views/Sessions/SessionsPage';
import { SystemStatus } from 'views/SystemStatus/SystemStatus';

const UserRouter = () => {
  const { setLanguage } = useContext(AppContext);
  const { config, loadingUser, user } = useContext(UserContext);
  const navigationType = useNavigationType();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const confItem = utils.getConfigItem(config);
    if (confItem) {
      setLanguage(confItem.language);
    }
  }, [config, setLanguage]);

  useEffect(() => {
    if (navigationType === 'PUSH' && searchParams) window.scrollTo(0, 0);
  }, [navigationType, searchParams]);

  function havePermission(permission: Permissions) {
    return user?.permissions?.includes(permission);
  }

  if (!getStoredAuthToken()) {
    return <Navigate replace to={`/${LOGIN_ROUTE}`} />;
  }

  if (loadingUser) {
    return <LoginLoader />;
  }

  return (
    <>
      <SideNav />
      <Routes>
        <Route path={OVERVIEW_ROUTE} element={<OverviewPage />} />
        <Route path={SESSIONS_ROUTE} element={<SessionsPage />} />
        <Route element={<SessionContextProvider />}>
          <Route path={`${SESSIONS_ROUTE}/:sessionId`} element={<SessionPage />} />
        </Route>
        <Route
          path={`${SESSIONS_ROUTE}/:collId/insurance/:externalId`}
          element={<InsurancePageWrapper />}
        />
        <Route path={`${SESSIONS_ROUTE}/:collId/pension/:externalId`} element={<PensionPage />} />
        <Route path={CANCELLATIONS_ROUTE} element={<CancellationsPage />} />
        <Route
          path={`${CANCELLATIONS_ROUTE}/:collId/insurance/:externalId`}
          element={<InsurancePageWrapper />}
        />
        <Route path={COLLECTIONS_ROUTE} element={<Collections />} />
        <Route path={`${COLLECTIONS_ROUTE}/:collectionIdList`} element={<CollectionInfoPage />} />
        <Route
          path={`${COLLECTIONS_ROUTE}/:collId/insurance/:externalId`}
          element={<InsurancePageWrapper />}
        />
        <Route
          path={`${COLLECTIONS_ROUTE}/:collId/pension/:externalId`}
          element={<PensionPage />}
        />
        <Route path={COMPARISON_ROUTE} element={<ComparisonPage />} />

        <Route
          path={`${ANALYTICS_ROUTE}/:boardName/:thoughtSpotLiveboardId`}
          element={<Analytics />}
        />
        <Route path={FAQ_ROUTE} element={<FAQ />} />
        <Route path={GET_STARTED_ROUTE} element={<GetStarted />} />
        <Route path={CONTACT_ROUTE} element={<Contact />} />
        <Route path={SYSTEM_STATUS_ROUTE} element={<SystemStatus />} />

        {havePermission(Permissions.INSURELY_ADMIN) && (
          <>
            <Route path={ADMIN_PANEL_ROUTE} element={<AdminPanel />} />
            <Route
              path={`${ADMIN_PANEL_ROUTE}/${CUSTOMERS_ROUTE}/:customerId`}
              element={<CustomerConfig />}
            />
            <Route
              path={`${ADMIN_PANEL_ROUTE}/${CUSTOMERS_ROUTE}/:customerId/${CLIENT_ROUTE}/:clientId`}
              element={<ClientConfig />}
            />
            <Route
              path={`${ADMIN_PANEL_ROUTE}/${CUSTOMERS_ROUTE}/:customerId/${USER_GROUP_ROUTE}/:userGroupId`}
              element={<UserGroupConfig />}
            />
            <Route
              path={`${ADMIN_PANEL_ROUTE}/${LIVEBOARDS_ROUTE}/:thoughtspotLiveboardId`}
              element={<LiveboardConfig />}
            />
          </>
        )}

        <Route path="/*" element={<Navigate replace to={OVERVIEW_ROUTE} />} />
      </Routes>
    </>
  );
};

export default UserRouter;
