import { getInsurances } from 'client/client';
import { InsuranceResponse } from 'types/insurance';
import { FinancialProduct } from 'types/wealth';
import { logError } from 'utils/datadog';
import { getWealthProducts } from 'views/PensionPage/api';

export async function getItemsInCollections(
  ids: { collectionId: string; creationDate: number | string }[],
  contentType: string,
): Promise<InsuranceResponse[] | FinancialProduct[]> {
  const responses = await Promise.all(
    ids.map(async ({ collectionId, creationDate }) => {
      try {
        const response =
          contentType === 'pension'
            ? (await getWealthProducts(collectionId)).financialProducts
            : await getInsurances(collectionId);

        return response.map((item) => ({
          ...item,
          collectionId,
          collectionDate: new Date(creationDate).toISOString(),
          // TODO: Make sure the above makes it look good. The one below was from the old code on collections page
          // collectionDate: creationDate,
        }));
      } catch (error) {
        if (![401, 404].includes(error?.response?.status)) {
          const nErr = new Error(`Failed to get ${contentType}`, { cause: error });
          logError(nErr, { collectionId, contentType });
          // setErrorFetchingSessionData(true);
        }

        return undefined;
      }
    }),
  );
  const nonFilteredItems = responses.flat().filter((response) => !!response) as
    | FinancialProduct[]
    | InsuranceResponse[];

  return nonFilteredItems;
}
