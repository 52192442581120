import { Card, Typography, Icon, Loader, Notification } from '@insurely/ui';

import classNames from 'classnames';
import { useContext, useRef } from 'react';

import { useAsync, useLocalStorage, useMount } from 'react-use';

import { checkIfPolicyLetterExists } from 'client/client';
import { ComparisonTable } from 'components/ComparisonTable/ComparisonTable';
import { convertInsuranceResponseToInsuranceBody } from 'components/ComparisonTable/utils';
import { InfoBox } from 'components/InfoBox/InfoBox';
import { ModifiedList } from 'components/ModifiedList/ModifiedList';

import { CUSTOMER_T } from 'constants/customers';
import UserContext from 'contexts/user/UserContext';
import { useCompanies } from 'hooks/useCompanies';
import Utils from 'services/utils';
import { useIntl } from 'translations';
import { useFormatting } from 'translations/useFormatting';
import { InsuranceResponse } from 'types/insurance';
import { Market } from 'types/v3';
import { CarInsurance, Driver, Insurance } from 'types/v3/Insurance';
import { logError } from 'utils/datadog';

import { PostHogAction, PostHogCategory, postHogCapture } from 'utils/posthog';

import styles from '../../insurancePage.module.css';

import { DocumentList, Document } from './comp/DocumentList/DocumentList';
import { ErrorBanner } from './comp/ErrorBanner/ErrorBanner';
import { insuranceDetailsKeys, insuranceHolderKeys, paymentPricesKeys } from './sectionRows';

import { useComparisonDetails } from './useComparisonDetails';
import { useSetSections } from './useSetSections';

interface InsuranceDetailsProps {
  insurance: InsuranceResponse;
  className?: string;
}

const isCarInsurance = (tile: Insurance): tile is CarInsurance =>
  (tile as CarInsurance).primaryDriver !== undefined;

export const InsuranceDetails = ({ insurance, className }: InsuranceDetailsProps) => {
  const { config, user } = useContext(UserContext);
  const { formatMessage } = useIntl();
  const { formatCurrency } = useFormatting();
  const { getCompanyDisplayName } = useCompanies();
  const [infoBoxClosure, setInfoBoxClosure] = useLocalStorage<boolean | undefined>(
    'comparison-table-explanation',
  );
  const comparisonTableRef = useRef<HTMLDivElement>(null);

  const { versions, right: rightDetails, left: leftDetails } = useComparisonDetails({ insurance });
  const { leftPackageVersions, rightPackageVersions, errorFetchingVersion } = versions;
  const {
    selectedRightInsurance,
    setSelectedRightInsurance,
    availableRightInsurances,
    rightParameters = [],
    isLoadingRight,
  } = rightDetails;
  const {
    isLoadingLeft,
    leftParameters = [],
    selectedLeftInsurance,
    setSelectedLeftInsurance,
  } = leftDetails;

  const hasParameters = insurance.parameters.length > 2;

  const scrollIntoComparisonTrigger = () => {
    if (comparisonTableRef.current) {
      const scrollY = comparisonTableRef.current.getBoundingClientRect().y;
      if (scrollY < 100) {
        window.removeEventListener('scroll', scrollIntoComparisonTrigger);
        postHogCapture(`tracking:${PostHogAction.VIEW}`, {
          action: PostHogAction.VIEW,
          category: PostHogCategory.COMPARISON,
          object: 'scrollIntoComparison',
        });
      }
    }
  };

  useMount(() => {
    if (hasParameters && selectedRightInsurance) {
      postHogCapture(`tracking:${PostHogAction.STATE}`, {
        action: PostHogAction.STATE,
        category: PostHogCategory.COMPARISON,
        object: 'comparisonIsAvailable',
      });
    }

    window.addEventListener('scroll', scrollIntoComparisonTrigger);
  });

  const { value: hasPolicyDocument } = useAsync(() =>
    checkIfPolicyLetterExists(
      insurance.collectionId || '',
      insurance.insurance.externalId,
      insurance.insurance.insuranceType,
      insurance.insurance.insuranceSubType,
    ).catch((err) => {
      const nErr = new Error('Error checking policy letter', { cause: err });
      logError(nErr, {
        collectionId: insurance.collectionId,
        externalId: insurance.insurance.externalId,
        insuranceType: insurance.insurance.insuranceType,
        insuranceSubType: insurance.insurance.insuranceSubType,
      });
      throw nErr;
    }),
  );

  const insuranceHolderDetails = useSetSections({
    insurance,
    keys: insuranceHolderKeys,
  });

  const insuranceDetails = useSetSections({
    insurance,
    keys: insuranceDetailsKeys,
  });

  const paymentAndPrices = useSetSections({
    insurance,
    keys: paymentPricesKeys,
  });

  let drivers: { label: string; value: string }[] = [];

  if (isCarInsurance(insurance.insurance) && insurance.insurance.primaryDriver) {
    drivers = [
      {
        label: formatMessage({ id: 'shared.primary-driver' }),
        value: insurance.insurance.primaryDriver?.name || '',
      },
      {
        label: formatMessage({ id: 'shared.date-of-birth' }),
        value: insurance.insurance.primaryDriver?.birthDate || '',
      },
      {
        label: formatMessage({ id: 'shared.insurance.license-issuing-date' }),
        value: insurance.insurance.primaryDriver?.licenseIssuingDate || '',
      },
    ];

    const secondaryDrivers = (insurance.insurance as CarInsurance)?.secondaryDrivers?.flatMap(
      (driver: Driver) => [
        { label: formatMessage({ id: 'shared.secondary-driver' }), value: driver.name },
        { label: formatMessage({ id: 'shared.date-of-birth' }), value: driver.birthDate },
        {
          label: formatMessage({ id: 'shared.insurance.license-issuing-date' }),
          value: driver.licenseIssuingDate,
        },
      ],
    );

    if (secondaryDrivers?.length) {
      drivers.push(...secondaryDrivers);
    }
  }

  insuranceDetails.splice(0, 0, {
    label: formatMessage({ id: 'Insurance company' }),
    value: getCompanyDisplayName(insurance.insurance.insuranceCompany ?? '-'),
    description: '',
  });

  if (insurance.deductibles.length) {
    insurance.deductibles.forEach((deductible) => {
      insuranceDetails.splice(insuranceDetails.length - 1, 0, {
        label: deductible.deductibleName,
        value: deductible.deductibleId.toLowerCase().includes('variable')
          ? `${Utils.addThousandSeparator(deductible.deductibleAmount)}%`
          : formatCurrency(Math.ceil(deductible.deductibleAmount || 0)),
        description: '',
      });
    });
  }

  const showDownloadPdfButton = user?.customerId !== CUSTOMER_T;

  const documents = [
    showDownloadPdfButton && 'save-insurance-data',
    hasPolicyDocument && 'policy-letter',
    config.cancellationEnabled && !config.cancellationServiceEnabled && 'cancellation',
    !hasParameters && 'insurance-terms',
  ].filter(Boolean) as Document[];

  const renderCoverageContent = () => {
    // Never show anything related to coverage for Denmark
    if (config.market === Market.DK) {
      return null;
    }

    if (!hasParameters) {
      return <ErrorBanner />;
    }

    if (errorFetchingVersion) {
      return (
        <Notification
          status="error"
          headline={formatMessage({ id: 'page.insurance.package-version.error' })}
        />
      );
    }

    if (!leftPackageVersions) {
      return (
        <div style={{ position: 'relative' }}>
          <Loader.Content />
        </div>
      );
    }

    return (
      <div ref={comparisonTableRef}>
        <Card className={styles.card}>
          <ComparisonTable
            leftInsurance={{
              isLoading: isLoadingLeft,
              parameters: selectedLeftInsurance ? leftParameters : insurance.parameters,
              value: selectedLeftInsurance ?? convertInsuranceResponseToInsuranceBody(insurance),
              insuranceOptions: [convertInsuranceResponseToInsuranceBody(insurance)],
              versionOptions: leftPackageVersions,
              onChange: (ins) => setSelectedLeftInsurance(ins),
            }}
            rightInsurance={
              availableRightInsurances.length > 0 && selectedRightInsurance && rightPackageVersions
                ? {
                    isLoading: isLoadingRight,
                    parameters: rightParameters,
                    value: selectedRightInsurance,
                    insuranceOptions: availableRightInsurances,
                    versionOptions: rightPackageVersions,
                    onChange: (ins) => setSelectedRightInsurance(ins),
                  }
                : undefined
            }
          />
        </Card>
      </div>
    );
  };

  return (
    <div className={classNames(className, styles.cardsWrapper)}>
      <div className={styles.topRowWrapper}>
        <Card className={classNames(styles.card, styles.listCard)}>
          <ModifiedList
            rows={insuranceHolderDetails}
            valueClassName="ph-no-capture"
            title={formatMessage({ id: 'Insurance holder' })}
            icon={<Icon name="user" size={24} />}
          />
        </Card>
        <Card className={classNames(styles.card, styles.listCard)}>
          <ModifiedList
            rows={paymentAndPrices}
            title={formatMessage({ id: 'Cost' })}
            icon={<Icon name="cost" size={24} />}
          />
        </Card>
        {documents.length > 0 && (
          <Card className={classNames(styles.card, styles.listCard, styles.documentsContainer)}>
            <DocumentList documents={documents} insurance={insurance} />
          </Card>
        )}
      </div>
      <Card className={classNames(styles.card, styles.listCard)}>
        <ModifiedList
          rows={insuranceDetails}
          valueClassName="ph-no-capture"
          title={formatMessage({ id: 'Insurance' })}
          icon={<Icon name="contract" size={24} />}
          showColumns
        />
      </Card>
      {config.market === Market.FR && drivers.length > 0 && (
        <Card className={classNames(styles.card, styles.listCard)}>
          <ModifiedList
            rows={drivers}
            valueClassName="ph-no-capture"
            title={formatMessage({ id: 'page.insurance.drivers-title' })}
            icon={<Icon name="car" size={24} />}
            showColumns
            listClassName={styles.driverColumns}
          />
        </Card>
      )}
      {!infoBoxClosure && (
        <InfoBox
          className={styles.infoBox}
          title={formatMessage({ id: 'Parameter comparison' })}
          content={
            <div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--space-4)' }}>
              <Typography variant="ParagraphCaption" component="p">
                {formatMessage({
                  id: 'Here you can compare insurance coverage. The data shown here is based on the general insurance terms.',
                })}
              </Typography>
              <Typography variant="ParagraphCaption" component="p">
                {formatMessage({ id: 'Hover over the different labels for more info.' })}
              </Typography>
              <Typography variant="ParagraphCaption" component="p">
                {formatMessage({
                  id: 'Feel free to give feedback to Insurely using the banner on the left or send an email using "Help" at the bottom right.',
                })}
              </Typography>
            </div>
          }
          button={{
            label: formatMessage({ id: 'I understand' }),
            onClick: () => setInfoBoxClosure(true),
          }}
          fullWidth
        />
      )}
      {renderCoverageContent()}
    </div>
  );
};
