import { Button } from '@insurely/ui';

import { FormattedMessage } from 'translations';

import styles from '../actionBanner.module.css';
import { Target } from '../type';

interface Props {
  target: Target;
  onClick: () => void;
  isPending: boolean;
  disabled: boolean;
}

// Default deletion action, extract into its own component if you need to make changes here
export const BatchDeletion = ({ target, disabled, onClick, isPending }: Props) => (
  <div className={styles.batchRemoval}>
    <FormattedMessage id={`page.config.action-banner.batch-${target.toLowerCase()}.delete-title`} />
    <Button
      onClick={() => onClick()}
      loading={isPending}
      size="medium"
      disabled={disabled}
      variant="secondary"
    >
      <FormattedMessage id="page.config.action-banner.batch.delete" />
    </Button>
  </div>
);
