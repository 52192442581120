import { Card, CompanyLogo, Icon, StatusText, Tooltip, Typography } from '@insurely/ui';

import { useContext, useMemo } from 'react';

import { putUserAttributes } from 'client/client';
import { InfoBox } from 'components/InfoBox/InfoBox';
import { Page } from 'components/Page/Page';
import UserContext from 'contexts/user/UserContext';
import { FormattedMessage, useIntl } from 'translations';

import { Company, LoginMethod } from 'types/v3';

import { logError } from 'utils/datadog';

import styles from './systemStatus.module.css';

const CompanyItem = ({ comp }: { comp: Company }) => {
  const { functional, insuranceCompany, loginMethods } = comp;
  const { formatMessage } = useIntl();

  const shouldDisplayTooltip =
    (insuranceCompany === 'se-trekronor' || insuranceCompany === 'se-swedbank') &&
    !loginMethods.includes(
      LoginMethod.SWEDISH_MOBILE_BANKID_SAME_DEVICE_CLIENT_SIDE_AUTHENTICATION,
    );

  const companyStatus = useMemo(() => {
    if (!functional) {
      return {
        color: 'var(--red-1)',
        labelKey: 'page.system-status.integration.temporarily-closed',
      };
    }
    return { color: 'var(--green)', labelKey: 'page.system-status.integration.active' };
  }, [functional]);

  return (
    <div className={styles.companyItem}>
      <CompanyLogo company={comp.insuranceCompany} width={40} />
      <div className={styles.companyItemContent}>
        <Typography variant="Label-2" component="p">
          {comp.insuranceCompanyDisplayName}
        </Typography>
        <div className={styles.statusWrapper}>
          <StatusText
            color={companyStatus.color}
            text={formatMessage({ id: companyStatus.labelKey })}
          />
          {shouldDisplayTooltip && (
            <Tooltip
              style={{ padding: 0, width: 'fit-content' }}
              content={formatMessage({ id: 'page.system-status.external-device.tooltip' })}
            >
              <div className={styles.externalDeviceLabel}>
                <Icon name="info" size={20} />
                <Typography
                  variant="ParagraphTiny"
                  component="p"
                  className={styles.otherDeviceTitle}
                >
                  <FormattedMessage id="page.system-status.bank-id-other-device" />
                </Typography>
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export const SystemStatus = () => {
  const { formatMessage } = useIntl();
  const { companies, user, userAttributes, setUserAttributes } = useContext(UserContext);

  const handleQrRequirementConfirmation = async () => {
    if (user?.selectedClientId) {
      await putUserAttributes(user.selectedClientId, true)
        .then(() => {
          setUserAttributes?.({ ...userAttributes, qrRequirementConfirmation: true });
        })
        .catch((err) => {
          const nErr = new Error('Failed to update user attributes', { cause: err });
          logError(nErr, {
            userId: user.uuid,
            clientId: user.selectedClientId,
            customerId: user.customerId,
          });
          throw nErr;
        });
    }
  };

  const sortedCompanies = companies.sort((a, b) =>
    a.insuranceCompanyDisplayName.localeCompare(b.insuranceCompanyDisplayName),
  );
  const functionalCompanies = sortedCompanies.filter((comp) => comp.functional);
  const nonFunctionalCompanies = sortedCompanies.filter((comp) => !comp.functional);

  const showQRCodeInfoBox =
    !userAttributes.qrRequirementConfirmation &&
    functionalCompanies.some(({ insuranceCompany }) =>
      ['se-trekronor', 'se-swedbank'].includes(insuranceCompany),
    );

  return (
    <Page title={formatMessage({ id: 'shared.navigation.system-status' })}>
      <div className={styles.container}>
        <Typography component="h1" variant="Headline-3">
          <FormattedMessage id="shared.navigation.system-status" />
        </Typography>
        {showQRCodeInfoBox && (
          <InfoBox
            title={formatMessage({ id: 'Login and signing with QR code' })}
            maxWidth={456}
            content={
              <div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--space-4)' }}>
                <Typography variant="ParagraphCaption" component="p">
                  {formatMessage({
                    id: 'Some companies only allow identification with BankID via QR code. It confirms that you are physically present at both devices, reducing the risk of fraud.',
                  })}
                </Typography>
                <Typography variant="ParagraphCaption" component="p">
                  {formatMessage({
                    id: 'Ask the customer to retrieve their insurance from a device other than the one used for BankID.',
                  })}
                </Typography>
              </div>
            }
            button={{
              label: formatMessage({ id: 'I understand' }),
              onClick: handleQrRequirementConfirmation,
            }}
          />
        )}
        <Card className={styles.cardContent}>
          {nonFunctionalCompanies.length > 0 && (
            <div style={{ marginBottom: 'var(--space-14)' }}>
              <Typography component="h6" variant="Headline-6">
                {formatMessage({ id: 'page.system-status.temporary-offline.title' })}
              </Typography>
              <Typography component="p" variant="ParagraphCaption">
                {formatMessage({ id: 'page.system-status.temporary-offline.description' })}
              </Typography>
              <div className={styles.companyItemContainer}>
                {nonFunctionalCompanies.map((comp) => (
                  <CompanyItem comp={comp} key={comp.insuranceCompany} />
                ))}
              </div>
            </div>
          )}
          <Typography component="h6" variant="Headline-6">
            {formatMessage({ id: 'page.system-status.available.title' })}
          </Typography>
          <Typography component="p" variant="ParagraphCaption">
            {formatMessage({ id: 'page.system-status.available.description' })}
          </Typography>
          <div className={styles.companyItemContainer}>
            {functionalCompanies.map((comp) => (
              <CompanyItem comp={comp} key={comp.insuranceCompany} />
            ))}
          </div>
        </Card>
      </div>
    </Page>
  );
};
