import { zodResolver } from '@hookform/resolvers/zod';
import { Button, CompanyLogo, Divider, TextInput, Typography, Notification } from '@insurely/ui';

import React, { useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';

import { Link, useSearchParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { z } from 'zod';

import { forgotPassword } from 'client/client';
import { LOGIN_MAGIC_LINK_ROUTE, LOGIN_ROUTE, REGISTER_ROUTE } from 'constants/routes';

import { useNavigateQueryParams } from 'hooks/useNavigateQueryParams';
import { FormattedMessage, useIntl } from 'translations';

import { logError } from 'utils/datadog';

import styles from '../login.module.css';

import ResetPassword from './ResetPassword';

type InnerState = 'default' | 'resetting' | 'reset-sent' | 'reset-done';

const Header = ({ state, email }: { state: InnerState; email: string }) => {
  const { formatMessage } = useIntl();

  const computedHeader = useMemo(() => {
    switch (state) {
      case 'reset-sent':
        return {
          title: formatMessage({ id: 'page.login.reset-password.confirmation.title' }),
          description: formatMessage(
            {
              id: 'We just sent you an email to {email}. Follow the instructions in the email to reset your password',
            },
            { email },
          ),
        };
      case 'reset-done':
        return {
          title: formatMessage({ id: 'page.login.done' }),
          description: formatMessage({ id: 'Your password is now saved' }),
        };
      case 'default':
      default:
        return {
          title: formatMessage({ id: 'Reset password' }),
          description: formatMessage({
            id: 'Input you email address. You will then get an email with a link back to this page where you can set a new password.',
          }),
        };
    }
  }, [formatMessage, state, email]);

  return (
    <React.Fragment>
      <CompanyLogo company="se-demo" width="56px" aria-label="Insurely" />
      <div className={styles.header}>
        <Typography component="h1" variant="Headline-4" className={styles.signInTitle}>
          {computedHeader.title}
        </Typography>
        <Typography component="p" variant="ParagraphBodySmall" className={styles.signInText}>
          {computedHeader.description}
        </Typography>
      </div>
    </React.Fragment>
  );
};

const LoginOptionsContainer = ({ state }: { state: InnerState }) => {
  const navigate = useNavigateQueryParams();

  if (!['default', 'reset-done', 'reset-sent'].includes(state)) {
    return null;
  }

  return (
    <div className={styles.secondaryLoginContainer}>
      <Divider />
      {state === 'default' && (
        <Button
          variant="secondary"
          onClick={() => navigate(`/${LOGIN_ROUTE}/${LOGIN_MAGIC_LINK_ROUTE}`)}
          fullWidth
        >
          <FormattedMessage id="page.login.link.sign-in-email" />
        </Button>
      )}
      {['reset-done', 'reset-sent'].includes(state) && (
        <Button variant="secondary" onClick={() => navigate(`/${LOGIN_ROUTE}`)} fullWidth>
          <FormattedMessage id="page.login.link.username-login" />
        </Button>
      )}
      <Divider />
    </div>
  );
};

export const ForgotPassword = () => {
  const { formatMessage } = useIntl();
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  const [innerState, setInnerState] = useState<InnerState>(token ? 'resetting' : 'default');

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    trigger,
  } = useForm<{ email: string }>({
    mode: 'onTouched',
    delayError: 500,
    resolver: zodResolver(
      z.object({
        email: z.string().email({ message: formatMessage({ id: 'Please enter a valid e-mail' }) }),
      }),
    ),
  });

  const [
    { loading: isSendingPasswordChangeEmail, error: errorSendingPasswordChangeEmail },
    doSendPasswordChangeEmail,
  ] = useAsyncFn(({ email }: { email: string }) =>
    forgotPassword(email)
      .then(() => setInnerState('reset-sent'))
      .catch((err) => {
        const { data } = err.response;

        const nErr = new Error(data?.localizedMessage || data?.message);
        logError(nErr);
        throw nErr;
      }),
  );

  function renderContent() {
    if (token && innerState === 'resetting') {
      return <ResetPassword onChangeSuccess={() => setInnerState('reset-done')} token={token} />;
    }

    return (
      <>
        <form className={styles.form} onSubmit={handleSubmit(doSendPasswordChangeEmail)}>
          <Header email={getValues('email')} state={innerState} />
          {errorSendingPasswordChangeEmail && (
            <Notification
              className={styles.notification}
              headline={errorSendingPasswordChangeEmail.message}
              status="error"
            />
          )}
          {innerState === 'default' && (
            <React.Fragment>
              <TextInput
                {...register('email')}
                label={formatMessage({ id: 'shared.email' })}
                className={styles.formInput}
                error={!!errors.email}
                helperText={errors.email?.message}
                onBlur={() => {
                  if (getValues('email')) {
                    trigger('email');
                  }
                }}
                autoFocus
              />
              <Button
                variant="primary"
                fullWidth
                type="submit"
                loading={isSendingPasswordChangeEmail}
                disabled={isSendingPasswordChangeEmail}
              >
                {formatMessage({ id: 'Reset password' })}
              </Button>
            </React.Fragment>
          )}
        </form>
        <LoginOptionsContainer state={innerState} />
        {innerState !== 'resetting' && (
          <div className={styles.linksContainer}>
            <Link to={`/${LOGIN_ROUTE}/${REGISTER_ROUTE}`}>
              {formatMessage({ id: 'page.login.link.create-account' })}
            </Link>
          </div>
        )}
      </>
    );
  }

  return <div className={styles.formContainer}>{renderContent()}</div>;
};
