import { Button, Modal, Notification } from '@insurely/ui';
import { useState } from 'react';

import { useParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';

import { FormattedMessage, useIntl } from 'translations';

import { logError } from 'utils/datadog';
import { AddToList } from 'views/Config/comp/AddToList/AddToList';

import { createCustomerEmails, deleteCustomerEmails } from '../../../../api';

import styles from './editEmailsModal.module.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  emails: string[];
}

export const EditEmailsModal = ({ isOpen, onClose, emails, onSuccess }: Props) => {
  const { formatMessage } = useIntl();
  const { customerId } = useParams() as { customerId: string };

  const [curDomains, setCurDomains] = useState(emails.sort((a, b) => a.localeCompare(b)));

  const [{ error, loading }, doUpdateEmailDomains] = useAsyncFn(() => {
    const toAdd = curDomains.filter((d) => !emails.includes(d));
    const toDelete = emails.filter((e) => !curDomains.includes(e));

    return Promise.all(
      [
        toAdd.length > 0 && createCustomerEmails({ customerId, emails: toAdd }),
        toDelete.length > 0 && toDelete.map((d) => deleteCustomerEmails({ customerId, email: d })),
      ]
        .filter(Boolean)
        .flat(),
    )
      .then(onSuccess)
      .catch((err) => {
        const nErr = new Error('Failed to create/delete email domains for customer', {
          cause: err,
        });
        logError(nErr, { customerId });
        throw nErr;
      });
  }, [curDomains]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({ id: 'page.config.customer-config.edit-emails.title' })}
      width={560}
      className={styles.modal}
    >
      <AddToList
        values={curDomains}
        title={formatMessage({
          id: 'page.config.customer-config.edit-emails.add.title',
        })}
        onChange={setCurDomains}
      />
      <div className={styles.actionButtons}>
        <Button variant="secondary" onClick={onClose}>
          <FormattedMessage id="page.config.customer-config.edit-emails.cancel" />
        </Button>
        <Button loading={loading} onClick={doUpdateEmailDomains}>
          <FormattedMessage id="page.config.customer-config.edit-emails.save" />
        </Button>
      </div>
      {!!error && (
        <Notification
          headline={formatMessage({
            id: 'page.config.customer-config.edit-emails.error',
          })}
          status="error"
        />
      )}
    </Modal>
  );
};
