import { Loader } from '@insurely/ui';
import { useState, useEffect, useCallback, useContext } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import { getCancellations, getInsurances } from 'client/client';
import { Cancellation, CancellationStatus } from 'client/types';

import { Page } from 'components/Page/Page';

import UserContext from 'contexts/user/UserContext';
import { useIntl } from 'translations';
import { InsuranceResponse } from 'types/insurance';

import { logError } from 'utils/datadog';
import { getDisplayName, getDisplayType } from 'utils/insurance';

import { InsuranceTabs } from './comp/InsuranceTabs/InsuranceTabs';
import { InsurancePage } from './InsurancePage';

import styles from './insurancePage.module.css';

export const InsurancePageWrapper = () => {
  const { pathname } = useLocation();
  const { externalId, collId } = useParams() as {
    collId: string;
    externalId: string;
  };
  const { formatMessage } = useIntl();
  const { config } = useContext(UserContext);

  const [insurance, setInsurance] = useState<InsuranceResponse>();
  const [insurances, setInsurances] = useState<InsuranceResponse[] | undefined>();
  const [cancellation, setCancellation] = useState<Cancellation>();
  const [creatingNewCancellation, setCreatingNewCancellation] = useState(false);

  const fetchCancellation = useCallback(async () => {
    try {
      const resp = await getCancellations(`?collectionIds=${collId}`);
      const latestCancellation = resp.cancellations.find(
        (_cancellation: Cancellation) =>
          _cancellation.insurances[0]?.externalId === externalId &&
          ![CancellationStatus.CANCELED, CancellationStatus.DOCUMENT_ERROR].includes(
            _cancellation.status,
          ),
      );
      setCancellation(latestCancellation);
    } catch (error) {
      const nErr = new Error('Error fetching cancellation', { cause: error });
      logError(nErr, { collectionId: collId });
      throw nErr;
    }
  }, [collId, externalId]);

  const fetchAndSetInsurances = useCallback(async () => {
    const resp = await getInsurances(collId).catch((err) => {
      const nErr = new Error('Failed to get insurances', { cause: err });
      logError(nErr, { collectionId: collId, externalId });
      throw nErr;
    });
    const resp2 = resp.map((item) => ({
      ...item,
      collectionId: collId,
      // collectionDate: new Date(creationDate).toISOString(),
    }));
    await fetchCancellation();
    setInsurance(resp2.find((ins) => ins.insurance.externalId.toString() === externalId));
    setInsurances(resp2);
  }, [collId, externalId, fetchCancellation]);

  useEffect(() => {
    setInsurance(undefined);
    fetchAndSetInsurances();
  }, [collId, fetchAndSetInsurances]);

  function getTitle() {
    if (!insurance) {
      return 'Insurance';
    }
    return `${getDisplayType(insurance.insurance, formatMessage, config.market)}, ${getDisplayName(
      insurance.insurance,
    )}`;
  }

  function renderContent() {
    if (!insurance || !insurances) {
      return <Loader.Content />;
    }

    if (!insurance) {
      return null;
    }

    return (
      <InsurancePage
        insurance={insurance}
        cancellation={cancellation}
        creatingNewCancellation={creatingNewCancellation}
        setCreatingNewCancellation={setCreatingNewCancellation}
        fetchCancellation={fetchCancellation}
      />
    );
  }

  return (
    <Page
      title={getTitle()}
      goback={!pathname.includes('/sessions')}
      className={styles.insurancePage}
    >
      {pathname.includes('/sessions') && <InsuranceTabs insurances={insurances} />}

      <div className={styles.insurancePageContent}>{renderContent()}</div>
    </Page>
  );
};
