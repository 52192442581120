import { Icon, Tooltip, Typography } from '@insurely/ui';

import { useMemo } from 'react';

import { Permissions } from 'types/v3';

import styles from './permissionMapper.module.css';

interface Props {
  permissions: Permissions[];
}

export const IconFromPermission = ({
  permission,
  color,
}: {
  permission: Permissions;
  color?: string;
}) => {
  const iconName = useMemo(() => {
    switch (permission) {
      case Permissions.READ_CUSTOMER_CLIENTS:
        return 'award';
      case Permissions.READ_TEAM_STATISTICS:
      case Permissions.READ_COLLECTION_STATISTICS:
      case Permissions.READ_USER_JOURNEY_STATISTICS:
        return 'donut-chart';
      case Permissions.READ_ALL_CANCELLATIONS:
        return 'compare-arrows';
      default:
        return null;
    }
  }, [permission]);

  if (!iconName) {
    return null;
  }
  return <Icon name={iconName} size={24} color={color} />;
};

export const PermissionMapper = ({ permissions }: Props) => {
  const renderIcon = (p: Permissions) => {
    const color = permissions.includes(p) ? 'var(--midnight)' : 'var(--grey-2)';

    const IconComponent = IconFromPermission({ permission: p, color });

    if (IconComponent) {
      return (
        <Tooltip trigger="hover" hideCloseIcon key={p} content={p}>
          {IconComponent}
        </Tooltip>
      );
    }
    return null;
  };

  const standardPermissions = Object.values(Permissions).filter((p) => !p.includes('STATISTICS'));
  const statisticsPermissions = Object.values(Permissions).filter((p) => p.includes('STATISTICS'));
  const availableStatisticsPermissions = permissions.filter((p) => p.includes('STATISTICS'));

  return (
    <div className={styles.container}>
      {standardPermissions.map(renderIcon)}
      <Tooltip
        trigger="hover"
        hideCloseIcon
        contentStyle={{ whiteSpace: 'nowrap', width: 'auto', maxWidth: undefined }}
        content={
          <div className={styles.statisticsTooltip}>
            {statisticsPermissions.map((p) => (
              <div key={p} className={styles.permission}>
                <Icon name={permissions.includes(p) ? 'check' : 'close'} size={14} />
                <Typography
                  component="p"
                  variant="ParagraphTiny"
                  style={{ color: permissions.includes(p) ? 'var(--midnight)' : 'var(--grey-1)' }}
                >
                  {p}
                </Typography>
              </div>
            ))}
          </div>
        }
      >
        <Icon
          name="donut-chart"
          size={24}
          color={availableStatisticsPermissions.length > 0 ? 'var(--midnight)' : 'var(--grey-2)'}
        />
      </Tooltip>
    </div>
  );
};
