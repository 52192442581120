import { Button, Card, Typography } from '@insurely/ui';
import { useContext } from 'react';

import { Page } from 'components/Page/Page';

import {
  ANALYTICS_ROUTE,
  CANCELLATIONS_ROUTE,
  COLLECTIONS_ROUTE,
  CONTACT_ROUTE,
  FAQ_ROUTE,
  GET_STARTED_ROUTE,
  SESSIONS_ROUTE,
} from 'constants/routes';
import UserContext from 'contexts/user/UserContext';

import { useNavigateQueryParams } from 'hooks/useNavigateQueryParams';
import { FormattedMessage, useIntl } from 'translations';
import { Permissions, Market } from 'types/v3';

import styles from './overview.module.css';

const QuickAccessCard = ({
  title,
  description,
  button,
}: {
  title: string;
  description: string;
  button: { label: string; link: string };
}) => {
  const navigate = useNavigateQueryParams();

  return (
    <Card className={styles.quickAccessCard}>
      <div>
        <Typography variant="Headline-6" component="h6" style={{ marginBottom: 'var(--space-4)' }}>
          {title}
        </Typography>
        <Typography variant="ParagraphBodySmall" component="p" style={{ color: 'var(--grey-1)' }}>
          {description}
        </Typography>
      </div>
      <Button size="small" onClick={() => navigate(`/${button.link}`)}>
        {button.label}
      </Button>
    </Card>
  );
};

export const OverviewPage = () => {
  const { formatMessage } = useIntl();
  const { config, user, liveboards, productLine } = useContext(UserContext);
  const firstLiveboard = liveboards.find((l) => !l.liveboardName.includes('/'));

  const defaultQuickAccess = [
    {
      title: formatMessage({ id: `page.overview.quick-access.session.${productLine}.title` }),
      description: formatMessage({
        id: `page.overview.quick-access.session.${productLine}.description`,
      }),
      button: {
        label: formatMessage({ id: `page.overview.quick-access.session.${productLine}.button` }),
        link: SESSIONS_ROUTE,
      },
    },
    {
      title: formatMessage({ id: 'page.overview.quick-access.cancellation.title' }),
      description: formatMessage({ id: 'page.overview.quick-access.cancellation.description' }),
      button: {
        label: formatMessage({ id: 'page.overview.quick-access.cancellation.button' }),
        link: CANCELLATIONS_ROUTE,
      },
      visible: config.cancellationEnabled,
    },
    {
      title: formatMessage({ id: `page.overview.quick-access.collections.${productLine}.title` }),
      description: formatMessage({
        id: `page.overview.quick-access.collections.${productLine}.description`,
      }),
      button: {
        label: formatMessage({
          id: `page.overview.quick-access.collections.${productLine}.button`,
        }),
        link: COLLECTIONS_ROUTE,
      },
      visible: user?.permissions?.includes(Permissions.READ_CUSTOMER_CLIENTS),
    },
    {
      title: formatMessage({ id: 'page.overview.quick-access.insights.title' }),
      description: formatMessage({ id: 'page.overview.quick-access.insights.description' }),
      button: {
        label: formatMessage({ id: 'page.overview.quick-access.insights.button' }),
        link: `${ANALYTICS_ROUTE}/${firstLiveboard?.liveboardName}/${firstLiveboard?.thoughtspotLiveboardId}`,
      },
      visible: liveboards.length > 0,
    },
  ];

  const supportQuickAccess = [
    {
      title: formatMessage({ id: 'page.overview.quick-access.get-started.title' }),
      description: formatMessage({ id: 'page.overview.quick-access.get-started.description' }),
      button: {
        label: formatMessage({ id: 'page.overview.quick-access.get-started.button' }),
        link: GET_STARTED_ROUTE,
      },
      visible: config.market === Market.SE,
    },
    {
      title: formatMessage({ id: 'page.overview.quick-access.faq.title' }),
      description: formatMessage({ id: 'page.overview.quick-access.faq.description' }),
      button: {
        label: formatMessage({ id: 'page.overview.quick-access.faq.button' }),
        link: FAQ_ROUTE,
      },
      visible: config.market !== Market.SE,
    },
    {
      title: formatMessage({ id: 'page.overview.quick-access.contact.title' }),
      description: formatMessage({ id: 'page.overview.quick-access.contact.description' }),
      button: {
        label: formatMessage({ id: 'page.overview.quick-access.contact.button' }),
        link: CONTACT_ROUTE,
      },
    },
  ];

  return (
    <Page maxWidth="1312px" title={formatMessage({ id: 'Overview' })}>
      <div className={styles.pageContainer}>
        <Typography variant="Headline-3" component="h3" className={styles.pageTitle}>
          <FormattedMessage id="page.overview.title" />
        </Typography>
        <div className={styles.accessCardContainer}>
          {defaultQuickAccess
            .filter((a) => a.visible === undefined || !!a.visible)
            .map((access) => (
              <QuickAccessCard
                key={access.title}
                title={access.title}
                description={access.description}
                button={access.button}
              />
            ))}
        </div>
        <div>
          <Typography
            variant="Headline-5"
            component="h5"
            style={{ color: 'var(--white)', marginBottom: 'var(--space-4)' }}
          >
            <FormattedMessage id="page.overview.help-support.title" />
          </Typography>
          <div className={styles.accessCardContainer}>
            {supportQuickAccess
              .filter((a) => a.visible === undefined || !!a.visible)
              .map((access) => (
                <QuickAccessCard
                  key={access.title}
                  title={access.title}
                  description={access.description}
                  button={access.button}
                />
              ))}
          </div>
        </div>
      </div>
    </Page>
  );
};
