import {
  Button,
  Checkbox,
  Icon,
  IconButton,
  Loader,
  Modal,
  Plate,
  TextInput,
  Tooltip,
  Typography,
} from '@insurely/ui';
import classNames from 'classnames';
import { useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ADMIN_PANEL_ROUTE, LIVEBOARDS_ROUTE } from 'constants/routes';
import tableStyles from 'styles/table.module.css';
import { FormattedMessage, useIntl } from 'translations';

import { Liveboard } from 'types/liveboard';

import { Action, ActionsBanner } from '../comp/ActionBanner/ActionBanner';
import { Target } from '../comp/ActionBanner/type';
import { IconFromPermission } from '../comp/PermissionMapper/PermissionMapper';
import { TableTitle } from '../comp/TableTitle/TableTitle';

import configStyles from '../config.module.css';

import { CreateLiveboardForm } from './Form';
import styles from './liveboards.module.css';

interface Props {
  liveboards?: Liveboard[];
  isFetchingLiveboards: boolean;
  onRefresh: () => void;
}

export const Liveboards = ({ liveboards, isFetchingLiveboards, onRefresh }: Props) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [addLiveboard, setAddLiveboard] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedLiveboards, setSelectedLiveboards] = useState<string[]>([]);

  const filteredLiveboards = useMemo(() => {
    if (!liveboards) {
      return [];
    }
    return liveboards.filter((l) => l.liveboardName.toLowerCase().includes(search.toLowerCase()));
  }, [search, liveboards]);

  const handleOnSelectAllLiveboards = () => {
    if (!liveboards) {
      return;
    }
    if (liveboards.every((l) => selectedLiveboards.includes(l.thoughtspotLiveboardId))) {
      setSelectedLiveboards((cur) =>
        cur.filter((c) => !liveboards.some((l) => l.thoughtspotLiveboardId === c)),
      );
    } else {
      setSelectedLiveboards((cur) => [
        ...new Set([...cur, ...liveboards.map((l) => l.thoughtspotLiveboardId)]),
      ]);
    }
  };

  const onSelectLiveboard = (liveboardId: string) => {
    setSelectedLiveboards((cur) =>
      !cur.includes(liveboardId) ? [...cur, liveboardId] : cur.filter((u) => u !== liveboardId),
    );
  };

  if (isFetchingLiveboards || !liveboards) {
    return (
      <div style={{ position: 'relative', height: '30px' }}>
        <Loader.Content size="default" />
      </div>
    );
  }

  return (
    <Plate>
      <div className={styles.tableHeadline}>
        <TableTitle
          icon="bar-chart"
          title={formatMessage({ id: 'page.config.liveboards-title' })}
        />
        <div className={styles.headlineActions}>
          <Button
            variant="primary"
            size="medium"
            onClick={() => setAddLiveboard(true)}
            icon={<Icon name="add-document" size={20} />}
          >
            <FormattedMessage id="page.config.liveboards.create-new" />
          </Button>
          <TextInput
            startAdornment={<Icon name="search" size={20} />}
            endAdornment={
              search.length > 0 ? (
                <IconButton icon={<Icon name="close" size={20} />} onClick={() => setSearch('')} />
              ) : undefined
            }
            textInputSize="extra-small"
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            placeholder={formatMessage({ id: 'page.config.liveboards.search.placeholder' })}
            label=""
          />
        </div>
      </div>
      <table className={tableStyles.table}>
        <thead>
          <tr className={classNames(tableStyles.head, configStyles.tableHeader)}>
            <th style={{ width: '5%' }}>
              <Checkbox
                aria-label="checkbox-user"
                checked={liveboards?.some((l) =>
                  selectedLiveboards.includes(l.thoughtspotLiveboardId),
                )}
                partial={
                  !liveboards?.every((l) => selectedLiveboards.includes(l.thoughtspotLiveboardId))
                }
                onCheckedChange={handleOnSelectAllLiveboards}
                disabled={liveboards?.length === 0}
              />
            </th>
            <th aria-labelledby="liveboard name" style={{ width: '40%' }}>
              <Typography component="p" variant="Label-3">
                <FormattedMessage id="page.config.liveboards.column.liveboard-name" />
              </Typography>
            </th>
            <th aria-labelledby="description" style={{ width: '40%' }}>
              <Typography component="p" variant="Label-3">
                <FormattedMessage id="page.config.liveboards.column.description" />
              </Typography>
            </th>
            <th aria-labelledby="permission" style={{ width: '15%' }}>
              <Typography component="p" variant="Label-3">
                <FormattedMessage id="page.config.liveboards.column.permission" />
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredLiveboards.length === 0 && (
            <tr>
              <td colSpan={3} aria-label="no-elements">
                <div className={configStyles.emptyRow}>
                  <Typography variant="ParagraphBodySmall" component="p">
                    <FormattedMessage id="page.config.liveboards.empty-table" />
                  </Typography>
                </div>
              </td>
            </tr>
          )}
          {filteredLiveboards
            .sort((a, b) => a.liveboardName.localeCompare(b.liveboardName))
            .map((l) => (
              <tr
                className={classNames(tableStyles.row, configStyles.tableRow)}
                key={l.thoughtspotLiveboardId}
                tabIndex={0}
                onClick={() =>
                  navigate(`/${ADMIN_PANEL_ROUTE}/${LIVEBOARDS_ROUTE}/${l.thoughtspotLiveboardId}`)
                }
              >
                {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
                <td aria-label="liveboard-select" onClick={(e) => e.stopPropagation()}>
                  <Checkbox
                    aria-label="checkbox-liveboard"
                    checked={selectedLiveboards.some((e) => e === l.thoughtspotLiveboardId)}
                    onCheckedChange={() => onSelectLiveboard(l.thoughtspotLiveboardId)}
                  />
                </td>
                <td>
                  <Typography component="p" variant="ParagraphCaption">
                    {l.liveboardName}
                  </Typography>
                </td>
                <td>
                  <Typography component="p" variant="ParagraphCaption">
                    {l.description}
                  </Typography>
                </td>
                <td aria-labelledby="permission">
                  <Tooltip trigger="hover" hideCloseIcon content={l.requiredUserPermission}>
                    <IconFromPermission permission={l.requiredUserPermission} />
                  </Tooltip>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {selectedLiveboards.length > 0 && (
        <div className={styles.actionBannerContainer}>
          <ActionsBanner
            actions={[Action.LIVEBOARDS_DELETE]}
            target={Target.LIVEBOARDS}
            targetIds={selectedLiveboards}
            onClose={() => setSelectedLiveboards([])}
            onSuccess={() => {
              setSelectedLiveboards([]);
              onRefresh();
            }}
          />
        </div>
      )}
      <Modal
        isOpen={addLiveboard}
        onClose={() => setAddLiveboard(false)}
        title={formatMessage({ id: 'page.config.liveboards.form.creation-liveboard' })}
        width={560}
      >
        <CreateLiveboardForm
          mode="creation"
          onSuccess={(id) => {
            if (id) {
              navigate(`/${ADMIN_PANEL_ROUTE}/${LIVEBOARDS_ROUTE}/${id}`);
            }
          }}
        />
      </Modal>
    </Plate>
  );
};
