import { AxiosError } from 'axios';
import { useAsyncFn } from 'react-use';

import { Permissions } from 'types/v3';
import { logError } from 'utils/datadog';

import {
  addPermissionsForUser,
  addUsersToGroup,
  deleteLiveboard,
  deleteUserFromGroup,
  removeClientFromGroup,
  removeLiveboardFromGroup,
  removePermissionForUser,
  updateUser,
} from '../../api';

interface Props {
  ids: string[];
}

export const useRequests = ({ ids }: Props) => {
  const handleError = (err: AxiosError) => {
    // We do not want to show an error message if the response is 404, it just
    // means nothing happened
    if (err?.response?.status === 404) {
      return Promise.resolve();
    }
    const nErr = new Error('Error while performing an action in the action banner', { cause: err });
    logError(nErr);
    throw err;
  };

  const [{ loading: isAddingUsers, error: errorAddingUsers }, doAddToUserGroup] = useAsyncFn(
    (userGroupIds: string[]) =>
      Promise.all(
        userGroupIds
          .map((userGroupId) => addUsersToGroup({ userGroupId, userIds: ids }).catch(handleError))
          .flat(),
      ),
    [ids],
  );

  const [{ loading: isRemovingUsers, error: errorRemovingUsers }, doRemoveFromUserGroup] =
    useAsyncFn(
      (userGroupIds: string[]) =>
        Promise.all(
          userGroupIds
            .map((userGroupId) =>
              ids.map((id) => deleteUserFromGroup({ userGroupId, userId: id }).catch(handleError)),
            )
            .flat(),
        ),
      [ids],
    );

  const [{ loading: isEditingUsers, error: errorEditingUsers }, doEditUsers] = useAsyncFn(
    (userBody: { enabled: boolean; customerId: string }) =>
      Promise.all(ids.map((id) => updateUser({ userId: id, userBody }))),
    [ids],
  );

  const [{ loading: isRemovingClients, error: errorRemovingClients }, doRemoveClients] = useAsyncFn(
    (userGroupId: string) =>
      Promise.all(
        ids.map((id) => removeClientFromGroup({ userGroupId, clientId: id }).catch(handleError)),
      ),
    [ids],
  );

  const [{ loading: isRemovingLiveboards, error: errorRemovingLiveboards }, doRemoveLiveboards] =
    useAsyncFn(
      (userGroupId: string) =>
        Promise.all(
          ids.map((l) =>
            removeLiveboardFromGroup({ userGroupId, liveboardId: l }).catch(handleError),
          ),
        ),
      [ids],
    );

  const [{ loading: isRemovingPermission, error: errorRemovingPermission }, doRemovePermission] =
    useAsyncFn(
      (permissions: Permissions[]) =>
        Promise.all(
          ids
            .map((userId) =>
              permissions.map((p) =>
                removePermissionForUser({ userId, permission: p }).catch(handleError),
              ),
            )
            .flat(),
        ),
      [ids],
    );

  const [{ loading: isAddingPermission, error: errorAddingPermission }, doAddPermission] =
    useAsyncFn(
      (permissions: Permissions[]) =>
        Promise.all(
          ids.map((userId) => addPermissionsForUser({ userId, permissions }).catch(handleError)),
        ),
      [ids],
    );

  const [{ loading: isDeletingLiveboards, error: errorDeletingLiveboards }, doDeleteLiveboards] =
    useAsyncFn(
      () =>
        Promise.all(
          ids.map((thoughtspotLiveboardId) =>
            deleteLiveboard({ thoughtspotLiveboardId }).catch(handleError),
          ),
        ),
      [ids],
    );

  return {
    addUsersInGroup: { isAddingUsers, errorAddingUsers, doAddToUserGroup },
    removeUsersFromGroup: { isRemovingUsers, errorRemovingUsers, doRemoveFromUserGroup },
    editUsers: { isEditingUsers, errorEditingUsers, doEditUsers },
    removeClientsFromGroup: { isRemovingClients, errorRemovingClients, doRemoveClients },
    removeLiveboardsFromGroup: {
      isRemovingLiveboards,
      errorRemovingLiveboards,
      doRemoveLiveboards,
    },
    removePermissionFromUser: {
      isRemovingPermission,
      errorRemovingPermission,
      doRemovePermission,
    },
    addPermissionFromUser: {
      isAddingPermission,
      errorAddingPermission,
      doAddPermission,
    },
    deleteLiveboards: {
      isDeletingLiveboards,
      errorDeletingLiveboards,
      doDeleteLiveboards,
    },
  };
};
