import { Loader, Button, Typography, Divider, Icon } from '@insurely/ui';

import { useContext } from 'react';

import { useParams } from 'react-router-dom';

import { useAsync, useAsyncFn } from 'react-use';

import { getCollectionsInfo } from 'client/client';
import EventLog from 'components/EventLog/EventLog';
import { findAndRemoveDuplicates, getEventLogRowsFromCollections } from 'components/EventLog/utils';
import { InsuranceListing } from 'components/InsuranceListing/InsuranceListing';

import { Page } from 'components/Page/Page';
import { PensionListing } from 'components/PensionListing/PensionListing';
import { getItemsInCollections } from 'contexts/session/helpers';
import UserContext from 'contexts/user/UserContext';
import { useCompanies } from 'hooks/useCompanies';
import { FormattedMessage, useIntl } from 'translations';

import { InsuranceResponse } from 'types/insurance';

import { FinancialProduct } from 'types/wealth';
import { logError } from 'utils/datadog';

import { ProductLine } from 'utils/product-line';

import styles from './collectionInfoPage.module.css';
import ContactDetails from './ContactDetails/ContactDetails';
import { ExtraInformation } from './ExtraInformation/ExtraInformation';

export const CollectionInfoPage = () => {
  const { formatMessage } = useIntl();
  const { getCompanyDisplayName } = useCompanies();
  const { collectionIdList, collId } = useParams() as {
    collectionIdList: string;
    collId?: string;
  };
  const { productLine } = useContext(UserContext);

  const [{ value: items, loading: isFetchingItemsInCollection }, doFetchItemsInCollections] =
    useAsyncFn(
      (ids: Array<{ collectionId: string; creationDate: string }>) =>
        productLine
          ? getItemsInCollections(ids, productLine).catch((err) => {
              const nErr = new Error('Failed to get items in collections', { cause: err });
              logError(nErr, { collectionIds: ids, productLine });
              throw nErr;
            })
          : Promise.resolve(undefined),
      [productLine],
    );

  const collectionIds = collectionIdList ? collectionIdList.split('_') : [collId || ''];

  const { value: collections, loading: isFetchingCollection } = useAsync(async () => {
    const resp = await getCollectionsInfo(collectionIds).catch((err) => {
      const nErr = new Error('Failed to get collections info', { cause: err });
      logError(nErr, { collectionIds });
      throw nErr;
    });
    const ids = resp.map(({ collectionId, creationDate }) => ({
      collectionId,
      creationDate,
    }));
    doFetchItemsInCollections(ids);
    return resp;
  });

  if (isFetchingItemsInCollection || isFetchingCollection) {
    return (
      <Page title={formatMessage({ id: 'Insurances' })} goback>
        {collectionIdList && (
          <Typography className={styles.title} component="h1" variant="Headline-3">
            {formatMessage({ id: 'Collections' })}
          </Typography>
        )}
        <Loader.Content />
      </Page>
    );
  }

  if (!collectionIdList || !items || !collections) {
    return null;
  }

  const { filteredItems, updatedCollections } = findAndRemoveDuplicates(items, collections);

  const eventLogRows = getEventLogRowsFromCollections({
    collections: updatedCollections,
    productLine,
    formatMessage,
    getCompanyDisplayName,
  });

  return (
    <Page title={formatMessage({ id: 'Insurances' })} className={styles.page} goback>
      <Typography component="h1" variant="Headline-3" className={styles.headline}>
        <FormattedMessage id="Collections" />
        <Button
          variant="secondary"
          size="medium"
          icon={<Icon name="download" size={20} />}
          iconPosition="right"
          onClick={() => window.print()}
          style={{ fontFamily: 'Insurely sans' }}
        >
          <FormattedMessage id="page.collection.export-details" />
        </Button>
      </Typography>

      <ContactDetails collections={collections ?? []} />
      <ExtraInformation collectionId={collectionIds[0]} />

      {filteredItems.length > 0 && productLine === ProductLine.INSURANCE && (
        <InsuranceListing insurances={filteredItems as InsuranceResponse[]} />
      )}

      {filteredItems.length > 0 && productLine === ProductLine.PENSION && (
        <PensionListing pensions={filteredItems as FinancialProduct[]} />
      )}

      <div className={styles.hideOnPrint}>
        <Divider />
        <EventLog rows={eventLogRows} />
      </div>
    </Page>
  );
};
