import { CompanyLogo, Typography, Button, IconButton, Icon } from '@insurely/ui';

import classNames from 'classnames';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import { useMeasure, useWindowSize } from 'react-use';

import { SESSIONS_ROUTE } from 'constants/routes';

import UserContext from 'contexts/user/UserContext';
import { useNavigateQueryParams } from 'hooks/useNavigateQueryParams';
import { useIntl } from 'translations';
import { InsuranceResponse } from 'types/insurance';
import { getDisplayType } from 'utils/insurance';

import styles from './insuranceTabs.module.css';
import { getDisplayName } from './utils/getDisplayName';
import { getInsurancesLists } from './utils/getInsurancesLists';

const DESKTOP_LEFT_MENU_WIDTH = 470;
const MOBILE_AND_TABLET_BACK_BUTTON_OFFSET = 160;

interface Props {
  insurances?: InsuranceResponse[];
}

export const InsuranceTabs = ({ insurances }: Props) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigateQueryParams();
  const { pathname } = useLocation();
  const { externalId } = useParams();
  const { width: windowWidth } = useWindowSize();

  const [tabsContainerRef, { width: tabsContainerWidth }] = useMeasure<HTMLDivElement>();
  const currentInsuranceElement = useRef<HTMLButtonElement>(null);
  const [transformValue, setTransformValue] = useState(0);
  const { config } = useContext(UserContext);

  const movingStep = useMemo(() => {
    if (windowWidth <= 565) {
      return 80;
    }
    if (windowWidth <= 770) {
      return 120;
    }
    return 220;
  }, [windowWidth]);

  const insurancesLists = getInsurancesLists(insurances);

  // Used to decide wether or not to show the right arrow after transform
  const tabsOutsideOfWindow = useMemo(
    () => tabsContainerWidth > windowWidth,
    [tabsContainerWidth, windowWidth],
  );

  // Used to transform the current tab into view
  const setTransformValueForCurrentTab = () => {
    const currentInsuranceClientRect = currentInsuranceElement.current?.getBoundingClientRect();

    if (currentInsuranceClientRect) {
      const boundingClientRect = currentInsuranceClientRect;

      const leftMenuOffset =
        windowWidth >= 990 ? DESKTOP_LEFT_MENU_WIDTH : MOBILE_AND_TABLET_BACK_BUTTON_OFFSET;

      const isOutsideToTheRight = boundingClientRect.right - windowWidth > 10;
      const isOutsideToTheLeft = boundingClientRect.left < leftMenuOffset;

      // A part or whole of the current tab is outside the window to the right
      if (isOutsideToTheRight) {
        const rightButtonOffset = tabsOutsideOfWindow ? 80 : 0;

        const currentTabWidthOutsideOfWindow =
          boundingClientRect.right + rightButtonOffset - windowWidth;

        const stepsRequiredToDisplayCurrentTab = Math.ceil(
          currentTabWidthOutsideOfWindow / movingStep,
        );

        setTransformValue(transformValue + stepsRequiredToDisplayCurrentTab * movingStep);
      }

      // A part or whole of the current tab is outside the tab container to the left
      if (isOutsideToTheLeft) {
        const currentTabWidthOutsideContainer = leftMenuOffset - boundingClientRect.left;

        const stepsRequiredToDisplayCurrentTab = Math.ceil(
          currentTabWidthOutsideContainer / movingStep,
        );

        setTransformValue(transformValue - stepsRequiredToDisplayCurrentTab * movingStep);
      }
    }
  };

  const canScrollLeft = useMemo(() => {
    const leftMenuOffset =
      windowWidth >= 990 ? DESKTOP_LEFT_MENU_WIDTH : MOBILE_AND_TABLET_BACK_BUTTON_OFFSET;

    return tabsContainerWidth - (transformValue + windowWidth - leftMenuOffset) > 0;
  }, [tabsContainerWidth, transformValue, windowWidth]);

  // Used to check the visibility of the current tab when changing tabs
  useEffect(() => {
    setTransformValueForCurrentTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleClickScrollLeft = () => {
    setTransformValue(transformValue - movingStep);
  };

  const handleClickScrollRight = () => {
    setTransformValue(transformValue + movingStep);
  };

  const handleTabClick = (collectionId: string, id: string) => {
    navigate(`/${SESSIONS_ROUTE}/${collectionId}/insurance/${id}`);
  };

  const getCurrentInsurance = (id: string) => (externalId === id ? currentInsuranceElement : null);

  return (
    <div className={styles.tabsContainer}>
      <div className={styles.previousButtonContainer}>
        <Button
          className={styles.backButton}
          variant="tertiary"
          onClick={() => navigate(-1)}
          icon={<Icon name="arrow-left" size={20} />}
        >
          {formatMessage({ id: 'shared.go-back' })}
        </Button>
      </div>
      <div>
        {transformValue > 0 && (
          <div className={classNames(styles.scrollButtonContainer, styles.leftScroll)}>
            <IconButton
              aria-label="scroll-left"
              icon={<Icon name="chevron-left" size={24} />}
              onClick={handleClickScrollLeft}
            />
          </div>
        )}
        <div
          ref={tabsContainerRef}
          className={styles.tabsContent}
          style={{ transition: 'left 0.3s', left: `-${transformValue}px`, position: 'relative' }}
        >
          {insurancesLists
            .flatMap((i) => i)
            .map((insurance) => (
              <button
                key={insurance.insurance.externalId}
                ref={getCurrentInsurance(insurance.insurance.externalId)}
                type="button"
                className={classNames(
                  styles.buttonTab,
                  externalId === insurance.insurance.externalId ? styles.active : styles.notActive,
                )}
                onClick={() =>
                  handleTabClick(insurance.collectionId as string, insurance.insurance.externalId)
                }
              >
                <CompanyLogo company={insurance.insurance.insuranceCompany} width="16px" />
                <div className={styles.tabTextContainer}>
                  <Typography component="p" variant="ParagraphCaption">
                    <strong>
                      {getDisplayType(insurance.insurance, formatMessage, config.market)}
                    </strong>
                  </Typography>
                  <Typography
                    component="p"
                    variant="ParagraphTiny"
                    className={classNames(styles.tabText, 'ph-no-capture')}
                  >
                    {getDisplayName(insurance.insurance)}
                  </Typography>
                </div>
              </button>
            ))}
        </div>
        {tabsOutsideOfWindow && (
          <div className={classNames(styles.scrollButtonContainer, styles.rightScroll)}>
            <IconButton
              disabled={!canScrollLeft}
              aria-label="scroll-right"
              icon={<Icon name="chevron-right" size={24} />}
              onClick={handleClickScrollRight}
            />
          </div>
        )}
      </div>
    </div>
  );
};
