import deepEqual from 'deep-equal';
import { useSearchParams } from 'react-router-dom';
import { useMount } from 'react-use';

import { FilterQuery } from '../types';
import { getQueryFromUrl, transformFilterQueryToUrl } from '../utils';

const FILTERS_STORAGE_KEY = 'collectionsFilters';

export const useStoreFilters = () => {
  const [, setSearchParams] = useSearchParams();

  const getFilters = () => {
    const filtersFromSession = sessionStorage.getItem(FILTERS_STORAGE_KEY);

    if (filtersFromSession) {
      return JSON.parse(filtersFromSession) as FilterQuery;
    }

    return getQueryFromUrl();
  };

  const setFilters = (filters: FilterQuery) =>
    sessionStorage.setItem(FILTERS_STORAGE_KEY, JSON.stringify(filters));

  useMount(() => {
    const filtersFromSession = sessionStorage.getItem(FILTERS_STORAGE_KEY);
    const filtersFromUrl = getQueryFromUrl();

    if (filtersFromSession && !deepEqual(filtersFromUrl, JSON.parse(filtersFromSession))) {
      setSearchParams(transformFilterQueryToUrl(JSON.parse(filtersFromSession)), { replace: true });
      setFilters(JSON.parse(filtersFromSession));
    } else if (!filtersFromSession) {
      setFilters(filtersFromUrl);
    }
  });

  return {
    getFilters,
    setFilters,
  };
};
