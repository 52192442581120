import { newRequestWithToken } from 'client/client';
import { Wealth } from 'types/wealth';

export const getWealthProducts = async (collectionId: string): Promise<Wealth> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/wealth/${collectionId}`,
  });
  const data = res.data as Wealth;

  return {
    ...data,
    financialProducts: data.financialProducts.map((fP) => ({
      ...fP,
      collectionId: data.collectionId,
      collectionDate: new Date(data.creationDate).toISOString(),
    })),
  };
};
