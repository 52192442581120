import { Icon, Typography } from '@insurely/ui';

import { useNavigate } from 'react-router-dom';

import styles from './breadcrumb.module.css';

interface Props {
  crumbs: Array<{ label: string; link?: string }>;
}

export const Breadcrumbs = ({ crumbs }: Props) => {
  const navigate = useNavigate();

  return (
    <nav aria-label="breadcrumb" className={styles.nav}>
      <ol className={styles.breadcrumbs}>
        {crumbs.map((crumb, index) => (
          <li key={crumb.label} className={styles.breadcrumbItem}>
            {crumb.link ? (
              <button
                type="button"
                className={styles.crumbLink}
                onClick={() => {
                  if (crumb.link) {
                    navigate(crumb.link);
                  }
                }}
              >
                <Typography
                  variant={index < crumbs.length - 1 ? 'ParagraphBodySmall' : 'ParagraphTitleSmall'}
                  component="p"
                >
                  {crumb.label}
                </Typography>
              </button>
            ) : (
              <Typography variant="ParagraphTitleSmall" component="p">
                {crumb.label}
              </Typography>
            )}
            {index < crumbs.length - 1 && <Icon name="chevron-right" size={20} />}
          </li>
        ))}
      </ol>
    </nav>
  );
};
