import { zodResolver } from '@hookform/resolvers/zod';
import { Button, TextInput, CompanyLogo, Notification, Typography, Divider } from '@insurely/ui';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';

import { Link } from 'react-router-dom';
import { useAsync, useAsyncFn } from 'react-use';
import { z } from 'zod';

import { getUser, login } from 'client/client';
import {
  LOGIN_ROUTE,
  OVERVIEW_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  REGISTER_ROUTE,
  LOGIN_MAGIC_LINK_ROUTE,
} from 'constants/routes';

import { useNavigateQueryParams } from 'hooks/useNavigateQueryParams';

import { getStoredAuthToken } from 'services/authToken';
import { FormattedMessage, IntlShape, useIntl } from 'translations';

import { logError } from 'utils/datadog';
import { logout } from 'utils/logout';

import { PostHogAction, PostHogCategory, postHogCapture } from 'utils/posthog';

import { getSSOLoginUrl } from './api';
import styles from './login.module.css';

interface FormValues {
  email: string;
  password: string;
}

const schema = (formatMessage: IntlShape['formatMessage']) =>
  z.object({
    email: z.string().email({ message: formatMessage({ id: 'Please enter a valid e-mail' }) }),
    password: z.string().min(1, { message: formatMessage({ id: 'Please enter a password' }) }),
  });

const ErrorBlock = ({ message }: { message?: string }) => {
  const { formatMessage } = useIntl();

  if (!message) {
    return null;
  }

  switch (message) {
    case 'NOT_ALLOWED':
      return (
        <Notification
          className={styles.notification}
          description={formatMessage({ id: 'page.login.error.not-allowed.description' })}
          headline={formatMessage({ id: 'page.login.error.not-allowed.title' })}
          status="error"
        />
      );
    case 'TOO_MANY_REQUESTS':
      return (
        <Notification
          className={styles.notification}
          description={formatMessage({ id: 'page.login.error.too-many-logins.description' })}
          headline={formatMessage({ id: 'page.login.error.too-many-logins.title' })}
          status="error"
        />
      );
    default:
      return <Notification className={styles.notification} headline={message} status="error" />;
  }
};

export const Login: React.FC = () => {
  const navigate = useNavigateQueryParams();
  const { formatMessage } = useIntl();

  const {
    register,
    handleSubmit,
    trigger,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    delayError: 500,
    resolver: zodResolver(schema(formatMessage)),
  });

  // Check if the user is already logged in to redirect to the home page if
  // that is the case
  const { error: fetchUserError } = useAsync(() =>
    getUser()
      .then(() => navigate(`/${OVERVIEW_ROUTE}`, { cleanQueryParams: true }))
      .catch((err) => {
        const nErr = new Error('Failed to get current user', { cause: err });
        if (err.reponse.status !== 401) {
          logError(nErr);
        }
        throw nErr;
      }),
  );

  const [{ error, loading }, doLogin] = useAsyncFn(({ email, password }: FormValues) =>
    login(email, password)
      .then(() => {
        postHogCapture(`tracking:${PostHogAction.STATE}`, {
          action: PostHogAction.STATE,
          category: PostHogCategory.GENERAL,
          object: 'userLoggedIn',
        });
        return navigate(`/${OVERVIEW_ROUTE}`, { cleanQueryParams: true });
      })
      .catch((err) => {
        const { message, status } = err?.response?.data ?? {};
        if (message === 'Not allowed') {
          throw new Error('NOT_ALLOWED');
        }
        if (['TOO_MANY_REQUESTS', 'UNAUTHORIZED'].includes(status)) {
          throw new Error(message);
        }
        const nErr = new Error(message, { cause: err });
        if (err.response.status !== 403) {
          logError(nErr);
        }
        throw nErr;
      }),
  );

  const [{ loading: isFetchingSsoUrl }, doGetSSOLoginUrl] = useAsyncFn(async () => {
    const url = await getSSOLoginUrl().catch((err) => {
      const nErr = new Error('Failed to get SSO login URL', { cause: err });
      logError(nErr);
      throw nErr;
    });

    window.location.href = url;
  });

  // If we get a 401 from the endpoint using a authToken, this means that the token
  // does not grant us access to SST. Reasons could be:
  // - using a prod token is test (or vice versa)
  // - being logged in with an account that does not grant us access to SST
  if (
    (fetchUserError as AxiosError | undefined)?.response?.status === 401 &&
    getStoredAuthToken()
  ) {
    return (
      <div className={styles.unauthorisedContainer}>
        <CompanyLogo company="se-demo" width="56px" aria-label="Insurely" />
        <Notification
          status="error"
          headline={formatMessage({ id: 'page.login.unauthorised.title' })}
          description={formatMessage({ id: 'page.login.unauthorised.description' })}
        />
        <Button onClick={logout}>
          <FormattedMessage id="page.login.unauthorised.log-out" />
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.formContainer}>
      <form className={styles.form} onSubmit={handleSubmit(doLogin)}>
        <CompanyLogo company="se-demo" width="56px" aria-label="Insurely" />
        <div className={styles.header}>
          <Typography component="h1" variant="Headline-4">
            {formatMessage({ id: 'page.login.title' })}
          </Typography>
          <Typography component="p" variant="ParagraphBodySmall">
            {formatMessage({ id: 'page.login.description' })}
          </Typography>
        </div>

        <ErrorBlock message={error?.message} />

        <TextInput
          {...register('email')}
          label={formatMessage({ id: 'page.login.email' })}
          error={!!errors.email}
          helperText={errors.email?.message}
          onBlur={() => {
            if (getValues('email')) {
              trigger('email');
            }
          }}
          autoFocus
        />
        <TextInput
          {...register('password')}
          label={formatMessage({ id: 'page.login.password' })}
          type="password"
          error={!!errors.password}
          onBlur={() => {
            if (getValues('password')) {
              trigger('password');
            }
          }}
          helperText={errors.password?.message}
        />
        <Link
          className={classNames(styles.link, styles.forgotPasswordLink)}
          to={FORGOT_PASSWORD_ROUTE}
          style={{ alignSelf: 'flex-start', marginTop: '-8px' }}
        >
          {formatMessage({ id: 'page.login.link.forgot-password' })}
        </Link>
        <Button variant="primary" type="submit" loading={loading} disabled={loading} fullWidth>
          {formatMessage({ id: 'page.login.sign-in-button' })}
        </Button>
      </form>
      <div className={styles.secondaryLoginContainer}>
        <Divider />
        <Button
          variant="secondary"
          fullWidth
          icon={<CompanyLogo width={20} company="gl-microsoft" />}
          onClick={doGetSSOLoginUrl}
          disabled={isFetchingSsoUrl}
          loading={isFetchingSsoUrl}
        >
          <FormattedMessage id="page.login.sso-sign-in-button" />
        </Button>
        <Button variant="secondary" onClick={() => navigate(LOGIN_MAGIC_LINK_ROUTE)} fullWidth>
          {formatMessage({ id: 'page.login.link.sign-in-email' })}
        </Button>
        <Divider />
      </div>
      <div className={styles.linksContainer}>
        <Link to={`/${LOGIN_ROUTE}/${REGISTER_ROUTE}`}>
          {formatMessage({ id: 'page.login.link.create-account' })}
        </Link>
      </div>
    </div>
  );
};
