import { FormatXMLElementFn } from 'intl-messageformat';
import {
  MessageDescriptor,
  PrimitiveType,
  useIntl as useIntlReactIntl,
  FormattedMessage as FormattedMessageIntl,
} from 'react-intl';
import { useSearchParams } from 'react-router-dom';

interface FormattedMessageProps extends MessageDescriptor {
  values?:
    | Record<
        string,
        React.ReactNode | PrimitiveType | FormatXMLElementFn<React.ReactNode, React.ReactNode>
      >
    | undefined;
}

export const useIntl = () => {
  const { formatMessage: formatMessageIntl } = useIntlReactIntl();
  const [searchParams] = useSearchParams();

  const formatMessage = (
    descriptor: MessageDescriptor,
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>> | undefined,
  ) => {
    if (searchParams.get('translations') === 'raw') {
      return descriptor.id as string;
    }
    return formatMessageIntl(descriptor, { br: (...chunks) => `${chunks}\n`, ...values });
  };

  return {
    ...useIntlReactIntl,
    formatMessage,
  };
};

export const FormattedMessage = ({ id, defaultMessage, values }: FormattedMessageProps) => {
  const [searchParams] = useSearchParams();

  if (searchParams.get('translations') === 'raw') {
    return <div>{id}</div>;
  }

  return (
    <FormattedMessageIntl
      id={id}
      defaultMessage={defaultMessage}
      values={{
        br: (...chunks) => (
          <>
            {chunks}
            <br />
            <br />
          </>
        ),
        b: (...chunks) => <strong>{chunks}</strong>,
        ...values,
      }}
    />
  );
};
