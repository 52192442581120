import { CompanyLogo, Typography } from '@insurely/ui';

import { useCompanies } from 'hooks/useCompanies';

import styles from './companyTag.module.css';

export const CompanyTag = ({ company }: { company: string }) => {
  const { getCompanyDisplayName } = useCompanies();
  const companyName = getCompanyDisplayName(company);

  return (
    <div className={styles.tag}>
      <div className={styles.logoContainer}>
        <CompanyLogo company={company} width={18} />
      </div>
      <div className={styles.textContainer}>
        <Typography component="p" variant="ParagraphTiny" className={styles.text}>
          {companyName}
        </Typography>
      </div>
    </div>
  );
};
