import { Button, Icon } from '@insurely/ui';
import { useState } from 'react';
import { CSVDownload } from 'react-csv';

import { useIntl } from 'translations';

import { useFormatting } from 'translations/useFormatting';
import { CollectionsTableRow } from 'types/v3';
import { handleExport } from 'views/Collections/utils';

export const ExportButton = ({
  rows,
  selectedRow,
  columns,
}: {
  rows: CollectionsTableRow[];
  selectedRow: string[];
  columns: { id: string; name: string; align: 'left' | 'right' }[];
}) => {
  const { formatMessage } = useIntl();
  const { formatDate } = useFormatting();
  const [csvData, setCSVData] = useState<string[][]>([]);
  const [startDownload, setStartDownload] = useState<boolean>(false);

  return (
    <>
      <Button
        variant="secondary"
        size="small"
        icon={<Icon name="download" size={16} />}
        iconPosition="left"
        style={{ marginBottom: '24px' }}
        onClick={() => {
          setCSVData(handleExport({ rows, selectedRow, columns, formatMessage, formatDate }));
          setStartDownload(true);
          setTimeout(() => setStartDownload(false), 500);
        }}
      >
        {formatMessage({ id: 'Export collections' })}
      </Button>

      {startDownload && <CSVDownload data={csvData} target="_self" />}
    </>
  );
};
