import { CompanyLogo, Icon, Loader, Plate, Typography } from '@insurely/ui';
import classNames from 'classnames';

import { FormattedMessage } from 'react-intl';

import tableStyles from 'styles/table.module.css';
import { useIntl } from 'translations';
import { useFormatting } from 'translations/useFormatting';

import styles from './eventLog.module.css';

export interface EventLogRow {
  icon: {
    type: 'companyLogo' | 'sms-icon';
    key: string;
  };
  label: string;
  status: {
    icon: string;
    text: string;
  };
  creationDate: number;
}

export default function EventLog({ rows }: { rows: EventLogRow[] }) {
  const { formatTimeAgo } = useFormatting();
  const { formatMessage } = useIntl();

  function getStatusIcon(type: string) {
    if (type === 'success') {
      return <Icon name="check" size={20} color="var(--green)" />;
    }
    if (type === 'failed') {
      return <Icon name="close" size={20} color="var(--red-1)" />;
    }
    return (
      <span className={styles.loaderWrapper}>
        <Loader.Content size="small" />
      </span>
    );
  }

  if (!rows.length) {
    return null;
  }

  return (
    <Plate className={styles.plate}>
      <div className={styles.tableHeadline}>
        <Typography variant="Headline-7" component="p">
          <FormattedMessage id="shared.event-log.title" />
        </Typography>
      </div>
      <table className={tableStyles.table}>
        <thead>
          <tr className={classNames(tableStyles.head, styles.tableHeader)}>
            <th
              aria-labelledby={formatMessage({ id: 'shared.word.event' })}
              style={{ width: '25%' }}
            />
            <th
              aria-labelledby={formatMessage({ id: 'shared.word.status' })}
              style={{ width: '40%' }}
            >
              <Typography component="p" variant="Label-3">
                <FormattedMessage id="shared.word.status" />
              </Typography>
            </th>
            <th
              aria-labelledby={formatMessage({ id: 'shared.word.date' })}
              style={{ width: '15%' }}
            >
              <Typography component="p" variant="Label-3">
                <FormattedMessage id="shared.word.date" />
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {rows
            .sort((a, b) => (a.creationDate < b.creationDate ? 1 : -1))
            .map((row) => (
              <tr key={row.creationDate} className={styles.tableRow}>
                <td>
                  {row.icon.type === 'companyLogo' && (
                    <CompanyLogo company={row.icon.key} width={24} className={styles.companyLogo} />
                  )}
                  {row.icon.type === 'sms-icon' && <Icon size={22} name="phone-message" />}
                  <Typography component="span" variant="ParagraphCaption">
                    {row.label}
                  </Typography>
                </td>
                <td>
                  <div className={styles.statusRow}>
                    {getStatusIcon(row.status.icon)}
                    <Typography component="p" variant="ParagraphCaption">
                      {row.status.text}
                    </Typography>
                  </div>
                </td>
                <td>
                  <Typography component="p" variant="ParagraphCaption">
                    {formatTimeAgo(row.creationDate)}
                  </Typography>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </Plate>
  );
}
