import { newRequest, newRequestWithToken } from 'client/client';

export const getSSOLoginUrl = async (): Promise<string> => {
  const res = await newRequest({
    method: 'GET',
    url: '/sso/microsoft/hub',
  });
  return res?.data || res;
};

export const validateSSOLogin = async (token: string): Promise<string> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/user/hub/sso/login/${token}`,
  });
  return res?.data || res;
};
