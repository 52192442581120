import { MultiSelect } from '@insurely/ui';
import { useState } from 'react';

import { useIntl } from 'translations';

import styles from '../actionBanner.module.css';

interface Props {
  isPending: boolean;
  disabled: boolean;
  onClick: (action: UsersActionsType) => void;
}

export enum UsersActionsType {
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
}

export const UsersActions = ({ disabled, isPending, onClick }: Props) => {
  const { formatMessage } = useIntl();
  const [action, setAction] = useState<UsersActionsType[]>([]);

  const handleOnChange = (val: UsersActionsType[]) => {
    if (val.length <= 1) {
      setAction(val);
    } else {
      setAction([val[1]]);
    }
  };

  return (
    <div className={styles.action}>
      <MultiSelect
        fullWidth
        variant="small"
        id="action"
        options={[
          {
            label: formatMessage({ id: 'page.config.action-banner.users-action.enable' }),
            value: UsersActionsType.ENABLE,
          },
          {
            label: formatMessage({ id: 'page.config.action-banner.users-action.disable' }),
            value: UsersActionsType.DISABLE,
          },
        ]}
        onChange={handleOnChange}
        placeholder={formatMessage({ id: 'page.config.action-banner.users-action.placeholder' })}
        value={action}
        primaryCallback={{
          label: formatMessage({ id: 'page.config.action-banner.users-action.submit' }),
          action: () => onClick(action[0] as UsersActionsType),
          loading: isPending,
          disabled: disabled || !action,
        }}
      />
    </div>
  );
};
