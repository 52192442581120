import { Icon, IconButton, Loader, Modal, Notification, Tabs, Typography } from '@insurely/ui';
import { useContext, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useAsync, useAsyncFn, useMount } from 'react-use';

import { Page } from 'components/Page/Page';
import { ADMIN_PANEL_ROUTE, CUSTOMERS_ROUTE } from 'constants/routes';
import UserContext from 'contexts/user/UserContext';
import { useIntl } from 'translations';
import { Permissions } from 'types/v3';
import { logError } from 'utils/datadog';
import { Breadcrumbs } from 'views/Config/comp/Breadcrumb/Breadcrumb';

import { useQueryParamTab } from 'views/Config/useQueryParamTab';

import {
  getUserGroupById,
  getUserGroupClients,
  getUserGroupLiveboards,
  getUserGroupPermissions,
  getUserGroupUsers,
  getCustomer,
} from '../../api';

import { CustomerConfigTab, UserGroupConfigTab as Tab } from '../../config.types';

import { ClientsContent } from './ClientsContent';
import { LiveboardsContent } from './LiveboardsContent';
import { MembersContent } from './MembersContent';
import { PermissionsContent } from './PermissionContent';
import styles from './userGroupConfig.module.css';
import { UserGroupOverview } from './UserGroupOverview';
import { UserGroupSettings } from './UserGroupSettings';

export const UserGroupConfig = () => {
  const { customerId, userGroupId } = useParams() as { customerId: string; userGroupId: string };
  const { formatMessage } = useIntl();
  const { user } = useContext(UserContext);

  const { tab: activeTab, setTab: setActiveTab } = useQueryParamTab<Tab>(Tab.OVERVIEW);

  const [isEditing, setIsEditing] = useState(false);
  const [isHoveringTitle, setIsHoveringTitle] = useState(false);

  const { value: customer } = useAsync(() =>
    getCustomer(customerId).catch((err) => {
      const nErr = new Error('Failed to fetch customer for user group config', { cause: err });
      logError(nErr, { customerId });
      throw nErr;
    }),
  );

  const [
    { loading: isFetchingUserGroup, value: userGroup, error: errorFetchingUserGroup },
    doFetchUserGroup,
  ] = useAsyncFn(() =>
    getUserGroupById(customerId, userGroupId).catch((err) => {
      const nErr = new Error('Failed to fetch user group for customer', { cause: err });
      logError(nErr, { customerId, userGroupId });
      throw nErr;
    }),
  );

  const [{ loading: isFetchingMembers, value: members }, doFetchUserGroupMembers] = useAsyncFn(() =>
    getUserGroupUsers(userGroupId).catch((err) => {
      const nErr = new Error('Failed to fetch users for user group', { cause: err });
      logError(nErr, { customerId, userGroupId });
      throw nErr;
    }),
  );

  const [{ loading: isFetchingPermissions, value: permissions }, doFetchUserGroupPermissions] =
    useAsyncFn(() =>
      getUserGroupPermissions(userGroupId).catch((err) => {
        const nErr = new Error('Failed to fetch permissions for user group', { cause: err });
        logError(nErr, { customerId, userGroupId });
        throw nErr;
      }),
    );

  const [{ loading: isFetchingClients, value: clients }, doFetchUserGroupClients] = useAsyncFn(() =>
    getUserGroupClients(userGroupId).catch((err) => {
      const nErr = new Error('Failed to fetch clients for user group', { cause: err });
      logError(nErr, { customerId, userGroupId });
      throw nErr;
    }),
  );

  const [{ loading: isFetchingLiveboards, value: liveboards }, doFetchUserGroupLiveboards] =
    useAsyncFn(() =>
      getUserGroupLiveboards(userGroupId).catch((err) => {
        const nErr = new Error('Failed to fetch liveboards for user group', { cause: err });
        logError(nErr, { customerId, userGroupId });
        throw nErr;
      }),
    );

  useMount(() => {
    Promise.all([
      doFetchUserGroup(),
      doFetchUserGroupMembers(),
      doFetchUserGroupClients(),
      doFetchUserGroupPermissions(),
      doFetchUserGroupLiveboards(),
    ]);
  });

  const userGroupTabs = useMemo(() => Object.keys(Tab).filter((g) => g !== Tab.PERMISSIONS), []);

  const isInsurelyAdmin = useMemo(
    () => user?.permissions.includes(Permissions.INSURELY_ADMIN),
    [user?.permissions],
  );

  const isFetchingEntities = isFetchingMembers || isFetchingClients || isFetchingLiveboards;

  if (errorFetchingUserGroup) {
    return (
      <Page title="">
        <Notification
          status="error"
          headline={formatMessage({
            id: 'page.config.user-group-config.error-fetching-group',
          })}
        />
      </Page>
    );
  }

  if (isFetchingUserGroup || !userGroup) {
    return (
      <Page title="">
        <div style={{ position: 'relative' }}>
          <Loader.Content size="default" />
        </div>
      </Page>
    );
  }

  const handleTabAccess = (tab: 'users' | 'liveboards' | 'clients') => {
    switch (tab) {
      case 'users':
        setActiveTab(Tab.USERS);
        break;
      case 'liveboards':
        setActiveTab(Tab.LIVEBOARDS);
        break;
      case 'clients':
        setActiveTab(Tab.CLIENTS);
        break;
      default:
        break;
    }
  };

  const renderTab = () => {
    switch (activeTab) {
      case Tab.OVERVIEW:
        return (
          <UserGroupOverview
            entities={{ users: members, clients, liveboards }}
            isFetchingEntities={isFetchingEntities}
            onAccess={handleTabAccess}
          />
        );
      case Tab.CLIENTS:
        return (
          <ClientsContent
            loading={isFetchingClients}
            clients={clients}
            onRefresh={doFetchUserGroupClients}
          />
        );
      case Tab.PERMISSIONS:
        return <PermissionsContent loading={isFetchingPermissions} permissions={permissions} />;
      case Tab.LIVEBOARDS:
        return (
          <LiveboardsContent
            loading={isFetchingLiveboards}
            liveboards={liveboards}
            onRefresh={doFetchUserGroupLiveboards}
          />
        );
      case Tab.USERS:
      default:
        return (
          <MembersContent
            loading={isFetchingMembers}
            members={members}
            onRefresh={() => {
              doFetchUserGroupMembers();
            }}
          />
        );
    }
  };

  return (
    <Page title={userGroup.name}>
      <Breadcrumbs
        crumbs={
          [
            isInsurelyAdmin && {
              label: formatMessage({ id: 'page.config.user-group-config.breadcrumb.customers' }),
              link: `/${ADMIN_PANEL_ROUTE}`,
            },
            {
              label: customer?.customerName,
              link: `/${ADMIN_PANEL_ROUTE}/${CUSTOMERS_ROUTE}/${customer?.customerId}?tab=${CustomerConfigTab.USER_GROUPS_TAB}`,
            },
            { label: userGroup.name },
          ].filter(Boolean) as Array<{ label: string; link: string }>
        }
      />
      <div
        className={styles.headTitle}
        onMouseEnter={() => setIsHoveringTitle(true)}
        onMouseLeave={() => setIsHoveringTitle(false)}
      >
        <div>
          <Typography component="h3" variant="Headline-3">
            {userGroup.name}
          </Typography>
          <Typography component="h3" variant="ParagraphBodySmall">
            {userGroup.description}
          </Typography>
        </div>
        {isHoveringTitle && (
          <IconButton icon={<Icon name="edit" size={18} />} onClick={() => setIsEditing(true)} />
        )}
      </div>
      <Tabs
        className={styles.tabsContainer}
        items={userGroupTabs.map((t) => ({
          label: formatMessage({ id: `page.config.user-group-config.tabs.${t.toLowerCase()}` }),
          value: t,
        }))}
        activeTab={activeTab}
        onChange={(tab: Tab) => setActiveTab(tab)}
      />
      <div className={styles.container}>{renderTab()}</div>
      <Modal
        isOpen={isEditing}
        onClose={() => setIsEditing(false)}
        title={formatMessage({ id: 'page.config.user-group-config.tabs.settings' })}
        width={560}
      >
        <UserGroupSettings
          userGroup={userGroup}
          onSuccess={() => {
            doFetchUserGroup();
            setIsEditing(false);
          }}
        />
      </Modal>
    </Page>
  );
};
