import { Button, Icon, IconButton, Loader, Modal, Plate, Typography } from '@insurely/ui';
import { useContext, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useAsync, useAsyncFn, useMount } from 'react-use';

import { ModifiedList } from 'components/ModifiedList/ModifiedList';
import { Page } from 'components/Page/Page';
import { ADMIN_PANEL_ROUTE, CUSTOMERS_ROUTE } from 'constants/routes';
import UserContext from 'contexts/user/UserContext';
import { useCompanies } from 'hooks/useCompanies';
import { FormattedMessage, useIntl } from 'translations';
import { Permissions } from 'types/v3';
import { logError } from 'utils/datadog';
import { snakeCaseToCamelCase, snakeCaseToKebabCase } from 'utils/formatters';
import { ConfirmationModal } from 'views/Config/comp/ConfirmationModal/ConfirmationModal';
import {
  ClientConfigApi,
  CustomerConfigTab,
  ExtendedClientConfig,
  ProductType,
} from 'views/Config/config.types';

import {
  createClientApiKey,
  deleteClientApiKey,
  getCustomer,
  getAllClientsOnCustomer,
  getExtendedClientConfig,
  updateClient,
} from '../../api';
import { Breadcrumbs } from '../../comp/Breadcrumb/Breadcrumb';

import { ClientForm } from '../Clients/comp/ClientForm/ClientForm';

import { computeProductLine, computeTarget } from '../Clients/utils';

import styles from './clientConfig.module.css';

const OtherApiKeys = ({
  otherApiKeys,
  onRefresh,
}: {
  otherApiKeys: string[];
  onRefresh: () => void;
}) => {
  const { clientId } = useParams() as {
    clientId: string;
    customerId: string;
  };

  const [keyToDelete, setKeyToDelete] = useState<string>();

  const [{ loading: isCreatingApiKey }, doCreateApiKey] = useAsyncFn(() =>
    createClientApiKey(clientId)
      .then(onRefresh)
      .catch((err) => {
        const nErr = new Error('Failed to create API key for client', { cause: err });
        logError(nErr, { clientId });
        throw nErr;
      }),
  );

  const { loading: isDeletingApiKey } = useAsync(() => {
    if (!keyToDelete) {
      return Promise.resolve();
    }
    return deleteClientApiKey({ clientId, apiKey: keyToDelete })
      .then(() => {
        onRefresh();
        setKeyToDelete(undefined);
      })
      .catch((err) => {
        const nErr = new Error('Failed to delete API key for client', { cause: err });
        logError(nErr, { clientId, apiKey: keyToDelete });
        throw nErr;
      });
  }, [keyToDelete]);

  return (
    <div className={styles.otherApiKeysContainer}>
      <Typography variant="ParagraphCaption" component="p" style={{ color: 'var(--grey-1)' }}>
        <FormattedMessage id="page.config.client-config.field.other-api-keys" />
      </Typography>
      <div className={styles.otherApiKeysList}>
        {otherApiKeys.length === 0 && '-'}
        {otherApiKeys.map((key) => (
          <div key={key} className={styles.otherApiKeysItem}>
            <Typography variant="ParagraphCaption" component="p">
              {key}
            </Typography>
            <IconButton
              loading={isDeletingApiKey && keyToDelete === key}
              icon={<Icon name="delete" size={14} />}
              onClick={() => setKeyToDelete(key)}
            />
          </div>
        ))}
        <Button
          size="small"
          onClick={doCreateApiKey}
          loading={isCreatingApiKey}
          style={{ marginTop: 'var(--space-2)' }}
        >
          <FormattedMessage id="page.config.client-config.field.other-api-keys.add" />
        </Button>
      </div>
    </div>
  );
};

export default function ClientConfigPage() {
  const { clientId, customerId } = useParams() as {
    clientId: string;
    customerId: string;
  };
  const { formatMessage } = useIntl();
  const { getCompanyDisplayName } = useCompanies();
  const { user } = useContext(UserContext);

  const [isEditing, setIsEditing] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { value: customer } = useAsync(() =>
    getCustomer(customerId).catch((err) => {
      const nErr = new Error('Failed to fetch customer for client config', { cause: err });
      logError(nErr, { customerId });
      throw nErr;
    }),
  );

  const { value: clients } = useAsync(() =>
    getAllClientsOnCustomer(customerId).catch((err) => {
      const nErr = new Error('Failed to fetch clients in client config', { cause: err });
      logError(nErr, { customerId });
      throw nErr;
    }),
  );

  const [{ loading: isFetchingConfig, value: client }, doFetchExtendedClient] = useAsyncFn(() =>
    getExtendedClientConfig(clientId).catch((err) => {
      const nErr = new Error('Failed to fetch client config', { cause: err });
      logError(nErr, { customerId, clientId });
      throw nErr;
    }),
  );

  const isInsurelyAdmin = useMemo(
    () => user?.permissions.includes(Permissions.INSURELY_ADMIN),
    [user?.permissions],
  );

  useMount(() => {
    doFetchExtendedClient();
  });

  const fieldsToIgnore = useMemo(() => {
    // TODO: Remove apiKey when BE stops returning it
    const base = ['logotype', 'apiKey', 'apiKeys'];

    if (client && computeTarget(client.productTypes[0] as ProductType) === 'api') {
      base.push('baseUrl');
    }
    return base;
  }, [client]);

  const listRows = useMemo(() => {
    if (!client) {
      return [];
    }

    const renderFieldValue = (
      field: keyof ExtendedClientConfig,
      value: ExtendedClientConfig[keyof ExtendedClientConfig],
    ) => {
      const sortAndJoin = (t: string[]) => t.sort((a, b) => a.localeCompare(b)).join(', ');

      switch (field) {
        case 'productTypes': {
          const val = value as ExtendedClientConfig[typeof field];
          if (val.length === 0) {
            return '-';
          }
          return sortAndJoin(
            val.map((t) => formatMessage({ id: `shared.productType.${snakeCaseToCamelCase(t)}` })),
          );
        }
        case 'insuranceTypes':
        case 'insuranceSubTypes': {
          const val = value as ExtendedClientConfig[typeof field];
          if (val.length === 0) {
            return '-';
          }
          return sortAndJoin(val.map((t) => formatMessage({ id: snakeCaseToCamelCase(t) })));
        }
        case 'wealthTypes': {
          const val = value as ExtendedClientConfig[typeof field];
          if (val.length === 0) {
            return '-';
          }
          return sortAndJoin(val.map((t) => formatMessage({ id: t })));
        }
        case 'clientCompanies': {
          const val = value as ExtendedClientConfig[typeof field];
          if (val.length === 0) {
            return '-';
          }
          return sortAndJoin(val.map((c) => getCompanyDisplayName(snakeCaseToKebabCase(c))));
        }
        case 'allowedOrigins': {
          const val = value as ExtendedClientConfig[typeof field];
          if (val.length === 0) {
            return '-';
          }
          return sortAndJoin(val);
        }
        case 'allowAnonymousRetention':
        case 'hasDpa':
        case 'forceMtls':
        case 'filterOtherInsuredPerson':
        case 'getPolicyDocuments':
        case 'isDisabled':
          return formatMessage({ id: `shared.boolean.${value?.toString().toLowerCase()}` }) ?? '-';
        case 'language':
          return formatMessage({ id: `shared.language.${value?.toString().toLowerCase()}` }) ?? '-';
        case 'market':
          return formatMessage({ id: `shared.market.${value?.toString().toLowerCase()}` }) ?? '-';
        case 'clientId':
        case 'clientName':
        case 'configurationName':
        case 'baseUrl':
        case 'logRetention':
        case 'minimumApiVersion':
        default:
          return value?.toString() ?? '-';
      }
    };

    const configRows = Object.entries(client)
      .filter(([field]) => !fieldsToIgnore.includes(field))
      .map(([field, value]) => ({
        label: formatMessage({
          id: `page.config.client-form.field.${field}`,
        }),
        value: renderFieldValue(field as keyof ExtendedClientConfig, value),
      }));

    return configRows;
  }, [client, fieldsToIgnore, formatMessage, getCompanyDisplayName]);

  const [{ loading: isUpdatingClient }, doUpdateClient] = useAsyncFn((_client: ClientConfigApi) =>
    updateClient({ clientId, config: _client }).then(() => {
      doFetchExtendedClient();
      setShowModal(false);
    }),
  );

  if (!client || isFetchingConfig) {
    return <Loader.Content />;
  }

  return (
    <Page title="Client Config">
      <Breadcrumbs
        crumbs={
          [
            isInsurelyAdmin && {
              label: formatMessage({ id: 'page.config.client-config.breadcrumb.customers' }),
              link: `/${ADMIN_PANEL_ROUTE}`,
            },
            {
              label: customer?.customerName,
              link: `/${ADMIN_PANEL_ROUTE}/${CUSTOMERS_ROUTE}/${customerId}?tab=${CustomerConfigTab.CLIENTS_TAB}`,
            },
            { label: client.clientName },
          ].filter(Boolean) as Array<{ label: string; link?: string }>
        }
      />
      <Typography component="h1" variant="Headline-3" className={styles.headline}>
        {client.clientName}
      </Typography>
      <Plate className={styles.card}>
        <div>
          <ModifiedList
            rows={listRows}
            title={client.clientName}
            icon={<Icon name="settings" size={28} />}
          />
          <OtherApiKeys otherApiKeys={client.apiKeys} onRefresh={doFetchExtendedClient} />
        </div>
        <div className={styles.actionButtons}>
          <Button
            variant="secondary"
            size="medium"
            loading={isUpdatingClient}
            onClick={() => {
              if (client.isDisabled) {
                doUpdateClient({ ...client, isDisabled: false });
              } else {
                setShowModal(true);
              }
            }}
          >
            {!client.isDisabled ? (
              <FormattedMessage id="page.config.client-config.disable-client" />
            ) : (
              <FormattedMessage id="page.config.client-config.enable-client" />
            )}
          </Button>
          <Button size="medium" onClick={() => setIsEditing(true)}>
            <FormattedMessage id="page.config.client-config.edit-client" />
          </Button>
        </div>
      </Plate>
      <Modal
        isOpen={isEditing}
        onClose={() => setIsEditing(false)}
        title={formatMessage({ id: 'page.config.customer-config.clients.form.edit.title' })}
        className={styles.formModal}
        width={660}
      >
        <ClientForm
          onClose={() => setIsEditing(false)}
          onSuccess={() => {
            setIsEditing(false);
            doFetchExtendedClient();
          }}
          mode="edit"
          defaultValues={{
            ...client,
            productLine: computeProductLine(client.productTypes[0] as ProductType),
            productInterface: computeTarget(client.productTypes[0] as ProductType),
          }}
          existingClients={clients?.filter((c) => c.clientId !== clientId) ?? []}
        />
      </Modal>
      {showModal && (
        <ConfirmationModal
          onCancel={() => setShowModal(false)}
          isPending={isUpdatingClient}
          onConfirm={() => doUpdateClient({ ...client, isDisabled: true })}
        />
      )}
    </Page>
  );
}
