import { SmsStatus } from 'client/types';
import { IntlShape } from 'translations';

import { InsuranceResponse } from 'types/insurance';
import { CollectionInfoResponse, CollectionStartedResponse, CollectionStatus } from 'types/v3';
import { FinancialProduct } from 'types/wealth';
import { ProductLine } from 'utils/product-line';

import { EventLogRow } from './EventLog';
import { UpdatedCollectionType } from './types';

export const getStatusEnum = (status: string): CollectionStatus =>
  CollectionStatus[status.replace(/ /g, '_') as keyof typeof CollectionStatus];

export const SMS_SUCCESS = [SmsStatus.DELIVERED, SmsStatus.SENT];

export const SMS_FAILED = [SmsStatus.FAILED, SmsStatus.UNDELIVERED, SmsStatus.UNKNOWN];

export const STATUS_SUCCESS = [CollectionStatus.COMPLETED, CollectionStatus.COMPLETED_PARTIAL];

export const STATUS_FAILED = [
  CollectionStatus.AUTHENTICATION_CANCELLED,
  CollectionStatus.AUTHENTICATION_CONFLICT,
  CollectionStatus.AUTHENTICATION_MISMATCH,
  CollectionStatus.COMPLETED_EMPTY,
  CollectionStatus.CONTACT_FORM,
  CollectionStatus.CUSTOMER_ENROLLMENT_REQUIRED,
  CollectionStatus.FAILED,
  CollectionStatus.FAILED_PDF_PARSE,
  CollectionStatus.FAILED_PDF_USER_INPUT,
  CollectionStatus.INCORRECT_CREDENTIALS,
  CollectionStatus.KYC_FORM,
  CollectionStatus.REJECTED_INCORRECT_LOGIN_METHOD,
  CollectionStatus.WAITING_FOR_AUTHENTICATION,
];

export const STATUS_IN_PROGRESS = [
  CollectionStatus.COLLECTING,
  CollectionStatus.COLLECTION_COMPLETED,
  CollectionStatus.CONTACT_FORM_PENDING,
  CollectionStatus.LOGIN,
  CollectionStatus.RUNNING,
  CollectionStatus.TWO_FACTOR_PENDING,
];

export const getCollectionStatusCopy = (
  status: string,
  productType: ProductLine | undefined,
  formatMessage: IntlShape['formatMessage'],
) => {
  const statusForKey = status.replace(/ /g, '-').toLowerCase();

  switch (getStatusEnum(status)) {
    case CollectionStatus.AUTHENTICATION_CANCELLED:
    case CollectionStatus.AUTHENTICATION_CONFLICT:
    case CollectionStatus.COMPLETED:
    case CollectionStatus.CONTACT_FORM:
    case CollectionStatus.CUSTOMER_ENROLLMENT_REQUIRED:
    case CollectionStatus.FAILED:
    case CollectionStatus.INCORRECT_CREDENTIALS:
    case CollectionStatus.KYC_FORM:
    case CollectionStatus.REJECTED_INCORRECT_LOGIN_METHOD:
    case CollectionStatus.RUNNING:
    case CollectionStatus.WAITING_FOR_AUTHENTICATION:
      return formatMessage({ id: `shared.event-log.collection-status.${statusForKey}` });
    case CollectionStatus.LOGIN:
    case CollectionStatus.COLLECTING:
    case CollectionStatus.COMPLETED_EMPTY:
      return formatMessage({
        id: `shared.event-log.collection-status.${statusForKey}.${productType}`,
      });
    // Use the same key as COMPLETED
    case CollectionStatus.COMPLETED_PARTIAL:
      return formatMessage({ id: 'shared.event-log.collection-status.completed' });
    default:
      return status;
  }
};

export const getNotificationTitle = (
  status: CollectionStatus,
  productType: ProductLine | undefined,
  formatMessage: IntlShape['formatMessage'],
  hiddenInsurances: number,
) => {
  switch (status) {
    case CollectionStatus.COMPLETED:
      return formatMessage(
        { id: `We found {insurances} that are not displayed` },
        {
          insurances: `${hiddenInsurances} ${formatMessage(
            {
              id:
                productType === ProductLine.PENSION
                  ? 'shared.word.pension'
                  : 'shared.word.insurance',
            },
            { count: hiddenInsurances },
          ).toLowerCase()}`,
        },
      );
    case CollectionStatus.COMPLETED_PARTIAL:
      return formatMessage({ id: 'Some information is missing' });
    case CollectionStatus.COMPLETED_EMPTY:
      return productType === ProductLine.PENSION
        ? formatMessage({ id: 'shared.event-log.no-pensions-found' })
        : formatMessage({ id: 'shared.event-log.no-insurances-found' });
    case CollectionStatus.FAILED:
      return formatMessage({ id: 'Technical issue' });
    case CollectionStatus.WAITING_FOR_AUTHENTICATION:
      return formatMessage({ id: 'The customer did not authenticate in time' });
    case CollectionStatus.AUTHENTICATION_CANCELLED:
      return formatMessage({ id: 'Authentication was suspended' });
    case CollectionStatus.AUTHENTICATION_CONFLICT:
      return formatMessage({ id: 'Double authentication with BankID' });
    case CollectionStatus.INCORRECT_CREDENTIALS:
      return formatMessage({ id: 'Incorrect login details' });
    case CollectionStatus.CUSTOMER_ENROLLMENT_REQUIRED:
      return formatMessage({ id: 'The customer needs to create an account' });
    case CollectionStatus.CONTACT_FORM:
    case CollectionStatus.KYC_FORM:
      return formatMessage({ id: 'Update contact information' });
    default:
      return formatMessage({ id: 'Technical issue' });
  }
};

interface NotificationDescriptionProps {
  status: CollectionStatus;
  prevStatus: CollectionStatus;
  productLine: ProductLine | undefined;
  formatMessage: IntlShape['formatMessage'];
  company?: string;
}

export const getNotificationDescription = (input: NotificationDescriptionProps) => {
  const { status, prevStatus, productLine, formatMessage, company } = input;

  const data = formatMessage({
    id: productLine === ProductLine.PENSION ? 'Pensions' : 'Insurances',
  }).toLowerCase();
  switch (status) {
    case CollectionStatus.COMPLETED:
      return formatMessage({
        id: 'The customer has insurance that does not match the insurance categories you offer.',
      });
    case CollectionStatus.COMPLETED_PARTIAL:
      return formatMessage(
        { id: 'We could not get all the information belonging to the {data}.' },
        { data },
      );
    case CollectionStatus.COMPLETED_EMPTY:
      return formatMessage(
        {
          id: 'It looks like the customer has no {data} with {company}. Ask the customer to try another company.',
        },
        { data, company },
      );
    case CollectionStatus.FAILED:
      return prevStatus === CollectionStatus.LOGIN
        ? formatMessage({ id: 'Something went wrong when logging in to {company}' }, { company })
        : formatMessage({ id: 'The collection could not be completed' });
    case CollectionStatus.WAITING_FOR_AUTHENTICATION:
      return formatMessage({
        id: 'It took too long for the customer to identify with BankID. Ask the customer to try again and contact our support via email or the chat bubble on the bottom right if the problem persists.',
      });
    case CollectionStatus.AUTHENTICATION_CANCELLED:
      return formatMessage({
        id: 'The customer canceled authentication with BankID. Ask the customer to try again if it was a mistake.',
      });
    case CollectionStatus.AUTHENTICATION_CONFLICT:
      return formatMessage({
        id: 'The customer has already started another authentication with BankID. Ask the customer to end the existing one and try again.',
      });
    case CollectionStatus.INCORRECT_CREDENTIALS:
      return formatMessage({
        id: 'The customer has entered the wrong login details. Ask the customer to try again.',
      });
    case CollectionStatus.CUSTOMER_ENROLLMENT_REQUIRED:
      return formatMessage(
        {
          id: 'The customer needs to create an account with {company} in order for us to retrieve the data. This is sometimes needed if they have never logged in with them before, even if the customer has insurance or a pension there.',
        },
        { company },
      );
    case CollectionStatus.CONTACT_FORM:
    case CollectionStatus.KYC_FORM:
      return formatMessage({
        id: "The insurance company requires the customer to update their contact details. Ask the customer to log in via the insurance company's app or website and answer the questions. Then start a new collection.",
      });
    default:
      return formatMessage({ id: 'Technical issue' });
  }
};

type MergedItemsType = (InsuranceResponse | FinancialProduct)[];

const isInsurance = (item: InsuranceResponse | FinancialProduct): item is InsuranceResponse =>
  'displayType' in item;

export const findAndRemoveDuplicates = (
  items: MergedItemsType,
  collections: CollectionInfoResponse[] | CollectionStartedResponse[],
): {
  filteredItems: MergedItemsType;
  updatedCollections: UpdatedCollectionType[];
} => {
  const seen = new Set<string>();
  const duplicates: MergedItemsType = [];
  const updatedCollections: UpdatedCollectionType[] = collections.map((col) => ({
    ...col,
    numberOfDuplicates: 0,
  }));

  const latestCollectionFirstInArray = (
    a: InsuranceResponse | FinancialProduct,
    b: InsuranceResponse | FinancialProduct,
  ) => ((a.collectionDate ?? 0) > (b.collectionDate ?? 0) ? -1 : 1);

  items.sort(latestCollectionFirstInArray);

  const filterDuplicates = (arr: MergedItemsType): MergedItemsType =>
    arr.filter((item) => {
      const objToCompare = isInsurance(item) ? item.insurance : item;
      const identifier = JSON.stringify({ ...objToCompare, externalId: null });
      const duplicate = seen.has(identifier);
      seen.add(identifier);
      if (duplicate) duplicates.push(item);
      return !duplicate;
    });

  const filteredItems = filterDuplicates(items);

  if (duplicates.length && updatedCollections) {
    duplicates.forEach((dupe) => {
      const collection = updatedCollections.find((c) => c.collectionId === dupe.collectionId);
      if (collection) {
        collection.numberOfDuplicates = (collection.numberOfDuplicates ?? 0) + 1;
      }
    });
  }

  return {
    filteredItems,
    updatedCollections,
  };
};

interface GetEventLogRowsFromCollectionsProps {
  collections: UpdatedCollectionType[];
  productLine: ProductLine | undefined;
  formatMessage: IntlShape['formatMessage'];
  getCompanyDisplayName: (id: string) => string;
}

export const getEventLogRowsFromCollections = (
  input: GetEventLogRowsFromCollectionsProps,
): EventLogRow[] => {
  const { collections, productLine, formatMessage, getCompanyDisplayName } = input;

  return collections.map((col) => {
    const { numberOfFilteredInsurances: filtered, numberOfInsurances: collected } = col;
    const isIns = productLine === ProductLine.INSURANCE;

    function getLatestStatus() {
      if (col.status) return col.status;
      return col.statuses && col.statuses.length
        ? col.statuses[col.statuses.length - 1].status
        : CollectionStatus.RUNNING;
    }

    const typeCopy = formatMessage(
      {
        id: isIns ? 'shared.word.insurance' : 'shared.word.pension',
      },
      { count: collected },
    ).toLowerCase();

    function getStatusText() {
      if (STATUS_IN_PROGRESS.includes(getLatestStatus()) && col.statuses) {
        return getCollectionStatusCopy(getLatestStatus(), productLine, formatMessage);
      }

      if (STATUS_FAILED.includes(col.status)) {
        return getCollectionStatusCopy(getLatestStatus(), productLine, formatMessage);
      }

      if (!col.retained) {
        return `${formatMessage(
          { id: 'shared.event-log.collected-items_copy' },
          { collected, typeCopy },
        )}. ${formatMessage({ id: 'page.session.collection-expired' })}`;
      }

      if (collected) {
        let text = '';
        if (filtered !== collected) {
          text = formatMessage(
            { id: 'shared.event-log.filtered-items' },
            { filtered, collected, typeCopy, diff: collected - filtered },
          );
        } else {
          text = formatMessage(
            { id: 'shared.event-log.collected-items_copy' },
            { collected, typeCopy },
          );
        }

        if (col.numberOfDuplicates === collected) {
          text = `${text}. (${formatMessage({ id: 'shared.event-log.filtered-out-all' }, { numberOfDuplicates: col.numberOfDuplicates })})`;
        } else if (col.numberOfDuplicates) {
          text = `${text}. (${formatMessage({ id: 'shared.event-log.filtered-out-partial' }, { numberOfDuplicates: col.numberOfDuplicates })})`;
        }

        return text;
      }

      return formatMessage({
        id: isIns ? 'shared.event-log.no-insurances-found' : 'shared.event-log.no-pensions-found',
      });
    }

    function getIconFromStatus(status: CollectionStatus) {
      if (STATUS_SUCCESS.includes(status)) return 'success';
      if (STATUS_FAILED.includes(status)) return 'failed';
      return 'loading';
    }

    return {
      creationDate: new Date(col.creationDate).getTime(),
      icon: {
        type: 'companyLogo',
        key: col.insuranceCompany,
      },
      label: `${formatMessage({ id: 'shared.word.collection-from' })} ${getCompanyDisplayName(col.insuranceCompany)}`,
      status: {
        icon: getIconFromStatus(getLatestStatus()),
        text: getStatusText(),
      },
    };
  });
};
