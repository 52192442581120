import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Loader, TextArea, TextInput, Notification, Select } from '@insurely/ui';
import { Controller, useForm } from 'react-hook-form';

import { useAsync, useAsyncFn } from 'react-use';
import { z } from 'zod';

import { FormattedMessage, useIntl } from 'translations';

import { Permissions } from 'types/v3';

import { logError } from 'utils/datadog';

import { createLiveboard, getAllPermissions, updateLiveboard } from '../api';

import styles from './liveboards.module.css';

interface Props {
  onSuccess: (createdLiveboardId?: string) => void;
  mode: 'creation' | 'edit';
  defaultValues?: FormValues;
}

interface FormValues {
  liveboardName: string;
  description: string;
  thoughtspotLiveboardId: string;
  requiredUserPermission: Permissions;
}

export const CreateLiveboardForm = ({ onSuccess, mode, defaultValues }: Props) => {
  const { formatMessage } = useIntl();

  const missingFieldMessage = formatMessage({ id: 'page.config.liveboards.form.field-error' });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(
      z.object({
        liveboardName: z.string().min(1, { message: missingFieldMessage }),
        description: z.string().min(1, { message: missingFieldMessage }),
        thoughtspotLiveboardId: z.string().min(1, { message: missingFieldMessage }),
        requiredUserPermission: z.nativeEnum(Permissions, { message: missingFieldMessage }),
      }),
    ),
  });

  const { value: permissions, loading: isFetchingPermissions } = useAsync(() =>
    getAllPermissions().catch((err) => {
      const nErr = new Error('Failed to fetch all permissions', { cause: err });
      logError(nErr);
      throw nErr;
    }),
  );

  const [{ loading: isCreatingLiveboard, error: errorCreatingLiveboard }, doCreateLiveboard] =
    useAsyncFn(
      async (body: FormValues) =>
        createLiveboard({
          liveboardName: body.liveboardName,
          description: body.description,
          requiredUserPermission: body.requiredUserPermission,
          thoughtspotLiveboardId: body.thoughtspotLiveboardId,
        })
          .then(() => {
            onSuccess(body.thoughtspotLiveboardId);
          })
          .catch((err) => {
            const nErr = new Error('Failed to create liveboard', { cause: err });
            logError(nErr);
            throw nErr;
          }),
      [],
    );

  const [{ loading: isUpdatingLiveboard, error: errorUpdatingLiveboard }, doUpdateLiveboard] =
    useAsyncFn(
      async (body: FormValues) =>
        updateLiveboard({
          body: {
            liveboardName: body.liveboardName,
            description: body.description,
            requiredUserPermission: body.requiredUserPermission,
            thoughtspotLiveboardId: body.thoughtspotLiveboardId,
          },
          thoughtspotLiveboardId: body.thoughtspotLiveboardId,
        })
          .then(() => {
            onSuccess();
          })
          .catch((err) => {
            const nErr = new Error('Failed to update a liveboard', { cause: err });
            logError(nErr, { thoughtspotLiveboardId: body.thoughtspotLiveboardId });
            throw nErr;
          }),
      [],
    );

  const handleOnSubmit = (values: FormValues) => {
    if (mode === 'creation') {
      doCreateLiveboard(values);
    } else if (mode === 'edit') {
      doUpdateLiveboard(values);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(handleOnSubmit)} className={styles.createForm}>
        <TextInput
          label={formatMessage({ id: 'page.config.liveboards.form.field.name' })}
          {...register('liveboardName')}
          error={!!errors.liveboardName}
          helperText={errors.liveboardName?.message}
        />
        <TextArea
          label={formatMessage({ id: 'page.config.liveboards.form.field.description' })}
          {...register('description')}
          error={!!errors.description}
          helperText={errors.description?.message}
        />
        <TextInput
          label={formatMessage({ id: 'page.config.liveboards.form.field.thoughtspot' })}
          {...register('thoughtspotLiveboardId')}
          disabled={mode === 'edit'}
          error={!!errors.thoughtspotLiveboardId}
          helperText={errors.thoughtspotLiveboardId?.message}
        />
        {isFetchingPermissions || !permissions ? (
          <div className={styles.loaderWrapper}>
            <Loader.Content />
          </div>
        ) : (
          <Controller
            control={control}
            name="requiredUserPermission"
            render={({ field: { onChange, value } }) => (
              <Select
                id="permissions"
                label={formatMessage({ id: 'page.config.liveboards.form.field.permission' })}
                fullWidth
                options={permissions.map((p) => ({ label: p, value: p }))}
                value={value}
                onChange={onChange}
                error={!!errors.requiredUserPermission}
                helperText={errors.requiredUserPermission?.message}
              />
            )}
          />
        )}
        <Button
          type="submit"
          style={{ alignSelf: 'flex-end' }}
          size="medium"
          loading={isCreatingLiveboard || isUpdatingLiveboard}
        >
          <FormattedMessage id={`page.config.liveboards.form.${mode}.button`} />
        </Button>
        {(!!errorCreatingLiveboard || !!errorUpdatingLiveboard) && (
          <Notification
            headline={formatMessage({ id: `page.config.liveboards.form.${mode}.error` })}
            status="error"
          />
        )}
      </form>
    </div>
  );
};
