import {
  Button,
  CompanyLogo,
  Icon,
  Loader,
  Notification,
  Plate,
  Tooltip,
  Typography,
} from '@insurely/ui';
import classNames from 'classnames';
import React, { useContext } from 'react';

import { useParams } from 'react-router-dom';

import { useAsync } from 'react-use';

import { ModifiedList, Row } from 'components/ModifiedList/ModifiedList';
import { Page } from 'components/Page/Page';
import SessionContext from 'contexts/session/SessionContext';
import { FormattedMessage, useIntl } from 'translations';
import { useFormatting } from 'translations/useFormatting';

import { FinancialProduct, MovabilityStatus } from 'types/wealth';
import { logError } from 'utils/datadog';

import { MovableStatus } from '../../components/MovableStatus/MovableStatus';

import { getWealthProducts } from './api';
import styles from './pensionPage.module.css';
import { notificationHandling } from './util/notificationHandling';

const DisclaimerWrapper = ({ value, disclaimer }: { value: string; disclaimer: string }) => (
  <Tooltip trigger="hover" position="bottom" content={`*${disclaimer}`}>
    {value}*
  </Tooltip>
);

export const PensionPage = () => {
  const { formatMessage } = useIntl();
  const { formatCurrency, formatDate } = useFormatting();
  const { externalId, collId } = useParams() as {
    collId: string;
    externalId: string;
  };
  const { items } = useContext(SessionContext);

  const { value: pension } = useAsync(async () => {
    let pensions = [];
    if (items && items.length > 0) {
      pensions = items;
    } else {
      const response = await getWealthProducts(collId).catch((err) => {
        const nErr = new Error('Failed to get pensions', { cause: err });
        logError(nErr, { externalId, collectionId: collId });
        throw nErr;
      });
      pensions = response.financialProducts;
    }
    return pensions.find(
      (_pension: FinancialProduct) => _pension.id === externalId,
    ) as FinancialProduct;
  }, [collId]);

  if (!pension) {
    return (
      <Page title={formatMessage({ id: 'Pension' })} goback>
        <Loader.Content />
      </Page>
    );
  }

  const numberToPercent = (value: number | undefined, fixed?: number) => {
    if (typeof value !== 'number') {
      return formatMessage({ id: 'Not available' });
    }
    const per = fixed ? (value * 100).toFixed(fixed) : value * 100;
    return `${per} %`;
  };

  const formattedCurrency = (val: number | undefined) =>
    typeof val === 'number' ? formatCurrency(val) : formatMessage({ id: 'Not available' });

  const currentValue = formattedCurrency(pension.financialInformation.currentValue.amount);
  const feeFixed = formattedCurrency(pension.fees.feeFixed?.amount);
  const feeVariable = numberToPercent(pension.fees.feeVariable, 2);
  // We do not verify the totalPaid type here because we also want to exclude 0 to avoid
  // a division by 0 which would lead to "Infinity"
  const developmentSinceStart =
    typeof pension.financialInformation.currentValue.amount === 'number' &&
    pension.financialInformation.totalDeposits
      ? numberToPercent(
          (pension.financialInformation.currentValue.amount -
            pension.financialInformation.totalDeposits.amount) /
            pension.financialInformation.totalDeposits.amount,
          2,
        )
      : formatMessage({ id: 'Not available' });

  const notificationContent = notificationHandling(pension.movability);

  const pensionDetailsTopSection: {
    title: string | JSX.Element;
    value: string | JSX.Element;
    id: string;
  }[] = [
    {
      id: 'id:0',
      title: formatMessage({ id: 'Traditional' }),
      value: pension.traditionallyManaged
        ? formatMessage({ id: 'Yes' })
        : formatMessage({ id: 'No' }),
    },
    {
      id: 'id:1',
      title: formatMessage({ id: 'Total amount' }),
      value: currentValue,
    },
    {
      id: 'id:2',
      title:
        pension.fees.feesCollected || pension.fees.feeFixed === undefined ? (
          formatMessage({ id: 'Fixed charge' })
        ) : (
          <DisclaimerWrapper
            disclaimer={formatMessage({ id: 'page.pension.collected-fee.disclaimer' })}
            value={formatMessage({ id: 'Fixed charge' })}
          />
        ),
      value: feeFixed,
    },
    {
      id: 'id:3',
      title:
        pension.fees.feesCollected || pension.fees.feeVariable === undefined ? (
          formatMessage({ id: 'Variable charge' })
        ) : (
          <DisclaimerWrapper
            disclaimer={formatMessage({ id: 'page.pension.collected-fee.disclaimer' })}
            value={formatMessage({ id: 'Variable charge' })}
          />
        ),
      value: feeVariable,
    },
    {
      id: 'id:4',
      title: formatMessage({ id: 'Development since start' }),
      value: developmentSinceStart,
    },
    {
      id: 'id:5',
      title: (
        <DisclaimerWrapper
          disclaimer={formatMessage({ id: 'shared.movability-disclaimer' })}
          value={formatMessage({ id: 'Movable' })}
        />
      ),
      value: <MovableStatus status={pension.movability?.result || MovabilityStatus.UNKNOWN} />,
    },
  ];

  const pensionDetailsList: Row[] = [
    {
      id: 'id:0',
      label: formatMessage({ id: 'Employer' }),
      value: pension.holder.name ?? formatMessage({ id: 'Not available' }),
      description: '',
    },
    {
      id: 'id:1',
      label: formatMessage({ id: 'Active payments' }),
      value:
        pension.activePayment !== undefined
          ? pension.activePayment
            ? formatMessage({ id: 'Yes' })
            : formatMessage({ id: 'No' })
          : formatMessage({ id: 'Not available' }),
      description: '',
    },
    {
      id: 'id:2',
      label: formatMessage({ id: 'Active payouts' }),
      value:
        pension.activePayout !== undefined
          ? pension.activePayout
            ? formatMessage({ id: 'Yes' })
            : formatMessage({ id: 'No' })
          : formatMessage({ id: 'Not available' }),
      description: '',
    },
    {
      id: 'id:3',
      label: formatMessage({ id: 'Start date' }),
      value: pension.startDate
        ? formatDate(pension.startDate)
        : formatMessage({ id: 'Not available' }),
      description: '',
    },
    {
      id: 'id:4',
      label: formatMessage({ id: 'Survivors protection' }),
      value:
        pension.insurance.survivorsProtection !== undefined
          ? pension.insurance.survivorsProtection
            ? formatMessage({ id: 'Yes' })
            : formatMessage({ id: 'No' })
          : formatMessage({ id: 'Not available' }),
      description: '',
    },
    {
      id: 'id:5',
      label: formatMessage({ id: 'Insurance number' }),
      value: pension.productNumber ?? formatMessage({ id: 'Not available' }),
      description: '',
    },
    {
      id: 'id:6',
      label: formatMessage({ id: 'Actual fees paid (previous year)' }),
      value: formattedCurrency(pension.fees.feesPreviousYear?.amount),
      description: '',
    },
    {
      id: 'id:7',
      label: formatMessage({ id: 'Company number' }),
      value: pension.holder.organization?.number ?? formatMessage({ id: 'Not available' }),
      description: '',
    },
  ];

  const fundDetails =
    pension.holdings?.map((fundDetail) => ({
      name: fundDetail.name,
      totalValue: fundDetail.value.amount
        ? formatCurrency(Number(fundDetail.value.amount))
        : formatMessage({ id: 'Not available' }),
      details: [
        {
          title: formatMessage({ id: 'Fund fee' }),
          value: numberToPercent(fundDetail.feeVariable, 2),
        },
        {
          title: formatMessage({ id: 'ISIN code' }),
          value: fundDetail.isin,
        },
      ],
    })) ?? [];

  return (
    <Page title={formatMessage({ id: 'Pension' })} goback>
      <div className={styles.pageLayout}>
        <div className={styles.printTopContent}>
          <Typography component="p" variant="ParagraphBody">
            {formatMessage({ id: 'Export was made at {date}' }, { date: formatDate(Date.now()) })}
          </Typography>
          {pension.startDate && (
            <Typography component="p" variant="ParagraphBody">
              {formatMessage(
                { id: 'Consent to collect this information were given on {date}' },
                { date: formatDate(pension.startDate) },
              )}
            </Typography>
          )}
        </div>

        <div className={styles.pensionName}>
          <CompanyLogo company={pension.company} width={50} />
          <Typography component="h4" variant="Headline-4">
            {pension.productName}
          </Typography>
        </div>

        <Button
          className={styles.downloadExportButton}
          variant="secondary"
          onClick={() => window.print()}
          icon={<Icon name="download" aria-hidden size={24} />}
          iconPosition="left"
        >
          {formatMessage({ id: 'Save pension data' })}
        </Button>

        {notificationContent && (
          <Notification
            className={styles.notification}
            headline={
              notificationContent.headline
                ? formatMessage({ id: notificationContent.headline })
                : ''
            }
            status="info"
          >
            <ul>
              {notificationContent.reasons.map((r) => (
                <li>
                  <Typography variant="ParagraphCaption" component="p">
                    {notificationContent.reasons.length > 1 ? '- ' : ''}
                    <FormattedMessage id={r} />
                  </Typography>
                </li>
              ))}
            </ul>
          </Notification>
        )}

        <Plate className={classNames(styles.summaryCard)}>
          {pensionDetailsTopSection.map((info) => (
            <div className={styles.summaryCardColumn} key={info.id}>
              <Typography component="p" variant="ParagraphBodySmall">
                {info.title}
              </Typography>
              <Typography component="h2" variant="Headline-6">
                {info.value}
              </Typography>
            </div>
          ))}
        </Plate>

        <div className={styles.details}>
          <Plate className={classNames(styles.listCard)}>
            <ModifiedList rows={pensionDetailsList} valueClassName="ph-no-capture" />
          </Plate>
          <Plate className={classNames(styles.listCard, styles.fundCard)}>
            <Typography component="span" variant="Label-1">
              {formatMessage({ id: 'Fund holdings' })}
            </Typography>
            {fundDetails.length === 0 && (
              <Typography component="p" variant="ParagraphBodySmall">
                <FormattedMessage id="page.pension.no-fund-details" />
              </Typography>
            )}
            {fundDetails.map((fundDetail) => (
              <div className={styles.fundCardItem} key={fundDetail.name}>
                <div className={styles.fundCardRow}>
                  <Typography component="p" variant="ParagraphBodySmall">
                    {fundDetail.name}
                  </Typography>
                  <Typography component="h3" variant="Label-2">
                    {fundDetail.totalValue}
                  </Typography>
                </div>
                {fundDetail.details.map((detail) => (
                  <div
                    className={classNames(styles.fundCardRow, styles.fundCardDetail)}
                    key={detail.title}
                  >
                    <Typography component="p" variant="ParagraphCaption">
                      {detail.title}
                    </Typography>
                    <Typography component="p" variant="ParagraphCaption">
                      {detail.value}
                    </Typography>
                  </div>
                ))}
              </div>
            ))}
          </Plate>
        </div>
      </div>
    </Page>
  );
};
