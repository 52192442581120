import {
  Button,
  Icon,
  IconButton,
  Loader,
  Modal,
  Plate,
  StatusText,
  TextInput,
  Typography,
} from '@insurely/ui';

import classNames from 'classnames';
import { useContext, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { CLIENT_ROUTE } from 'constants/routes';
import UserContext from 'contexts/user/UserContext';
import tableStyles from 'styles/table.module.css';
import { FormattedMessage, useIntl } from 'translations';
import { Permissions } from 'types/v3';

import { snakeCaseToCamelCase } from 'utils/formatters';
import { TableTitle } from 'views/Config/comp/TableTitle/TableTitle';
import { TagsGroup } from 'views/Config/comp/TagsGroup/TagsGroup';

import { ExtendedClientConfig } from 'views/Config/config.types';

import configStyles from '../../config.module.css';

import styles from './clients.module.css';
import { ClientForm } from './comp/ClientForm/ClientForm';

interface Props {
  clients?: ExtendedClientConfig[];
  isFetchingClients: boolean;
  onRefresh: () => void;
}

export default function Clients({ clients, isFetchingClients, onRefresh }: Props) {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { formatMessage } = useIntl();

  const [search, setSearch] = useState('');

  const [addClientConfig, setAddClientConfig] = useState(false);

  const isInsurelyAdmin = useMemo(
    () => user?.permissions.includes(Permissions.INSURELY_ADMIN),
    [user?.permissions],
  );

  const filteredClients = useMemo(
    () =>
      clients
        ? clients.filter((c) => c.clientName.toLowerCase().includes(search.toLowerCase()))
        : [],
    [clients, search],
  );

  if (isFetchingClients || !clients) {
    return <Loader.Content />;
  }

  return (
    <Plate>
      <div className={styles.headline}>
        <TableTitle
          icon="settings"
          title={formatMessage({ id: 'page.config.customer-config.clients.title' })}
        />
        <div className={styles.headlineActions}>
          <Button
            variant="primary"
            size="medium"
            onClick={() => setAddClientConfig(true)}
            icon={<Icon name="plus" size={20} />}
          >
            <FormattedMessage id="page.config.customer-config.clients.add-new" />
          </Button>
          <TextInput
            startAdornment={<Icon name="search" size={20} />}
            endAdornment={
              search.length > 0 ? (
                <IconButton icon={<Icon name="close" size={16} />} onClick={() => setSearch('')} />
              ) : undefined
            }
            textInputSize="extra-small"
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            placeholder={formatMessage({
              id: 'page.config.customer-config.clients.search.placeholder',
            })}
            label=""
          />
        </div>
      </div>
      <table className={tableStyles.table}>
        <thead>
          <tr className={classNames(tableStyles.head, configStyles.tableHeader)}>
            <th aria-labelledby="name" style={{ width: '25%' }}>
              <Typography component="p" variant="Label-3">
                <FormattedMessage id="page.config.customer-config.clients.column.name" />
              </Typography>
            </th>
            <th aria-labelledby="product types" style={{ width: '25%' }}>
              <Typography component="p" variant="Label-3">
                <FormattedMessage id="page.config.customer-config.clients.column.product-types" />
              </Typography>
            </th>
            <th aria-labelledby="market" style={{ width: '15%' }}>
              <Typography component="p" variant="Label-3">
                <FormattedMessage id="page.config.customer-config.clients.column.market" />
              </Typography>
            </th>
            <th aria-labelledby="language" style={{ width: '15%' }}>
              <Typography component="span" variant="Label-3">
                <FormattedMessage id="page.config.customer-config.clients.column.language" />
              </Typography>
            </th>
            <th aria-labelledby="status" style={{ width: '20%' }}>
              <Typography component="span" variant="Label-3">
                <FormattedMessage id="page.config.customer-config.clients.column.status" />
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredClients.length === 0 && (
            <tr>
              <td aria-label="empty-table" colSpan={6}>
                <div className={configStyles.emptyRow}>
                  <Typography component="p" variant="ParagraphBodySmall">
                    <FormattedMessage id="page.config.customer-config.clients.no-clients" />
                  </Typography>
                </div>
              </td>
            </tr>
          )}
          {filteredClients
            .sort((a, b) => a.clientName.localeCompare(b.clientName))
            .map((client) => (
              <tr
                className={classNames(tableStyles.row, configStyles.tableRow, {
                  [styles.notClickableRow]: !isInsurelyAdmin,
                  [styles.disabledClientRow]: client.isDisabled,
                })}
                key={client.clientId}
                tabIndex={0}
                onClick={() => {
                  if (isInsurelyAdmin) {
                    navigate(`${CLIENT_ROUTE}/${client.clientId}`);
                  }
                }}
              >
                <td>
                  <Typography
                    component="p"
                    variant="ParagraphCaption"
                    style={{ color: client.isDisabled ? 'var(--grey-2)' : undefined }}
                  >
                    {client.clientName}
                  </Typography>
                </td>
                <td aria-label="product-types">
                  <Typography
                    component="p"
                    variant="ParagraphCaption"
                    style={{ color: client.isDisabled ? 'var(--grey-2)' : undefined }}
                  >
                    <TagsGroup
                      tags={client.productTypes.map((t) =>
                        formatMessage({ id: `shared.productType.${snakeCaseToCamelCase(t)}` }),
                      )}
                    />
                  </Typography>
                </td>
                <td>
                  <Typography
                    component="p"
                    variant="ParagraphCaption"
                    style={{ color: client.isDisabled ? 'var(--grey-2)' : undefined }}
                  >
                    {client.market}
                  </Typography>
                </td>
                <td>
                  <Typography
                    component="p"
                    variant="ParagraphCaption"
                    style={{ color: client.isDisabled ? 'var(--grey-2)' : undefined }}
                  >
                    {client.language}
                  </Typography>
                </td>
                <td aria-label="status">
                  <StatusText
                    color={!client.isDisabled ? 'var(--green)' : 'var(--red-1)'}
                    text={formatMessage({
                      id: `page.config.customer-config.clients.${!client.isDisabled ? 'active' : 'inactive'}`,
                    })}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Modal
        isOpen={addClientConfig}
        onClose={() => setAddClientConfig(false)}
        title={formatMessage({ id: 'page.config.customer-config.clients.form.creation.title' })}
        className={styles.formModal}
        width={660}
      >
        <ClientForm
          onClose={() => setAddClientConfig(false)}
          onSuccess={() => {
            setAddClientConfig(false);
            onRefresh();
          }}
          mode="creation"
          existingClients={clients}
        />
      </Modal>
    </Plate>
  );
}
