import { Button, Card, Icon, Typography } from '@insurely/ui';
import React from 'react';

import { UseFormGetValues } from 'react-hook-form';

import { useIntl } from 'translations';

import { FormValues } from '../../utils';

import styles from './errorCard.module.css';

interface ErrorCardProps {
  submitCancellation: (data: FormValues) => Promise<void>;
  getFormValues: UseFormGetValues<FormValues>;
  onHide: () => void;
}

export const ErrorCard: React.FC<ErrorCardProps> = ({
  submitCancellation,
  getFormValues,
  onHide,
}) => {
  const { formatMessage } = useIntl();
  const formValues = getFormValues();
  return (
    <Card className={styles.card}>
      <div className={styles.icon}>
        <Icon name="error" aria-hidden size={40} color="var(--red-1)" />
      </div>
      <Typography component="h3" variant="Headline-7">
        {formatMessage({ id: 'shared.something-went-wrong' })}
      </Typography>
      <Typography component="p" variant="ParagraphBodySmall" className={styles.paragraph}>
        {formatMessage({
          id: 'Something went wrong when we were submitting the cancellation power of attorney to the customer. Please try again in a little while.',
        })}
      </Typography>
      <div className={styles.buttonContainer}>
        <Button variant="secondary" onClick={onHide}>
          {formatMessage({ id: 'Cancel' })}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            submitCancellation(formValues);
            onHide();
          }}
        >
          {formatMessage({ id: 'shared.try-again' })}
        </Button>
      </div>
    </Card>
  );
};
