import { Loader, MultiSelect } from '@insurely/ui';

import { useState } from 'react';

import { useAsync } from 'react-use';

import { useIntl } from 'translations';

import { Permissions } from 'types/v3';
import { logError } from 'utils/datadog';

import { getAllPermissions } from '../../../api';

import styles from '../actionBanner.module.css';

interface Props {
  addCallback: {
    onAdd: (permissions: Permissions[]) => void;
    loading: boolean;
    disabled: boolean;
  };
  removeCallback: {
    onRemove: (permissions: Permissions[]) => void;
    loading: boolean;
    disabled: boolean;
  };
}

export const PermissionMgmt = ({ addCallback, removeCallback }: Props) => {
  const { formatMessage } = useIntl();

  const [selectedPermissions, setSelectedPermissions] = useState<Permissions[]>([]);

  const { loading: fetchingPermissions, value: permissions } = useAsync(() =>
    getAllPermissions().catch((err) => {
      const nErr = new Error('Failed to fetch all permissions', { cause: err });
      logError(nErr);
      throw nErr;
    }),
  );

  const { onAdd, disabled: addDisabled, loading: addLoading } = addCallback;
  const { onRemove, disabled: removeDisabled, loading: removeLoading } = removeCallback;

  const handleSelected = (count: number) => {
    if (!permissions) {
      return '';
    }
    if (count === 1) {
      return permissions.find((p) => p === selectedPermissions[0]) ?? '';
    }
    return formatMessage(
      { id: 'page.config.action-banner.permissions-mgmt.amount-selected' },
      { count: selectedPermissions.length },
    );
  };

  return (
    <div className={styles.action}>
      {fetchingPermissions ? (
        <div className={styles.spinnerContainer}>
          <Loader.Content size="small" />
        </div>
      ) : (
        <MultiSelect
          variant="small"
          fullWidth
          renderValue={(values) => {
            if (values.length === 0) {
              return null;
            }
            if (values.length === permissions?.length) {
              return formatMessage({
                id: `page.config.action-banner.permissions-mgmt.all-selected`,
              });
            }
            return handleSelected(values.length);
          }}
          id="permissions"
          options={
            permissions
              ?.map((p) => ({ label: p, value: p }))
              .sort((a, b) => a.label.localeCompare(b.label)) ?? []
          }
          onChange={(val: Permissions[]) => setSelectedPermissions(val)}
          value={selectedPermissions}
          placeholder={formatMessage({
            id: 'page.config.action-banner.permissions-mgmt.placeholder',
          })}
          primaryCallback={{
            label: formatMessage({ id: 'page.config.action-banner.permissions-mgmt.add' }),
            loading: addLoading,
            disabled: addDisabled || fetchingPermissions || selectedPermissions.length === 0,
            action: () => onAdd(selectedPermissions),
          }}
          secondaryCallback={{
            label: formatMessage({ id: 'page.config.action-banner.permissions-mgmt.remove' }),
            loading: removeLoading,
            disabled: removeDisabled || fetchingPermissions || selectedPermissions.length === 0,
            action: () => onRemove(selectedPermissions),
          }}
        />
      )}
    </div>
  );
};
