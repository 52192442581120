import { Button, Card, Typography, useToastContext } from '@insurely/ui';

import classNames from 'classnames';
import { useContext } from 'react';

import { v4 as uuid } from 'uuid';

import { deleteSession } from 'client/client';

import { SESSIONS_ROUTE } from 'constants/routes';
import UserContext from 'contexts/user/UserContext';
import { useNavigateQueryParams } from 'hooks/useNavigateQueryParams';
import { FormattedMessage, useIntl } from 'translations';
import { Session } from 'types/v3';
import { logError } from 'utils/datadog';
import { ProductLine } from 'utils/product-line';

import { RemoveSessionModal } from '../RemoveSessionModal/RemoveSessionModal';

import styles from './sessionInfo.module.css';

interface SessionInfoProps {
  sessionId: string;
  sessionLink: string;
  loadSessions: (contentType: string) => Promise<Session[] | undefined>;
}

export const SessionInfo = ({ sessionId, sessionLink, loadSessions }: SessionInfoProps) => {
  const { formatMessage } = useIntl();
  const { dispatch } = useToastContext();
  const navigate = useNavigateQueryParams();
  const { productLine } = useContext(UserContext);

  const removeSession = async () => {
    try {
      await deleteSession(sessionId);
      await loadSessions(productLine === ProductLine.PENSION ? 'pension' : 'insurance');
      navigate(`/${SESSIONS_ROUTE}`);
    } catch (error) {
      const nErr = new Error('Error removing session', { cause: error });
      logError(nErr, { sessionId });
      throw nErr;
    }
  };

  const rows = [
    { id: 'id', label: 'Session id: ', value: sessionId },
    { id: 'link', label: 'Session link: ', value: sessionLink },
  ];

  const handleOnCopy = (value: string, id: string) => {
    const message =
      id === 'id'
        ? formatMessage({ id: 'The session ID is copied' })
        : formatMessage({ id: 'The session link is copied' });

    navigator.clipboard.writeText(value);
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        id: uuid(),
        type: 'success',
        message,
      },
    });
  };

  return (
    <Card className={styles.card}>
      <Typography component="h6" variant="Headline-6" className={styles.headline}>
        <FormattedMessage id="page.session.session-info-headline" />
      </Typography>
      {rows.map((row) => (
        <div className={styles.row} key={row.label}>
          <Typography component="p" variant="Label-3" className={styles.textWrapper}>
            <FormattedMessage id={row.label} />
          </Typography>
          <Typography component="p" variant="ParagraphCaption" className={styles.rowValue}>
            {row.id === 'id' && <span className="ph-no-capture">{row.value}</span>}
            {row.id === 'link' && (
              <a
                href={row.value}
                className={classNames(styles.link, 'ph-no-capture')}
                rel="nofollow noreferrer"
                target="_blank"
              >
                {row.value}
              </a>
            )}
            <Button
              variant="secondary"
              size="small"
              onClick={() => handleOnCopy(row.value, row.id)}
            >
              <FormattedMessage id={`page.session.copy-${row.id}`} />
            </Button>
          </Typography>
        </div>
      ))}

      <RemoveSessionModal removeSession={removeSession} />
    </Card>
  );
};
