import { Typography } from '@insurely/ui';

import { Link, useLocation } from 'react-router-dom';

import { COLLECTIONS_ROUTE, SESSIONS_ROUTE } from 'constants/routes';
import { useIntl } from 'translations';
import { useFormatting } from 'translations/useFormatting';
import { InsuranceResponse } from 'types/insurance';

import { groupByDate } from 'utils/insurance';

import { InsuranceCard } from './comp/InsuranceCard/InsuranceCard';

import styles from './insuranceListing.module.css';

export const InsuranceListing = ({ insurances }: { insurances: InsuranceResponse[] }) => {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const { formatCurrency } = useFormatting();

  const home = insurances.filter((ins) => /house/.test(ins.insurance.insuranceType));
  const car = insurances.filter((ins) => /vehicle/.test(ins.insurance.insuranceType));
  const person = insurances.filter((ins) => /person/.test(ins.insurance.insuranceType));
  const animal = insurances.filter((ins) => /animal/.test(ins.insurance.insuranceType));

  const other = insurances.filter(
    (ins) => [...home, ...car, ...person, ...animal].indexOf(ins) === -1,
  );

  const lists = [];

  if (home.length) lists.push({ title: formatMessage({ id: 'Home insurances' }), items: home });
  if (car.length) lists.push({ title: formatMessage({ id: 'Vehicle insurances' }), items: car });
  if (person.length)
    lists.push({
      title: formatMessage({ id: 'Person insurances' }),
      items: person,
    });
  if (animal.length)
    lists.push({
      title: formatMessage({ id: 'Animal insurances' }),
      items: animal,
    });
  if (other.length) lists.push({ title: formatMessage({ id: 'Other insurances' }), items: other });

  function getLink(insurance: InsuranceResponse) {
    const {
      collectionId,
      insurance: { externalId },
    } = insurance;

    const rootPage = pathname.match(SESSIONS_ROUTE) ? SESSIONS_ROUTE : COLLECTIONS_ROUTE;
    return `/${rootPage}/${collectionId}/insurance/${externalId}`;
  }

  function printInsurancesForEachCollectionDate(items: InsuranceResponse[]) {
    const groupedItems = groupByDate(items);

    return (
      <div className={styles.section}>
        {Object.keys(groupedItems)
          .reverse()
          .map((date) => (
            <div key={date} className={styles.sectionBlock}>
              <ul className={styles.insuranceCardList}>
                {groupedItems[date].map((ins: InsuranceResponse) => (
                  <Link to={getLink(ins)} key={ins.insurance.externalId} className={styles.item}>
                    <InsuranceCard insurance={ins} />
                  </Link>
                ))}
              </ul>
            </div>
          ))}
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      {lists.map((list) => (
        <div key={list.title} className={styles.listingItem}>
          <div className={styles.listingItemTitle}>
            <Typography component="h2" variant="Headline-5">
              {list.title}
            </Typography>
            <Typography component="p" variant="ParagraphPreamble" className={styles.total}>
              {formatMessage({ id: 'Total:' })}
              {formatMessage(
                { id: 'shared.amount-per-month' },
                {
                  amount: formatCurrency(
                    Math.ceil(
                      list.items.reduce(
                        (acc, ins) => acc + (ins.insurance.premiumAmountYearRounded || 0),
                        0,
                      ) / 12,
                    ),
                  ),
                },
              )}
            </Typography>
          </div>
          {printInsurancesForEachCollectionDate(list.items)}
        </div>
      ))}
    </div>
  );
};
