import { Card, Icon, Typography } from '@insurely/ui';

import { useIntl } from 'translations';

import styles from './errorBanner.module.css';

export const ErrorBanner = () => {
  const { formatMessage } = useIntl();

  return (
    <Card flat className={styles.card}>
      <div className={styles.iconBackground}>
        <Icon name="no-file" size={36} />
      </div>
      <div className={styles.content}>
        <Typography variant="Headline-5" component="h5">
          {formatMessage({
            id: 'Unfortunately, we do not have support for the content of this insurance type',
          })}
        </Typography>
        <Typography variant="ParagraphBodySmall" component="p">
          {formatMessage({
            id: 'See the general insurance conditions to see what this insurance covers',
          })}
        </Typography>
      </div>
    </Card>
  );
};
