import { Button, Checkbox, Select } from '@insurely/ui';

import classNames from 'classnames';
import { subMilliseconds } from 'date-fns';
import { useContext, useState } from 'react';

import { assignAdvisor, removeAdvisor, updateCollectionState } from 'client/client';
import { COLLECTIONS_ROUTE } from 'constants/routes';
import UserContext from 'contexts/user/UserContext';
import { useNavigateQueryParams } from 'hooks/useNavigateQueryParams';
import { useIntl } from 'translations';
import { useFormatting } from 'translations/useFormatting';
import {
  Advisor,
  CollectionState,
  CollectionsTableData,
  CollectionsTableRow,
  Market,
} from 'types/v3';

import { logError } from 'utils/datadog';
import { ProductLine } from 'utils/product-line';
import { FilterQuery } from 'views/Collections/types';
import { formatPersonalNumber, getQueryFromUrl } from 'views/Collections/utils';

import styles from './collectionsTable.module.css';
import { CompanyTag } from './comp/CompanyTag/CompanyTag';
import { ExportButton } from './comp/ExportButton/ExportButton';
import { ExternalReferenceTag } from './comp/ExternalReferenceTag/ExternalReferenceTag';

interface CollectionsTableProps {
  tableData: CollectionsTableData;
  advisors: Advisor[];
  isLoadingMore: boolean;
  setTableData: (tableData: CollectionsTableData) => void;
  onLoadMore: (query: FilterQuery) => void;
}

export interface Column {
  id: keyof CollectionsTableRow;
  name: string;
  align: 'left' | 'right';
  hidden?: boolean;
}

export const CollectionsTable = ({
  tableData,
  advisors,
  isLoadingMore,
  setTableData,
  onLoadMore,
}: CollectionsTableProps) => {
  const [selectedRow, setSelectedRow] = useState<string[]>([]);
  const [allRowsSelected, setAllRowsSelected] = useState<boolean>(false);
  const { config, productLine } = useContext(UserContext);
  const { formatMessage } = useIntl();
  const { formatDate } = useFormatting();
  const navigate = useNavigateQueryParams();

  const handleCheckboxHeadClick = () => {
    if (allRowsSelected) {
      setSelectedRow([]);
      setAllRowsSelected(false);
    } else {
      setSelectedRow(tableData.rows.map((item) => item.collectionId));
      setAllRowsSelected(true);
    }
  };

  const handleCheckboxBodyClick = (id: string) => {
    if (selectedRow.includes(id)) {
      setSelectedRow(selectedRow.filter((rowId) => rowId !== id));
      setAllRowsSelected(false);
    } else {
      setSelectedRow([...selectedRow, id]);
    }
  };

  const columns: Column[] = [
    { id: 'personalNumber', name: 'Customer', align: 'left' },
    { id: 'creationDate', name: 'Collection date', align: 'right' },
    { id: 'clientName', name: 'Source', align: 'left' },
    {
      id: 'insuranceCompanyList',
      name: productLine === ProductLine.PENSION ? 'Pension company' : 'Insurance company',
      align: 'left',
    },
    { id: 'rowState', name: 'Contact status', align: 'left' },
    {
      id: 'assignee',
      name: 'Assigned advisor',
      align: 'left',
      hidden: [Market.FR].includes(config.market),
    },
    {
      id: 'externalReferenceList',
      name: 'Reference ID',
      align: 'right',
    },
  ];

  const handleLoadMore = () => {
    const query = getQueryFromUrl();
    const lastItem = tableData.rows[tableData.rows.length - 1];
    const beforeDate = subMilliseconds(new Date(lastItem.creationDate), 1);
    query.beforeDate = new Date(`${beforeDate}Z`).toISOString();
    onLoadMore(query);
  };

  const updateRow = (data: CollectionsTableRow, value: string, type: string) => {
    try {
      Promise.all(
        data.collectionIds.map((collectionId) => {
          if (type === 'assignee' && value === '') {
            return removeAdvisor(collectionId).catch((err) => {
              const nErr = new Error('Failed to remove advisor', { cause: err });
              logError(nErr, { collectionId });
              throw nErr;
            });
          }
          if (type === 'assignee') {
            return assignAdvisor(collectionId, value).catch((err) => {
              const nErr = new Error('Failed to assign advisor', { cause: err });
              logError(nErr, { collectionId });
              throw nErr;
            });
          }
          return updateCollectionState(collectionId, value).catch((err) => {
            const nErr = new Error('Failed to assign advisor', { cause: err });
            logError(nErr, { collectionId });
            throw nErr;
          });
        }),
      );

      const newTableData = tableData.rows.map((item) => {
        if (item.collectionId === data.collectionId) {
          return {
            ...item,
            assignee: type === 'assignee' ? value : item.assignee,
            rowState: type === 'rowState' ? value : item.rowState,
          };
        }
        return item;
      });
      setTableData({ rows: newTableData, searchHasMoreData: tableData.searchHasMoreData });
      // TODO: Toast on successful
    } catch (e) {
      // TODO: Do a Toast when the advisor could not be assigned
      logError(new Error('Error updating collection row', { cause: e }), {
        collectionIds: data.collectionId,
      });
    }
  };

  const renderCell = (
    columnId: keyof CollectionsTableRow,
    data: CollectionsTableRow,
  ): string | JSX.Element => {
    if (
      (!data[columnId] && columnId !== 'assignee') ||
      (columnId === 'externalReferenceList' && !data[columnId].length)
    ) {
      return <span style={{ color: 'var(--grey-1)' }}>-</span>;
    }

    switch (columnId) {
      case 'personalNumber':
        return (
          <span className="ph-no-capture">
            {typeof data[columnId] === 'number'
              ? formatPersonalNumber(data[columnId])
              : data[columnId]}
          </span>
        );

      case 'creationDate':
        return formatDate(data[columnId]);

      case 'insuranceCompanyList':
        return (
          <div className={styles.cellCompany}>
            {data[columnId].map((company) => (
              <CompanyTag key={`${data.collectionId}-${company}`} company={company} />
            ))}
          </div>
        );

      case 'rowState':
        return (
          <Select
            aria-label="Contact status"
            id="contact-status"
            variant="filter"
            options={[
              { label: formatMessage({ id: 'Contacted' }), value: CollectionState.CONTACTED },
              { label: formatMessage({ id: 'Not handled' }), value: CollectionState.NOT_SET },
            ]}
            value={data[columnId]}
            onChange={(value) => updateRow(data, value, 'rowState')}
          />
        );

      case 'assignee':
        return (
          <Select
            aria-label="Assigned advisor"
            id="assigned-advisor"
            search={{
              noResultText: formatMessage({ id: 'Nothing found' }),
            }}
            variant="filter"
            options={[
              { email: formatMessage({ id: 'Not assigned' }), userUuid: '' },
              ...advisors.sort((a, b) => a.email.localeCompare(b.email)),
            ].map((advisor) => ({
              label: advisor.email,
              value: advisor.userUuid,
            }))}
            value={data[columnId]}
            onChange={(value) => updateRow(data, value, 'assignee')}
          />
        );

      case 'clientName':
        return data[columnId];

      case 'externalReferenceList':
        return (
          <div className={styles.cellExternal}>
            {data[columnId].map((externalReference) => (
              <ExternalReferenceTag
                externalReference={externalReference}
                key={`${data.collectionId}-${externalReference}`}
              />
            ))}
          </div>
        );

      default:
        return '';
    }
  };

  return (
    <>
      {selectedRow.length > 0 && (
        <ExportButton rows={tableData.rows} selectedRow={selectedRow} columns={columns} />
      )}

      <div className={styles.tableContainer}>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr className={styles.head}>
                <th style={{ borderRadius: '12px' }}>
                  <Checkbox
                    aria-label="checkbox-header"
                    checked={allRowsSelected}
                    onCheckedChange={handleCheckboxHeadClick}
                  />
                </th>
                {columns.map((column) => {
                  if (column.hidden) {
                    return null;
                  }
                  return (
                    <th key={column.id} className={classNames(styles[column.align])}>
                      {formatMessage({ id: column.name })}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {tableData.rows.map((data) => (
                <tr
                  className={classNames(styles.row, {
                    [styles.rowViewed]: data.viewed,
                  })}
                  key={data.collectionId}
                  tabIndex={0}
                  onClick={() => navigate(`/${COLLECTIONS_ROUTE}/${data.collectionIds.join('_')}`)}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      navigate(`/${COLLECTIONS_ROUTE}/${data.collectionIds.join('_')}`);
                    }
                  }}
                >
                  <td>
                    <div
                      role="none"
                      onClick={(e) => e.stopPropagation()}
                      onKeyDown={(e) => e.stopPropagation()}
                    >
                      <Checkbox
                        aria-label={`checkbox-${data.collectionId}`}
                        checked={selectedRow.includes(data.collectionId)}
                        onCheckedChange={() => handleCheckboxBodyClick(data.collectionId)}
                      />
                    </div>
                  </td>
                  {columns.map((column) => {
                    if (column.hidden) {
                      return null;
                    }
                    return (
                      <td key={column.id} className={styles[column.align]}>
                        <span
                          role="none"
                          className={styles.cell}
                          onClick={(e) => e.stopPropagation()}
                          onKeyDown={(e) => e.stopPropagation()}
                        >
                          {renderCell(column.id, data)}
                        </span>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {tableData.searchHasMoreData && (
          <div className={styles.tableBottom}>
            <Button loading={isLoadingMore} onClick={handleLoadMore}>
              {formatMessage({ id: 'shared.show-more' })}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
