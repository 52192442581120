import { Card, Divider, Typography } from '@insurely/ui';

import { useAsync } from 'react-use';

import { ModifiedList } from 'components/ModifiedList/ModifiedList';
import { FormattedMessage } from 'translations';
import { logError } from 'utils/datadog';
import { fetchQuestionsAnswers } from 'views/Collections/api';

import styles from './extraInformation.module.css';

interface Props {
  collectionId: string;
}

export const ExtraInformation = ({ collectionId }: Props) => {
  const { value: questions } = useAsync(() =>
    fetchQuestionsAnswers(collectionId).catch((err) => {
      const nErr = new Error('Failed to fetch extra information', { cause: err });
      logError(nErr, { collectionId });
      throw nErr;
    }),
  );

  if (!questions || questions.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Typography variant="Headline-5" component="h5">
        <FormattedMessage id="page.collection.extra-information.title" />
      </Typography>
      <Card className={styles.listWrapper}>
        <Typography variant="Label-2" component="p" style={{ marginTop: 'var(--space-3)' }}>
          <FormattedMessage id="page.collection.extra-information.list-title" />
        </Typography>
        <Divider />
        <ModifiedList
          rows={questions.map((q) => ({ label: q.question, value: q.answer, description: '' }))}
        />
      </Card>
    </div>
  );
};
