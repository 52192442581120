import { Button, Icon, Link, Typography } from '@insurely/ui';

import { useContext } from 'react';

import UserContext from 'contexts/user/UserContext';
import { useInsuranceTermLink } from 'hooks/useInsuranceTermLink';
import { useIntl } from 'translations';
import { InsuranceResponse } from 'types/insurance';
import { PostHogAction, PostHogCategory, postHogCapture } from 'utils/posthog';

import DownloadButton, { ButtonType } from '../../../DownloadButton/DownloadButton';

import styles from './documentList.module.css';

export type Document = 'save-insurance-data' | 'insurance-terms' | 'policy-letter' | 'cancellation';

interface Props {
  documents: Document[];
  insurance: InsuranceResponse;
}

export const DocumentList = ({ documents, insurance }: Props) => {
  const { formatMessage } = useIntl();
  const { user } = useContext(UserContext);

  const { linkUrl } = useInsuranceTermLink({
    company: insurance.insurance.insuranceCompany as string,
    name: insurance.insurance.insuranceName,
    subType: insurance.insurance.insuranceSubType,
    startDate: insurance.insurance.startDate,
  });

  return (
    <div>
      <div className={styles.title}>
        <div className={styles.icon}>
          <Icon name="file" size={24} />
        </div>
        <Typography component="span" variant="Headline-8">
          {formatMessage({ id: 'Documents' })}
        </Typography>
      </div>
      <div className={styles.list}>
        {documents.map((doc) => {
          if (doc === 'save-insurance-data') {
            return (
              <Button
                variant="tertiary"
                size="small"
                onClick={() => {
                  postHogCapture('Save insurance', {
                    action: PostHogAction.CLICK,
                    category: PostHogCategory.INSURANCE,
                    ...(user && { metadata: { clientId: user?.selectedClientId } }),
                  });
                  window.print();
                }}
                icon={<Icon name="download" aria-hidden size={20} />}
                iconPosition="left"
                key="save-insurance-data"
              >
                {formatMessage({ id: 'Save insurance data' })}
              </Button>
            );
          }
          if (doc === 'insurance-terms') {
            if (!linkUrl) {
              return null;
            }
            return (
              <Button
                variant="tertiary"
                Component={Link}
                size="small"
                href={linkUrl}
                external
                icon={<Icon name="download" aria-hidden size={20} />}
                iconPosition="left"
                key="save-insurance-terms"
              >
                <div style={{ marginTop: 'var(--space-1)', display: 'inline-block' }}>
                  {formatMessage({ id: 'Complete insurance terms' })}
                </div>
              </Button>
            );
          }
          return <DownloadButton type={doc as ButtonType} insurance={insurance} key={doc} />;
        })}
      </div>
    </div>
  );
};
