import { Typography } from '@insurely/ui';
import classNames from 'classnames';
import { Fragment } from 'react/jsx-runtime';

import { ColumnType, ComparisonInsurance, LocalInsurance } from 'components/ComparisonTable/types';
import { upperCaseFirstLetter } from 'utils/formatters';

import { PostHogAction, PostHogCategory, postHogCapture } from 'utils/posthog';

import styles from '../../comparisonTable.module.css';
import { InsuranceHeader } from '../InsuranceHeader/InsuranceHeader';

interface Props {
  leftInsurance: ComparisonInsurance;
  rightInsurance?: ComparisonInsurance;
  columns: {
    id: ColumnType;
    label: string;
  }[][];
}

export const TableHeader = ({ rightInsurance, leftInsurance, columns }: Props) => {
  /**
   * index = 0 => parameter name, 30%
   * index = 1 => insurance 1, 33% or 70%
   * index = 2 => insurance 2, 33%
   */
  const computeColumnWidth = (index: number) => {
    switch (index) {
      case 1:
      case 2:
        return rightInsurance ? '33%' : '70%';
      case 0:
      default:
        return '30%';
    }
  };

  const mapInsurance = (input: ComparisonInsurance): LocalInsurance => ({
    company: input.value.insuranceCompany,
    subType: input.value.insuranceSubType,
    insPackage: {
      name: input.value.packageName,
      options: input.insuranceOptions.map((p) => ({
        label: upperCaseFirstLetter(p.packageName),
        value: `${p.packageName}-${p.insuranceCompany}`,
        company: p.insuranceCompany as string,
      })),
    },
    terms: {
      value: input.value.packageVersionId,
      options: input.versionOptions.map((v) => ({
        label: v.versionId,
        value: v.versionId,
        date: v.termsDate,
      })),
    },
  });

  const handleOnInsuranceChange = ({
    packageName,
    versionId,
    comp,
  }: {
    packageName: string;
    versionId: string;
    comp: ComparisonInsurance;
  }) => {
    const insHit = comp.insuranceOptions.find(
      (i) => `${i.packageName}-${i.insuranceCompany}` === packageName,
    );

    const versionHit = comp.versionOptions.find((v) => v.versionId === versionId);

    if (insHit) {
      comp.onChange({ ...insHit, packageVersionId: versionHit?.versionId ?? '' });
      if (window.location.pathname.match(/sessions|collection/g)) {
        postHogCapture(`tracking:${PostHogAction.CLICK}`, {
          action: PostHogAction.CLICK,
          category: PostHogCategory.COMPARISON,
          object: 'changeComparedInsurance',
        });
      }
    }
  };

  const generateKey = (index: number) => `group-${index}`;

  return (
    <thead>
      <tr>
        <th
          aria-label="empty"
          className={styles.cellGroup}
          style={{ width: computeColumnWidth(0) }}
        />
        <th
          colSpan={columns[1].length}
          className={classNames(styles.cellGroup, styles.headerCell)}
          style={{ width: computeColumnWidth(1) }}
          aria-label="insurance"
        >
          <InsuranceHeader
            insurance={mapInsurance(leftInsurance)}
            onChange={(val) => handleOnInsuranceChange({ ...val, comp: leftInsurance })}
          />
        </th>
        {rightInsurance && (
          <th
            className={classNames(styles.hideOnPrint, styles.cellGroup, styles.headerCell)}
            colSpan={columns[2].length}
            aria-label="insurance"
            style={{ width: computeColumnWidth(2) }}
          >
            <InsuranceHeader
              insurance={mapInsurance(rightInsurance)}
              onChange={(val) => handleOnInsuranceChange({ ...val, comp: rightInsurance })}
            />
          </th>
        )}
      </tr>
      <tr className={styles.tableHeader}>
        {columns.map((colGroup, colGroupIndex) => (
          <Fragment key={generateKey(colGroupIndex)}>
            {colGroup.map((header, index) => (
              <th
                key={header.id}
                className={classNames({
                  [styles.cellGroup]: colGroup.length - 1 === index,
                  [styles.hideOnPrint]: colGroupIndex > 1,
                  [styles.centerCell]: colGroupIndex > 0,
                })}
              >
                <Typography variant="Label-3" component="p">
                  {header.label}
                </Typography>
              </th>
            ))}
          </Fragment>
        ))}
      </tr>
    </thead>
  );
};
