import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Outlet, useParams } from 'react-router-dom';

import { getCollectionStatuses, getSession, getSmsInvites } from 'client/client';

import { Invitation } from 'client/types';
import UserContext from 'contexts/user/UserContext';
import { InsuranceResponse } from 'types/insurance';
import { CollectionStartedResponse, Session } from 'types/v3';
import { FinancialProduct } from 'types/wealth';
import { logError } from 'utils/datadog';
import { ProductLine } from 'utils/product-line';

import { getItemsInCollections } from './helpers';
import SessionContext, { CollectionsWithStatuses } from './SessionContext';

export const SessionContextProvider = () => {
  const { sessionId } = useParams() as {
    sessionId: string;
  };

  const [loadingSessionData, setLoadingSessionData] = useState(true);
  const [errorFetchingSessionData, setErrorFetchingSessionData] = useState(false);
  const [collections, setCollections] = useState<CollectionsWithStatuses[]>([]);
  const [items, setItems] = useState<FinancialProduct[] | InsuranceResponse[]>([]);
  const [smsInvites, setSmsInvites] = useState<Invitation[]>([]);
  const { loadSessions, productLine } = useContext(UserContext);
  const [session, setSession] = useState<Session>();

  const contentType: string = productLine === ProductLine.PENSION ? 'pension' : 'insurance';

  const loadSmsInvites = useCallback(async () => {
    try {
      const invitesResponse = await getSmsInvites(sessionId);
      setSmsInvites(invitesResponse);
      const loadingStatuses = ['REQUESTED', 'ACCEPTED', 'QUEUED', 'SENDING'];
      const responseStatuses = invitesResponse.map((inv) => inv.status);
      const stillSending = responseStatuses.find((s) => loadingStatuses.includes(s));
      if (stillSending) {
        setTimeout(loadSmsInvites, 5000);
      }
    } catch (error) {
      if (![401, 404].includes(error?.response?.status)) {
        const nErr = new Error('Error fetching SMS invites', { cause: error });
        logError(nErr, { sessionId });
      }
    }
  }, [sessionId]);

  const getCollectionsStatuses = async (colls: CollectionStartedResponse[]) => {
    try {
      const responses = await Promise.all(
        colls.map((response) =>
          getCollectionStatuses(response.collectionId).then((update) => ({
            ...response,
            statuses: update.changes.filter((st) => st.status !== 'RUNNING'),
          })),
        ),
      );

      setCollections(responses);
    } catch (error) {
      if (![401, 404].includes(error?.response?.status)) {
        const nErr = new Error('Error fetching SMS invites', { cause: error });
        logError(nErr, { collectionIds: colls.map((c) => c.collectionId) });
      }
    }
  };

  const checkForNewCollection = useCallback(async () => {
    try {
      const newSessionResponse = await getSession(contentType, sessionId);
      const latestCollections = newSessionResponse.collectionStartedResponses;

      setCollections((prevCollections) => {
        if (prevCollections.length < latestCollections.length) {
          const newCollection = latestCollections.find(
            (a) => prevCollections.findIndex((b) => b.collectionId === a.collectionId) === -1,
          );
          if (newCollection) {
            return [...prevCollections, { ...newCollection, statuses: [] }];
          }
        }
        return latestCollections.map((c) => ({
          ...c,
          statuses: prevCollections.find((p) => p.collectionId === c.collectionId)?.statuses ?? [],
        }));
      });
    } catch (error) {
      const status = error?.response?.status;
      if (![401, 404].includes(status)) {
        if (status !== 403) {
          const nErr = new Error('Error checking new collections', { cause: error });
          logError(nErr, { sessionId });
        }
      }
      setErrorFetchingSessionData(true);
    }
  }, [contentType, sessionId]);

  const loadSession = useCallback(async () => {
    const sessionToLoad = await getSession(contentType, sessionId);
    const ids = sessionToLoad.collectionStartedResponses.map(({ collectionId, creationDate }) => ({
      collectionId,
      creationDate,
    }));

    await checkForNewCollection();
    const newItems = await getItemsInCollections(ids, contentType);
    setItems(newItems);
    await getCollectionsStatuses(sessionToLoad.collectionStartedResponses);
    await loadSmsInvites();
    loadSessions(contentType);
    setSession(sessionToLoad);
    setLoadingSessionData(false);
  }, [checkForNewCollection, contentType, loadSessions, loadSmsInvites, sessionId]);

  useEffect(() => {
    loadSession();

    const interval = setInterval(() => {
      checkForNewCollection();
    }, 10000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SessionContextValue = useMemo(
    () => ({
      session,
      loadSession,
      collections,
      updateCollections: setCollections,
      items,
      smsInvites,
      loadSmsInvites,
      loadingSessionData,
      errorFetchingSessionData,
    }),
    [
      session,
      loadSession,
      collections,
      items,
      smsInvites,
      loadSmsInvites,
      loadingSessionData,
      errorFetchingSessionData,
    ],
  );

  return (
    <SessionContext.Provider value={SessionContextValue}>
      <Outlet />
    </SessionContext.Provider>
  );
};
