import { TextInput } from '@insurely/ui';

import { Cancellation } from 'client/types';
import { useIntl } from 'translations';

import cancellationsViewStyles from '../../cancellationView.module.css';

import { CopyToClipboardButton } from '../CopyToClipboardButton/CopyToClipboardButton';

export const ReceiverInfo = ({
  cancellation,
  receiverValue,
}: {
  cancellation: Cancellation;
  receiverValue: string;
}) => {
  const { formatMessage } = useIntl();

  if (cancellation.email || cancellation.phoneNumber) {
    return (
      <TextInput
        label={
          cancellation.email
            ? formatMessage({ id: 'shared.email' })
            : cancellation.phoneNumber
              ? formatMessage({ id: 'shared.cell-phone-number' })
              : ''
        }
        value={receiverValue || ''}
        textLine
        className={cancellationsViewStyles.input}
      />
    );
  }
  return (
    <CopyToClipboardButton
      label={formatMessage({ id: 'shared.manual-link' })}
      copyString={cancellation.documentUrl}
      status={cancellation.status}
    >
      <span className="ph-no-capture">{cancellation.documentUrl}</span>
    </CopyToClipboardButton>
  );
};
