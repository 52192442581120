import { Typography } from '@insurely/ui';
import classNames from 'classnames';

import { useIntl } from 'translations';

import styles from './modifiedList.module.css';

export interface Row {
  label: string;
  value: string | string[];
  description?: string;
  id?: string;
}

type Props = {
  rows: Row[];
  title?: string;
  icon?: React.ReactNode;
  valueClassName?: string;
  highlightedValueIndex?: number;
  emphasizeValues?: boolean;
  showColumns?: boolean;
  listClassName?: string;
};

export function ModifiedList({
  rows,
  title,
  icon,
  valueClassName,
  highlightedValueIndex,
  emphasizeValues,
  showColumns,
  listClassName,
}: Props) {
  const { formatMessage } = useIntl();

  const getValueComponent = (value: string | string[], index: number) => {
    if (emphasizeValues) {
      return (
        <Typography
          component="p"
          variant="Headline-7"
          className={classNames(styles.value, valueClassName, {
            [styles.withBackground]: highlightedValueIndex === index,
          })}
        >
          <span style={{ position: 'relative' }}>{value}</span>
        </Typography>
      );
    }

    if (Array.isArray(value)) {
      return (
        <div>
          {value.map((v) => (
            <Typography
              key={v}
              component="p"
              variant="Label-3"
              className={classNames(styles.value, valueClassName)}
            >
              {v}
            </Typography>
          ))}
        </div>
      );
    }

    return (
      <Typography
        component="p"
        variant="Label-3"
        className={classNames(styles.value, valueClassName)}
      >
        {value}
      </Typography>
    );
  };

  return (
    <div className={styles.wrapper}>
      {(icon || title) && (
        <div className={styles.title}>
          <div className={styles.icon}>{icon}</div>
          <Typography component="span" variant="Headline-8">
            {title}
          </Typography>
        </div>
      )}
      <ul
        className={classNames(styles.container, listClassName, {
          [styles.containerColumns]: showColumns,
        })}
      >
        {rows.map(({ label, value, description }, index) => (
          <li className={styles.row} key={`${JSON.stringify(value)}-${label}`}>
            <div>
              <Typography
                component="p"
                variant="ParagraphCaption"
                className={classNames(styles.label)}
              >
                {label}
              </Typography>

              {value === formatMessage({ id: 'Not available' }) ? (
                <Typography
                  component="p"
                  variant="Label-3"
                  className={classNames(styles.value, valueClassName, styles.notAvailable)}
                >
                  {value}
                </Typography>
              ) : (
                getValueComponent(value, index)
              )}
            </div>

            {description && (
              <Typography component="p" variant="ParagraphTiny" className={styles.description}>
                {description}
              </Typography>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
