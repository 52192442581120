import { Language } from 'translations/types';
import { Market, Permissions } from 'types/v3';
import { InsuranceSubType, InsuranceType } from 'types/v3/Insurance';
import { FinancialProductType } from 'types/wealth';

export enum AdminPanelTab {
  CUSTOMERS = 'CUSTOMERS',
  LIVEBOARDS = 'LIVEBOARDS',
}

export enum CustomerConfigTab {
  OVERVIEW_TAB = 'OVERVIEW_TAB',
  CLIENTS_TAB = 'CLIENTS_TAB',
  USER_GROUPS_TAB = 'USER_GROUPS_TAB',
  USERS_TAB = 'USERS_TAB',
}

export enum UserGroupConfigTab {
  OVERVIEW = 'OVERVIEW',
  CLIENTS = 'CLIENTS',
  USERS = 'USERS',
  LIVEBOARDS = 'LIVEBOARDS',
  PERMISSIONS = 'PERMISSIONS',
}

export interface Customer {
  customerName: string;
  customerId: string;
  numberOfClients: number;
  numberOfUserGroups: number;
  numberOfUsers: number;
  productTypes: ProductType[];
}

export interface User {
  customerId: string;
  email: string;
  enabled: boolean;
  uuid: string;
  permissions?: Permissions[];
  userGroups: UserGroup[];
}

export interface UserGroup {
  createdAt: string;
  customerId: string;
  description: string;
  name: string;
  updatedAt: string;
  uuid: string;
}

export interface CustomerUserGroup extends UserGroup {
  clients: Array<{ clientId: string; clientName: string }>;
  liveboards: Array<{ liveboardId: string; liveboardName: string; description: string }>;
}

export interface ClientSSTConfig {
  language: Language;
  baseUrl: string;
  logotype: string;
}

export interface SmsConfig {
  senderId: string;
  smsTemplate: string;
  createdDate?: string;
  updatedDate?: string;
}

export interface MinimalClientConfig {
  clientId: string;
  clientName: string;
  disabled: boolean;
  productTypes: ProductType[];
}

export interface BaseClientConfig {
  clientId: string;
  apiKeys: string[];
  clientName: string;
  configurationName: string | undefined;
  isDisabled: boolean;
  logRetention: number;
  allowAnonymousRetention: boolean;
  minimumApiVersion: string;
  language: Language;
  hasDpa: boolean;
  forceMtls: boolean;
  filterOtherInsuredPerson: boolean;
  market: Market;
  getPolicyDocuments: boolean;
}

export interface ExtendedClientConfig extends BaseClientConfig {
  productTypes: ProductType[];
  insuranceTypes: InsuranceType[];
  insuranceSubTypes: InsuranceSubType[];
  wealthTypes: FinancialProductType[];
  clientCompanies: string[];
  baseUrl: string;
  logotype: string;
  allowedOrigins: string[];
}

export type ClientConfigApi = Omit<BaseClientConfig, 'clientId' | 'apiKeys'>;

export interface ThoughtspotUser {
  customerId: string;
  customerName: string;
  thoughtspotUserName: string;
}

export enum ProductType {
  SST_PENSION = 'SST_PENSION',
  PENSION = 'PENSION',
  CHECKOUT = 'CHECKOUT',
  INSIGHTS = 'INSIGHTS',
  SALES_OPTIMIZATION = 'SALES_OPTIMIZATION',
}
