import { Button, CompanyLogo, Select, Typography } from '@insurely/ui';

import { useMemo, useState } from 'react';

import { useCompanies } from 'hooks/useCompanies';
import { useIntl } from 'translations';
import { InsuranceSubType } from 'types/v3/Insurance';
import { InsurancePackage } from 'views/Comparison/api';
import { companiesForSubType } from 'views/Comparison/utils';

import styles from '../../comparisonPage.module.css';

interface Props {
  coveragePackages: InsurancePackage[];
  selectedInsuranceSubType: InsuranceSubType | null;
  setSelectedInsuranceSubType: React.Dispatch<React.SetStateAction<InsuranceSubType | null>>;
  onCompare: ({
    insuranceSubType,
    selectedCompany1,
    selectedCompany2,
  }: {
    insuranceSubType: InsuranceSubType;
    selectedCompany1: string;
    selectedCompany2: string;
  }) => void;
  isLoading: boolean;
}

export const CompaniesPicker = ({
  selectedInsuranceSubType,
  setSelectedInsuranceSubType,
  coveragePackages,
  onCompare,
  isLoading,
}: Props) => {
  const { formatMessage } = useIntl();
  const { getCompanyDisplayName, getPrimaryCompanyFromSubsidiary } = useCompanies();

  const [selectedCompany1, setSelectedCompany1] = useState('');
  const [selectedCompany2, setSelectedCompany2] = useState('');

  const allAvailableCompanies = useMemo(
    () => [...new Set((coveragePackages || []).map((p) => p.insuranceCompany))],
    [coveragePackages],
  );

  const availableInsuranceSubTypes = useMemo(
    () => [...new Set((coveragePackages || []).map((p) => p.insuranceSubType))],
    [coveragePackages],
  )
    .map((subType) => ({
      label: formatMessage({ id: subType }),
      value: subType,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const companyOptions = allAvailableCompanies
    .reduce((acc, item) => [...acc, { label: getCompanyDisplayName(item), value: item }], [])
    .filter((c) => {
      if (!selectedInsuranceSubType) {
        return true;
      }
      return companiesForSubType({
        packages: coveragePackages || [],
        insuranceSubType: selectedInsuranceSubType,
      }).includes(c.value);
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  const renderCompanyOption = (value: string) => {
    const companyName = getPrimaryCompanyFromSubsidiary(value);

    return (
      <div className={styles.dropdownOption}>
        <CompanyLogo width={32} company={companyName} />
        <Typography variant="ParagraphBody" component="p">
          {getCompanyDisplayName(value)}
        </Typography>
      </div>
    );
  };

  const handleOnChangeSubType = (val: string) => {
    const subType = val as InsuranceSubType;
    const cp = companiesForSubType({ packages: coveragePackages || [], insuranceSubType: subType });

    if (!cp.includes(selectedCompany1)) {
      setSelectedCompany1('');
    }
    if (!cp.includes(selectedCompany2)) {
      setSelectedCompany2('');
    }
    setSelectedInsuranceSubType(subType);
  };

  const renderCompanyValue = (value: string) => {
    const val = companyOptions.find((o) => o.value === value);

    if (!val) {
      return formatMessage({ id: 'Select' });
    }

    const companyName = getPrimaryCompanyFromSubsidiary(val.value);

    return (
      <div className={styles.dropdownOption}>
        <CompanyLogo width={32} company={companyName} />
        <Typography variant="ParagraphBody" component="p">
          {val.label}
        </Typography>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <Select
        label={formatMessage({ id: 'Insurance type' })}
        value={selectedInsuranceSubType ?? ''}
        options={availableInsuranceSubTypes}
        onChange={handleOnChangeSubType}
        placeholder={formatMessage({ id: 'Select' })}
        variant="small"
        fullWidth
        id="insurance-sub-type"
      />
      <div className={styles.companySelectors}>
        <Select
          id="company-1"
          variant="small"
          label={formatMessage({ id: 'Company 1' })}
          value={selectedCompany1}
          options={companyOptions}
          onChange={setSelectedCompany1}
          disabled={!selectedInsuranceSubType}
          fullWidth
          renderOption={renderCompanyOption}
          renderValue={renderCompanyValue}
        />
        <Select
          id="company-2"
          variant="small"
          label={formatMessage({ id: 'Company 2' })}
          value={selectedCompany2}
          options={companyOptions}
          disabled={!selectedInsuranceSubType}
          onChange={setSelectedCompany2}
          fullWidth
          renderOption={renderCompanyOption}
          renderValue={renderCompanyValue}
        />
      </div>
      <Button
        variant="primary"
        size="large"
        onClick={() =>
          onCompare({
            insuranceSubType: selectedInsuranceSubType as InsuranceSubType,
            selectedCompany1,
            selectedCompany2,
          })
        }
        className={styles.compareButton}
        disabled={!selectedCompany1 || !selectedCompany2 || !selectedInsuranceSubType || isLoading}
        loading={isLoading}
      >
        {formatMessage({ id: 'Compare' })}
      </Button>
    </div>
  );
};
