/* eslint-disable max-lines */
import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { v4 as uuid } from 'uuid';

import { SELECTED_WORKSPACE_ID_KEY } from 'hooks/useSelectedWorkspaceId';
import { getStoredAuthToken, storeAuthToken } from 'services/authToken';
import { InsuranceResponse } from 'types/insurance';
import { Liveboard } from 'types/liveboard';
import {
  CollectionInfoResponse,
  Company,
  Config,
  Session,
  User,
  UserAttributes,
  Advisor,
  SSTClientResponse,
} from 'types/v3';

import { logout } from 'utils/logout';

import {
  CancellationRequest,
  CancellationsResponse,
  CollectionStatusUpdatesResponse,
  CompareParameter,
  CreateCancellationResponse,
  CreateSessionResponse,
  EnableAccountResponse,
  Invitation,
  InvitationRequest,
  LoginResponse,
  RegisterResponse,
  SearchCollectionsRequest,
  SearchCollectionsResponse,
  TermsStatus,
} from './types';

if (process.env.NODE_ENV === 'test') {
  axios.defaults.adapter = 'xhr';
}

export const INSURELY_VERSION_HEADER = '2023-03-15';

const client = axios.create({
  baseURL: !ENV ? `${API_URL}/api` : `${API_URL}`,
});

let userSessionId = uuid();
if (typeof Storage !== 'undefined') {
  userSessionId = window.localStorage.getItem('userSessionId') || '';
}

const isAxiosError = (e: unknown): e is AxiosError<{ message: string; localizedMessage: string }> =>
  axios.isAxiosError(e);

const handleError = (e: unknown) => {
  const SESSION_EXPIRED = 'Session expired';
  const WRONG_EMAIL_OR_PASSWORD = 'Wrong e-mail or password';
  const NOT_ALLOWED = 'Not allowed';
  const JWT_EXCEPTION = 'JWTVerificationException';

  switch (true) {
    case isAxiosError(e) && e.response?.status === 0:
    case isAxiosError(e) &&
      e.response?.status === 400 &&
      e.response.data?.message === SESSION_EXPIRED:
    case isAxiosError(e) &&
      e.response?.status === 401 &&
      e.response.data?.message !== WRONG_EMAIL_OR_PASSWORD &&
      e.response.data?.message !== NOT_ALLOWED:
    case isAxiosError(e) &&
      e.response?.status === 403 &&
      e.response.data?.localizedMessage === JWT_EXCEPTION: {
      logout();
      return Promise.reject(e);
    }
    default:
      return Promise.reject(e);
  }
};

export const newRequest = async (config: AxiosRequestConfig) => {
  client.defaults.headers.common['Insurely-Version'] = INSURELY_VERSION_HEADER;
  client.defaults.headers.common['insurely-session-id'] = userSessionId;
  delete client.defaults.headers.common.authorization;

  client.interceptors.response.use(
    (response) => response,
    (e) => handleError(e),
  );
  return client(config);
};

export const newRequestWithToken = async (config: AxiosRequestConfig) => {
  const authToken = getStoredAuthToken();

  if (authToken) {
    client.defaults.headers.common.authorization = authToken;
  }

  client.defaults.headers.common['Insurely-Version'] = INSURELY_VERSION_HEADER;
  client.defaults.headers.common['insurely-session-id'] = userSessionId;

  const selectedClientId = window.localStorage.getItem(SELECTED_WORKSPACE_ID_KEY) || '';

  if (selectedClientId) {
    client.defaults.headers.common['Selected-Hub-Client-Id'] = selectedClientId.replaceAll('"', '');
  }

  client.interceptors.response.use(
    (response) => {
      if (response?.headers?.authorization) {
        client.defaults.headers.common.authorization = response?.headers?.authorization;
        storeAuthToken(response?.headers?.authorization?.replace('Bearer ', ''));
      }
      return response;
    },
    (e) => handleError(e),
  );

  return client(config);
};

export const login = async (email: string, password: string): Promise<LoginResponse> => {
  userSessionId = uuid();
  if (typeof Storage !== 'undefined') {
    window.localStorage.setItem('userSessionId', userSessionId);
  }
  const res = await newRequestWithToken({
    method: 'POST',
    url: '/user/hub/login',
    data: { email, password },
  });
  return res?.data || res;
};

export const registerUser = async (
  email: string,
  password: string,
  passwordVerification: string,
): Promise<RegisterResponse> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: '/user/hub/register',
    data: { email, password, passwordVerification },
  });
  return res?.data || res;
};

export const forgotPassword = async (email: string): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: '/user/hub/reset-password',
    data: { email },
  });
  return res?.data || res;
};

export const changePassword = async ({
  token,
  password,
  repeatPassword,
}: {
  token: string;
  password: string;
  repeatPassword: string;
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: '/user/change-password',
    data: { token, password, passwordVerification: repeatPassword },
  });
  return res?.data || res;
};

export const enableAccount = async (token: string): Promise<EnableAccountResponse> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: '/user/hub/enable-account',
    data: { token },
  });
  return res?.data || res;
};

export const checkFile = async (fileUrl: string) => {
  const res = await newRequestWithToken({
    method: 'HEAD',
    url: fileUrl,
    headers: {
      'Cache-Control': 'no-cache',
    },
  });
  return res;
};

export const getConfig = async (): Promise<Config> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: '/sst/config',
  });
  return res?.data;
};

export const getUser = async (): Promise<User> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: '/user/hub/me',
  });
  return res?.data;
};

export const getUserAttributes = async (clientId: string): Promise<UserAttributes> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: '/user/hub/attributes',
    headers: {
      'client-id': clientId,
    },
  });
  if (res.status === 204) {
    return { qrRequirementConfirmation: false };
  }
  return res.data;
};

export const putUserAttributes = async (
  clientId: string,
  qrRequirementConfirmation: boolean,
): Promise<UserAttributes> => {
  const res = await newRequestWithToken({
    method: 'PUT',
    url: '/user/hub/attributes',
    headers: {
      'client-id': clientId,
    },
    data: {
      qrRequirementConfirmation,
    },
  });
  return res?.data;
};

export const getSessions = async (contentType: string): Promise<Session[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/session/${contentType}/associated-sessions`,
  });
  return res?.data;
};

export const getSession = async (contentType: string, sessionId: string): Promise<Session> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/session/${contentType}/${sessionId}`,
  });
  return res?.data;
};

export const getInsurances = async (collectionId: string): Promise<InsuranceResponse[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/insurances/new/${collectionId}`,
  });
  return res?.data;
};

export const checkIfPolicyLetterExists = async (
  collectionId: string,
  insuranceId: string,
  insuranceType: string,
  insuranceSubType: string,
): Promise<boolean> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/insurances/${collectionId}/${insuranceId}/has-policy-letter?insuranceType=${insuranceType}&insuranceSubType=${insuranceSubType}`,
  });
  return res?.data;
};

export const getParameters = async (
  insuranceType: string,
  insuranceSubType: string,
  insuranceName: string,
  insuranceCompany: string,
  clientId: string,
  insurelyVersion?: string,
): Promise<CompareParameter[]> => {
  const res = await newRequest({
    method: 'POST',
    url: `/insurance-collection/get-parameters`,
    headers: insurelyVersion
      ? { 'client-id': clientId, 'Insurely-Version': insurelyVersion }
      : { 'client-id': clientId },
    data: {
      insuranceType,
      insuranceSubType,
      insuranceName,
      insuranceCompany,
    },
  });
  return res?.data;
};

export const exportPolicyLetter = async (
  collectionId: string,
  insuranceId: string,
): Promise<Blob> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/insurances/policy-letter`,
    data: {
      collectionId,
      insuranceId,
    },
    responseType: 'blob',
  });
  return res?.data;
};

export const checkIfTermsExist = async (
  company: string,
  subType: string,
  date: string | undefined,
): Promise<TermsStatus> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/insurance-terms/terms-exist/${company}/${subType}/${date ?? ''}`,
  });
  return res?.data;
};

export const getCompanyStatus = async (clientId: string): Promise<Company[]> => {
  const res = await newRequest({
    method: 'GET',
    url: '/insurance-company/status',
    headers: {
      'client-id': clientId,
    },
  });
  return res?.data;
};

export const getCancellations = async (query?: string): Promise<CancellationsResponse> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/insurances/cancellations/search${query || ''}`,
  });
  return res?.data;
};

export const createCancellation = async (
  cancellation: CancellationRequest,
): Promise<CreateCancellationResponse> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: '/sst/insurances/cancellations',
    data: cancellation,
  });
  return res?.data;
};

export const downloadPopulatedCancellationTemplate = async (
  collectionId: string,
  externalId: string,
): Promise<Blob> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: '/sst/insurances/cancellations/pdf',
    responseType: 'blob',
    data: {
      collectionId,
      externalId,
    },
  });
  return res?.data;
};

export const downloadCancellationPdf = async (documentId: string): Promise<Blob> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/insurances/cancellations/${documentId}/pdf`,
    responseType: 'blob',
  });
  return res?.data;
};

export const sendCancellationReminder = async (documentId: string) => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/insurances/cancellations/${documentId}/remind`,
  });
  return res?.data;
};

export const getCancellationEventLog = async (documentId: string, externalId: string) => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/insurances/cancellations/${documentId}/${externalId}/events`,
  });
  return res?.data;
};

export const deleteCancellation = async (documentId: string) => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/insurances/cancellations/${documentId}/cancel`,
  });
  return res?.data;
};

export const getSmsInvites = async (sessionId: string): Promise<Invitation[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/session/invitation/history/${sessionId}`,
  });
  return res?.data;
};

export const sendSmsInvite = async (data: InvitationRequest): Promise<Invitation> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: '/sst/session/invitation',
    data,
  });
  return res?.data;
};

export const updateCollectionStatus = async (
  sessionId: string,
  collectionId: string,
): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/session/complete/${sessionId}/${collectionId}`,
  });
  return res?.data;
};

export const getCollectionStatuses = async (
  collectionId: string,
): Promise<CollectionStatusUpdatesResponse> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/collections/${collectionId}/status-updates`,
  });
  return res?.data;
};

export const getCollectionsInfo = async (
  collectionIds: string[],
): Promise<CollectionInfoResponse[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/collections/info?collectionId=${collectionIds.join(',')}`,
  });
  return res?.data;
};

export const searchCollections = async (
  requestBody: SearchCollectionsRequest,
): Promise<SearchCollectionsResponse> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: '/sst/insurances/search',
    data: { ...requestBody },
  });
  return res?.data;
};

export const getAllAdvisors = async (): Promise<Advisor[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: '/sst/customer/users',
  });
  return res?.data;
};

export const assignAdvisor = async (collectionId: string, userUuid: string): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'PUT',
    url: `/sst/collection-assignee/${collectionId}/user/${userUuid}`,
  });
  return res?.data;
};

export const removeAdvisor = async (collectionId: string): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'DELETE',
    url: `/sst/collection-assignee/${collectionId}`,
  });
  return res?.data;
};

export const updateCollectionState = async (collectionId: string, state: string): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: '/sst/insurances/collection/state',
    data: {
      collectionId,
      state,
    },
  });
  return res?.data;
};

export const getCompanyClients = async (): Promise<SSTClientResponse[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: '/sst/insurances/clients',
  });
  return res?.data;
};

export const createSession = async ({
  clientId,
  freeText,
}: {
  clientId: string;
  freeText: string;
}): Promise<CreateSessionResponse> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: '/sst/session',
    data: { clientId, freeText },
  });
  return res?.data;
};

export const deleteSession = async (sessionId: string): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'DELETE',
    url: `/sst/session/${sessionId}`,
  });
  return res?.data;
};

export const sendLoginMagicLink = async (email: string): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/user/hub/magic-link`,
    data: { email },
  });
  return res?.data;
};

export const magicLinkLogin = async (token: string | null): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/user/hub/magic-link/login/${token}`,
  });
  return res?.data;
};

export const getToughtSpotToken = async (): Promise<string> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: '/thoughtspot/token',
  });
  return res?.data.token;
};

export const fetchAvailableLiveboards = async (): Promise<Liveboard[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: '/thoughtspot/liveboard',
  });

  return res.data;
};

export const changeUserWorkspace = async (workspaceId: string): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/user/hub/select-hub-client/${workspaceId}`,
  });

  return res.data;
};

export const fetchCompaniesForClient = async (): Promise<
  Array<{ companyName: string; companyDisplayName: string }>
> => {
  const res = await newRequestWithToken({
    url: `/sst/config/companies`,
  });

  return res.data;
};
