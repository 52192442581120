import { Icon, IconButton, Typography } from '@insurely/ui';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { LOGOUT_ROUTE } from 'constants/routes';
import UserContext from 'contexts/user/UserContext';
import { FormattedMessage } from 'translations';

import styles from './profile.module.css';

export const Profile = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <div className={styles.content}>
      <div className={styles.textContainer}>
        <Typography component="p" variant="Label-4" style={{ color: 'var(--grey-1)' }}>
          <FormattedMessage id="shared.profile.logged-in-as" />
        </Typography>
        <Typography variant="Label-2" component="p" className={styles.textEllipsis}>
          {user?.email}
        </Typography>
      </div>
      <IconButton icon={<Icon name="logout" size={24} />} onClick={() => navigate(LOGOUT_ROUTE)} />
    </div>
  );
};
