import { IntlShape } from 'translations';

import { CollectionState, CollectionsTableRow, SSTCollectionResponse } from 'types/v3';

import { FilterQuery } from './types';

export const getQueryFromUrl = (): FilterQuery => {
  const searchParams = new URLSearchParams(window.location.search);

  return {
    personalNumber: searchParams.get('personalNumber') || '',
    selectedCompanies: searchParams.get('companies')?.split(',') || [],
    afterDate: searchParams.get('afterDate') || '',
    clientIds: searchParams.get('clientIds')?.split(',') || [],
    showEmpty: !!searchParams.get('showEmpty') || false,
    externalReference: searchParams.get('externalReference') || '',
    assignee: searchParams.get('assignee') || '',
    contactEmail: searchParams.get('contactEmail') || '',
    contactPhone: searchParams.get('contactPhone') || '',
  };
};

export const transformFilterQueryToUrl = (query: FilterQuery) => {
  const searchString = Object.entries(query)
    .map(([key, value]) =>
      (typeof value === 'boolean' && value) || value.length > 0
        ? `&${key}=${Array.isArray(value) ? value.join(',') : value}`
        : '',
    )
    .join('');

  return `?${searchString}`;
};

export const compareString = (str1: string, str2: string) => {
  if (str1 > str2) return -1;
  if (str1 < str2) return 1;
  return 0;
};

export const formatPersonalNumber = (num: string) =>
  `${num.substring(0, 8)}-${num.substring(8, 12)}`;

export const createTableData = (data: SSTCollectionResponse[]): CollectionsTableRow[] => {
  const tableData: CollectionsTableRow[] = [];

  data.forEach((item) => {
    const existingItem = tableData.find(
      (item2) =>
        item.personalNumber === item2.personalNumber &&
        item.creationDate.substring(0, 10) === item2.creationDate.substring(0, 10),
    );

    if (existingItem) {
      existingItem.numberOfFilteredInsurances += item.numberOfFilteredInsurances;
      existingItem.numberOfInsurances += item.numberOfInsurances;

      if (!existingItem.insuranceCompanyList.includes(item.insuranceCompany)) {
        existingItem.insuranceCompanyList.push(item.insuranceCompany);
      }

      if (item.externalReference && existingItem.externalReference !== item.externalReference) {
        existingItem.externalReferenceList.push(item.externalReference);
      }

      if (!existingItem.collectionIds.map((id) => id).includes(item.collectionId)) {
        existingItem.collectionIds.push(item.collectionId);
      }

      existingItem.rowState =
        existingItem.rowState === CollectionState.CONTACTED
          ? item.state ?? CollectionState.NOT_SET
          : CollectionState.NOT_SET;

      existingItem.rowIsViewed = existingItem.viewed ? !!item.viewed : false;
    } else {
      tableData.push({
        ...item,
        insuranceCompanyList: [item.insuranceCompany],
        externalReferenceList: item.externalReference ? [item.externalReference] : [],
        collectionIds: [item.collectionId],
        rowState: item.state ?? CollectionState.NOT_SET,
        rowIsViewed: !!item.viewed,
        assignee: item.assignee || '',
      });
    }
  });

  return tableData.sort((a, b) => compareString(a.creationDate, b.creationDate));
};

export const getRequestBody = (filterQuery?: FilterQuery) => ({
  limit: 100,
  personalNumber: filterQuery?.personalNumber || '',
  companies: filterQuery?.selectedCompanies || [],
  afterDate: filterQuery?.afterDate || '',
  beforeDate: filterQuery?.beforeDate || '',
  clientIds: filterQuery?.clientIds || [],
  excludeEmpty: !filterQuery?.showEmpty,
  externalReference: filterQuery?.externalReference || '',
  assignee: filterQuery?.assignee || '',
  contactPhone: filterQuery?.contactPhone.replace(/\s/g, ''),
  contactEmail: filterQuery?.contactEmail,
});

export const handleExport = ({
  rows,
  selectedRow,
  columns,
  formatMessage,
  formatDate,
}: {
  rows: CollectionsTableRow[];
  selectedRow: string[];
  columns: { id: string; name: string; align: 'left' | 'right' }[];
  formatMessage: IntlShape['formatMessage'];
  formatDate: (date: string) => string;
}) => {
  const result = rows
    .filter((row) => selectedRow.includes(row.collectionId))
    .map((row) => [
      /\d{12}/.test(row.personalNumber)
        ? formatPersonalNumber(row.personalNumber)
        : row.personalNumber,
      formatDate(row.creationDate),
      row.clientName,
      row.insuranceCompanyList.join(),
      row.rowState === CollectionState.CONTACTED
        ? formatMessage({ id: 'Contacted' })
        : formatMessage({ id: 'Not handled' }),
      row.externalReferenceList.join(', '),
    ]);
  result.unshift(columns.map((column) => column.name));
  return result;
};
