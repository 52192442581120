import { Icon, IconButton, Loader, Modal, Tabs, Typography } from '@insurely/ui';
import { useContext, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useAsyncFn, useMount } from 'react-use';

import { Page } from 'components/Page/Page';
import { ADMIN_PANEL_ROUTE } from 'constants/routes';
import UserContext from 'contexts/user/UserContext';
import { useIntl } from 'translations';
import { Permissions } from 'types/v3';

import { logError } from 'utils/datadog';

import {
  getCustomer,
  getAllClientsOnCustomer,
  getAllLiveboardsForCustomer,
  getAllUserGroups,
  getAllUsers,
  getCustomerEmails,
  getCustomerSmsConfig,
} from '../api';
import { Breadcrumbs } from '../comp/Breadcrumb/Breadcrumb';
import { CustomerConfigTab as Tab } from '../config.types';

import styles from '../Customers/configPages.module.css';
import { useQueryParamTab } from '../useQueryParamTab';

import Clients from './Clients/Clients';
import { Overview } from './Overview/Overview';
import Settings from './Settings';
import UserGroups from './UserGroups/UserGroups';
import Users from './Users/Users';

export default function CustomerConfig() {
  const { customerId } = useParams() as {
    customerId: string;
  };
  const { formatMessage } = useIntl();
  const { user } = useContext(UserContext);

  const { tab: activeTab, setTab: setActiveTab } = useQueryParamTab<Tab>(Tab.OVERVIEW_TAB);

  const [isEditing, setIsEditing] = useState(false);
  const [isHoveringTitle, setIsHoveringTitle] = useState(false);

  const [{ loading: isFetchingCustomer, value: customer }, doFetchCustomer] = useAsyncFn(
    () =>
      getCustomer(customerId).catch((err) => {
        const nErr = new Error('Failed to fetch customer', { cause: err });
        logError(nErr, { customerId });
        throw nErr;
      }),
    [customerId],
  );

  const [{ loading: isFetchingClients, value: clients }, doFetchClients] = useAsyncFn(() =>
    getAllClientsOnCustomer(customerId).catch((err) => {
      const nErr = new Error('Failed to fetch all clients for customer', { cause: err });
      logError(nErr, { customerId });
      throw nErr;
    }),
  );
  const [{ loading: isFetchingUsers, value: users }, doFetchUsers] = useAsyncFn(() =>
    getAllUsers(customerId).catch((err) => {
      const nErr = new Error('Failed to fetch all users for customer', { cause: err });
      logError(nErr, { customerId });
      throw nErr;
    }),
  );
  const [{ loading: isFetchingUserGroups, value: userGroups }, doFetchUserGroups] = useAsyncFn(() =>
    getAllUserGroups(customerId).catch((err) => {
      const nErr = new Error('Failed to fetch all user groups for customer', { cause: err });
      logError(nErr, { customerId });
      throw nErr;
    }),
  );
  const [{ loading: isFetchingLiveboards, value: liveboards }, doFetchLiveboards] = useAsyncFn(() =>
    getAllLiveboardsForCustomer({ customerId }).catch((err) => {
      const nErr = new Error('Failed to fetch all liveboards for customer', { cause: err });
      logError(nErr, { customerId });
      throw nErr;
    }),
  );
  const [{ value: emails }, doFetchEmails] = useAsyncFn(() =>
    getCustomerEmails(customerId).catch((err) => {
      const nErr = new Error('Failed to fetch all email domains for customer', { cause: err });
      logError(nErr, { customerId });
      throw nErr;
    }),
  );
  const [{ value: smsConfig }, doFetchSmsConfig] = useAsyncFn(() =>
    getCustomerSmsConfig(customerId).catch((err) => {
      const nErr = new Error('Failed to fetch all sms config for customer', { cause: err });
      if (err.response.status !== 404) {
        logError(nErr, { customerId });
      }
      throw nErr;
    }),
  );

  useMount(() => {
    Promise.all([
      doFetchCustomer(),
      doFetchClients(),
      doFetchUsers(),
      doFetchUserGroups(),
      doFetchLiveboards(),
      doFetchEmails(),
      doFetchSmsConfig(),
    ]);
  });

  const isInsurelyAdmin = useMemo(
    () => user?.permissions.includes(Permissions.INSURELY_ADMIN),
    [user?.permissions],
  );

  const handleOnTabAccess = (tab: 'clients' | 'user-groups' | 'users') => {
    switch (tab) {
      case 'clients':
        setActiveTab(Tab.CLIENTS_TAB);
        break;
      case 'user-groups':
        setActiveTab(Tab.USER_GROUPS_TAB);
        break;
      case 'users':
        setActiveTab(Tab.USERS_TAB);
        break;
      default:
        break;
    }
  };

  const isFetchingEntities =
    isFetchingClients || isFetchingLiveboards || isFetchingUserGroups || isFetchingUsers;

  if (!customer || isFetchingCustomer) {
    return (
      <Page title={formatMessage({ id: 'page.config.customer-config.title' })}>
        <div style={{ position: 'relative', height: '30px' }}>
          <Loader.Content />
        </div>
      </Page>
    );
  }

  function renderTab() {
    switch (activeTab) {
      case Tab.OVERVIEW_TAB:
        return (
          <Overview
            onAccess={handleOnTabAccess}
            entities={{
              users,
              userGroups,
              liveboards,
              clients,
              emails,
              smsConfig,
            }}
            isFetchingEntities={isFetchingEntities}
            refreshers={{
              refreshEmails: doFetchEmails,
              refreshSmsConfig: doFetchSmsConfig,
              refreshLiveboards: doFetchLiveboards,
            }}
          />
        );
      case Tab.USERS_TAB:
        return <Users users={users} onRefresh={doFetchUsers} />;
      case Tab.USER_GROUPS_TAB:
        return (
          <UserGroups
            userGroups={userGroups}
            isFetchingUserGroups={isFetchingUserGroups}
            onRefresh={doFetchUserGroups}
          />
        );
      case Tab.CLIENTS_TAB:
      default:
        return (
          <Clients
            clients={clients}
            isFetchingClients={isFetchingClients}
            onRefresh={doFetchClients}
          />
        );
    }
  }

  return (
    <Page title={formatMessage({ id: 'page.config.customer-config.title' })}>
      {isInsurelyAdmin && (
        <Breadcrumbs
          crumbs={[
            {
              label: formatMessage({ id: 'page.config.customer-config.breadcrumb.customers' }),
              link: `/${ADMIN_PANEL_ROUTE}`,
            },
            { label: customer.customerName },
          ]}
        />
      )}
      <div
        className={styles.editableHeader}
        onMouseEnter={() => setIsHoveringTitle(true)}
        onMouseLeave={() => setIsHoveringTitle(false)}
      >
        <Typography component="h1" variant="Headline-3">
          {customer.customerName}
        </Typography>
        {isHoveringTitle && (
          <IconButton icon={<Icon name="edit" size={18} />} onClick={() => setIsEditing(true)} />
        )}
      </div>
      <div className={styles.tabsWrapper}>
        <Tabs
          items={[
            {
              value: Tab.OVERVIEW_TAB,
              label: formatMessage({ id: 'page.config.customer-config.tabs.overview' }),
            },
            {
              value: Tab.CLIENTS_TAB,
              label: formatMessage({ id: 'page.config.customer-config.tabs.clients' }),
            },
            {
              value: Tab.USER_GROUPS_TAB,
              label: formatMessage({ id: 'page.config.customer-config.tabs.user-groups' }),
            },
            {
              value: Tab.USERS_TAB,
              label: formatMessage({ id: 'page.config.customer-config.tabs.users' }),
            },
          ]}
          activeTab={activeTab}
          onChange={(tab) => setActiveTab(tab as Tab)}
          className={styles.tabs}
        />
        {renderTab()}
      </div>
      <Modal
        isOpen={isEditing}
        onClose={() => setIsEditing(false)}
        title={formatMessage({ id: 'page.config.customer-config.tabs.settings' })}
        className={styles.formModal}
      >
        <Settings
          customerName={customer.customerName}
          customerId={customerId}
          onSuccess={() => {
            setIsEditing(false);
            doFetchCustomer();
          }}
        />
      </Modal>
    </Page>
  );
}
