import { ContextualMenu, ContextualMenuItem, Icon } from '@insurely/ui';
import React, { useState } from 'react';

import { useWindowSize } from 'react-use';

import {
  downloadCancellationPdf,
  downloadPopulatedCancellationTemplate as downloadCancellationTemplateBlob,
} from 'client/client';
import { Cancellation, CancellationStatus } from 'client/types';
import { useIntl } from 'translations';

import { InsuranceResponse } from 'types/insurance';
import { logError } from 'utils/datadog';
import { downloadFile } from 'utils/downloadFile';

import { PostHogAction, PostHogCategory, postHogCapture } from 'utils/posthog';

import styles from './cancellationContextualMenu.module.css';

interface CancellationContextualMenuProps {
  insurance: InsuranceResponse;
  cancellation?: Cancellation;
  setShowErrorCard: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CancellationContextualMenu: React.FC<CancellationContextualMenuProps> = ({
  insurance,
  cancellation,
  setShowErrorCard,
}) => {
  const { formatMessage } = useIntl();
  const [downloadingCancellationTemplate, setDownloadingCancellationTemplate] = useState(false);
  const [downloadingCancellationPdf, setDownloadingCancellationPdf] = useState(false);
  const { width } = useWindowSize();

  const downloadCancellationTemplate = async () => {
    if (insurance.collectionId) {
      setDownloadingCancellationTemplate(true);

      postHogCapture(`tracking:${PostHogAction.CLICK}`, {
        action: PostHogAction.CLICK,
        category: PostHogCategory.CANCELLATION,
        object: 'downloadCancellation',
      });

      try {
        const cancellationTemplate = await downloadCancellationTemplateBlob(
          insurance.collectionId,
          insurance.insurance.externalId,
        );
        downloadFile({
          data: cancellationTemplate,
          id: insurance.collectionId,
          name: `${formatMessage({ id: 'Cancel' })} `,
        });
      } catch (error) {
        logError(new Error('Error downloading cancellation template', { cause: error }), {
          collectionId: insurance.collectionId,
          externalId: insurance.insurance.externalId,
        });
      }
      setDownloadingCancellationTemplate(false);
    }
  };

  const handleDownloadCancellationPdf = async () => {
    if (cancellation?.id) {
      setDownloadingCancellationPdf(true);

      postHogCapture(`tracking:${PostHogAction.CLICK}`, {
        action: PostHogAction.CLICK,
        category: PostHogCategory.CANCELLATION,
        object: 'downloadCancellationPdf',
      });

      try {
        const cancellationPdf = await downloadCancellationPdf(cancellation.id);
        downloadFile({
          data: cancellationPdf,
          id: cancellation?.id,
          name: `${formatMessage({ id: 'Cancel' })} `,
        });
      } catch (error) {
        logError(new Error('Error downloading cancellation PDF', { cause: error }), {
          cancellationId: cancellation.id,
        });
      }
      setDownloadingCancellationPdf(false);
    }
  };

  const showDownloadPdf =
    cancellation &&
    [CancellationStatus.CLOSED, CancellationStatus.PENDING, CancellationStatus.CREATED].includes(
      cancellation.status,
    );
  const showDelete =
    cancellation &&
    [CancellationStatus.PENDING, CancellationStatus.CREATED].includes(cancellation.status);

  if (cancellation && !showDownloadPdf && !showDelete) return null;

  return (
    <ContextualMenu
      openDirection={width < 1100 ? 'left' : 'right'}
      menuWidth="fit-content"
      className={styles.contextualMenu}
    >
      {cancellation ? (
        <>
          {showDownloadPdf && (
            <ContextualMenuItem
              loading={downloadingCancellationPdf}
              onClick={() => handleDownloadCancellationPdf()}
              startIcon={<Icon name="download" aria-hidden size={20} />}
            >
              {formatMessage({ id: 'Download power of attorney' })}
            </ContextualMenuItem>
          )}
          {showDelete && (
            <ContextualMenuItem
              loading={downloadingCancellationTemplate}
              onClick={() => setShowErrorCard(true)}
              startIcon={<Icon name="delete" aria-hidden size={20} />}
            >
              {formatMessage({ id: 'Delete power of attorney' })}
            </ContextualMenuItem>
          )}
        </>
      ) : (
        <ContextualMenuItem
          loading={downloadingCancellationTemplate}
          onClick={() => downloadCancellationTemplate()}
          startIcon={<Icon name="download" aria-hidden size={20} />}
        >
          {formatMessage({ id: 'Download manual power of attorney' })}
        </ContextualMenuItem>
      )}
    </ContextualMenu>
  );
};
