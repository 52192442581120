/* eslint-disable no-param-reassign */
import { datadogRum } from '@datadog/browser-rum';

export const init = () => {
  if (!ENV || ENV === 'localhost') {
    return;
  }
  datadogRum.init({
    applicationId: 'bf0c6b70-bbde-47ad-a102-b1ecf584f25c',
    clientToken: 'pubde67134456ee6e3798f9901ae91a676e',
    site: 'datadoghq.eu',
    service: 'HUB',
    env: ENV,
    sessionSampleRate: 100,
    defaultPrivacyLevel: 'mask',
    silentMultipleInit: true,
    beforeSend(event) {
      const urlShouldBeCleaned = (url: string) => url.match(/reset-password|magic-link/g);

      if (urlShouldBeCleaned(event.view.url)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (event.resource && urlShouldBeCleaned(event.resource.url)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          event.resource.url = event.resource.url?.split('?')[0];
        }
        event.view.url = event.view.url?.split('?')[0];
      }
      return true;
    },
  });
};

// List of key terms that will prevent an error from being reported to Datadog
export const IGNORED_ERRORS_FROM_REPORTING = ['api-js.mixpanel.com'];

export const setUser = (userId: string) => {
  datadogRum.setUser({ id: userId });
};

export const logInfo = (name: string, context?: { [key: string]: unknown }) => {
  datadogRum.addAction(name, context);
};

export const logError = (error: Error, context?: { [key: string]: unknown }) => {
  datadogRum.addError(error, context);
};

export const setContext = (context: { [key: string]: unknown }) => {
  datadogRum.setGlobalContext(context);
};

export const setContextProperty = (key: string, value: unknown) => {
  datadogRum.setGlobalContextProperty(key, value);
};
