import { Button, Icon, Typography } from '@insurely/ui';
import classNames from 'classnames';
import { Fragment, useContext, useState } from 'react';

import { SkeletonText } from 'components/Skeletons/SkeletonText';
import UserContext from 'contexts/user/UserContext';
import { FormattedMessage, useIntl } from 'translations';
import { useFormatting } from 'translations/useFormatting';

import { Filters } from './comp/Filters/Filters';
import { Legend } from './comp/Legend/Legend';
import { ReportModalMetadata, ReportProblemModal } from './comp/ReportProblem/ReportProblemModal';
import { TableHeader } from './comp/TableHeader/TableHeader';
import styles from './comparisonTable.module.css';
import { ColumnType, ComparisonInsurance, Filters as FiltersType } from './types';
import { useComparisonParameters } from './useComparisonParameters';
import { getAvailableColumns } from './utils';

export interface Props {
  leftInsurance: ComparisonInsurance;
  rightInsurance?: ComparisonInsurance;
}

export const ComparisonTable = ({ leftInsurance, rightInsurance }: Props) => {
  const { formatMessage } = useIntl();
  const { user } = useContext(UserContext);
  const { formatCurrency } = useFormatting();

  const [filters, setFilters] = useState<FiltersType>({
    differenceFilter: 'none',
    search: '',
  });

  const [reportProblemModalMetadata, setReportProblemModalMetadata] =
    useState<ReportModalMetadata>();
  const [showLegendModal, setShowLegendModal] = useState(false);

  const [hoveredCell, setHoveredCell] = useState<{
    group: number | undefined;
    row: number | undefined;
    cell: number | undefined;
  }>({
    group: undefined,
    row: undefined,
    cell: undefined,
  });

  const availableColumns = getAvailableColumns({ customerId: user?.customerId });
  const currencyPerMarket = formatCurrency(0).split(' ')[1] ?? formatMessage({ id: 'currency' });

  const columns = [
    [{ id: 'protection', label: formatMessage({ id: 'page.comparison.column.protection' }) }],
    [
      { id: 'included', label: formatMessage({ id: 'page.comparison.column.included' }) },
      {
        id: 'deductible',
        label: `${formatMessage({
          id: 'page.comparison.column.deductible',
        })}, ${currencyPerMarket}`,
      },
      {
        id: 'compensation',
        label: `${formatMessage({
          id: 'page.comparison.column.compensation',
        })}, ${currencyPerMarket}`,
      },
    ].filter((c) => availableColumns.includes(c.id as ColumnType)),
    rightInsurance &&
      [
        { id: 'included', label: formatMessage({ id: 'page.comparison.column.included' }) },
        {
          id: 'deductible',
          label: `${formatMessage({
            id: 'page.comparison.column.deductible',
          })}, ${currencyPerMarket}`,
        },
        {
          id: 'compensation',
          label: `${formatMessage({
            id: 'page.comparison.column.compensation',
          })}, ${currencyPerMarket}`,
        },
      ].filter((c) => availableColumns.includes(c.id as ColumnType)),
  ].filter(Boolean) as Array<Array<{ id: ColumnType; label: string }>>;

  const onOpenReportProblemModal = ({
    parameter,
    value,
    columnName,
    side,
  }: {
    parameter: string;
    value: string;
    columnName: string;
    side: number;
  }) => {
    const parameterDisplayName = (
      side === 0
        ? leftInsurance.parameters.find((p) => p.parameterName === parameter)?.parameterDisplayName
        : rightInsurance?.parameters.find((p) => p.parameterName === parameter)
            ?.parameterDisplayName
    ) as string;
    const company = (
      side === 0 ? leftInsurance.value.insuranceCompany : rightInsurance?.value.insuranceCompany
    ) as string;
    const insuranceName = (
      side === 0 ? leftInsurance.value.packageName : rightInsurance?.value.packageName
    ) as string;

    setReportProblemModalMetadata({
      insuranceName,
      company,
      columnName,
      parameter,
      parameterDisplayName,
      value,
      insuranceType: leftInsurance.value.insuranceType,
      insuranceSubType: leftInsurance.value.insuranceSubType,
    });
  };

  const { groupedRows } = useComparisonParameters({
    left: {
      parameters: leftInsurance.parameters,
      columns: columns[1].map((c) => c.id),
      company: leftInsurance.value.insuranceCompany as string,
    },
    right: rightInsurance
      ? {
          parameters: rightInsurance.parameters,
          columns: columns[2]?.map((c) => c.id),
          company: rightInsurance.value.insuranceCompany as string,
        }
      : undefined,
    filters,
    onOpenReportProblemModal,
  });

  const isTableSideLoading = (index: number) => {
    if (index === 1) {
      return leftInsurance.isLoading;
    }
    if (index === 2) {
      return rightInsurance?.isLoading;
    }
    return false;
  };

  const generateKey = (key: string, id: number) => `${key}-${id}`;

  /**
   * Returns if a cell and its sibling on the other group is hovered.
   * @param group Index of the group of rows.
   * @param row Index of the row within its row group.
   * @param cell Index of the cell within its cell group.
   */
  const isCellHovered = (group: number, row: number, cell: number) => {
    if (typeof hoveredCell.cell !== 'number') {
      return false;
    }
    return cell === hoveredCell.cell && row === hoveredCell.row && group === hoveredCell.group;
  };

  /**
   * Returns if a cell and its sibling on the other group is hovered.
   * @param cellGroupIndex Index of the cell group which is based on the column.
   * E.g. 0 = parameter name, 1 = left insurance, 2 = right insurance.
   * @param rowGroupIndex Index of the group of rows.
   * @param rowIndex Index of the row within its row group.
   * @param index  Index of the cell within its cell group.
   */
  const handleOnMouseEnterCell = ({
    cellGroupIndex,
    rowGroupIndex,
    rowIndex,
    index,
  }: {
    cellGroupIndex: number;
    rowGroupIndex: number;
    rowIndex: number;
    index: number;
  }) => {
    if (cellGroupIndex === 0) {
      return;
    }
    setHoveredCell({
      group: rowGroupIndex,
      row: rowIndex,
      cell: index,
    });
  };

  const handleOnMouseLeaveCell = () =>
    setHoveredCell({
      row: undefined,
      cell: undefined,
      group: undefined,
    });

  return (
    <div className={styles.tableWrapper}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            <div className={styles.iconBubble}>
              <Icon name="document" size={24} />
            </div>
            <Typography variant="Headline-6" component="h6">
              <FormattedMessage id="page.comparison.table.title" />
            </Typography>
          </div>
          <div className={styles.legendButton}>
            <Button
              icon={<Icon name="info" size={24} />}
              size="medium"
              onClick={() => setShowLegendModal(true)}
            >
              <FormattedMessage id="page.comparison.table.legend.button" />
            </Button>
          </div>
        </div>
        <Filters
          filters={filters}
          setFilters={setFilters}
          columns={columns}
          hideDifference={!rightInsurance}
        />
      </div>
      <div className={styles.horizontalScroll}>
        <table className={styles.table}>
          <TableHeader
            leftInsurance={leftInsurance}
            rightInsurance={rightInsurance}
            columns={columns}
          />
          <tbody>
            {groupedRows.map((titleRow, rowGroupIndex) => (
              <Fragment key={generateKey('group-row', rowGroupIndex)}>
                <tr key={generateKey('title-row', rowGroupIndex)} className={styles.rowGroup}>
                  <td>{titleRow.title}</td>
                  <td colSpan={titleRow.rows[0][1].length} aria-label="empty" />
                  {titleRow.rows[0][2] && (
                    <td
                      aria-label="empty"
                      colSpan={titleRow.rows[0][2].length}
                      className={styles.hideOnPrint}
                    />
                  )}
                </tr>
                {titleRow.rows.map((row, rowIndex) => (
                  <tr key={generateKey('row', rowIndex)} className={styles.row}>
                    {row.map((cells, cellGroupIndex) => (
                      <Fragment key={generateKey('content-row', cellGroupIndex)}>
                        {isTableSideLoading(cellGroupIndex) ? (
                          <td
                            colSpan={cells.length}
                            aria-label="loading"
                            className={styles.cellGroup}
                            style={{ minWidth: cells.length === 3 ? '420px' : 'fit-content' }}
                          >
                            <SkeletonText width={rowIndex % 2 === 0 ? 160 : 110} height={16} />
                          </td>
                        ) : (
                          <>
                            {cells.map((cell, index) => (
                              <td
                                key={generateKey('cell', index)}
                                onMouseEnter={() =>
                                  handleOnMouseEnterCell({
                                    cellGroupIndex,
                                    index,
                                    rowGroupIndex,
                                    rowIndex,
                                  })
                                }
                                onMouseLeave={handleOnMouseLeaveCell}
                                className={classNames({
                                  [styles.cellGroup]: cells.length - 1 === index,
                                  [styles.hideOnPrint]: cellGroupIndex > 1,
                                  [styles.cellHovered]:
                                    cellGroupIndex > 0
                                      ? isCellHovered(rowGroupIndex, rowIndex, index)
                                      : false,
                                })}
                              >
                                <div
                                  className={classNames({
                                    [styles.cellContent]: cellGroupIndex !== 0,
                                  })}
                                >
                                  {cell}
                                </div>
                              </td>
                            ))}
                          </>
                        )}
                      </Fragment>
                    ))}
                  </tr>
                ))}
              </Fragment>
            ))}
          </tbody>
        </table>
        {!!reportProblemModalMetadata && (
          <ReportProblemModal
            isOpen={!!reportProblemModalMetadata}
            metadata={reportProblemModalMetadata}
            onClose={() => setReportProblemModalMetadata(undefined)}
          />
        )}
        {showLegendModal && (
          <Legend isOpen={showLegendModal} onClose={() => setShowLegendModal(false)} />
        )}
        {groupedRows.length === 0 && (
          <div className={styles.noResultsContainer}>
            <Typography
              component="p"
              variant="ParagraphBodySmall"
              style={{ color: 'var(--grey-1)' }}
            >
              <FormattedMessage id="page.comparison.no-results" />
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
