// This needs to be kept in sync with com.insurely.models.insurance
import { AddOn } from './Addon';
import { Deductible } from './Deductible';
import { Label } from './Label';

export enum InsuranceLevel {
  UNDEFINED,
  BASE,
  PLUS,
}

export type PremiumFrequencyNumbers = 12 | 4 | 2 | 1 | 0 | -1;

export const PremiumFrequency = {
  '12': 'MONTHLY_PREMIUM_FREQUENCY',
  '4': 'QUARTERLY_PREMIUM_FREQUENCY',
  '2': 'BI_YEARLY_PREMIUM_FREQUENCY',
  '1': 'YEARLY_PREMIUM_FREQUENCY',
  '0': 'ONE_TIME_PAYMENT',
  '-1': 'MONTHLY_PREMIUM_FREQUENCY',
};

export enum PaymentMethod {
  UNDEFINED = 'UNDEFINED',
  AUTOGIRO = 'AUTOGIRO',
  PAPER_INVOICE = 'PAPER_INVOICE',
  ELECTRONIC_INVOICE = 'ELECTRONIC_INVOICE',
  GENERIC_INVOICE = 'GENERIC_INVOICE',
  ONE_TIME_PAYMENT = 'ONE_TIME_PAYMENT',
  EMPLOYER_PAID = 'EMPLOYER_PAID',
  CREDIT_CARD = 'CREDIT_CARD',
}

export enum VehicleCoverage {
  UNDEFINED = 'UNDEFINED',
  FULL_WITH_CHASSI = 'FULL_WITH_CHASSI',
  FULL_WITHOUT_CHASSI = 'FULL_WITHOUT_CHASSI',
  TRAFFIC = 'TRAFFIC',
  NOT_IN_TRAFFIC = 'NOT_IN_TRAFFIC',
}

export enum VehicleType {
  SNOW_MOBILE = 'SNOW_MOBILE',
  ATV = 'ATV',
  JET_SKI = 'JET_SKI',
  TRACTOR = 'TRACTOR',
  UNKNOWN = 'UNKNOWN',
}

export enum AnimalGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  CASTRATED_MALE = 'CASTRATED_MALE',
  CASTRATED_FEMALE = 'CASTRATED_FEMALE',
  UNKNOWN = 'UNKNOWN',
}

export enum AdditionalBuildingType {
  BARN = 'BARN',
  BOAT_HOUSE = 'BOAT_HOUSE',
  GUEST_HOUSE = 'GUEST_HOUSE',
  CARPORT = 'CARPORT',
  ENCLOSED_PORCH = 'ENCLOSED_PORCH',
  GARAGE = 'GARAGE',
  GAZEBO = 'GAZEBO',
  GREEN_HOUSE = 'GREEN_HOUSE',
  POOL = 'POOL',
  SAUNA = 'SAUNA',
  SHED = 'SHED',
  STABLE = 'STABLE',
  UNKNOWN = 'UNKNOWN',
}

export enum FarmPropertyType {
  BARN = 'BARN',
  STABLE = 'STABLE',
  LOG_CABIN = 'LOG_CABIN',
  MACHINE_HALL = 'MACHINE_HALL',
  UNKNOWN = 'UNKNOWN',
}

export interface Driver {
  name: string;
  birthDate: string;
  licenseIssuingDate: string;
}

export interface Claims {
  numberOfClaims: number;
  periodInMonths: number;
}

export type InsuranceType =
  | 'animalInsurance'
  | 'vehicleInsurance'
  | 'houseContentInsurance'
  | 'personInsurance'
  | 'incomeInsurance'
  | 'groupInsurance'
  | 'generalInsurance';

export type InsuranceSubType =
  // VEHICLE_INSURANCE
  | 'carInsurance'
  | 'boatInsurance'
  | 'trailerInsurance'
  | 'mopedInsurance'
  | 'motorcycleInsurance'
  | 'motorhomeInsurance'
  | 'caravanInsurance'
  | 'otherVehicleInsurance'

  // PERSON_INSURANCE
  | 'childInsurance'
  | 'pregnancyInsurance'
  | 'healthInsurance'
  | 'accidentInsurance'
  | 'travelInsurance'
  | 'lifeInsurance'

  // HOUSECONTENT_INSURANCE
  | 'condoInsurance'
  | 'villaInsurance'
  | 'cottageInsurance'
  | 'farmInsurance'

  // ANIMAL_INSURANCE
  | 'catInsurance'
  | 'dogInsurance'
  | 'otherAnimalInsurance'
  | 'horseInsurance'

  // GROUP_INSURANCE
  | 'groupInsurance'

  // INCOME_INSURANCE
  | 'incomeInsurance'
  | 'sicknessInsurance'
  | 'loanInsurance';

export type GeneralInsurance = {
  externalId: string; // Optional since we are setting it after the fact
  endDate?: string;
  insuranceName: string;
  insuranceCompany?: string; // Optional since we are setting it after the fact
  insuranceType: InsuranceType;
  insuranceSubType: InsuranceSubType;
  insuranceNumber?: string;
  premiumAmountYearRounded?: number;
  discountAmount?: number;
  discountPercentage?: string;
  renewalDate?: string;
  renewalAmount?: string;
  startDate?: string;
  coming?: boolean;
  claims?: Claims;
  premiumFrequency: number;
  paymentMethod: PaymentMethod;
  employerPaid?: boolean;
  otherInsuranceHolder?: boolean;
  insuranceHolderName?: string;
  insuranceHolderEmail?: string;
  insuranceHolderDateOfBirth?: string;
  insuranceHolderStreetAddress?: string;
  insuranceObjectStreetAddress?: string;
  insuranceHolderPostalCode?: string;
  insuranceHolderGender?: string;
  insuranceHolderCity?: string;
  insuranceHolderPhoneNumber?: string;
  insuranceHolderClaimsLastFiveYears?: string;
  insuranceHolderClaimsFreeYears?: string;
  insuranceHolderCompanyCode?: string;
  insuranceHolderOccupation?: string;
  insuranceHolderPersonalCode?: string;
  insuranceBeneficiaryCode?: string;
  insuranceBeneficiaryName?: string;
  insuranceInsuredPersonCode?: string;
  insuranceInsuredPersonName?: string;
  insurancePreviouslyRefused?: boolean;
  insuredValuablesAmount?: number;
  numberOfDaysUntilFirstPayout?: number;
  numberOfDaysUntilLastPayout?: number;
  ownershipStatus?: string;
  employmentStatus?: string;
  occupation?: string;
  ownerStatus?: string;
  listedProperty?: string;
  typeOfProperty?: string;
  bikeCoverLimit?: string;
  qualifyingPeriodDays?: number;
  personalPossessionsLimit?: string;
  propertyBuiltYear?: string;
  numberOfBedrooms?: string;
  numberOfBathRooms?: string;
  usagePurposes?: string;
  insuredMonthlyAmount?: number;
  addOns: AddOn[];
  deductibles: Deductible[];
  version: '2';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unmapped?: any;
  // Label is used to distinguish special cases for insurance validation
  label?: Label;
  numberOfAdults: number;
  numberOfChildren: number;
};

export type AnimalInsurance = GeneralInsurance & {
  insuranceType: 'animalInsurance';
  animalName?: string;
  animalBreed?: string;
  animalGender: AnimalGender;
  chipNumber?: string;
  dateOfBirth?: string;
  veterinaryCareAmount?: number;
  veterinaryCareAmountRemaining?: number;
  veterinaryCareCovered?: boolean;
  animalPurchasePrice?: number;
};

export type VehicleInsurance = GeneralInsurance & {
  insuranceType: 'vehicleInsurance';
  registrationNo?: string;
  brand?: string;
  model?: string;
  parking?: boolean;
};

export type CarInsurance = VehicleInsurance & {
  insuranceSubType: 'carInsurance';
  maxMileage: number;
  youngDriver?: boolean;
  primaryDriver?: Driver;
  secondaryDrivers?: Driver[];
  youngDriverAge?: number;
  usedForWork?: boolean;
  // Standardized way of saying hel, halv, trafik, enbart vagnskada, avställning
  coverage: VehicleCoverage;
  insuranceLevel?: InsuranceLevel;
  vehicleOwnership?: string;
  bonusMalus?: { coefficient: number };
};

export type OtherVehicleInsurance = VehicleInsurance & {
  insuranceSubType: 'otherVehicleInsurance';
  // Standardized way of saying hel, halv, trafik, enbart vagnskada, avställning
  coverage: VehicleCoverage;
  vehicleType: VehicleType;
};

export type PersonInsurance = GeneralInsurance & {
  insuranceType: 'personInsurance';
  insuredPersonName?: string;
  insuredAmount?: number;
};

export type AccidentInsurance = PersonInsurance & {
  insuranceSubType: 'accidentInsurance' | 'childInsurance';
  insuredAmountBaseMedicalDisability?: number;
  insuredAmountBaseEconomicalDisability?: number;
  insuredAmountMaxMedicalDisability?: number;
  insuredAmountLife?: number;
};

export type PregnancyInsurance = PersonInsurance & {
  insuranceSubType: 'pregnancyInsurance';
  insuredAmountBaseMedicalDisability?: number;
};

export type LifeInsurance = PersonInsurance & {
  insuranceSubType: 'lifeInsurance';
  insuredAmountLife?: number;
};

export type ChildInsurance = AccidentInsurance;

export type HealthInsurance = PersonInsurance & {
  insuranceSubType: 'healthInsurance';
};

export type IncomeInsurance = GeneralInsurance & {
  insuranceType: 'incomeInsurance';
  // monthly payout amount
  insuredMonthlyAmount?: number;
  // For "sjukkapitalförsäkring", the selected insurance amount which a lump sum payout is based on
  insuredAmount?: number;
  // Number of working days before qualified for payouts
  qualifyingPeriodDays?: number;
  // number of days from unemployment/sickness until first payout
  numberOfDaysUntilFirstPayout?: number;
  // number of days from unemployment/sickness until last payout
  numberOfDaysUntilLastPayout?: number;
};

export type HomeInsurance = GeneralInsurance & {
  insuranceType: 'houseContentInsurance';
  insuranceSubType: 'condoInsurance';
  insuranceObjectStreetAddress?: string;
  insuranceObjectPostalCode?: string;
  insuranceObjectCity?: string;
  securityDoorGridDiscount?: boolean;
  connectedAlarmDiscount?: boolean;
  livingArea?: number;
  numberOfResidents?: number;
  insuredMovablesAmount?: number;
  jointPolicyholder?: boolean;
  unoccupiedMoreThanThirtyDays?: boolean;
  floodedLastTenYears?: boolean;
  professionalUsage?: boolean;
  residentialStatus?: string;
};

export type AdditionalBuilding = {
  buildingType: AdditionalBuildingType;
  // If building area is specifically given populate this
  area?: number;
  // Lower boundry for building size, eg 20 if 20-50 sqm
  areaRangeLower?: number;
  // Upper boundry for building size, eg 50 if 20-50 sqm
  areaRangeUpper?: number;
  floors?: number;
  waterConnection?: boolean;
};

export type GeneralHouseInsurance = GeneralInsurance & {
  insuranceType: 'houseContentInsurance';
  insuranceObjectStreetAddress?: string;
  insuranceObjectPropertyCode?: string;
  insuranceObjectPostalCode?: string;
  insuranceObjectCity?: string;
  connectedAlarmDiscount?: boolean;
  livingArea?: number;
  floor?: number;
  ancillaryArea?: number;
  numberOfResidents?: number;
  insuredMovablesAmount?: number;
  numberOfBathrooms?: number;
  numberOfToilets?: number;
  numberOfFloors?: number;
  numberOfRooms?: number;
  hasBasement?: boolean;
  purchaseYear?: string;
  constructionYear?: string;
  professionalUse?: boolean;
  // true if you are renting the house, false if you own it, empty if not specified
  rental?: boolean;
  additionalBuildings: AdditionalBuilding[];
  additionalAmenities?: string[];
  claimsLastThreeYears?: number;
  constructionYears?: string[];
};

export type VillaInsurance = GeneralHouseInsurance & {
  insuranceSubType: 'villaInsurance';
};

export type CottageInsurance = GeneralHouseInsurance & {
  insuranceSubType: 'cottageInsurance';
};

export type FarmProperty = {
  buildingType: FarmPropertyType;
};

export type FarmInsurance = GeneralHouseInsurance & {
  insuranceSubType: 'farmInsurance';
  farmProperties: FarmProperty[];
};

export type Insurance =
  | GeneralInsurance
  | AnimalInsurance
  | VehicleInsurance
  | CarInsurance
  | PersonInsurance
  | AccidentInsurance
  | ChildInsurance
  | PregnancyInsurance
  | LifeInsurance
  | HomeInsurance
  | VillaInsurance
  | FarmInsurance;

export type BothInsurance = Insurance;

type AllInsurances = Omit<GeneralInsurance, 'insuranceType' | 'insuranceSubType'> &
  Omit<AnimalInsurance, 'insuranceType' | 'insuranceSubType'> &
  Omit<VehicleInsurance, 'insuranceType' | 'insuranceSubType'> &
  Omit<CarInsurance, 'insuranceType' | 'insuranceSubType'> &
  Omit<PersonInsurance, 'insuranceType' | 'insuranceSubType'> &
  Omit<AccidentInsurance, 'insuranceType' | 'insuranceSubType'> &
  Omit<ChildInsurance, 'insuranceType' | 'insuranceSubType'> &
  Omit<PregnancyInsurance, 'insuranceType' | 'insuranceSubType'> &
  Omit<LifeInsurance, 'insuranceType' | 'insuranceSubType'> &
  Omit<HomeInsurance, 'insuranceType' | 'insuranceSubType'> &
  Omit<VillaInsurance, 'insuranceType' | 'insuranceSubType'> &
  Omit<FarmInsurance, 'insuranceType' | 'insuranceSubType'>;

export type AllInsurancesKeys = keyof AllInsurances;
