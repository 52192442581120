import { useContext, useEffect, useState } from 'react';

import { Invitation, SmsStatus } from 'client/types';
import { SMS_FAILED } from 'components/EventLog/utils';
import { CollectionEndStatuses } from 'constants/collection';
import SessionContext from 'contexts/session/SessionContext';
import UserContext from 'contexts/user/UserContext';

import { CollectionStatus } from 'types/v3';
import {
  SessionNotification,
  addSessionNotification,
  getSessionNotifications,
  removeOldSessionNotifications,
} from 'views/Session/utils';

import { NotificationCard } from '../NotificationCard/NotificationCard';

interface Props {
  sessionId: string;
  smsInvites: Invitation[];
}

export const Notifications = ({ sessionId, smsInvites }: Props) => {
  const [sessionNotifications, setSessionNotifications] = useState<SessionNotification[]>([]);
  const { config } = useContext(UserContext);
  const { collections } = useContext(SessionContext);

  useEffect(() => {
    removeOldSessionNotifications(config);
    setSessionNotifications(getSessionNotifications(sessionId));
  }, [sessionId, config]);

  useEffect(() => {
    const endedCollections = collections
      .filter((collection) => {
        const includesEndStatus =
          collection.statuses.filter((status) => CollectionEndStatuses.includes(status.status))
            .length > 0;
        return includesEndStatus;
      })
      .filter((collection) => {
        const lastStatus = collection.statuses.at(-1);

        if (lastStatus?.status !== CollectionStatus.COMPLETED) return true;
        if (
          lastStatus.status === CollectionStatus.COMPLETED &&
          (!collection.numberOfInsurances || !collection.numberOfFilteredInsurances)
        ) {
          return false;
        }
        const numInsurancesInCollection = collection?.numberOfInsurances ?? 0;
        const numFilteredInsurancesInCollection = collection?.numberOfFilteredInsurances ?? 0;
        const hiddenInsurances = numInsurancesInCollection - numFilteredInsurancesInCollection;
        return lastStatus.status === CollectionStatus.COMPLETED && hiddenInsurances > 0;
      });

    endedCollections.forEach((collection) => {
      addSessionNotification({
        sessionId,
        type: 'collection',
        lastUpdated: collection.statuses[collection.statuses.length - 1].updated,
        collectionId: collection.collectionId,
        closed: false,
      });
    });

    setSessionNotifications(getSessionNotifications(sessionId));
  }, [collections, sessionId]);

  useEffect(() => {
    smsInvites.forEach((sms) => {
      if (sms !== null && (SMS_FAILED as (SmsStatus | undefined)[]).includes(sms.status)) {
        addSessionNotification({
          sessionId: sessionId ?? '',
          type: 'sms',
          lastUpdated: sms.creationDate,
          closed: false,
        });
      }
    });
    setSessionNotifications(getSessionNotifications(sessionId));
  }, [sessionId, smsInvites]);

  if (!sessionNotifications.length) return null;

  return sessionNotifications.map((item) => (
    <NotificationCard key={item.lastUpdated} item={item} />
  ));
};
