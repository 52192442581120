import { Market } from 'types/v3';

import { Language } from './types';

export const getAvailableLanguagesForMarket = (market: Market) => {
  switch (market) {
    case Market.DK:
      return [Language.DA, Language.EN];
    case Market.FR:
      return [Language.FR, Language.EN];
    case Market.SE:
      return [Language.SV, Language.EN];
    case Market.EE: // Estonia uses English only for now
    default:
      return [Language.EN];
  }
};

export const languageToCountry = (language: Language) => {
  switch (language) {
    case Language.FR:
      return 'FRANCE';
    case Language.DA:
      return 'DENMARK';
    case Language.EN:
      return 'UNITEDKINGDOM';
    case Language.SV:
      return 'SWEDEN';
    default:
      return null;
  }
};
