import { Checkbox, StatusText, Typography } from '@insurely/ui';
import classNames from 'classnames';

import tableStyles from 'styles/table.module.css';
import { FormattedMessage, useIntl } from 'translations';

import { Target } from 'views/Config/comp/ActionBanner/type';
import { PermissionMapper } from 'views/Config/comp/PermissionMapper/PermissionMapper';
import { User } from 'views/Config/config.types';

import configStyles from '../../config.module.css';

import { TabWrapper } from './TabWrapper';
import styles from './userGroupConfig.module.css';

export const MembersContent = ({
  members,
  loading,
  onRefresh,
}: {
  loading: boolean;
  members?: User[];
  onRefresh: () => void;
}) => {
  const { formatMessage } = useIntl();

  return (
    <TabWrapper loading={loading} refresh={onRefresh} items={members} target={Target.USERS}>
      {({ filteredItems, handleOnSelectAll, handleOnSelect, selectedItems }) => (
        <table className={tableStyles.table}>
          <thead>
            <tr className={classNames(tableStyles.head, configStyles.tableHeader)}>
              <th style={{ width: '5%' }}>
                <Checkbox
                  aria-label="checkbox-user"
                  checked={selectedItems.length > 0}
                  partial={selectedItems.length > 0 && members?.length !== selectedItems.length}
                  onCheckedChange={handleOnSelectAll}
                  disabled={members?.length === 0}
                />
              </th>
              <th aria-labelledby="user name" style={{ width: '40%' }}>
                <FormattedMessage id="page.config.user-group-config.members.column.name" />
              </th>
              <th aria-labelledby="permissions" style={{ width: '40%' }}>
                <FormattedMessage id="page.config.user-group-config.members.column.permissions" />
              </th>
              <th aria-labelledby="status" style={{ width: '15%' }}>
                <FormattedMessage id="page.config.user-group-config.members.column.status" />
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredItems?.length === 0 && (
              <tr>
                <td colSpan={4} aria-label="empty-row-cell">
                  <div className={configStyles.emptyRow}>
                    <Typography variant="ParagraphBodySmall" component="p">
                      <FormattedMessage id="page.config.user-group-config.members.empty-table" />
                    </Typography>
                  </div>
                </td>
              </tr>
            )}
            {(filteredItems as User[])
              .sort((a, b) => a.email.localeCompare(b.email))
              .map((user) => (
                <tr
                  className={classNames(
                    tableStyles.row,
                    styles.notClickableRow,
                    configStyles.tableRow,
                  )}
                  key={user.uuid}
                  tabIndex={0}
                >
                  <td aria-label="user-select">
                    <Checkbox
                      aria-label="checkbox-user"
                      checked={selectedItems.some((u) => user.uuid === u)}
                      onCheckedChange={() => handleOnSelect(user.uuid)}
                    />
                  </td>
                  <td>
                    <Typography component="p" variant="ParagraphCaption">
                      {user.email}
                    </Typography>
                  </td>
                  <td>{user.permissions && <PermissionMapper permissions={user.permissions} />}</td>
                  <td aria-label="status">
                    <StatusText
                      color={user.enabled ? 'var(--green)' : 'var(--red-1)'}
                      text={formatMessage({
                        id: `page.config.customer-config.users.${user.enabled ? 'active' : 'inactive'}`,
                      })}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </TabWrapper>
  );
};
