import { Button, Icon } from '@insurely/ui';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { useNavigate } from 'react-router-dom';

import { useIntl } from 'translations';

import styles from './page.module.css';

interface PageProps {
  title?: string;
  className?: string;
  scrollToTop?: boolean;
  goback?: boolean;
  children: React.ReactNode;
  maxWidth?: string;
}

export const Page = ({ title, className, scrollToTop, goback, children, maxWidth }: PageProps) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  useEffect(() => {
    if (scrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [scrollToTop]);

  return (
    <div className={classNames(styles.page, className)}>
      <HelmetProvider>
        <Helmet>
          <title itemProp="name" lang="en">{`${title} | Insurely`}</title>
        </Helmet>
      </HelmetProvider>

      <div className={styles.mobileHeader} />

      {goback && (
        <header className={styles.header}>
          <Button
            className={styles.backButton}
            variant="tertiary"
            onClick={() => navigate(-1)}
            icon={<Icon name="arrow-left" size={20} />}
          >
            {formatMessage({ id: 'shared.go-back' })}
          </Button>
        </header>
      )}
      <section className={styles.content} style={{ maxWidth }}>
        {children}
      </section>
    </div>
  );
};
