import { Button, Typography, Icon } from '@insurely/ui';

import React, { useEffect, useState } from 'react';

import { CancellationStatus as CancellationStatusEnum } from 'client/types';
import { useIntl } from 'translations';

import styles from './copyToClipboardButton.module.css';

export const CopyToClipboardButton: React.FC<{
  copyString: string;
  label: string;
  status: string;
  children: React.ReactNode;
}> = ({ copyString, label, status, children }) => {
  const { formatMessage } = useIntl();
  const [copied, setCopied] = useState(false);

  let timeout: NodeJS.Timeout;

  function copyToClipboard() {
    setCopied(true);
    navigator.clipboard.writeText(copyString);
    timeout = setTimeout(() => setCopied(false), 1500);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => clearTimeout(timeout), []);

  return (
    <div className={styles.container}>
      {status === CancellationStatusEnum.REJECTED || status === CancellationStatusEnum.CLOSED ? (
        <>
          <p className={styles.label}>{formatMessage({ id: 'shared.manual-link' })}</p>
          <div className={styles.urlContainer}>
            <Typography component="p" variant="ParagraphCaption">
              {children}
            </Typography>
          </div>
        </>
      ) : (
        <>
          <p className={styles.label}>{label}</p>
          <div className={styles.content}>
            <div className={styles.urlContainer}>
              <Typography component="p" variant="ParagraphCaption">
                {children}
              </Typography>
            </div>
            <Button
              variant="secondary"
              size="small"
              style={{ maxHeight: '27px', minWidth: '75px' }}
              onClick={copyToClipboard}
            >
              {copied ? (
                <div style={{ marginTop: 'var(--space-1)' }}>
                  <Icon name="check" size={20} aria-hidden color="var(--green)" />
                </div>
              ) : (
                formatMessage({ id: 'Copy' })
              )}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
