import { Button, Card, Icon, Typography } from '@insurely/ui';
import { useContext } from 'react';

import UserContext from 'contexts/user/UserContext';
import { useIntl } from 'translations';

import { ProductLine } from 'utils/product-line';

import styles from '../EmptyState/index.module.css';

interface CollectionErrorProps {
  retry?: () => void;
}

const CollectionError = ({ retry }: CollectionErrorProps) => {
  const { formatMessage } = useIntl();
  const { productLine } = useContext(UserContext);
  const type = productLine === ProductLine.PENSION ? 'pensions' : 'insurances';

  return (
    <Card className={styles.errorCard}>
      <Icon size={36} name="error" />
      <Typography className={styles.collectionErrorTitle} component="h4" variant="Headline-6">
        {formatMessage({ id: 'shared.something-went-wrong' })}
      </Typography>
      <Typography
        component="p"
        variant="ParagraphBodySmall"
        className={styles.collectionErrorParagraph}
      >
        {formatMessage({
          id: `It seems something went wrong when we were going to pick up the ${type}.`,
        })}
      </Typography>
      {retry && (
        <Button variant="primary" onClick={retry}>
          {formatMessage({ id: 'shared.try-again' })}
        </Button>
      )}
    </Card>
  );
};

export default CollectionError;
