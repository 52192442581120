import { differenceInCalendarDays, isToday } from 'date-fns';

import { z } from 'zod';

import { Cancellation, DeliveryMethod } from 'client/types';
import { IntlShape } from 'translations';
import { InsuranceResponse } from 'types/insurance';
import { Config, CountryCode, User } from 'types/v3';

export interface FormValues {
  externalId: string;
  deliveryMethod: DeliveryMethod.EMAIL | DeliveryMethod.SMS | DeliveryMethod.API;
  receiver: string;
  countryCode: CountryCode;
  terminationDate: string;
  newInsuranceNumber?: string;
  personalNumber?: string;
}

export const schema = (
  formatMessage: IntlShape['formatMessage'],
  withPersonalNumber: boolean,
  requireNewInsuranceNumber: boolean,
) =>
  z
    .preprocess(
      (values: FormValues) => ({
        ...values,
        receiver: { value: values.receiver, deliveryMethod: values.deliveryMethod },
      }),
      z.object({
        deliveryMethod: z.nativeEnum(DeliveryMethod),
        terminationDate: z.preprocess((arg) => {
          if (typeof arg === 'string' || arg instanceof Date) {
            return new Date(arg);
          }
          return null;
        }, z.date()),
        newInsuranceNumber: z
          .string()
          .optional()
          .refine(
            (value) => {
              if (requireNewInsuranceNumber) {
                return z.string().min(1).safeParse(value).success;
              }
              return true;
            },
            { message: formatMessage({ id: 'Please enter a new insurance number.' }) },
          ),
        receiver: z
          .object({
            deliveryMethod: z.nativeEnum(DeliveryMethod),
            value: z.string(),
          })
          .refine(
            ({ deliveryMethod, value }) => {
              if (deliveryMethod === DeliveryMethod.EMAIL) {
                return z.string().min(1).safeParse(value).success;
              }
              return true;
            },
            { message: formatMessage({ id: 'Please enter an e-mail.' }) },
          )
          .refine(
            ({ deliveryMethod, value }) => {
              if (deliveryMethod === DeliveryMethod.EMAIL) {
                return z.string().email().safeParse(value).success;
              }
              return true;
            },
            { message: formatMessage({ id: 'Please enter a valid e-mail' }) },
          )
          .refine(
            ({ deliveryMethod, value }) => {
              if (deliveryMethod === DeliveryMethod.SMS) {
                return z.string().min(1).safeParse(value).success;
              }
              return true;
            },
            { message: formatMessage({ id: 'shared.enter-phone-number' }) },
          )
          .refine(
            ({ deliveryMethod, value }) => {
              if (deliveryMethod === DeliveryMethod.SMS) {
                return z
                  .string()
                  .regex(/^[0-9]{7,11}$/)
                  .safeParse(value).success;
              }
              return true;
            },
            { message: formatMessage({ id: 'shared.enter-valid-phone-number' }) },
          ),
        countryCode: z.nativeEnum(CountryCode),
        personalNumber: z
          .string()
          .optional()
          .refine(
            (value) => {
              if (withPersonalNumber) {
                return z.string().min(1).safeParse(value).success;
              }
              return true;
            },
            { message: formatMessage({ id: 'Please enter a personal number.' }) },
          )
          .refine(
            (value) => {
              if (withPersonalNumber) {
                return z
                  .string()
                  .regex(/^(19|20)[0-9]{2}((0[1-9])|(1[0-2]))([0-2][0-9]|3[0-1])\d{4}$/)
                  .safeParse(value).success;
              }
              return true;
            },
            { message: formatMessage({ id: 'Please enter a valid personal number.' }) },
          ),
      }),
    )
    .transform((values) => ({
      ...values,
      receiver: values.receiver.value,
    }));

interface Reminder {
  documentId: string;
  date: Date;
}

export const getHasRecentReminder = (documentId: string) => {
  if (typeof Storage !== 'undefined') {
    const recentReminders = JSON.parse(
      window.localStorage.getItem('recentReminders') || '[]',
    ) as Reminder[];
    return recentReminders.some(
      (reminder: Reminder) =>
        reminder.documentId === documentId && isToday(new Date(reminder.date)),
    );
  }
  return false;
};

export const updateRecentReminders = (documentId: string) => {
  if (typeof Storage !== 'undefined') {
    const recentReminders = (JSON.parse(window.localStorage.getItem('recentReminders') || '[]') ||
      []) as Reminder[];
    const newRecentReminders = recentReminders
      .filter(
        (reminder: Reminder) =>
          !isToday(new Date(reminder.date)) || reminder.documentId !== documentId,
      )
      .concat([{ documentId, date: new Date() }]);
    window.localStorage.setItem('recentReminders', JSON.stringify(newRecentReminders));
  }
};

export const getRenewalDateDifference = ({
  cancellation,
  insurance,
  terminationDate,
}: {
  cancellation?: Cancellation;
  insurance: InsuranceResponse;
  terminationDate: string;
}) => {
  if (cancellation?.insurances[0]?.terminationDate && insurance.insurance.renewalDate) {
    return differenceInCalendarDays(
      new Date(cancellation.insurances[0].terminationDate),
      new Date(insurance.insurance.renewalDate),
    );
  }
  if (terminationDate && insurance.insurance.renewalDate) {
    return differenceInCalendarDays(
      new Date(terminationDate),
      new Date(insurance.insurance.renewalDate),
    );
  }
  return 0;
};

export const getFormattedSubmitData = ({
  config,
  insurance,
  user,
  data,
  requirePersonalNumber,
}: {
  config: Config;
  insurance: InsuranceResponse;
  user?: User;
  data: FormValues;
  requirePersonalNumber: boolean;
}) => ({
  authenticationMethod: ENV === 'prod' ? 'BANK_ID' : 'STANDARD',
  viewAuthenticationMethod: config.viewCancellationAuthenticationMethod,
  collectionId: insurance.collectionId,
  deliveryMethod: data.deliveryMethod,
  ...(requirePersonalNumber ? { personalNumber: data.personalNumber } : {}),
  authorEmail: {
    value: user?.email,
  },
  insurances: [
    {
      externalId: insurance.insurance.externalId,
      terminationDate: data.terminationDate,
      newInsuranceNumber: data.newInsuranceNumber,
    },
  ],
  ...(data.deliveryMethod === DeliveryMethod.EMAIL && {
    email: {
      value: data.receiver,
    },
  }),
  ...(data.deliveryMethod === DeliveryMethod.SMS && {
    phoneNumber: {
      countryCode: data.countryCode,
      number: Number(data.receiver),
    },
  }),
  ...(data.deliveryMethod === DeliveryMethod.API && { deliveryMethod: data.deliveryMethod }),
});
