import { useContext } from 'react';

import UserContext from 'contexts/user/UserContext';
import Utils from 'services/utils';
import { useIntl } from 'translations';
import { Language } from 'translations/types';
import { useFormatting } from 'translations/useFormatting';
import { InsuranceResponse } from 'types/insurance';
import { Market } from 'types/v3';
import { AllInsurancesKeys, PremiumFrequencyNumbers } from 'types/v3/Insurance';
import { mapPremiumFrequency } from 'utils/insurance';

type Props = {
  insurance: InsuranceResponse;
  keys: {
    key: AllInsurancesKeys | 'addons' | 'address';
    copyKey: string;
    markets: Market[];
    insuranceTypes: string[];
    subTypes: string[];
  }[];
};

const EXCLUDED_KEYS_FORMATTING = [
  'brand',
  'model',
  'insuredPersonName',
  'insuranceObjectCity',
  'insuranceObjectStreetAddress',
  'insuranceHolderName',
  'insuranceHolderOccupation',
  'insuranceNumber',
  'animalName',
  'registrationNo',
];

export const useSetSections = ({ insurance, keys }: Props) => {
  const { config } = useContext(UserContext);
  const { formatDate, formatCurrency } = useFormatting();
  const { formatMessage } = useIntl();

  const { insurance: ins, addons } = insurance;

  const filteredKeys = keys
    .filter(({ markets }) => markets.length === 0 || markets.includes(config.market))
    .filter(
      ({ insuranceTypes: insType }) => insType.length === 0 || insType.includes(ins.insuranceType),
    )
    .filter(
      ({ subTypes: subType }) => subType.length === 0 || subType.includes(ins.insuranceSubType),
    );
  const getPostalCode = (input: string | undefined) =>
    config.insuranceConfig?.language === Language.SV && input
      ? `${input.slice(0, 3)} ${input.slice(3)}`
      : input || '';

  const sectionDetails = filteredKeys
    .map(({ key, copyKey }: { key: AllInsurancesKeys | 'addons' | 'address'; copyKey: string }) => {
      let value: string | string[] = '';
      let description = '';
      let label = formatMessage({ id: copyKey });

      const uppercaseRegex = /^[A-Z]+$/;

      const valueForKey = ins[key as keyof typeof ins];

      switch (key) {
        case 'insuredAmount':
        case 'insuredAmountBaseMedicalDisability':
        case 'insuredAmountBaseEconomicalDisability':
        case 'insuredAmountMaxMedicalDisability':
        case 'insuredMovablesAmount':
        case 'veterinaryCareAmount':
        case 'renewalAmount':
        case 'bikeCoverLimit':
        case 'personalPossessionsLimit':
          value =
            typeof valueForKey === 'number'
              ? formatCurrency(valueForKey)
              : formatMessage({ id: 'Not available' });
          break;

        case 'professionalUse':
        case 'jointPolicyholder':
        case 'hasBasement':
        case 'securityDoorGridDiscount':
        case 'unoccupiedMoreThanThirtyDays':
        case 'floodedLastTenYears':
          value = valueForKey ? formatMessage({ id: 'Yes' }) : formatMessage({ id: 'No' });
          break;

        case 'insuranceObjectPostalCode':
          value = getPostalCode(valueForKey);
          break;

        case 'claims':
          if (valueForKey) {
            if (valueForKey.numberOfClaims > 0) {
              label = formatMessage(
                { id: 'shared.insurance.claims-last-months' },
                { count: valueForKey.periodInMonths },
              );
            }
            value = valueForKey.numberOfClaims;
          }

          break;

        case 'address':
          value = [
            insurance.insurance.insuranceHolderStreetAddress,
            getPostalCode(insurance.insurance.insuranceHolderPostalCode),
            insurance.insurance.insuranceHolderCity,
          ].filter((item) => item && item.length > 0) as string[];
          break;

        case 'maxMileage':
          if (valueForKey === -1 || typeof valueForKey !== 'number') {
            value = formatMessage({ id: 'Not available' });
          } else {
            value = formatMessage(
              { id: '{mileage}km/year' },
              { mileage: Utils.addThousandSeparator(valueForKey) },
            );
          }
          break;

        case 'parking':
          value = [
            valueForKey?.parkingAddress?.street,
            valueForKey?.parkingAddress?.postalCode,
            valueForKey?.parkingAddress?.city,
          ].filter((item) => item && item.length > 0);
          break;

        case 'startDate':
        case 'renewalDate':
          value = valueForKey ? formatDate(valueForKey) : formatMessage({ id: 'Not available' });
          break;

        case 'ancillaryArea':
        case 'livingArea':
          value =
            typeof valueForKey === 'number'
              ? `${valueForKey}m²`
              : formatMessage({ id: 'Not available' });
          break;

        case 'constructionYears':
          value = valueForKey?.join(', ');
          break;

        case 'premiumFrequency':
          value = formatMessage({
            id: mapPremiumFrequency(valueForKey as PremiumFrequencyNumbers),
          });
          break;

        case 'addons':
          if (addons?.length) {
            label = formatMessage({ id: 'Addons' });
            value = addons.map((addOn) => addOn.addOnName);
          }
          break;

        case 'premiumAmountYearRounded':
          {
            const sum =
              copyKey === 'shared.monthly-premium' &&
              typeof ins.premiumAmountYearRounded === 'number'
                ? ins.premiumAmountYearRounded / 12
                : valueForKey;

            if (ins.employerPaid) {
              value = `${formatCurrency(0)}*`;
              description = `* ${formatMessage({ id: 'Employer or union paid' })}`;
              break;
            }

            value =
              typeof sum === 'number'
                ? formatCurrency(Math.ceil(sum))
                : formatMessage({ id: 'Not available' });
          }
          break;

        case 'discountAmount':
          if (typeof ins.discountAmount === 'number') {
            value = formatMessage(
              { id: 'shared.amount-per-year' },
              { amount: formatCurrency(ins.discountAmount) },
            );
          } else if (typeof ins.discountPercentage === 'number') {
            value = `${ins.discountPercentage}%`;
          } else {
            value = formatMessage({ id: 'Not available' });
          }
          break;

        case 'insuredAmountLife':
          if ('insuredAmountLife' in ins) {
            if (['accidentInsurance', 'lifeInsurance'].includes(ins.insuranceSubType)) {
              label = formatMessage({ id: 'Death compensation' });
              value =
                typeof ins.insuredAmountLife === 'number'
                  ? formatCurrency(ins.insuredAmountLife)
                  : formatMessage({ id: 'Not available' });
            } else {
              label = formatMessage({ id: 'Insurance amount' });
              value =
                typeof ins.insuredAmountLife === 'number'
                  ? formatCurrency(ins.insuredAmountLife)
                  : formatMessage({ id: 'Not available' });
            }
          }
          break;

        case 'residentialStatus':
        case 'ownershipStatus':
        case 'insuranceHolderGender':
        case 'paymentMethod':
        case 'insuranceLevel':
        case 'floor':
        case 'usagePurposes':
          if (valueForKey) {
            if (Array.isArray(valueForKey)) {
              value = valueForKey
                .map((item: string) =>
                  formatMessage({
                    id: `insurance.${key}.${item}`,
                  }),
                )
                .join(', ');
            } else {
              value = formatMessage({ id: `insurance.${key}.${valueForKey}` });
            }
          }
          break;
        case 'bonusMalus': {
          if (valueForKey) {
            value =
              typeof valueForKey.coefficient === 'number'
                ? valueForKey.coefficient.toFixed(2)
                : valueForKey.coefficient;
          }
          break;
        }
        default:
          // Catch values that includes an ENUM that we haven't mapped yet with a translation above
          if (
            valueForKey &&
            typeof valueForKey === 'string' &&
            uppercaseRegex.test(valueForKey.replace(/[\s_]/g, '')) &&
            !EXCLUDED_KEYS_FORMATTING.includes(key)
          ) {
            // This should generate in an error thrown by Lokalise
            value = formatMessage({ id: `insurance.${key}.${valueForKey}` });
          }
          value = valueForKey;
          break;
      }

      value = !value || value.length === 0 ? formatMessage({ id: 'Not available' }) : value;

      return { label, value, description };
    })
    .filter(({ value }) => value !== formatMessage({ id: 'Not available' }));

  return sectionDetails;
};
