import { Loader, Tabs, Typography } from '@insurely/ui';
import { AuthType, init } from '@thoughtspot/visual-embed-sdk';

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { useWindowSize } from 'react-use';

import { getToughtSpotToken } from 'client/client';

import { Page } from 'components/Page/Page';
import { ANALYTICS_ROUTE } from 'constants/routes';
import AppContext from 'contexts/app/AppContext';
import UserContext from 'contexts/user/UserContext';

import { useNavigateQueryParams } from 'hooks/useNavigateQueryParams';
import { useIntl } from 'translations';
import { Language } from 'translations/types';

import { Liveboard } from 'types/liveboard';
import { logError } from 'utils/datadog';
import { computeBoardNameKey } from 'utils/liveboard';

import styles from './analytics.module.css';

import { Board } from './Board';
import { dashboardCustomizations } from './dashboardCustomizations';

export interface AnalyticsProps {
  pageTitle: string;
  liveboardId: string;
}

export default function Analytics() {
  const { formatMessage } = useIntl();
  const { language } = useContext(AppContext);
  const { liveboards } = useContext(UserContext);
  const { boardName, thoughtSpotLiveboardId } = useParams();
  const navigate = useNavigateQueryParams();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [liveboardLoading, setLiveboardLoading] = useState(true);

  const { width } = useWindowSize();

  let maxWidth = '100%';
  if (width > 991) {
    maxWidth = `${width - 304 - 16 * 4 * 2}px`;
  }

  useEffect(() => {
    async function initThoughtSpot() {
      try {
        setLoading(false);
        init({
          thoughtSpotHost: 'https://insurely.thoughtspot.cloud/?customBrandingEnabled=true',
          authType: AuthType.TrustedAuthTokenCookieless,
          getAuthToken: () =>
            getToughtSpotToken().catch((err) => {
              const nErr = new Error('Failed to get thoughtspot token', { cause: err });
              logError(nErr);
              throw nErr;
            }),
          disableLoginRedirect: true,
          customizations: dashboardCustomizations,
        });
      } catch (e) {
        setError(true);
        const nErr = new Error('Thoughtspot initialization error', { cause: e });
        logError(nErr);
        throw nErr;
      }
    }

    initThoughtSpot();
  }, [boardName, thoughtSpotLiveboardId]);

  const onLoad = useCallback(() => {
    setLiveboardLoading(false);
  }, []);

  const translatedBoardName = useMemo(() => {
    const key = computeBoardNameKey(boardName as string);

    if (!key) {
      return boardName;
    }
    return formatMessage({ id: key });
  }, [boardName, formatMessage]);

  if (!thoughtSpotLiveboardId || !boardName || liveboards.length === 0) {
    return null;
  }

  const computeSubItemName = (name: string) => {
    const itemName = name.split('/')[1];

    if (boardName) {
      return formatMessage({ id: itemName }) ?? '';
    }
    return name;
  };

  const subItems = liveboards
    .filter((l) => l.liveboardName.includes(boardName) && l.liveboardName.includes('/'))
    .map((l) => ({
      label: computeSubItemName(l.liveboardName),
      value: l.thoughtspotLiveboardId,
    }));
  const mainLiveboard = liveboards.find((l) => l.liveboardName === boardName) as Liveboard;

  const tabItems = [
    { label: formatMessage({ id: 'All' }), value: mainLiveboard.thoughtspotLiveboardId },
    ...subItems,
  ];

  const renderContent = () => {
    if (loading) {
      return (
        <div style={{ position: 'relative' }}>
          <Loader.Content size="large" style={{ paddingTop: '160px' }} />
        </div>
      );
    }
    if (error) {
      <div style={{ maxWidth: '600px' }}>
        <Typography component="p" variant="ParagraphBody">
          {formatMessage({ id: 'shared.something-went-wrong.support' })}
        </Typography>
      </div>;
    }
    return (
      <div style={{ position: 'relative' }}>
        <Loader.Content size="large" style={{ display: liveboardLoading ? 'block' : 'none' }} />
        <div style={{ margin: '0 calc(-1 * var(--space-6))' }}>
          <Board liveboardId={thoughtSpotLiveboardId} onLoad={onLoad} />
        </div>
      </div>
    );
  };

  return (
    <Page title={translatedBoardName} maxWidth={maxWidth}>
      <div className={styles.pageLayout}>
        <div className={styles.titleWrapper}>
          <Typography component="h1" variant="Headline-3">
            {translatedBoardName}
          </Typography>
          {language !== Language.EN && (
            <Typography component="p" variant="ParagraphTiny">
              {formatMessage({
                id: 'This version of the page is only available in English at this time',
              })}
            </Typography>
          )}
        </div>
        {tabItems.length > 1 && (
          <Tabs
            activeTab={thoughtSpotLiveboardId}
            items={tabItems}
            onChange={(thoughtspotLiveboardId) => {
              navigate(`/${ANALYTICS_ROUTE}/${boardName}/${thoughtspotLiveboardId}`);
            }}
          />
        )}
        {renderContent()}
      </div>
    </Page>
  );
}
