import { CUSTOMER_DA, CUSTOMER_II, CUSTOMER_S, CUSTOMER_SP, CUSTOMER_T } from 'constants/customers';
import { InsuranceResponse, LimitValueType, Parameter } from 'types/insurance';

import { ColumnIncidence, ColumnType, DifferenceFilter, Filters, InsuranceBody } from './types';

export const convertInsuranceResponseToInsuranceBody = (
  insurance: InsuranceResponse,
): InsuranceBody => ({
  insuranceCompany: insurance.insurance.insuranceCompany,
  insuranceSubType: insurance.insurance.insuranceSubType,
  insuranceType: insurance.insurance.insuranceType,
  packageName: insurance.coveragePackageInfo.packageName,
  packageVersionId: insurance.coveragePackageInfo.packageVersionId,
});

export const sortByGroupAndParameterGroup = (
  a: Pick<Parameter, 'parameterGroupOrder' | 'parameterOrder'>,
  b: Pick<Parameter, 'parameterGroupOrder' | 'parameterOrder'>,
) => {
  if (a.parameterGroupOrder !== b.parameterGroupOrder) {
    return a.parameterGroupOrder > b.parameterGroupOrder ? 1 : -1;
  }
  if (a.parameterOrder === undefined || b.parameterOrder === undefined) {
    return 0;
  }
  return a.parameterOrder === b.parameterOrder ? 0 : a.parameterOrder > b.parameterOrder ? 1 : -1;
};

export const isNumeric = (value: string) =>
  !Number.isNaN(parseFloat(value)) && Number.isFinite(Number(value));

type HighlightedMapping = {
  rowName: string;
  column: ColumnType;
  incidence: ColumnIncidence;
};

/**
 * Left is better than right if:
 * Left is included without being a bought add-on and right is either not included or an add-on
 * Left is included being a bought add-on and right is not included
 * Left is an add-on (bought or not) and right is not included
 * Left is included without being an add-on and right is include being an add-on
 */
export const computeParameterIncidence = (left: Parameter, right: Parameter): ColumnIncidence => {
  const toCompare = (l: Parameter, r: Parameter) =>
    (l.value === 'true' && !l.isAddonPurchased && (r.value === 'false' || r.value === 'add-on')) ||
    (l.value === 'true' && l.isAddonPurchased && r.value === 'false') ||
    (l.value === 'add-on' && r.value === 'false') ||
    (l.value === 'true' && r.value === 'true' && !l.isAddonPurchased && r.isAddonPurchased);

  if (toCompare(left, right)) {
    return 'left';
  }
  if (toCompare(right, left)) {
    return 'right';
  }
  return 'neutral';
};

/**
 * Left is better than right if:
 * Left value is lower than right value
 */
export const computeDeductibleIncidence = (left: Parameter, right: Parameter): ColumnIncidence => {
  const leftRaw = left.parameterDeductible?.displayValue;
  const rightRaw = right.parameterDeductible?.displayValue;

  if (leftRaw && rightRaw && isNumeric(leftRaw) && isNumeric(rightRaw)) {
    const leftVal = Number.parseInt(leftRaw, 10);
    const rightVal = Number.parseInt(rightRaw, 10);
    return leftVal < rightVal ? 'left' : leftVal > rightVal ? 'right' : 'neutral';
  }

  return 'neutral';
};

/**
 * Left is better than right if:
 * Left value is higher than right value
 * Left is of type no-limit and right is not
 * Left is of type full-value and right is not
 */
export const computeCompensationIncidence = (left: Parameter, right: Parameter) => {
  const leftRaw = left.parameterLimit?.displayValue;
  const rightRaw = right.parameterLimit?.displayValue;

  if (leftRaw && isNumeric(leftRaw) && rightRaw && isNumeric(rightRaw)) {
    const leftVal = Number.parseInt(leftRaw, 10);
    const rightVal = Number.parseInt(rightRaw, 10);

    return leftVal > rightVal ? 'left' : leftVal < rightVal ? 'right' : 'neutral';
  }

  const leftType = left.parameterLimit?.limitType;
  const rightType = right.parameterLimit?.limitType;

  const typeCompare = (type: LimitValueType) => {
    if (leftType !== type && rightType !== type) {
      return undefined;
    }
    return leftType === type && rightType !== type ? 'left' : 'right';
  };

  const noLimitCompare = typeCompare('no-limit');
  if (noLimitCompare !== undefined) {
    return noLimitCompare;
  }

  const fullValueCompare = typeCompare('full-value');
  if (fullValueCompare !== undefined) {
    return fullValueCompare;
  }
  return 'neutral';
};

const compareColumnValues = (
  left: Parameter,
  right: Parameter,
  column: ColumnType,
): HighlightedMapping | null => {
  switch (column) {
    case 'included':
      if (left.value !== right.value || left.isAddonPurchased !== right.isAddonPurchased) {
        const incidence = computeParameterIncidence(left, right);

        return { rowName: left.parameterName, column, incidence };
      }
      return null;
    case 'deductible':
      if (
        left.parameterDeductible &&
        right.parameterDeductible &&
        left.parameterDeductible.displayValue !== right.parameterDeductible.displayValue
      ) {
        const incidence = computeDeductibleIncidence(left, right);

        return { rowName: left.parameterName, column, incidence };
      }
      return null;
    case 'compensation':
      if (
        left.parameterLimit &&
        right.parameterLimit &&
        left.parameterLimit.displayValue !== right.parameterLimit.displayValue
      ) {
        const incidence = computeCompensationIncidence(left, right);

        return { rowName: left.parameterName, column, incidence };
      }
      return null;
    default:
      return null;
  }
};

export const highlightRowCellDifference = ({
  left,
  right,
  columns,
}: {
  left: Parameter[];
  right: Parameter[];
  columns: ColumnType[];
}) =>
  left
    .map((l) => {
      const r = right.find((e) => e.parameterName === l.parameterName);

      if (!r) {
        return null;
      }

      return columns
        .map((c) => compareColumnValues(l, r, c))
        .filter(Boolean) as HighlightedMapping[];
    })
    .flat()
    .filter(Boolean) as HighlightedMapping[];

export const getAvailableColumns = ({ customerId }: { customerId?: string }): ColumnType[] => {
  if (
    [CUSTOMER_S, CUSTOMER_SP, CUSTOMER_II, CUSTOMER_T, CUSTOMER_DA].includes(customerId ?? '') ||
    window.location.search.includes('debug=true')
  ) {
    return ['included', 'deductible', 'compensation'];
  }
  return ['included'];
};

export const applyDifferenceFiltering = ({
  left,
  right,
  filter,
}: {
  left: Parameter[];
  right: Parameter[];
  filter?: DifferenceFilter;
}) => {
  if (!filter || filter === 'none') {
    return { left, right };
  }

  const rowsToRemove = left
    .map((l) => {
      const r = right.find((e) => e.parameterName === l.parameterName);

      if (!r) {
        return null;
      }

      switch (filter) {
        case 'included':
          return l.value === r.value && l.isAddonPurchased === r.isAddonPurchased
            ? l.parameterName
            : null;
        case 'deductible':
          return l.parameterDeductible?.displayValue === r.parameterDeductible?.displayValue
            ? l.parameterName
            : null;
        case 'compensation':
          return l.parameterLimit?.displayValue === r.parameterLimit?.displayValue
            ? l.parameterName
            : null;
        default:
          return null;
      }
    })
    .filter(Boolean);

  const reduceMethod = (acc: Parameter[], l: Parameter) =>
    rowsToRemove.some((r) => r === l.parameterName) ? acc : [...acc, l];

  return {
    left: left.reduce(reduceMethod, []),
    right: right.reduce(reduceMethod, []),
  };
};

export const applyFiltering = ({
  left,
  right,
  filters,
}: {
  left: Parameter[];
  right: Parameter[];
  filters: Filters;
}) => {
  let { left: filteredLeft, right: filteredRight } = applyDifferenceFiltering({
    left,
    right,
    filter: filters.differenceFilter,
  });

  const search = (e: { parameterDisplayName: string }) =>
    filters.search
      ? e.parameterDisplayName.toLowerCase().includes(filters.search.toLowerCase())
      : true;

  filteredLeft = filteredLeft.filter(search);
  filteredRight = filteredRight.filter(search);

  return { filteredLeft, filteredRight };
};
