import { Icon, Tooltip, Typography } from '@insurely/ui';

import { useIntl } from 'translations';

import styles from './marketingConsentTag.module.css';

export default function MarketingConsentTag({ consent }: { consent: boolean | undefined }) {
  const { formatMessage } = useIntl();
  function getIcon() {
    if (consent) {
      return (
        <div className={styles.tag}>
          <Icon name="success" size={20} />
          <Typography component="p" variant="Label-4">
            {formatMessage({ id: 'Yes' })}
          </Typography>
        </div>
      );
    }

    return (
      <div className={styles.tag}>
        <Icon size={20} name="error" color="var(--grey-2)" />
        <Typography component="p" variant="Label-4">
          {formatMessage({ id: 'No' })}
        </Typography>
      </div>
    );
  }

  if (consent === undefined) {
    return null;
  }

  return (
    <div className={styles.consent}>
      <Tooltip
        content={
          consent
            ? formatMessage({ id: 'This email can used for marketing communication' })
            : formatMessage({ id: 'This email cannot be used for marketing communication' })
        }
        contentStyle={{
          minWidth: '200px',
          padding: 'var(--space-2)',
        }}
        hideCloseIcon
        position="top"
      >
        <Typography component="p" variant="ParagraphTiny">
          {formatMessage({ id: 'Consents to marketing' })}?
        </Typography>
        {getIcon()}
      </Tooltip>
    </div>
  );
}
