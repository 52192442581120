import { Market } from 'types/v3';

export const phoneNumberValidationPerMarket = (market: Market) => {
  switch (market) {
    case Market.FR:
      return /^(?:\+33[67]|0[67]|0033[67])\d{8}$/;
    case Market.DK:
      return /^(\+45|0045)?\d{8}$/;
    case Market.SE:
      return /^(?:\+46|0|0046)7[02369]\d{7}$/;
    case Market.EE:
      return /^(?:\+372|00372|372)?(\d{7,8})$/;
    default:
      return /\d{8}/;
  }
};

export const validatePhoneNumber = (phoneNumber: string, market: Market) => {
  const regex = phoneNumberValidationPerMarket(market);

  return regex.test(phoneNumber);
};
