import {
  Button,
  Icon,
  IconAddOn,
  IconAddOnAdded,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  Typography,
} from '@insurely/ui';
import classNames from 'classnames';
import { useMemo, useRef, useState } from 'react';

import { useHoverDirty } from 'react-use';

import { isNumeric } from 'components/ComparisonTable/utils';
import { FormattedMessage, useIntl } from 'translations';
import { useFormatting } from 'translations/useFormatting';
import { LimitValueType, Parameter, ValueType } from 'types/insurance';

// The highlighting css must stay in the file below because of cascading
import tableStyle from '../../comparisonTable.module.css';

import cellStyle from './cells.module.css';

const PopoverWrapper = ({
  isPopoverOpen,
  setIsPopoverOpen,
  popoverContent,
  onClickReportModal,
  children,
}: {
  isPopoverOpen: boolean;
  setIsPopoverOpen: (val: boolean) => void;
  popoverContent?: string | null;
  onClickReportModal: () => void;
  children: React.ReactNode;
}) => (
  <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
    <PopoverTrigger style={{ backgroundColor: 'transparent', border: 'none' }}>
      {children}
    </PopoverTrigger>
    <PopoverContent align="center" className={cellStyle.popoverContent}>
      {popoverContent && (
        <Typography variant="ParagraphCaption" component="p">
          {popoverContent}
        </Typography>
      )}
      <Button size="small" variant="secondary" onClick={onClickReportModal}>
        <FormattedMessage id="page.comparison.report-problem.button" />
      </Button>
    </PopoverContent>
  </Popover>
);

const TooltipIcon = ({
  text,
  minWidth,
  hidden,
}: {
  text: string;
  minWidth?: string;
  hidden?: boolean;
}) => (
  <Tooltip
    content={text}
    hideCloseIcon
    position="bottom"
    style={{ display: 'flex', visibility: hidden ? 'hidden' : 'initial', padding: 0 }}
    contentStyle={{ minWidth }}
  >
    <div className={cellStyle.hideOnPrint}>
      <Icon name="info" size={20} />
    </div>
  </Tooltip>
);

export const NotValueCell = () => <div />;

export const BoolCell = ({
  item,
  highlighting,
  onClickReportModal,
}: {
  item: Parameter;
  highlighting: 'neutral' | 'win' | null;
  onClickReportModal: (value: string) => void;
}) => {
  const { formatMessage } = useIntl();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const content = useMemo(() => {
    switch (item.value) {
      case 'true':
        if (item.isAddonPurchased) {
          return {
            icon: <IconAddOnAdded width={24} color="var(--green)" />,
            text: 'Purchased add-on',
            color: 'var(--green)',
          };
        }
        return {
          icon: <Icon size={20} name="success" color="var(--green)" />,
          text: 'Yes',
          color: 'var(--green)',
        };
      case 'false':
        return {
          icon: <Icon size={20} name="error" color="var(--red-1)" />,
          text: 'No',
          color: 'var(--red-1)',
        };
      case 'add-on': {
        return {
          icon: <IconAddOn width={24} />,
          text: 'Add-on',
        };
      }
      default:
        return null;
    }
  }, [item.isAddonPurchased, item.value]);

  if (!content) {
    return null;
  }

  return (
    <PopoverWrapper
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      onClickReportModal={() => onClickReportModal(formatMessage({ id: content.text }) ?? '')}
    >
      <div
        className={classNames(
          tableStyle.cellWrapper,
          cellStyle.cellWrapperGrid,
          cellStyle.cellNoWrap,
          {
            [cellStyle.cellActive]: isPopoverOpen,
            [tableStyle.highlightedNeutral]: highlighting === 'neutral',
            [tableStyle.highlightedWin]: highlighting === 'win',
          },
        )}
      >
        {content.icon}
        <Typography
          variant="ParagraphCaption"
          component="p"
          className={cellStyle.cellText}
          style={{ color: content?.color }}
        >
          {formatMessage({ id: content.text })}
        </Typography>
      </div>
    </PopoverWrapper>
  );
};

export const NumberCell = ({
  displayValue,
  type,
  description,
  highlighting,
  onClickReportModal,
}: {
  displayValue?: string;
  type?: ValueType | LimitValueType;
  description?: string;
  highlighting: 'neutral' | 'win' | null;
  onClickReportModal: (value: string) => void;
}) => {
  const { formatMessage } = useIntl();
  const { formatNumber } = useFormatting();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const tooltipText = useMemo(() => {
    if (description) {
      return description;
    }

    switch (type) {
      case 'complex':
        return formatMessage({ id: 'page.comparison.tooltip.complex' });
      case 'general':
        return formatMessage({ id: 'page.comparison.tooltip.general' });
      default:
        return null;
    }
  }, [formatMessage, type, description]);

  const text = useMemo(() => {
    if (displayValue === 'n/a') {
      return null;
    }
    return displayValue && isNumeric(displayValue)
      ? formatNumber(Number.parseInt(displayValue.replace(' ', ''), 10))
      : displayValue;
  }, [displayValue, formatNumber]);

  if (displayValue === undefined || displayValue === 'n/a') {
    return <NotValueCell />;
  }

  return (
    <PopoverWrapper
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      popoverContent={tooltipText}
      onClickReportModal={() => onClickReportModal(text ?? '')}
    >
      <div
        className={classNames(tableStyle.cellWrapper, {
          [cellStyle.cellActive]: isPopoverOpen,
          [tableStyle.highlightedNeutral]: highlighting === 'neutral',
          [tableStyle.highlightedWin]: highlighting === 'win',
          [cellStyle.cellWrapperGrid]: !!tooltipText,
        })}
      >
        <Typography variant="ParagraphCaption" component="p" className={cellStyle.cellText}>
          {text}
        </Typography>
      </div>
    </PopoverWrapper>
  );
};

export const DefaultCell = ({
  item,
  searchHighlight,
}: {
  item: Parameter;
  searchHighlight?: string;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const isHovering = useHoverDirty(ref);

  const showTooltip = isHovering && item.parameterDescription.length > 0;

  const highlightMatch = () => {
    if (!searchHighlight) {
      return item.parameterDisplayName;
    }

    const generateKey = (part: string) => `${part}-${Math.random().toString(36).slice(2, 7)}`;

    return item.parameterDisplayName.split(new RegExp(`(${searchHighlight})`, 'gi')).map((part) =>
      part.toLowerCase() === searchHighlight.toLowerCase() ? (
        <mark key={generateKey(part)} className={cellStyle.highlight}>
          {part}
        </mark>
      ) : (
        part
      ),
    );
  };

  return (
    <div className={cellStyle.cellWrapperGridReverse} ref={ref}>
      <Typography variant="Label-3" component="p">
        {highlightMatch()}
      </Typography>
      {item.parameterDescription.length > 0 && (
        <TooltipIcon text={item.parameterDescription} minWidth="250px" hidden={!showTooltip} />
      )}
    </div>
  );
};
