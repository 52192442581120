import { Select, TextInput } from '@insurely/ui';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormResetField,
  UseFormWatch,
} from 'react-hook-form';

import { useUpdateEffect } from 'react-use';

import { useIntl } from 'translations';

import { Language } from 'translations/types';
import { getAvailableLanguagesForMarket } from 'translations/utils';
import { Market } from 'types/v3';
import { ProductLine } from 'utils/product-line';
import { ExtendedClientConfig } from 'views/Config/config.types';

import { FormValues } from '../../../type';
import styles from '../clientForm.module.css';
import { Picker } from '../Picker/Picker';
import { TitleWithDescription } from '../TitleWithDescription/TitleWithDescription';

export const Step1 = ({
  markets,
  languages,
  formUtils,
  metadata,
}: {
  markets: Market[];
  languages: Language[];
  formUtils: {
    register: UseFormRegister<FormValues>;
    control: Control<FormValues, unknown>;
    errors: FieldErrors<FormValues>;
    watch: UseFormWatch<FormValues>;
    resetField: UseFormResetField<FormValues>;
  };
  metadata: { isClientNameExisting: boolean; mode: 'creation' | 'edit' };
}) => {
  const { formatMessage } = useIntl();

  const { register, control, errors, watch, resetField } = formUtils;
  const { isClientNameExisting, mode } = metadata;

  const dropdownOptions = (
    field: keyof ExtendedClientConfig,
  ): Array<{ label: string; value: string }> => {
    switch (field) {
      case 'language':
        return languages
          .filter((l) => getAvailableLanguagesForMarket(watch('market')).includes(l))
          .map((l) => ({
            label: formatMessage({ id: `shared.language.${l.toLowerCase()}` }),
            value: l,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
      case 'market':
        return markets
          .filter((m) => Object.values(Market).includes(m))
          .map((m) => ({
            label: formatMessage({ id: `shared.market.${m}` }),
            value: m,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
      case 'minimumApiVersion':
        return [
          { label: '2024-06-01', value: '2024-06-01' },
          { label: '2023-03-15', value: '2023-03-15' },
          { label: '2022-09-26', value: '2022-09-26' },
          { label: '2021-01-01', value: '2021-01-01' },
        ];
      default:
        return [];
    }
  };

  const market = watch('market');
  const productLine = watch('productLine');
  const language = watch('language');

  useUpdateEffect(() => {
    if (!getAvailableLanguagesForMarket(market).includes(language)) {
      resetField('language');
    }

    resetField('clientCompanies');
  }, [market, language]);

  useUpdateEffect(() => {
    resetField('clientCompanies');
    resetField('insuranceTypes');
    resetField('insuranceSubTypes');
  }, [productLine]);

  const renderField = (field: keyof FormValues) => {
    switch (field) {
      case 'clientName':
      case 'configurationName':
        return (
          <TextInput
            label={
              <TitleWithDescription
                title={formatMessage({ id: `page.config.client-form.field.${field}` })}
                description={formatMessage({
                  id: `page.config.client-form.field.${field}.description`,
                })}
              />
            }
            placeholder={formatMessage({
              id: `page.config.client-form.field.${field}.placeholder`,
            })}
            autoFocus={field === 'clientName'}
            textInputSize="small"
            error={!!errors[field]}
            helperText={
              errors[field]?.message ??
              (isClientNameExisting && field === 'clientName'
                ? formatMessage({ id: 'page.config.client-form.field.client-name.existing' })
                : undefined)
            }
            {...register(field)}
          />
        );
      case 'language':
      case 'market':
      case 'minimumApiVersion':
        return (
          <Controller
            control={control}
            name={field}
            render={({ field: { onChange, value } }) => (
              <div className={styles.dropdownContainer}>
                <TitleWithDescription
                  title={formatMessage({ id: `page.config.client-form.field.${field}` })}
                  description={formatMessage({
                    id: `page.config.client-form.field.${field}.description`,
                  })}
                />
                <Select
                  variant="small"
                  fullWidth
                  placeholder={formatMessage({
                    id: `page.config.client-form.field.${field}.placeholder`,
                  })}
                  options={dropdownOptions(field)}
                  value={value || ''}
                  onChange={onChange}
                  error={!!errors[field]}
                  helperText={errors[field]?.message}
                  id={field}
                />
              </div>
            )}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className={styles.singleLineElements}>
        {renderField('clientName')}
        {renderField('configurationName')}
      </div>
      <div className={styles.singleLineElements}>
        {renderField('market')}
        {renderField('language')}
      </div>
      <Controller
        control={control}
        name="productLine"
        render={({ field: { onChange, value } }) => (
          <Picker
            label={
              <TitleWithDescription
                title={formatMessage({ id: 'page.config.client-form.field.productLine' })}
                description={formatMessage({
                  id: 'page.config.client-form.field.productLine.description',
                })}
              />
            }
            left={{
              label: formatMessage({ id: 'page.config.client-form.field.productLine.insurance' }),
              value: ProductLine.INSURANCE,
            }}
            right={{
              label: formatMessage({ id: 'page.config.client-form.field.productLine.pension' }),
              value: ProductLine.PENSION,
            }}
            selected={value}
            onChange={onChange}
            disabled={mode === 'edit'}
          />
        )}
      />
      <Controller
        control={control}
        name="productInterface"
        render={({ field: { onChange, value } }) => (
          <Picker
            label={
              <TitleWithDescription
                title={formatMessage({ id: 'page.config.client-form.field.productInterface' })}
                description={formatMessage({
                  id: 'page.config.client-form.field.productInterface.description',
                })}
              />
            }
            left={{
              label: formatMessage({ id: 'page.config.client-form.field.productInterface.hub' }),
              value: 'hub',
            }}
            right={{
              label: formatMessage({ id: 'page.config.client-form.field.productInterface.api' }),
              value: 'api',
            }}
            selected={value}
            onChange={onChange}
          />
        )}
      />
      {renderField('minimumApiVersion')}
    </>
  );
};
