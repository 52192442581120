import { Typography } from '@insurely/ui';

import { Page } from 'components/Page/Page';
import { useIntl } from 'translations';

import styles from './contact.module.css';

export const Contact = () => {
  const { formatMessage } = useIntl();

  return (
    <Page title={formatMessage({ id: 'Mail support' })}>
      <div className={styles.container}>
        <Typography className={styles.title} component="h1" variant="Headline-3">
          {formatMessage({ id: 'Contact' })}
        </Typography>
        <Typography className={styles.subTitle} component="h2" variant="Headline-5">
          {formatMessage({ id: 'Address' })}
        </Typography>

        <div className={styles.addressContainer}>
          <Typography component="p" variant="ParagraphBody">
            Insurely AB
          </Typography>
          <Typography component="p" variant="ParagraphBody">
            (org. nr 559103-5646)
          </Typography>
          <Typography component="p" variant="ParagraphBody">
            Drottninggatan 71c
          </Typography>
          <Typography component="p" variant="ParagraphBody">
            111 36 Stockholm
          </Typography>
        </div>
        <Typography className={styles.subTitle} component="h2" variant="Headline-5">
          Mail
        </Typography>
        <Typography component="p" variant="ParagraphBody">
          <a href="mailto:support@insurely.com">support@insurely.com</a>
        </Typography>
      </div>
    </Page>
  );
};
