import { CompanyLogo, Notification, Tabs, Typography } from '@insurely/ui';
import React, { useState, useContext } from 'react';

import { Cancellation } from 'client/types';
import UserContext from 'contexts/user/UserContext';
import { useIntl } from 'translations';
import { useFormatting } from 'translations/useFormatting';
import { InsuranceResponse } from 'types/insurance';
import { getDisplayName, getDisplayType } from 'utils/insurance';

import { CancellationView } from './CancellationView/CancellationView';
import { SaleCard } from './comp/InsuranceDetails/comp/SaleCard/SaleCard';
import { InsuranceDetails } from './comp/InsuranceDetails/InsuranceDetails';

import styles from './insurancePage.module.css';

interface InsurancePageProps {
  insurance: InsuranceResponse;
  cancellation?: Cancellation;
  creatingNewCancellation: boolean;
  startOnCancellation?: boolean;
  setCreatingNewCancellation: React.Dispatch<React.SetStateAction<boolean>>;
  fetchCancellation: (id: string) => Promise<void>;
}

enum Tab {
  DETAILS_TAB = 'DETAILS_TAB',
  CANCEL_TAB = 'CANCEL_TAB',
}

export const InsurancePage = ({
  insurance,
  cancellation,
  creatingNewCancellation,
  startOnCancellation,
  setCreatingNewCancellation,
  fetchCancellation,
}: InsurancePageProps) => {
  const { formatMessage } = useIntl();
  const { formatDate } = useFormatting();
  const { config } = useContext(UserContext);

  const [activeTab, setActiveTab] = useState<Tab>(
    startOnCancellation ? Tab.CANCEL_TAB : Tab.DETAILS_TAB,
  );

  return (
    <>
      <div className={styles.printTopContent}>
        <Typography component="p" variant="ParagraphBody">
          {formatMessage({ id: 'page.insurance.export-title' }, { date: formatDate(Date.now()) })}
        </Typography>
        {insurance.collectionDate && (
          <Typography component="p" variant="ParagraphBody">
            {formatMessage(
              { id: 'page.insurance.consent-date' },
              { date: formatDate(insurance.collectionDate) },
            )}
          </Typography>
        )}
      </div>

      <div className={styles.topContent}>
        <div className={styles.alignItems}>
          <CompanyLogo
            className={styles.logo}
            company={insurance.insurance.insuranceCompany}
            width="50px"
          />
          <div>
            <Typography component="p" variant="ParagraphCaption" className={styles.insuranceType}>
              {getDisplayType(insurance.insurance, formatMessage, config.market)}
            </Typography>
            <Typography component="h1" variant="Headline-3" className="ph-no-capture">
              {getDisplayName(insurance.insurance)}
            </Typography>
          </div>
        </div>

        {insurance.insurance.otherInsuranceHolder && (
          <Notification
            status="warning"
            headline={formatMessage({ id: 'page.insurance.other-insurance-holder-card.title' })}
            description={formatMessage({
              id: 'page.insurance.other-insurance-holder-card.description',
            })}
          />
        )}

        {insurance.collectionId && (
          <SaleCard
            insuranceId={insurance.insurance.externalId}
            collectionId={insurance.collectionId}
          />
        )}
      </div>

      {config.cancellationServiceEnabled && (
        <Tabs
          items={[
            {
              value: Tab.DETAILS_TAB,
              label: formatMessage({ id: 'page.insurance.tab.insurance-details' }),
            },
            {
              value: Tab.CANCEL_TAB,
              label: formatMessage({ id: 'page.insurance.tab.cancel-insurance' }),
            },
          ]}
          activeTab={activeTab}
          onChange={(tab) => setActiveTab(tab as Tab)}
          className={styles.tabs}
        />
      )}

      {(activeTab === Tab.DETAILS_TAB || !config.cancellationServiceEnabled) && (
        <InsuranceDetails className={styles.insuranceDetails} insurance={insurance} />
      )}

      {activeTab === Tab.CANCEL_TAB && (
        <CancellationView
          insurance={insurance}
          cancellation={(!creatingNewCancellation && cancellation) || undefined}
          setCreatingNewCancellation={setCreatingNewCancellation}
          fetchCancellation={fetchCancellation}
        />
      )}
    </>
  );
};
