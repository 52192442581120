import { Icon, IconButton, Select, TextInput } from '@insurely/ui';

import { useState } from 'react';
import { useDebounce } from 'react-use';

import { useIntl } from 'translations';

import { ColumnType, DifferenceFilter, Filters as FiltersType } from '../../types';

import styles from './filters.module.css';

interface Props {
  filters: FiltersType;
  setFilters: React.Dispatch<React.SetStateAction<FiltersType>>;
  hideDifference: boolean;
  columns: {
    id: ColumnType;
    label: string;
  }[][];
}

const SearchInput = ({
  value,
  setSearchValue,
}: {
  value: string;
  setSearchValue: (val: string) => void;
}) => {
  const { formatMessage } = useIntl();

  const [innerValue, setInnerValue] = useState(value);

  // Wait 300ms before the actual search value is updated. That gives better performances
  // by not updating the table for each characters typed.
  useDebounce(() => setSearchValue(innerValue), 300, [innerValue]);

  return (
    <TextInput
      textInputSize="small"
      startAdornment={<Icon name="search" size={24} />}
      value={innerValue}
      onChange={(e) => setInnerValue(e.currentTarget.value)}
      name="search-filter"
      label=""
      placeholder={formatMessage({ id: 'page.comparison.search-filter' })}
      endAdornment={
        innerValue.trim().length > 0 ? (
          <IconButton icon={<Icon name="close" size={24} />} onClick={() => setInnerValue('')} />
        ) : undefined
      }
    />
  );
};

export const Filters = ({ filters, setFilters, hideDifference, columns }: Props) => {
  const { formatMessage } = useIntl();

  const shouldShowFilter = (filterId: string) =>
    ['none', 'included'].includes(filterId) ||
    columns.flat().reduce((count, col) => count + (col.id === filterId ? 1 : 0), 0) === 2;

  const filteringOptions = [
    { label: formatMessage({ id: 'page.comparison.filter.all-parameters' }), value: 'none' },
    {
      label: formatMessage({ id: 'page.comparison.filter.coverage-difference' }),
      value: 'included',
    },
    {
      label: formatMessage({ id: 'page.comparison.filter.deductible-difference' }),
      value: 'deductible',
    },
    {
      label: formatMessage({ id: 'page.comparison.filter.limit-difference' }),
      value: 'compensation',
    },
  ].filter((f) => shouldShowFilter(f.value)) as Array<{
    label: string;
    value: DifferenceFilter;
  }>;

  return (
    <div className={styles.container}>
      <SearchInput
        value={filters.search}
        setSearchValue={(val) => setFilters((cur) => ({ ...cur, search: val }))}
      />
      {!hideDifference && (
        <Select
          id="filter"
          options={filteringOptions}
          fullWidth
          value={filters.differenceFilter}
          onChange={(opt: DifferenceFilter) =>
            setFilters((cur) => ({ ...cur, differenceFilter: opt }))
          }
          variant="small"
        />
      )}
    </div>
  );
};
