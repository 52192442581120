import { zodResolver } from '@hookform/resolvers/zod';
import { Button, TextInput, CompanyLogo, Typography, Divider, Notification } from '@insurely/ui';

import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useAsyncFn } from 'react-use';
import { z } from 'zod';

import { registerUser } from 'client/client';

import { LOGIN_MAGIC_LINK_ROUTE, LOGIN_ROUTE } from 'constants/routes';

import { useNavigateQueryParams } from 'hooks/useNavigateQueryParams';
import { IntlShape, useIntl } from 'translations';

import { logError } from 'utils/datadog';

import styles from '../login.module.css';

interface FormValues {
  email: string;
  password: string;
  repeatPassword: string;
}

const schema = (formatMessage: IntlShape['formatMessage']) =>
  z
    .object({
      email: z.string().email({ message: formatMessage({ id: 'Please enter a valid e-mail' }) }),
      password: z.string().min(1, { message: formatMessage({ id: 'Please enter a password' }) }),
      repeatPassword: z
        .string()
        .min(1, { message: formatMessage({ id: 'page.login.please-repeat-password' }) }),
    })
    .refine((data) => data.password.length >= 8, {
      message: formatMessage({ id: 'page.login.password-validation' }),
      path: ['password'],
    })
    .refine((data) => data.password === data.repeatPassword, {
      message: formatMessage({ id: 'The passwords needs to match' }),
      path: ['repeatPassword'],
    });

export const Register: React.FC = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigateQueryParams();

  const [showConfirmation, setShowConfirmation] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    trigger,
  } = useForm<FormValues>({
    mode: 'onTouched',
    delayError: 500,
    resolver: zodResolver(schema(formatMessage)),
    defaultValues: {
      password: '',
    },
  });

  const [{ loading: isRegisteringUser, error: errorRegisterUser }, doRegisterUser] = useAsyncFn(
    ({ email, password, repeatPassword }: FormValues) =>
      registerUser(email, password, repeatPassword)
        .then(() => setShowConfirmation(true))
        .catch((error) => {
          const nErr = new Error(error.response.data.message, { cause: error });
          if (error.response.status !== 403) {
            logError(nErr);
          }
          throw nErr;
        }),
  );

  return (
    <div className={styles.formContainer}>
      <form className={styles.form} onSubmit={handleSubmit(doRegisterUser)}>
        <CompanyLogo company="se-demo" width="56px" aria-label="Insurely" />
        {showConfirmation ? (
          <>
            <Typography component="h1" variant="Headline-4" className={styles.signInTitle}>
              {formatMessage({ id: 'page.login.create-account.confirmation.title' })}
            </Typography>
            <Typography component="p" variant="ParagraphBodySmall">
              {formatMessage(
                {
                  id: 'We just sent you an email to {email}. Follow the instructions in the email to create your account',
                },
                { email: getValues('email') },
              )}
            </Typography>
          </>
        ) : (
          <>
            <Typography component="h1" variant="Headline-4" className={styles.signInTitle}>
              {formatMessage({ id: 'page.login.link.create-account' })}
            </Typography>
            <Typography component="p" variant="ParagraphBodySmall" className={styles.signInText}>
              {formatMessage({
                id: 'To sign up you need to have an ongoing cooperation with Insurely and use your corporate email.',
              })}
            </Typography>

            {!!errorRegisterUser && (
              <Notification
                className={styles.notification}
                headline={errorRegisterUser.message}
                status="error"
              />
            )}

            <TextInput
              {...register('email')}
              label={formatMessage({ id: 'shared.email' })}
              className={styles.formInput}
              error={!!errors.email}
              helperText={errors.email?.message}
              onBlur={() => {
                if (getValues('email')) {
                  trigger('email');
                }
              }}
              autoFocus
            />
            <TextInput
              {...register('password')}
              label={formatMessage({ id: 'page.login.password' })}
              type="password"
              className={styles.formInput}
              error={!!errors.password}
              onBlur={() => {
                if (getValues('password')) {
                  trigger('password');
                }
              }}
              helperText={
                watch('password').length < 8 && !errors.password?.message
                  ? formatMessage({ id: 'page.login.password-validation' })
                  : errors.password?.message
              }
            />
            <TextInput
              {...register('repeatPassword')}
              label={formatMessage({ id: 'page.login.repeat-password' })}
              type="password"
              className={styles.formInput}
              error={!!errors.repeatPassword}
              helperText={errors.repeatPassword?.message}
              onBlur={() => {
                if (getValues('repeatPassword')) {
                  trigger('repeatPassword');
                }
              }}
            />
            <Button
              variant="primary"
              fullWidth
              type="submit"
              loading={isRegisteringUser}
              disabled={isRegisteringUser}
            >
              {formatMessage({ id: 'page.login.link.create-account' })}
            </Button>
          </>
        )}
      </form>

      <div className={styles.secondaryLoginContainer}>
        <Divider />
        <Button
          variant="secondary"
          fullWidth
          onClick={() => navigate(`/${LOGIN_ROUTE}/${LOGIN_MAGIC_LINK_ROUTE}`)}
        >
          {formatMessage({ id: 'page.login.link.sign-in-email' })}
        </Button>
        <Button variant="secondary" fullWidth onClick={() => navigate(`/${LOGIN_ROUTE}`)}>
          {formatMessage({ id: 'page.login.link.username-login' })}
        </Button>
      </div>
    </div>
  );
};
