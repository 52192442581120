import {
  CompanyLogo,
  Icon,
  IconDownload,
  Link,
  Loader,
  Select,
  Tooltip,
  Typography,
} from '@insurely/ui';

import { useMemo } from 'react';

import { LocalInsurance } from 'components/ComparisonTable/types';
import { useCompanies } from 'hooks/useCompanies';
import { useInsuranceTermLink } from 'hooks/useInsuranceTermLink';
import { useIntl } from 'translations';
import { upperCaseFirstLetter } from 'utils/formatters';

import styles from './insuranceHeader.module.css';

export const InsuranceHeader = ({
  insurance,
  onChange,
}: {
  insurance: LocalInsurance;
  onChange: ({ packageName, versionId }: { packageName: string; versionId: string }) => void;
}) => {
  const { formatMessage } = useIntl();
  const { company, subType, insPackage, terms } = insurance;
  const { name, options } = insPackage;

  const { linkUrl, isLoadingLinkUrl, isExactTerms } = useInsuranceTermLink({
    company: company as string,
    name: insPackage.name,
    subType,
    startDate: terms.options.find((t) => t.value === terms.value)?.date,
  });

  const { getCompanyDisplayName, getPrimaryCompanyFromSubsidiary } = useCompanies();

  const companyName = getPrimaryCompanyFromSubsidiary(company as string);

  const linkLabel = useMemo(
    () =>
      [getCompanyDisplayName(company as string), name, isExactTerms ? terms.value : null]
        .filter(Boolean)
        .join(' - '),
    [company, getCompanyDisplayName, isExactTerms, name, terms.value],
  );

  return (
    <div className={styles.insuranceHeaderWrapper}>
      <div className={styles.companyTitle}>
        <CompanyLogo company={companyName} width={24} />
        <Typography variant="Headline-6" component="p">
          {getCompanyDisplayName(company as string)}
        </Typography>
      </div>
      <div className={styles.dropdowns}>
        <Select
          id={`package-${insurance.company}`}
          value={`${name}-${company}`}
          onChange={(val) => onChange({ packageName: val, versionId: terms.value })}
          disabled={options.length === 1}
          options={options.sort((a, b) => a.label.localeCompare(b.label))}
          fullWidth
          variant="extra-small"
          label={formatMessage({ id: 'page.comparison.table.header.insurance-product' })}
          renderOption={(value) => {
            const opt = options.find((o) => o.value === value);
            const cpName = getPrimaryCompanyFromSubsidiary(opt?.company as string);

            return (
              <div className={styles.optionContainer}>
                <CompanyLogo company={cpName} width={16} />
                <Typography variant="Label-4" component="p">
                  {upperCaseFirstLetter(opt?.label)}
                </Typography>
              </div>
            );
          }}
        />
        <Select
          id={`terms-${insurance.company}`}
          value={terms.value}
          onChange={(val) => onChange({ packageName: `${name}-${company}`, versionId: val })}
          disabled={terms.options.length <= 1}
          options={terms.options}
          label={formatMessage({ id: 'page.comparison.table.header.insurance-version' })}
          fullWidth
          variant="extra-small"
        />
      </div>
      {isLoadingLinkUrl && (
        <div className={styles.insuranceTermsLoader}>
          <Loader.Content size="small" />
        </div>
      )}
      {linkUrl && !isLoadingLinkUrl && (
        <div className={styles.linkWrapper}>
          <Link href={linkUrl} external startIcon={IconDownload}>
            {linkLabel}
          </Link>
          {!isExactTerms && (
            <Tooltip
              trigger="hover"
              content={formatMessage({ id: 'page.comparison.table.header.link-tooltip' })}
            >
              <Icon name="info" size={20} />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};
