import { useCallback, useRef } from 'react';
import { useDeepCompareEffect } from 'react-use';

interface Props {
  effect: () => void;
  deps: unknown[];
  delay: number;
}

export const useDebouncedEffect = ({ effect, delay, deps }: Props) => {
  const callback = useRef(effect);
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  useCallback(() => {
    callback.current = effect;
  }, [effect]);

  useDeepCompareEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    timeoutId.current = setTimeout(() => {
      callback.current();
    }, delay);

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, deps);
};
