import { Button, Card, Icon, Typography } from '@insurely/ui';
import classNames from 'classnames';

import { useState, useContext } from 'react';

import {
  getNotificationDescription,
  getNotificationTitle,
  getStatusEnum,
} from 'components/EventLog/utils';
import SessionContext from 'contexts/session/SessionContext';
import UserContext from 'contexts/user/UserContext';
import { useCompanies } from 'hooks/useCompanies';
import { useIntl } from 'translations';

import { CollectionStatus } from 'types/v3';

import { SessionNotification, closeSessionNotification } from '../../utils';

import styles from './notificationCard.module.css';

interface NotificationCardProps {
  item: SessionNotification;
}

export const NotificationCard = ({ item }: NotificationCardProps) => {
  const { formatMessage } = useIntl();
  const { productLine } = useContext(UserContext);
  const { collections } = useContext(SessionContext);
  const { getCompanyDisplayName } = useCompanies();

  const [closed, setClosed] = useState(false);

  const { type, lastUpdated, collectionId } = item;

  const collection = collections.find((c) => c.collectionId === collectionId);

  const status = getStatusEnum(collection?.statuses?.at(-1)?.status ?? '');
  const prevStatus = getStatusEnum(collection?.statuses?.at(-2)?.status ?? CollectionStatus.LOGIN);
  const numInsurancesInCollection = collection?.numberOfInsurances ?? 0;
  const numFilteredInsurancesInCollection = collection?.numberOfFilteredInsurances ?? 0;
  const hiddenInsurances = numInsurancesInCollection - numFilteredInsurancesInCollection;
  const company = collection?.insuranceCompany ?? '';

  const getVariant = () =>
    (
      [
        CollectionStatus.COMPLETED,
        CollectionStatus.COMPLETED_PARTIAL,
        CollectionStatus.COMPLETED_EMPTY,
      ] as (CollectionStatus | undefined)[]
    ).includes(status)
      ? 'info'
      : 'error';

  const getIcon = () => {
    if (type === 'collection' && getVariant() === 'info') return <Icon name="info" size={20} />;
    if (type === 'sms' || (type === 'collection' && getVariant() === 'error'))
      return <Icon name="error" size={20} />;
    return null;
  };

  const handleRemoveNotification = () => {
    setClosed(true);
    closeSessionNotification(lastUpdated);
  };

  if (closed) return null;

  if (type === 'collection' && status && !Object.values(CollectionStatus).includes(status))
    return null;

  return (
    <div className={styles.notificationWrapper}>
      <Card className={classNames(styles.card, styles[getVariant()])}>
        {getIcon()}
        <div style={{ marginTop: '4px', maxWidth: '460px' }}>
          <Typography component="h3" variant="Label-2" className={styles.cardHeadline}>
            {status && type === 'collection'
              ? getNotificationTitle(status, productLine, formatMessage, hiddenInsurances)
              : formatMessage({ id: 'SMS could not be delivered' })}
          </Typography>
          <Typography component="p" variant="ParagraphCaption" className={styles.cardText}>
            {status && prevStatus && type === 'collection'
              ? getNotificationDescription({
                  status,
                  prevStatus,
                  productLine,
                  formatMessage,
                  company: getCompanyDisplayName(company || ''),
                })
              : formatMessage({
                  id: 'Something went wrong when we were going to deliver the SMS. Try sending again.',
                })}
          </Typography>

          <div style={{ maxWidth: '128px', marginTop: '16px' }}>
            <Button
              type="button"
              variant="secondary"
              size="small"
              fullWidth
              onClick={() => handleRemoveNotification()}
            >
              {formatMessage({ id: 'Okay' })}
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};
