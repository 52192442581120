import { differenceInDays } from 'date-fns';

import { Config } from 'types/v3';

const SESSION_NOTIFICATIONS = 'sessionNotifications';

export type SessionNotification = {
  sessionId: string;
  type: 'collection' | 'sms';
  lastUpdated: number;
  collectionId?: string;
  closed: boolean;
};

export const addSessionNotification = (notification: SessionNotification) => {
  if (typeof Storage !== 'undefined') {
    const recentNotifications = JSON.parse(
      window.localStorage.getItem(SESSION_NOTIFICATIONS) || '[]',
    ) as SessionNotification[];
    if (recentNotifications.map((item) => item.lastUpdated).includes(notification.lastUpdated)) {
      return;
    }
    const newNotifications = recentNotifications.concat([notification]);
    window.localStorage.setItem(SESSION_NOTIFICATIONS, JSON.stringify(newNotifications));
  }
};

export const getSessionNotifications = (sessionId: string) => {
  if (typeof Storage !== 'undefined') {
    const notifications = JSON.parse(
      window.localStorage.getItem(SESSION_NOTIFICATIONS) || '[]',
    ) as SessionNotification[];
    return notifications.filter((item) => item.sessionId === sessionId && !item.closed);
  }
  return [];
};

export const closeSessionNotification = (lastUpdated: number) => {
  if (typeof Storage !== 'undefined') {
    const recentNotifications = JSON.parse(
      window.localStorage.getItem(SESSION_NOTIFICATIONS) || '[]',
    ) as SessionNotification[];
    const closedNotification = recentNotifications.find((item) => item.lastUpdated === lastUpdated);
    if (closedNotification) {
      closedNotification.closed = true;
      const newNotifications = recentNotifications.filter(
        (item) => !(item.lastUpdated === lastUpdated),
      );
      window.localStorage.setItem(
        SESSION_NOTIFICATIONS,
        JSON.stringify(newNotifications.concat(closedNotification)),
      );
    }
  }
};

export const removeOldSessionNotifications = (config: Config) => {
  const maximumDays = config.retention < 30 ? config.retention : 30;

  if (typeof Storage !== 'undefined') {
    const recentNotifications = JSON.parse(
      window.localStorage.getItem(SESSION_NOTIFICATIONS) || '[]',
    ) as SessionNotification[];

    const newNotifications = recentNotifications
      .filter((item) => differenceInDays(new Date(), new Date(item.lastUpdated)) < maximumDays)
      .filter((item) => (item.collectionId && item.type === 'collection') || item.type === 'sms')
      .filter((item) => typeof item.lastUpdated !== 'string');

    window.localStorage.setItem(SESSION_NOTIFICATIONS, JSON.stringify(newNotifications));
  }
};
