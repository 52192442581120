import { Flag, Select, Typography } from '@insurely/ui';

import { useCallback, useContext } from 'react';

import AppContext from 'contexts/app/AppContext';
import UserContext from 'contexts/user/UserContext';
import { Language } from 'translations/types';
import { getAvailableLanguagesForMarket, languageToCountry } from 'translations/utils';

import styles from '../sideNav.module.css';

export const LanguageSwitch = () => {
  const { language, setLanguage } = useContext(AppContext);
  const {
    config: { market },
  } = useContext(UserContext);

  const localeLanguageTranslation = useCallback((lang: Language) => {
    switch (lang) {
      case Language.FR:
        return 'Français';
      case Language.DA:
        return 'Dansk';
      case Language.EN:
        return 'English';
      case Language.SV:
        return 'Svenska';
      default:
        return null;
    }
  }, []);

  const options = getAvailableLanguagesForMarket(market).map((l) => ({
    label: localeLanguageTranslation(l) ?? l,
    value: l,
  }));

  // If there is only one language enabled for the market, we don't display the dropdown
  if (options.length === 1) {
    return null;
  }

  const renderOption = (value: string) => {
    const country = languageToCountry(value as Language);

    return (
      <div className={styles.countryOptions}>
        {country && <Flag country={country} width={24} />}
        <Typography variant="ParagraphBodySmall" component="p">
          {options.find((o) => o.value === value)?.label}
        </Typography>
      </div>
    );
  };

  const renderValue = (value: string) => {
    const val = options.find((o) => o.value === value);

    if (!val) {
      return value;
    }

    const country = languageToCountry(val.value);

    return (
      <div className={styles.countryOptions}>
        {country && <Flag country={country} width={24} />}
        <Typography variant="ParagraphBodySmall" component="p">
          {val.label}
        </Typography>
      </div>
    );
  };

  return (
    <div className={styles.languageDropdown}>
      <Select
        id="language"
        variant="small"
        options={options}
        value={language}
        fullWidth
        onChange={(v) => {
          setLanguage(v as Language);
        }}
        renderValue={renderValue}
        renderOption={renderOption}
      />
    </div>
  );
};
