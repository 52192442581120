import { Icon, Typography } from '@insurely/ui';
import classNames from 'classnames';
import { ReactNode } from 'react';

import styles from './filterPill.module.css';

type FilterPillProps = {
  text: ReactNode;
  onClick: () => void;
  add?: boolean;
};

export default function FilterPill({ text, onClick, add = false }: FilterPillProps) {
  return (
    <button className={classNames(styles.pill)} type="button" onClick={onClick}>
      <Typography variant="ParagraphBodySmall" component="p" className={styles.text}>
        {text}
      </Typography>
      <div className={classNames(styles.icon, { [styles.add]: add })}>
        <Icon name={add ? 'plus' : 'close'} size={16} color="var(--midnight)" />
      </div>
    </button>
  );
}
