import { Button, Icon, useToastContext } from '@insurely/ui';
import { useState } from 'react';

import { v4 as uuid } from 'uuid';

import {
  downloadPopulatedCancellationTemplate as downloadCancellationTemplateBlob,
  exportPolicyLetter,
} from 'client/client';
import { useIntl } from 'translations';

import { InsuranceResponse } from 'types/insurance';
import { logError } from 'utils/datadog';
import { downloadFile } from 'utils/downloadFile';
import { PostHogAction, PostHogCategory, postHogCapture } from 'utils/posthog';

export type ButtonType = 'cancellation' | 'policy-letter';

type Props = {
  type: ButtonType;
  insurance: InsuranceResponse;
};

type ContentType = {
  [key: string]: {
    buttonText: string;
    click: string;
    fileName: string;
    func: (arg1: string, arg2: string) => Promise<Blob>;
  };
};

const content: ContentType = {
  cancellation: {
    buttonText: 'Download manual power of attorney',
    click: '/downloadCancellation',
    fileName: 'Cancel',
    func: downloadCancellationTemplateBlob,
  },
  'policy-letter': {
    buttonText: 'Download insurance policy document',
    click: '/downloadExport',
    fileName: 'Export',
    func: exportPolicyLetter,
  },
};

export default function DownloadButton({ insurance, type }: Props) {
  const [downloading, setDownloading] = useState(false);
  const { formatMessage } = useIntl();
  const { dispatch } = useToastContext();
  const config = content[type];

  const downloadExport = async () => {
    if (!insurance?.collectionId) {
      return;
    }

    setDownloading(true);

    postHogCapture(`tracking:${PostHogAction.CLICK}`, {
      action: PostHogAction.CLICK,
      category: PostHogCategory.INSURANCE,
      object: config.click,
    });

    try {
      const exportResponse = await config
        .func(insurance.collectionId, insurance.insurance.externalId)
        .catch((err) => {
          const nErr = new Error('Failed to execute callback function', { cause: err });
          logError(nErr, {
            collectionId: insurance.collectionId,
            externalId: insurance.insurance.externalId,
          });
          throw nErr;
        });
      downloadFile({
        data: exportResponse,
        id: insurance.insurance.externalId,
        name: formatMessage({ id: config.fileName }),
      });
    } catch (error) {
      logError(new Error('Error downloading file', { cause: error }), {
        externalId: insurance.insurance.externalId,
      });
      dispatch({
        type: 'ADD_TOAST',
        payload: {
          id: uuid(),
          type: 'error',
          message: formatMessage({ id: 'Something unexpected happened, please try again' }),
        },
      });
    }
    setDownloading(false);
  };

  return (
    <Button
      variant="tertiary"
      size="small"
      onClick={downloadExport}
      loading={downloading}
      icon={<Icon name="download" aria-hidden size={20} />}
      iconPosition="left"
    >
      {formatMessage({ id: config.buttonText })}
    </Button>
  );
}
