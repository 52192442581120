import { useContext } from 'react';

import { Invitation, SmsStatus } from 'client/types';

import EventLog, { EventLogRow } from 'components/EventLog/EventLog';
import { UpdatedCollectionType } from 'components/EventLog/types';
import { SMS_FAILED, SMS_SUCCESS, getEventLogRowsFromCollections } from 'components/EventLog/utils';
import UserContext from 'contexts/user/UserContext';
import { useCompanies } from 'hooks/useCompanies';
import { useIntl } from 'translations';

import { useCollectionsStatusPoll } from './hooks/useCollectionsStatusPoll';

interface EventsProps {
  smsInvites: Invitation[];
  collections: UpdatedCollectionType[];
}

export const SessionEvents = ({ smsInvites, collections }: EventsProps) => {
  const { productLine } = useContext(UserContext);
  const { getCompanyDisplayName } = useCompanies();
  const { formatMessage } = useIntl();

  useCollectionsStatusPoll();

  function getSMSstatus(invite: Invitation) {
    if (SMS_FAILED.includes(invite.status)) {
      return formatMessage({ id: 'Could not be delivered' });
    }
    if (SMS_SUCCESS.includes(invite.status)) {
      return `${formatMessage({ id: 'page.session.sms-successfully-delivered' })} ${invite.phoneNumber}`;
    }
    return `${formatMessage({ id: 'page.session.sms-is-delivered' })} ${invite.phoneNumber}`;
  }

  function getSMSIcon(status: SmsStatus) {
    if (SMS_SUCCESS.includes(status)) return 'success';
    if (SMS_FAILED.includes(status)) return 'failed';
    return 'loading';
  }

  let eventLogRows = smsInvites.map(
    (invite): EventLogRow => ({
      icon: {
        type: 'sms-icon',
        key: '',
      },
      label: formatMessage({ id: 'page.session.sms-invite' }),
      status: {
        icon: getSMSIcon(invite.status),
        text: getSMSstatus(invite),
      },
      creationDate: invite.creationDate,
    }),
  );

  eventLogRows = [
    ...eventLogRows,
    ...getEventLogRowsFromCollections({
      collections,
      productLine,
      formatMessage,
      getCompanyDisplayName,
    }),
  ];

  return <EventLog rows={eventLogRows} />;
};
