import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Card,
  Notification,
  RadioGroup,
  TextInput,
  Tooltip,
  RadioButton,
  Typography,
  Icon,
} from '@insurely/ui';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useInterval } from 'react-use';

import {
  Cancellation,
  CancellationStatus as CancellationStatusEnum,
  Collection,
  DeliveryMethod,
} from 'client/types';
import UserContext from 'contexts/user/UserContext';
import { useIntl } from 'translations';
import { useFormatting } from 'translations/useFormatting';
import { InsuranceResponse } from 'types/insurance';

import { CountryCode } from 'types/v3';

import styles from './cancellationView.module.css';
import { CancellationContextualMenu } from './comp/CancellationContextualMenu/CancellationContextualMenu';
import { CancellationStatus } from './comp/CancellationStatus/CancellationStatus';
import { DeleteModal } from './comp/DeleteModal/DeleteModal';
import { ErrorCard } from './comp/ErrorCard/ErrorCard';
import { EventLog } from './comp/EventLog/EventLog';
import { InputFields } from './comp/InputFields/InputFields';
import { ReceiverInfo } from './comp/ReceiverInfo/ReceiverInfo';
import { RecentReminderModal } from './comp/RecentReminderModal/RecentReminderModal';
import { UnableToCancelSection } from './comp/UnableToCancelSection/UnableToCancelSection';
import { useCancellationEvents } from './hooks/useCancellationEvents';
import { FormValues, getRenewalDateDifference, schema } from './utils';

interface CancellationViewProps {
  insurance: InsuranceResponse;
  cancellation?: Cancellation;
  setCreatingNewCancellation: React.Dispatch<React.SetStateAction<boolean>>;
  fetchCancellation: (id: string) => Promise<void>;
}

export const CancellationView: FC<CancellationViewProps> = ({
  insurance,
  cancellation,
  setCreatingNewCancellation,
  fetchCancellation,
}) => {
  const { formatMessage } = useIntl();
  const { config, user, sessions } = useContext(UserContext);
  const { formatDate } = useFormatting();
  const [formValues, setFormValues] = useState<FormValues>({
    externalId: insurance.insurance.externalId,
    deliveryMethod:
      cancellation && 'phoneNumber' in cancellation ? DeliveryMethod.SMS : DeliveryMethod.EMAIL,
    countryCode: CountryCode.SE,
    terminationDate:
      cancellation?.insurances[0]?.terminationDate ?? insurance.insurance.renewalDate ?? '',
    newInsuranceNumber: cancellation?.insurances[0]?.newInsuranceNumber,
    receiver:
      (cancellation &&
        (('email' in cancellation && cancellation.email) ||
          ('phoneNumber' in cancellation && cancellation.phoneNumber))) ||
      '',
  });

  const [deliveryMethod, setDeliveryMethod] = useState<DeliveryMethod>(formValues.deliveryMethod);
  const [terminationDate, setTerminationDate] = useState<string>(formValues.terminationDate);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  let collection = {} as Collection;

  sessions?.forEach((session) => {
    collection =
      session.collectionStartedResponses.find(
        (coll) => coll.collectionId === insurance.collectionId,
      ) || collection;
  });
  const requirePersonalNumber = insurance.insurance.insuranceCompany === 'se-manypets';

  const canCreateNewCancellation = useMemo(
    () =>
      cancellation?.status &&
      [CancellationStatusEnum.REJECTED, CancellationStatusEnum.TIMED_OUT].includes(
        cancellation?.status,
      ),
    [cancellation?.status],
  );

  const requireNewInsuranceNumber = config.newInsuranceNumberMandatory;

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    resetField,
    reset,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onTouched',
    resolver: zodResolver(schema(formatMessage, requirePersonalNumber, requireNewInsuranceNumber)),
  });

  const deliveryMethodValue = getValues('deliveryMethod');
  const receiverValue = getValues('receiver');

  useEffect(() => {
    if (formValues.externalId === insurance.insurance.externalId) {
      reset(formValues);
    } else {
      reset({
        externalId: insurance.insurance.externalId,
        deliveryMethod: DeliveryMethod.EMAIL,
        countryCode: CountryCode.SE,
        receiver: '',
        newInsuranceNumber: '',
        personalNumber: '',
        terminationDate: insurance.insurance.renewalDate ?? '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insurance.insurance.externalId]);

  useEffect(
    () => () => {
      setFormValues({
        externalId: insurance.insurance.externalId,
        deliveryMethod: deliveryMethodValue,
        receiver: receiverValue,
        countryCode: getValues('countryCode'),
        terminationDate: getValues('terminationDate'),
        newInsuranceNumber: getValues('newInsuranceNumber'),
        personalNumber: getValues('personalNumber'),
      });
    },
    [getValues, setFormValues, insurance.insurance.externalId, deliveryMethodValue, receiverValue],
  );

  useInterval(fetchCancellation, cancellation ? 5000 : null);

  useEffect(() => {
    if (cancellation?.email) {
      setDeliveryMethod(DeliveryMethod.EMAIL);
      setValue('receiver', cancellation.email);
    } else if (cancellation?.phoneNumber) {
      setDeliveryMethod(DeliveryMethod.SMS);
      setValue('receiver', cancellation.phoneNumber);
    } else if (cancellation) {
      setDeliveryMethod(DeliveryMethod.API);
    }
    if (cancellation?.insurances[0]?.newInsuranceNumber) {
      setValue('newInsuranceNumber', cancellation.insurances[0].newInsuranceNumber);
    }
    if (cancellation?.insurances[0]?.terminationDate) {
      setValue('terminationDate', cancellation.insurances[0].terminationDate);
    }
  }, [cancellation, setValue]);

  useEffect(() => {
    if (!cancellation && deliveryMethodValue === DeliveryMethod.EMAIL) {
      setDeliveryMethod(DeliveryMethod.EMAIL);
      resetField('receiver');
    } else if (!cancellation && deliveryMethodValue === DeliveryMethod.SMS) {
      setDeliveryMethod(DeliveryMethod.SMS);
      resetField('receiver');
    } else if (!cancellation) {
      setDeliveryMethod(DeliveryMethod.API);
    }
  }, [cancellation, insurance.insurance.externalId, deliveryMethodValue, resetField]);

  const DeliveryMethodOptions: { value: DeliveryMethod; label: string }[] = [
    { value: DeliveryMethod.EMAIL, label: formatMessage({ id: 'shared.email' }) },
    { value: DeliveryMethod.SMS, label: formatMessage({ id: 'shared.cell-phone-number' }) },
    { value: DeliveryMethod.API, label: formatMessage({ id: 'shared.manual-link' }) },
  ];

  const {
    handleOnSubmit,
    handleSendReminder,
    handleDeleteCancellation,
    sendingRequest,
    sendReminder,
    showErrorCard,
    showReminderModal,
    setShowErrorCard,
    setShowReminderModal,
  } = useCancellationEvents({
    collection,
    config,
    fetchCancellation,
    insurance,
    requirePersonalNumber,
    resetField,
    setCreatingNewCancellation,
    cancellation,
    user,
  });

  const renewalDateDifference = useMemo(
    () => getRenewalDateDifference({ cancellation, insurance, terminationDate }),
    [cancellation, insurance, terminationDate],
  );

  if (showErrorCard) {
    return (
      <ErrorCard
        submitCancellation={handleOnSubmit}
        onHide={() => setShowErrorCard(false)}
        getFormValues={getValues}
      />
    );
  }

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <CancellationContextualMenu
          insurance={insurance}
          cancellation={cancellation}
          setShowErrorCard={setShowDeleteModal}
        />
        <div className={styles.cardContent}>
          <Typography component="h2" variant="Headline-5">
            {formatMessage({ id: 'Power of attorney for cancellation' })}
          </Typography>
          {cancellation ? (
            <CancellationStatus status={cancellation.status} deliveryMethod={deliveryMethod} />
          ) : (
            <>
              <Typography component="p" variant="ParagraphBodySmall" className={styles.description}>
                {formatMessage({
                  id: 'Create a power of attorney for cancellation to be able to cancel the customers current insurens.',
                })}
              </Typography>
              <Typography component="h4" variant="Label-2">
                {formatMessage({ id: 'Chose how to send the power of attorney' })}
              </Typography>
            </>
          )}
          <form onSubmit={handleSubmit(handleOnSubmit)} className={styles.form}>
            {!cancellation && (
              <Controller
                control={control}
                name="deliveryMethod"
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    aria-label="delivery method"
                    className={styles.radioGroup}
                    fullWidth
                    columns="1"
                  >
                    {DeliveryMethodOptions.map((option) => (
                      <RadioButton
                        key={option.value}
                        label={option.label}
                        value={option.value}
                        checked={option.value === value}
                        onChange={() => {
                          setDeliveryMethod(option.value as DeliveryMethod);
                          setValue('receiver', '');
                          onChange(option.value);
                        }}
                      />
                    ))}
                  </RadioGroup>
                )}
              />
            )}

            {cancellation ? (
              <ReceiverInfo cancellation={cancellation} receiverValue={receiverValue} />
            ) : (
              <InputFields
                cancellation={cancellation}
                control={control}
                deliveryMethod={deliveryMethod}
                errors={errors}
                register={register}
              />
            )}

            {!cancellation && requirePersonalNumber && (
              <TextInput
                className={styles.input}
                label={formatMessage({ id: 'Personal number' })}
                {...register('personalNumber')}
                error={!!errors.personalNumber}
                helperText={errors.personalNumber?.message}
                textLine={!!cancellation}
              />
            )}
            <TextInput
              className={styles.newInsuranceNumber}
              label={
                <>
                  {config.newInsuranceNumberMandatory
                    ? formatMessage({ id: 'New insurance number' })
                    : formatMessage({ id: 'New insurance number (optional)' })}
                  <Tooltip
                    style={{ top: '10px', marginLeft: 'var(--space-1)' }}
                    contentStyle={{ minWidth: '270px' }}
                    content={formatMessage({
                      id: 'Enter the new insurance number to be able to follow the customer journey more easily. The number is for internal use only and does not appear in the power of attorney.',
                    })}
                  >
                    <Icon name="info" aria-hidden size={20} />
                  </Tooltip>
                </>
              }
              {...register('newInsuranceNumber')}
              error={!!errors.newInsuranceNumber}
              helperText={errors.newInsuranceNumber?.message}
              textLine={!!cancellation}
            />
            <Controller
              control={control}
              name="terminationDate"
              render={({ field: { onChange, value } }) => (
                <TextInput
                  label={formatMessage({ id: 'Termination date' })}
                  type="date"
                  value={value ?? ''}
                  onChange={(event) => {
                    setTerminationDate(event.currentTarget.value);
                    onChange(event);
                  }}
                  error={!!errors.terminationDate}
                  helperText={
                    errors.terminationDate?.message ?? !cancellation
                      ? insurance.insurance.renewalDate
                        ? formatMessage(
                            { id: "The insurance's binding period is until {date}" },
                            {
                              date: formatDate(insurance.insurance.renewalDate),
                            },
                          )
                        : formatMessage({
                            id: 'Unfortunately we could not find the binding period. Please ask the customer to find the correct date the the power of attorney does not risk being canceled',
                          })
                      : ''
                  }
                  className={styles.input}
                  textLine={!!cancellation}
                />
              )}
            />
            {renewalDateDifference !== 0 && (
              <Notification
                className={styles.notification}
                status="warning"
                description={
                  renewalDateDifference < 0
                    ? formatMessage({
                        id: 'The termination date is before the binding period. This can cause the insurance company to decline the cancellation',
                      })
                    : formatMessage({
                        id: 'The termination date is after the binding period. This can cause the insurance company to decline the cancellation',
                      })
                }
              />
            )}
            {cancellation &&
              cancellation.status !== CancellationStatusEnum.CLOSED &&
              (canCreateNewCancellation ? (
                <Button
                  variant="secondary"
                  type="button"
                  style={{ marginTop: '24px' }}
                  onClick={() => setCreatingNewCancellation(true)}
                >
                  {formatMessage({ id: 'Create new power of attorney' })}
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  type="button"
                  style={{ marginTop: '24px' }}
                  onClick={handleSendReminder}
                  loading={sendingRequest}
                >
                  {formatMessage({ id: 'Send reminder' })}
                </Button>
              ))}
            {!cancellation && (
              <Button
                variant="primary"
                loading={sendingRequest}
                style={{ marginTop: '24px' }}
                type="submit"
              >
                {deliveryMethod === DeliveryMethod.API
                  ? formatMessage({ id: 'Create power of attorney' })
                  : formatMessage({ id: 'Send power of attorney' })}
              </Button>
            )}
          </form>
          {cancellation && !canCreateNewCancellation && (
            <UnableToCancelSection deliveryMethod={deliveryMethod} />
          )}
        </div>
      </Card>

      <RecentReminderModal
        onHide={() => setShowReminderModal(false)}
        sendReminder={sendReminder}
        isOpen={showReminderModal}
      />
      <DeleteModal
        isOpen={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        deleteCancellation={handleDeleteCancellation}
      />
      <EventLog cancellation={cancellation} insurance={insurance} />
    </div>
  );
};
