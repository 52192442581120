import { Checkbox, Typography } from '@insurely/ui';
import classNames from 'classnames';
import { useContext, useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { ADMIN_PANEL_ROUTE, LIVEBOARDS_ROUTE } from 'constants/routes';
import UserContext from 'contexts/user/UserContext';
import tableStyles from 'styles/table.module.css';
import { FormattedMessage } from 'translations';

import { Liveboard } from 'types/liveboard';

import { Permissions } from 'types/v3';
import { Target } from 'views/Config/comp/ActionBanner/type';

import configStyles from '../../config.module.css';

import { TabWrapper } from './TabWrapper';
import styles from './userGroupConfig.module.css';

export const LiveboardsContent = ({
  liveboards,
  loading,
  onRefresh,
}: {
  loading: boolean;
  liveboards?: Liveboard[];
  onRefresh: () => void;
}) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const isInsurelyAdmin = useMemo(
    () => user?.permissions.includes(Permissions.INSURELY_ADMIN),
    [user?.permissions],
  );

  return (
    <TabWrapper loading={loading} refresh={onRefresh} items={liveboards} target={Target.LIVEBOARDS}>
      {({ filteredItems, handleOnSelectAll, handleOnSelect, selectedItems }) => (
        <table className={tableStyles.table}>
          <thead>
            <tr className={classNames(tableStyles.head, configStyles.tableHeader)}>
              <th style={{ width: '5%' }}>
                <Checkbox
                  aria-label="checkbox-user"
                  checked={selectedItems.length > 0}
                  partial={selectedItems.length > 0 && liveboards?.length !== selectedItems.length}
                  onCheckedChange={handleOnSelectAll}
                  disabled={liveboards?.length === 0}
                />
              </th>
              <th aria-labelledby="liveboard name" style={{ width: '30%' }}>
                <FormattedMessage id="page.config.user-group-config.liveboards.column.name" />
              </th>
              <th aria-labelledby="category">
                <FormattedMessage id="page.config.user-group-config.liveboards.column.category" />
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredItems.length === 0 && (
              <tr>
                <td colSpan={4} aria-label="empty-row-cell">
                  <div className={configStyles.emptyRow}>
                    <Typography variant="ParagraphBodySmall" component="p">
                      <FormattedMessage id="page.config.user-group-config.liveboards.empty-table" />
                    </Typography>
                  </div>
                </td>
              </tr>
            )}
            {(filteredItems as Liveboard[])
              .sort((a, b) => a.liveboardName.localeCompare(b.liveboardName))
              .map((l) => (
                <tr
                  className={classNames(tableStyles.row, configStyles.tableRow, {
                    [styles.notClickableRow]: !isInsurelyAdmin,
                  })}
                  key={l.liveboardName}
                  tabIndex={0}
                  onClick={() => {
                    if (isInsurelyAdmin) {
                      navigate(
                        `/${ADMIN_PANEL_ROUTE}/${LIVEBOARDS_ROUTE}/${l.thoughtspotLiveboardId}`,
                      );
                    }
                  }}
                >
                  {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
                  <td aria-label="user-select" onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                      aria-label="checkbox-user"
                      checked={selectedItems.includes(l.thoughtspotLiveboardId)}
                      onCheckedChange={() => handleOnSelect(l.thoughtspotLiveboardId)}
                    />
                  </td>
                  <td>
                    {/* Since the liveboards name are more like a key, we instead display the description as name */}
                    <Typography component="p" variant="ParagraphCaption">
                      {l.description}
                    </Typography>
                  </td>
                  <td aria-label="category">{l.liveboardName}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </TabWrapper>
  );
};
