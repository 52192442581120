import { Loader, Notification, Typography } from '@insurely/ui';

import { Page } from 'components/Page/Page';
import { FormattedMessage, useIntl } from 'translations';

import styles from './cancellationsPage.module.css';
import { CancellationTable } from './comp/CancellationTable';
import { useCancellations } from './useCancellations';

export const CancellationsPage = () => {
  const { formatMessage } = useIntl();

  const {
    cancellations,
    loading,
    loadMoreCancellations,
    canLoadMore,
    firstTimeLoading,
    errorLoadingCollectionInfo,
  } = useCancellations();

  const renderContent = () => {
    if (firstTimeLoading) {
      return (
        <div style={{ position: 'relative' }}>
          <Loader.Content />
        </div>
      );
    }
    if (cancellations.length === 0) {
      return (
        <Typography variant="ParagraphBody" component="p">
          {formatMessage({ id: "You haven't made any cancellations yet" })}
        </Typography>
      );
    }
    return (
      <CancellationTable
        cancellations={cancellations}
        loadMoreCancellations={loadMoreCancellations}
        canLoadMore={canLoadMore}
        loading={loading}
      />
    );
  };

  return (
    <Page title={formatMessage({ id: 'Cancellations' })}>
      <div className={styles.header}>
        <Typography component="h1" variant="Headline-3">
          {formatMessage({ id: 'Cancellations' })}
        </Typography>
      </div>

      {errorLoadingCollectionInfo && (
        <Notification
          status="warning"
          headline={formatMessage({ id: 'page.cancellations.error-fetching-collections.headline' })}
          className={styles.notification}
          expandableDescription
        >
          <FormattedMessage id="page.cancellations.error-fetching-collections.description" />
        </Notification>
      )}

      <div>{renderContent()}</div>
    </Page>
  );
};
