import { Button, Notification, TextArea, TextInput } from '@insurely/ui';
import { useForm } from 'react-hook-form';

import { useParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';

import { FormattedMessage, useIntl } from 'translations';

import { logError } from 'utils/datadog';

import { UserGroup } from 'views/Config/config.types';

import { editUserGroup } from '../../api';

import styles from './userGroupConfig.module.css';

interface Props {
  userGroup: UserGroup;
  onSuccess: () => void;
}

type FormValues = Pick<UserGroup, 'name' | 'description'>;

export const UserGroupSettings = ({ userGroup, onSuccess }: Props) => {
  const { formatMessage } = useIntl();
  const { customerId, userGroupId } = useParams() as {
    customerId: string;
    userGroupId: string;
  };

  const { register, handleSubmit } = useForm<FormValues>({
    mode: 'onTouched',
    defaultValues: {
      name: userGroup.name,
      description: userGroup.description,
    },
  });

  const [{ loading, error }, doUpdateUserGroup] = useAsyncFn((values: FormValues) =>
    editUserGroup({ customerId, userGroupId, ...values })
      .then(onSuccess)
      .catch((err) => {
        const nErr = new Error('Failed to edit user in user group', { cause: err });
        logError(nErr, { customerId, userGroupId });
        throw nErr;
      }),
  );

  const handleSubmitUserGroup = (values: FormValues) => {
    doUpdateUserGroup(values);
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitUserGroup)} className={styles.settingForm}>
      <TextInput
        id="name"
        aria-label="User group name"
        {...register('name')}
        label={formatMessage({ id: 'page.config.user-group-config.settings.form.name' })}
      />
      <TextArea
        id="description"
        aria-label="User group description"
        rows={3}
        {...register('description')}
        label={formatMessage({ id: 'page.config.user-group-config.settings.form.description' })}
      />
      <Button type="submit" variant="primary" style={{ alignSelf: 'flex-end' }} loading={loading}>
        <FormattedMessage id="page.config.user-group-config.settings.form.submit" />
      </Button>
      {!!error && (
        <Notification
          status="error"
          headline={formatMessage({ id: 'page.config.user-group-config.settings.error' })}
        />
      )}
    </form>
  );
};
