import { DefaultExpandableSection, Typography } from '@insurely/ui';

import { DeliveryMethod } from 'client/types';
import { useIntl } from 'translations';

import styles from './unableToCancel.module.css';

export const UnableToCancelSection = ({ deliveryMethod }: { deliveryMethod: DeliveryMethod }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <DefaultExpandableSection
        className={styles.explanation}
        title={formatMessage({ id: 'To change or delete a power of attorney' })}
      >
        {formatMessage({
          id: 'If you want to change a sent power of attorney, you need to delete the existing one. The power of attorney then becomes invalid, and you can create and send a new power of attorney to the customer. You delete the power of attorney by clicking on the three dots in the right corner, and then selecting "Delete power of attorney".',
        })}
      </DefaultExpandableSection>
      {deliveryMethod !== DeliveryMethod.API && (
        <DefaultExpandableSection
          className={styles.explanation}
          title={formatMessage({ id: 'Does the customer not get the power of attorney' })}
        >
          <Typography variant="ParagraphCaption" component="p">
            {formatMessage({
              id: 'If the customer has not received the power of attorney, it may be because the wrong email or mobile number has been entered, or due to technical reasons.',
            })}
          </Typography>
          <Typography variant="ParagraphCaption" component="p">
            {formatMessage({
              id: 'Delete the existing authorization and create a new one. If you want to send the power of attorney from another system, select "Manual link" as the way to send the power of attorney.',
            })}
          </Typography>
        </DefaultExpandableSection>
      )}
    </>
  );
};
