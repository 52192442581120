/* eslint-disable no-param-reassign */
import posthog from 'posthog-js';

import { logError } from 'utils/datadog';

export enum PostHogAction {
  CLICK = 'click',
  FETCH = 'fetch',
  NAVIGATE = 'navigate',
  STATE = 'state',
  VIEW = 'view', // This should only be used when we are missing an individual path for a page and therefor want to fake a path
}

export enum PostHogCategory {
  COMPARISON = 'comparison',
  CANCELLATION = 'cancellation',
  ADMIN = 'admin',
  COLLECTIONS = 'collections',
  INSURANCE = 'insurance',
  GENERAL = 'general',
}

const TEST_ID = 'phc_GbXvjjq6o55mnHlQhzf38FpKjPobXxDLJ1VFe6vNsWZ';
const PROD_ID = 'phc_Pb2fgTfqf58wzutoh4qqOkw3vbAwu4mRRYxKBfsNkBM';

const collectionIdPattern = /[a-f0-9]{32}/g;
const uuidPattern = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g;

const cleanUrl = (input: string): string =>
  input.replace(uuidPattern, '{uuid}').replace(collectionIdPattern, '{collectionId}');

const getCookieObject = (name: string) => {
  const cookiestring = RegExp(`${name}=[^;]+`).exec(document.cookie);
  const cookie = decodeURIComponent(
    cookiestring ? cookiestring.toString().replace(/^[^=]+./, '') : '',
  );
  try {
    return JSON.parse(cookie);
  } catch (e) {
    return null;
  }
};

export const getUserId = (insurelyId: string) => {
  const cookie = getCookieObject(`ph_${ENV === 'prod' ? PROD_ID : TEST_ID}_posthog`);
  if (cookie?.distinct_id) {
    return cookie.distinct_id;
  }
  return insurelyId;
};

export const init = () => {
  if (!ENV || ENV === 'localhost') {
    return;
  }

  posthog.init(ENV === 'prod' ? PROD_ID : TEST_ID, {
    api_host: 'https://app.posthog.com',
    autocapture: false,
    capture_pageview: true,
    capture_pageleave: false,
    sanitize_properties(properties) {
      properties.$current_url = properties.$current_url ? cleanUrl(properties.$current_url) : null;
      properties.$pathname = properties.$pathname ? cleanUrl(properties.$pathname) : null;
      properties.$referrer = properties.$referrer ? cleanUrl(properties.$referrer) : null;
      return properties;
    },
    ip: false,
    disable_session_recording: true,
    on_xhr_error: (error) => {
      const nErr = new Error('Error XHR in Posthog', { cause: error });
      logError(nErr);
    },
    loaded: () => {
      posthog.capture('$pageview', {
        $current_url: cleanUrl(`${window.location.origin}${window.location.pathname}`),
      });
    },
  });
};

export const postHogCapture = (
  eventName: string,
  data: {
    action: PostHogAction;
    category: PostHogCategory;
    object?: string;
    metadata?: Record<string, string | string[] | boolean>;
  },
): void => {
  const options = {
    $current_url: cleanUrl(`${window.location.origin}${window.location.pathname}`),
    referrer: cleanUrl(window.location.ancestorOrigins?.[0] ?? document.referrer),
    queryParameters: cleanUrl(window.location.search),
    ...data,
  };

  posthog.capture(eventName, options);
};
