import { CompanyLogo, Loader, Typography } from '@insurely/ui';

import { useIntl } from 'translations';

import styles from './loginLoader.module.css';

export default function LoginLoader() {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.container}>
      <div>
        <CompanyLogo className={styles.logo} company="se-demo" width="56px" aria-label="Insurely" />
        <Typography component="h1" variant="Headline-4" className={styles.signInTitle}>
          {formatMessage({ id: 'We are signing you in' })}
        </Typography>

        <Loader.Content style={{ position: 'relative', marginTop: '48px' }} />
      </div>
    </div>
  );
}
