import { ExpandableMenuLink, Icon, MenuLink, Typography } from '@insurely/ui';
import { useContext, useEffect, useState } from 'react';

import { NavLink, useLocation } from 'react-router-dom';

import { ANALYTICS_ROUTE } from 'constants/routes';
import UserContext from 'contexts/user/UserContext';
import { useIntl } from 'translations';
import { computeBoardNameKey } from 'utils/liveboard';

import styles from './analysisMenu.module.css';

interface Props {
  onCloseMenu: () => void;
}

export const AnalysisMenu = ({ onCloseMenu }: Props) => {
  const { formatMessage } = useIntl();
  const { pathname, search } = useLocation();
  const { liveboards } = useContext(UserContext);
  const [isMenuOpen, setIsMenuOpen] = useState(pathname.includes(ANALYTICS_ROUTE));

  useEffect(() => {
    if (!isMenuOpen) {
      setIsMenuOpen(pathname.includes(ANALYTICS_ROUTE));
    }
  }, [pathname, isMenuOpen]);

  if (liveboards.length === 0) {
    return null;
  }

  const computeBoardName = (boardName: string) => {
    const key = computeBoardNameKey(boardName);

    if (!key) {
      return boardName;
    }
    return formatMessage({ id: key });
  };

  return (
    <ExpandableMenuLink
      icon={<Icon name="donut-chart" aria-hidden size={28} />}
      title={
        <Typography component="span" variant="ParagraphBodySmall">
          {formatMessage({ id: 'shared.navigation.analytics' })}
        </Typography>
      }
      isOpen={isMenuOpen}
      onOpen={setIsMenuOpen}
    >
      {liveboards
        .filter((l) => !l.liveboardName.includes('/'))
        .map((board) => (
          <NavLink
            key={board.liveboardName}
            end={false}
            to={`${ANALYTICS_ROUTE}/${board.liveboardName}/${board.thoughtspotLiveboardId}${search}`}
            onClick={onCloseMenu}
          >
            {({ isActive }) => (
              <MenuLink alternating isSubItem isActive={isActive}>
                <Typography
                  component="span"
                  variant="ParagraphBodySmall"
                  className={styles.menuSubItem}
                >
                  {computeBoardName(board.liveboardName)}
                </Typography>
              </MenuLink>
            )}
          </NavLink>
        ))}
    </ExpandableMenuLink>
  );
};
