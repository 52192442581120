import { Icon, Tooltip, Typography } from '@insurely/ui';

import styles from './titleWithDescription.module.css';

interface Props {
  title: string;
  description: string;
}

export const TitleWithDescription = ({ title, description }: Props) => (
  <div className={styles.title}>
    <Typography variant="Label-2" component="label">
      {title}
    </Typography>
    <Tooltip trigger="hover" content={description} hideCloseIcon contentStyle={{ zIndex: 110 }}>
      <Icon name="info" size={18} color="var(--grey-1)" />
    </Tooltip>
  </div>
);
