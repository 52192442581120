import { Button, TextInput } from '@insurely/ui';
import { useState } from 'react';

import { useAsyncFn } from 'react-use';

import { FormattedMessage, useIntl } from 'translations';

import { logError } from 'utils/datadog';

import { updateCustomer } from '../api';
import styles from '../Customers/configPages.module.css';

interface Props {
  customerName: string;
  customerId: string;
  onSuccess: () => void;
}

export default function Settings({ customerName, customerId, onSuccess }: Props) {
  const { formatMessage } = useIntl();
  const [newName, setNewName] = useState<string>(customerName);

  const [{ loading }, doUpdateCustomer] = useAsyncFn(
    () =>
      updateCustomer({ customerId, customerName: newName })
        .then(onSuccess)
        .catch((err) => {
          const nErr = new Error('Failed to update customer', { cause: err });
          logError(nErr, { customerId });
          throw nErr;
        }),
    [customerId, newName],
  );

  function updateCustomerName(event: React.FormEvent) {
    event.preventDefault();
    if (newName.length > 2) {
      doUpdateCustomer();
    }
  }

  return (
    <form className={styles.form} onSubmit={updateCustomerName}>
      <TextInput
        id="customerName"
        aria-label="Customer name"
        label={formatMessage({
          id: 'page.config.customer-config.settings.customer-name',
        })}
        value={newName}
        onChange={(event) => setNewName(event.currentTarget.value)}
        error={newName.length < 2}
        helperText={
          newName.length < 2
            ? formatMessage({
                id: 'page.config.customer-config.settings.name-field.error',
              })
            : ''
        }
      />
      <Button
        type="submit"
        variant="primary"
        className={styles.formButton}
        loading={loading}
        disabled={loading}
      >
        <FormattedMessage id="page.config.customer-config.settings.save" />
      </Button>
    </form>
  );
}
