import { Plate, Tabs, Typography } from '@insurely/ui';
import { useContext, useState } from 'react';

import UserContext from 'contexts/user/UserContext';
import { FormattedMessage, useIntl } from 'translations';

import styles from './sendLink.module.css';
import SendViaEmail from './SendViaEmail';
import SendViaSMS from './SendViaSMS';

interface SendLinkProps {
  sessionId: string;
  sessionLink: string;
}

enum Tab {
  MOBILE = 'MOBILE',
  EMAIL = 'EMAIL',
}

export const SendCollectionLink = ({ sessionId, sessionLink }: SendLinkProps) => {
  const { formatMessage } = useIntl();
  const { productLine, config } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState(config.smsServiceEnabled ? Tab.MOBILE : Tab.EMAIL);

  const items = [
    {
      value: Tab.EMAIL,
      label: formatMessage({ id: 'page.session.send-collection-link.tab-email' }),
    },
  ];

  if (config.smsServiceEnabled) {
    items.unshift({
      value: Tab.MOBILE,
      label: formatMessage({ id: 'page.session.send-collection-link.tab-mobile' }),
    });
  }

  return (
    <Plate className={styles.container}>
      <div>
        <div className={styles.headline}>
          <Typography component="h3" variant="Headline-6">
            <FormattedMessage id="page.session.send-collection-link.headline" />
          </Typography>
          <Typography component="p" variant="ParagraphBodySmall">
            <FormattedMessage id={`page.session.send-collection-link.description.${productLine}`} />
          </Typography>
        </div>

        {config.smsServiceEnabled && (
          <Tabs
            items={items}
            activeTab={activeTab}
            onChange={(tab) => setActiveTab(tab as Tab)}
            className={styles.tabs}
          />
        )}

        {activeTab === Tab.MOBILE && <SendViaSMS sessionId={sessionId} />}
        {activeTab === Tab.EMAIL && <SendViaEmail sessionLink={sessionLink} />}
      </div>
    </Plate>
  );
};
