import { InsurelyUIProvider } from '@insurely/ui';

import { useMount } from 'react-use';

import { AppContextProvider } from 'contexts/app/AppContextProvider';

import { init as initDatadog } from 'utils/datadog';
import { init as initPosthog } from 'utils/posthog';

import AppRouter from './routers/AppRouter';

// Setting variable for actual page height
// (https://css-tricks.com/the-trick-to-viewport-units-on-mobile/)
const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

const App = () => {
  useMount(() => {
    initPosthog();
    initDatadog();
  });

  return (
    <InsurelyUIProvider
      fonts={{
        headlineFont: { url: 'https://assets.insurely.com/fonts/Gelica-Light.woff', weight: '300' },
      }}
    >
      <main>
        {/* This is used for up-check, do not remove! */}
        <div
          id="uptime-check-element"
          style={{ position: 'absolute', height: '2px', width: '2px' }}
        />
        <AppContextProvider>
          <AppRouter />
        </AppContextProvider>
      </main>
    </InsurelyUIProvider>
  );
};

export default App;
