import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Icon,
  IconButton,
  Loader,
  Modal,
  Plate,
  TextInput,
  Typography,
} from '@insurely/ui';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useNavigate, useParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';

import { z } from 'zod';

import { USER_GROUP_ROUTE } from 'constants/routes';
import tableStyles from 'styles/table.module.css';
import { FormattedMessage, useIntl } from 'translations';

import { logError } from 'utils/datadog';
import { TableTitle } from 'views/Config/comp/TableTitle/TableTitle';

import { TagsGroup } from 'views/Config/comp/TagsGroup/TagsGroup';
import { CustomerUserGroup } from 'views/Config/config.types';

import { createUserGroup } from '../../api';

import configStyles from '../../config.module.css';

import styles from './userGroups.module.css';

interface Props {
  userGroups?: CustomerUserGroup[];
  isFetchingUserGroups: boolean;
  onRefresh: () => void;
}

interface UserGroupFormValues {
  name: string;
  description: string;
}

const UserGroupForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const { formatMessage } = useIntl();
  const { customerId } = useParams() as { customerId: string };

  const formErrorMsg = formatMessage({
    id: 'page.config.customer-config.user-groups.form.field-error',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserGroupFormValues>({
    resolver: zodResolver(
      z.object({
        name: z.string().min(1, { message: formErrorMsg }),
        description: z.string().min(1, { message: formErrorMsg }),
      }),
    ),
  });

  const [{ loading: creatingUserGroup }, doCreateUserGroup] = useAsyncFn(
    ({ name, description }: UserGroupFormValues) =>
      createUserGroup({ customerId, name, description })
        .then(onSuccess)
        .catch((err) => {
          const nErr = new Error('Failed to create a user group', { cause: err });
          logError(nErr, { customerId });
          throw nErr;
        }),
    [customerId],
  );

  const handleOnSubmit = (values: UserGroupFormValues) => {
    doCreateUserGroup(values);
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(handleOnSubmit)}>
      <TextInput
        id="Name"
        aria-label="Name"
        label={formatMessage({
          id: 'page.config.customer-config.user-groups.form.name',
        })}
        error={!!errors.name}
        helperText={errors.name?.message}
        {...register('name')}
      />
      <TextInput
        id="description"
        aria-label="Description"
        label={formatMessage({
          id: 'page.config.customer-config.user-groups.form.description',
        })}
        error={!!errors.description}
        helperText={errors.description?.message}
        {...register('description')}
      />
      <Button
        type="submit"
        variant="primary"
        className={styles.formButton}
        loading={creatingUserGroup}
        disabled={creatingUserGroup}
      >
        <FormattedMessage id="page.config.customer-config.user-groups.form.add-group" />
      </Button>
    </form>
  );
};

export default function UserGroups({ userGroups, isFetchingUserGroups, onRefresh }: Props) {
  const [addUserGroup, setAddUserGroup] = useState(false);
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');

  const filteredUserGroups = useMemo(
    () =>
      userGroups
        ? userGroups.filter((g) => g.name.toLowerCase().includes(search.toLowerCase()))
        : [],
    [userGroups, search],
  );

  function renderContent() {
    if (isFetchingUserGroups || !userGroups) {
      return <Loader.Content />;
    }

    return (
      <Plate>
        <div className={styles.headline}>
          <TableTitle
            icon="personal"
            title={formatMessage({ id: 'page.config.customer-config.user-groups.title' })}
          />
          <div className={styles.headlineActions}>
            <Button
              variant="primary"
              size="medium"
              onClick={() => setAddUserGroup(true)}
              icon={<Icon name="plus" size={20} />}
            >
              <FormattedMessage id="page.config.customer-config.user-groups.add-group.button" />
            </Button>
            <TextInput
              startAdornment={<Icon name="search" size={20} />}
              endAdornment={
                search.length > 0 ? (
                  <IconButton
                    icon={<Icon name="close" size={20} />}
                    onClick={() => setSearch('')}
                  />
                ) : undefined
              }
              textInputSize="extra-small"
              value={search}
              onChange={(e) => setSearch(e.currentTarget.value)}
              placeholder={formatMessage({
                id: 'page.config.customer-config.user-groups.search.placeholder',
              })}
              label=""
            />
          </div>
        </div>
        <table className={tableStyles.table}>
          <thead>
            <tr className={classNames(tableStyles.head, configStyles.tableHeader)}>
              <th aria-labelledby="name" style={{ width: '20%' }}>
                <Typography component="p" variant="Label-3">
                  <FormattedMessage id="page.config.customer-config.user-groups.column.name" />
                </Typography>
              </th>
              <th aria-labelledby="description" style={{ width: '20%' }}>
                <Typography component="p" variant="Label-3">
                  <FormattedMessage id="page.config.customer-config.user-groups.column.description" />
                </Typography>
              </th>
              <th aria-labelledby="created-at" style={{ width: '25%' }}>
                <Typography component="p" variant="Label-3">
                  <FormattedMessage id="page.config.customer-config.user-groups.column.clients" />
                </Typography>
              </th>
              <th aria-labelledby="last-updated" style={{ width: '35%' }}>
                <Typography component="span" variant="Label-3">
                  <FormattedMessage id="page.config.customer-config.user-groups.column.liveboards" />
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredUserGroups.length === 0 && (
              <tr>
                <td aria-label="empty-table" colSpan={5}>
                  <div className={configStyles.emptyRow}>
                    <Typography component="p" variant="ParagraphBodySmall">
                      <FormattedMessage id="page.config.customer-config.user-groups.no-group" />
                    </Typography>
                  </div>
                </td>
              </tr>
            )}
            {filteredUserGroups
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((group) => (
                <tr
                  className={classNames(tableStyles.row, configStyles.tableRow)}
                  key={group.uuid}
                  tabIndex={0}
                  onClick={() => navigate(`${USER_GROUP_ROUTE}/${group.uuid}`)}
                >
                  <td style={{ paddingLeft: 'var(--space-4)' }}>
                    <Typography component="p" variant="ParagraphCaption">
                      {group.name}
                    </Typography>
                  </td>
                  <td>
                    <Typography component="p" variant="ParagraphCaption">
                      {group.description}
                    </Typography>
                  </td>
                  <td aria-label="clients">
                    <Typography component="p" variant="ParagraphCaption">
                      <TagsGroup tags={group.clients.map((c) => c.clientName)} />
                    </Typography>
                  </td>
                  <td aria-label="liveboards">
                    <Typography component="p" variant="ParagraphCaption">
                      <TagsGroup tags={group.liveboards.map((l) => l.description)} />
                    </Typography>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <Modal
          isOpen={addUserGroup}
          onClose={() => setAddUserGroup(false)}
          title={formatMessage({ id: 'page.config.customer-config.user-groups.add-group' })}
          className={styles.formModal}
          width={560}
        >
          <UserGroupForm
            onSuccess={() => {
              onRefresh();
              setAddUserGroup(false);
            }}
          />
        </Modal>
      </Plate>
    );
  }

  return <div>{renderContent()}</div>;
}
