import { newRequestWithToken } from 'client/client';
import { Liveboard } from 'types/liveboard';
import { Permissions } from 'types/v3';
import { CustomerUserGroup, MinimalClientConfig, User, UserGroup } from 'views/Config/config.types';

export const getAllUserGroups = async (customerId: string): Promise<CustomerUserGroup[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/customer/${customerId}/user-group`,
  });
  return res?.data;
};

export const getUserGroupById = async (
  customerId: string,
  userGroupId: string,
): Promise<UserGroup> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/customer/${customerId}/user-group/${userGroupId}`,
  });
  return res?.data;
};

export const createUserGroup = async ({
  customerId,
  name,
  description,
}: {
  customerId: string;
  name: string;
  description: string;
}) => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/setup/customer/${customerId}/user-group`,
    data: { name, description },
  });
  return res?.data;
};

export const editUserGroup = async ({
  customerId,
  userGroupId,
  name,
  description,
}: {
  customerId: string;
  userGroupId: string;
  name: string;
  description: string;
}) => {
  const res = await newRequestWithToken({
    method: 'PUT',
    url: `/sst/setup/customer/${customerId}/user-group/${userGroupId}`,
    data: { name, description },
  });
  return res?.data;
};

export const getUserGroupClients = async (userGroupId: string): Promise<MinimalClientConfig[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/user-group/${userGroupId}/client`,
  });
  return res?.data;
};

export const removeClientFromGroup = async ({
  userGroupId,
  clientId,
}: {
  userGroupId: string;
  clientId: string;
}) => {
  const res = await newRequestWithToken({
    method: 'DELETE',
    url: `/sst/setup/user-group/${userGroupId}/client/${clientId}`,
  });
  return res?.data;
};

export const addClientsToGroup = async ({
  userGroupId,
  clientId,
}: {
  userGroupId: string;
  clientId: string;
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/setup/user-group/${userGroupId}/client`,
    headers: { 'Content-Type': 'text/plain' },
    data: clientId,
  });
  return res?.data;
};

export const getUserGroupUsers = async (userGroupId: string): Promise<User[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/user-group/${userGroupId}/members`,
  });
  return res?.data;
};

export const addUsersToGroup = async ({
  userGroupId,
  userIds,
}: {
  userGroupId: string;
  userIds: string[];
}) => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/setup/user-group/${userGroupId}/members`,
    data: userIds,
  });
  return res?.data;
};

export const deleteUserFromGroup = async ({
  userGroupId,
  userId,
}: {
  userGroupId: string;
  userId: string;
}) => {
  const res = await newRequestWithToken({
    method: 'DELETE',
    url: `/sst/setup/user-group/${userGroupId}/members/${userId}`,
  });
  return res?.data;
};

export const getUserGroupLiveboards = async (userGroupId: string): Promise<Liveboard[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/user-group/${userGroupId}/liveboard`,
  });
  return res?.data;
};

export const removeLiveboardFromGroup = async ({
  userGroupId,
  liveboardId,
}: {
  userGroupId: string;
  liveboardId: string;
}) => {
  const res = await newRequestWithToken({
    method: 'DELETE',
    url: `/sst/setup/user-group/${userGroupId}/liveboard/${liveboardId}`,
  });
  return res?.data;
};

export const addLiveboardsToGroup = async ({
  userGroupId,
  liveboardIds,
}: {
  userGroupId: string;
  liveboardIds: string[];
}) => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/setup/user-group/${userGroupId}/liveboard`,
    data: liveboardIds,
  });
  return res?.data;
};

export const getUserGroupPermissions = async (userGroupId: string): Promise<Permissions[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/user-group/${userGroupId}/permission`,
  });
  return res?.data;
};
