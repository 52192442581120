import { ExpandableMenuLink, Icon, MenuLink, Typography } from '@insurely/ui';
import { useContext, useEffect, useMemo, useState } from 'react';

import { NavLink, useLocation } from 'react-router-dom';

import { SYSTEM_STATUS_ROUTE, CONTACT_ROUTE, FAQ_ROUTE, GET_STARTED_ROUTE } from 'constants/routes';
import UserContext from 'contexts/user/UserContext';
import { useIntl } from 'translations';
import { Market } from 'types/v3';

import styles from './supportMenu.module.css';

interface Props {
  onClick: () => void;
}

export const SupportMenu = ({ onClick }: Props) => {
  const { pathname, search } = useLocation();
  const { config } = useContext(UserContext);
  const { formatMessage } = useIntl();

  const supportRoutes = useMemo(
    () => [
      config.market === Market.SE
        ? {
            route: GET_STARTED_ROUTE,
            label: formatMessage({ id: 'shared.navigation.get-started' }),
          }
        : { route: FAQ_ROUTE, label: formatMessage({ id: 'shared.navigation.faq' }) },
      {
        route: SYSTEM_STATUS_ROUTE,
        label: formatMessage({ id: 'shared.navigation.system-status' }),
      },
      { route: CONTACT_ROUTE, label: formatMessage({ id: 'shared.navigation.contact' }) },
    ],
    [config.market, formatMessage],
  );

  const [isMenuOpen, setIsMenuOpen] = useState(
    supportRoutes.some((r) => pathname.includes(r.route)),
  );

  useEffect(() => {
    if (!isMenuOpen) {
      setIsMenuOpen(supportRoutes.some((r) => pathname.includes(r.route)));
    }
  }, [pathname, isMenuOpen, supportRoutes]);

  return (
    <ExpandableMenuLink
      icon={<Icon name="guide" aria-hidden size={28} />}
      title={
        <Typography component="span" variant="ParagraphBodySmall">
          {formatMessage({ id: 'shared.navigation.support' })}
        </Typography>
      }
      isOpen={isMenuOpen}
      onOpen={setIsMenuOpen}
    >
      {supportRoutes.map((support) => (
        <NavLink key={support.label} end={false} to={`${support.route}${search}`} onClick={onClick}>
          {({ isActive }) => (
            <MenuLink alternating isSubItem isActive={isActive}>
              <Typography
                component="span"
                variant="ParagraphBodySmall"
                className={styles.menuSubItem}
              >
                {support.label}
              </Typography>
            </MenuLink>
          )}
        </NavLink>
      ))}
    </ExpandableMenuLink>
  );
};
