import { Loader, MultiSelect } from '@insurely/ui';

import { useState } from 'react';

import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';

import { useIntl } from 'translations';

import { logError } from 'utils/datadog';

import { getAllUserGroups } from '../../../api';

import styles from '../actionBanner.module.css';

interface Props {
  addCallback: {
    onAdd: (permissions: string[]) => void;
    loading: boolean;
    disabled: boolean;
  };
  removeCallback: {
    onRemove: (permissions: string[]) => void;
    loading: boolean;
    disabled: boolean;
  };
}

export const UserGroupMgmt = ({ addCallback, removeCallback }: Props) => {
  const { customerId } = useParams() as {
    customerId: string;
  };
  const { formatMessage } = useIntl();

  const [selectedUserGroups, setSelectedUserGroups] = useState<string[]>([]);

  const { loading: fetchingUserGroups, value: userGroups } = useAsync(() =>
    getAllUserGroups(customerId).catch((err) => {
      const nErr = new Error('Failed to fetch all user groups', { cause: err });
      logError(nErr, { customerId });
      throw nErr;
    }),
  );

  const { onAdd, loading: addLoading, disabled: addDisabled } = addCallback;
  const { onRemove, loading: removeLoading, disabled: removeDisabled } = removeCallback;

  const handleSelected = (count: number) => {
    if (!userGroups) {
      return '';
    }
    if (count === 1) {
      return userGroups.find((p) => p.uuid === selectedUserGroups[0])?.name ?? '';
    }
    return formatMessage(
      { id: 'page.config.action-banner.user-group-mgmt.amount-selected' },
      { count: selectedUserGroups.length },
    );
  };

  return (
    <div className={styles.action}>
      {fetchingUserGroups ? (
        <div className={styles.spinnerContainer}>
          <Loader.Content size="small" />
        </div>
      ) : (
        <MultiSelect
          variant="small"
          id="user-groups"
          renderValue={(values) => {
            if (values.length === 0) {
              return null;
            }
            if (values.length === userGroups?.length) {
              return formatMessage({
                id: `page.config.action-banner.user-group-mgmt.all-selected`,
              });
            }
            return handleSelected(values.length);
          }}
          fullWidth
          options={
            userGroups
              ?.map((g) => ({ label: g.name, value: g.uuid }))
              .sort((a, b) => a.label.localeCompare(b.label)) ?? []
          }
          onChange={setSelectedUserGroups}
          value={selectedUserGroups}
          placeholder={formatMessage({
            id: 'page.config.action-banner.user-group-mgmt.placeholder',
          })}
          error={userGroups?.length === 0}
          helperText={
            userGroups?.length === 0
              ? formatMessage({ id: 'page.config.action-banner.user-group-mgmt.no-items' })
              : undefined
          }
          secondaryCallback={{
            label: formatMessage({ id: 'page.config.action-banner.user-group-mgmt.remove' }),
            loading: removeLoading,
            disabled: removeDisabled || fetchingUserGroups || selectedUserGroups.length === 0,
            action: () => onRemove(selectedUserGroups),
          }}
          primaryCallback={{
            label: formatMessage({ id: 'page.config.action-banner.user-group-mgmt.add' }),
            loading: addLoading,
            disabled: addDisabled || fetchingUserGroups || selectedUserGroups.length === 0,
            action: () => onAdd(selectedUserGroups),
          }}
        />
      )}
    </div>
  );
};
