import { Card, CompanyLogo, Icon, StatusText, Tooltip, Typography } from '@insurely/ui';
import { useMemo } from 'react';

import { Link, useLocation } from 'react-router-dom';

import { getStatusColor } from 'components/MovableStatus/MovableStatus';
import { COLLECTIONS_ROUTE, SESSIONS_ROUTE } from 'constants/routes';
import { useIntl } from 'translations';
import { useFormatting } from 'translations/useFormatting';

import { FinancialProduct, MovabilityStatus } from 'types/wealth';

import styles from './pensionCard.module.css';
import { PensionCardTooltip } from './PensionCardTooltip';

export const PensionCard = ({ pension }: { pension: FinancialProduct }) => {
  const { pathname, search } = useLocation();
  const { formatMessage } = useIntl();
  const { formatCurrency } = useFormatting();

  const {
    collectionId,
    id,
    company,
    companyDisplayName,
    productName,
    holder,
    type,
    financialInformation,
    movability,
    startDate,
    activePayment,
    activePayout,
  } = pension;

  const statusText = useMemo(() => {
    switch (movability?.result) {
      case MovabilityStatus.NOT_MOVABLE:
        return formatMessage({ id: 'page.pension-listing.pension-card.not-movable' });
      case MovabilityStatus.MOVABLE:
        return formatMessage({ id: 'page.pension-listing.pension-card.movable' });
      case MovabilityStatus.UNKNOWN:
      default:
        return formatMessage({ id: 'page.pension-listing.pension-card.maybe-movable' });
    }
  }, [formatMessage, movability?.result]);

  const pageUrl = pathname.match(SESSIONS_ROUTE) ? SESSIONS_ROUTE : COLLECTIONS_ROUTE;
  const link = `/${pageUrl}/${collectionId}/pension/${id}${search}`;

  return (
    <Card className={styles.card}>
      <Link to={link}>
        <div className={styles.header}>
          <Typography component="h3" variant="Headline-7">
            {companyDisplayName}
          </Typography>
        </div>
        <div className={styles.content}>
          <CompanyLogo company={company} width={40} />
          <div className={styles.bottomContent}>
            <div>
              <Typography component="h4" variant="Headline-8">
                {productName}
              </Typography>
              {movability && (
                <div className={styles.movability}>
                  <StatusText color={getStatusColor(movability.result)} text={statusText} />*
                  <Tooltip
                    contentStyle={{
                      whiteSpace: 'nowrap',
                      width: 'auto',
                      maxWidth: undefined,
                    }}
                    trigger="hover"
                    position="bottom"
                    content={
                      <PensionCardTooltip
                        startDate={startDate}
                        activePayment={activePayment}
                        activePayout={activePayout}
                      />
                    }
                  >
                    <Icon name="info" aria-hidden size={16} />
                  </Tooltip>
                </div>
              )}
            </div>
            <div>
              <Typography
                component="p"
                variant="ParagraphBodySmall"
                className={styles.notAvailableType}
              >
                {type
                  ? formatMessage({ id: type })
                  : formatMessage({ id: 'page.pension-listing.pension-card.no-insurance-type' })}
              </Typography>
              <Typography
                component="p"
                variant="ParagraphTiny"
                className={styles.notAvailableEmployer}
              >
                {holder.name ||
                  formatMessage({ id: 'page.pension-listing.pension-card.no-employer' })}
              </Typography>
            </div>
            {financialInformation.currentValue !== undefined && (
              <div className={styles.currentValue}>
                <Typography component="h5" variant="Headline-6">
                  {formatCurrency(financialInformation.currentValue.amount)}
                </Typography>
              </div>
            )}
          </div>
          <Icon name="chevron-right" aria-hidden size={28} />
        </div>
      </Link>
    </Card>
  );
};
