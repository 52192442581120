import { Loader, ToastProvider } from '@insurely/ui';

import { useContext } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { IntlProvider } from 'react-intl';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import ErrorBoundaryFallbackComponent from 'components/ErrorHandling/ErrorBoundaryFallbackComponent';
import { ENABLE_ACCOUNT_ROUTE, LOGIN_ROUTE, LOGOUT_ROUTE } from 'constants/routes';

import AppContext from 'contexts/app/AppContext';
import { UserContextProvider } from 'contexts/user/UserContextProvider';
import { useTranslations } from 'hooks/useTranslations';
import { IGNORED_ERRORS_FROM_REPORTING, logError } from 'utils/datadog';
import { LoginPages } from 'views/Login/LoginPages';

import Logout from 'views/Logout/Logout';

import UserRouter from './UserRouter';

const AppRouter = () => {
  const { language } = useContext(AppContext);
  const { messages } = useTranslations();
  const { search } = useLocation();

  if (!messages) {
    return <Loader.Content />;
  }

  function onError(error: Error) {
    const nErr = new Error(error.message, { cause: error });
    // Temp disable of missing translation errors from config pages
    if (!error.toString().includes('page.config.')) {
      logError(nErr);
    }
  }

  return (
    <IntlProvider locale={language} messages={messages} onError={onError}>
      <ErrorBoundary
        fallback={<ErrorBoundaryFallbackComponent />}
        onError={(err) => {
          if (!IGNORED_ERRORS_FROM_REPORTING.some((msg) => err.message.includes(msg))) {
            logError(err);
          }
        }}
      >
        <ToastProvider>
          <Routes>
            <Route path={`/${LOGIN_ROUTE}/*`} element={<LoginPages />} />
            <Route path={`/${LOGOUT_ROUTE}`} element={<Logout />} />
            <Route
              path={ENABLE_ACCOUNT_ROUTE}
              element={<Navigate to={`/${LOGIN_ROUTE}/${ENABLE_ACCOUNT_ROUTE}${search}`} />}
            />
            <Route
              path="/*"
              element={
                <UserContextProvider>
                  <UserRouter />
                </UserContextProvider>
              }
            />
          </Routes>
        </ToastProvider>
      </ErrorBoundary>
    </IntlProvider>
  );
};

export default AppRouter;
