import { useSearchParams } from 'react-router-dom';
import { useMount } from 'react-use';

export const useQueryParamTab = <T>(defaultValue: T) => {
  const [params, setParams] = useSearchParams();

  const tab = params.get('tab') as T;

  const setTab = (n: T, replace?: boolean) => setParams(`tab=${n}`, { replace });

  useMount(() => {
    if (!tab && defaultValue) {
      setTab(defaultValue, true);
    }
  });

  return { tab, setTab };
};
