import { Typography } from '@insurely/ui';

import { Page } from 'components/Page/Page';
import ReloadInstructions from 'components/ReloadInstructions';
import { useIntl } from 'translations';

import styles from './fallbackComponent.module.css';

export default function ErrorMessage() {
  const { formatMessage } = useIntl();

  return (
    <Page title="Fel på sidan" className={styles.page}>
      <Typography component="h1" variant="Headline-3">
        {formatMessage({ id: 'The page crashed, possible reasons could be:' })}
      </Typography>
      <Typography component="h2" variant="Headline-5" className={styles.subTitle}>
        {formatMessage({ id: '1. You need to make a "hard" reload' })}
      </Typography>
      <Typography component="p" variant="ParagraphBody">
        {formatMessage({
          id: 'Sometimes you have an old, cached version of Insights and need to do whats called a "hard" reload. You do it like this:',
        })}
      </Typography>
      <ReloadInstructions />

      <Typography component="h2" variant="Headline-5" className={styles.subTitle}>
        {formatMessage({ id: '2. Something went wrong' })}
      </Typography>

      <div className={styles.textWrapper}>
        <Typography component="p" variant="ParagraphBody">
          {formatMessage({
            id: 'We have recived an error message and can be sure that we are working on a solution.',
          })}
        </Typography>

        <Typography component="p" variant="ParagraphBody">
          {formatMessage({ id: 'You can always e-mail us on' })}
          <a href="mailto:support@insurely.se">support@insurely.com</a>
          {formatMessage({ id: 'if you have any questions.' })}
        </Typography>
      </div>
    </Page>
  );
}
