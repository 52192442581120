import { Plate, Typography } from '@insurely/ui';

import { FormattedMessage, useIntl } from 'translations';

import { useFormatting } from 'translations/useFormatting';
import { CollectionInfoResponse } from 'types/v3';

import MarketingConsentTag from './components/MarketingConsentTag/MarketingConsentTag';
import styles from './contactDetails.module.css';

export default function ContactDetails({ collections }: { collections: CollectionInfoResponse[] }) {
  const { formatMessage } = useIntl();
  const { formatPhoneNumber } = useFormatting();

  const phoneNumbers = Array.from(
    new Set(
      collections
        .map((col) => (col.contactPhone ? formatPhoneNumber(col.contactPhone) : null))
        .filter((phone) => phone !== null),
    ),
  ) as string[];

  const emails = Array.from(
    new Set(collections.map((col) => col.contactEmail).filter((email) => email !== undefined)),
  ) as string[];

  function printContent(list: string[]) {
    if (list.length === 0) {
      return (
        <span style={{ color: 'var(--grey-1)' }}>{formatMessage({ id: 'Not available' })}</span>
      );
    }

    return list.map((item) => (
      <>
        {item}
        <br />
      </>
    ));
  }

  if (!phoneNumbers && !emails) {
    return null;
  }

  return (
    <>
      <Typography className={styles.title} component="h5" variant="Headline-5">
        {formatMessage({ id: 'Contact details' })}
      </Typography>

      <Plate className={styles.plate}>
        <ul>
          <li className={styles.row}>
            <Typography component="p" variant="ParagraphCaption">
              <FormattedMessage id="shared.phone-number" />
            </Typography>
            <div>
              <Typography className={styles.value} component="p" variant="Label-3">
                {printContent(phoneNumbers)}
              </Typography>
            </div>
          </li>
          <li className={styles.row}>
            <Typography component="p" variant="ParagraphCaption">
              <FormattedMessage id="shared.email" />
            </Typography>
            <div>
              <Typography className={styles.value} component="p" variant="Label-3">
                {printContent(emails)}
              </Typography>
              {emails.length > 0 && (
                <MarketingConsentTag consent={collections[0].marketingConsent} />
              )}
            </div>
          </li>
        </ul>
      </Plate>
    </>
  );
}
