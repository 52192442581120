import { Button, Modal, Typography } from '@insurely/ui';

import { FormattedMessage, useIntl } from 'translations';

import styles from './confirmationModal.module.css';

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
  isPending: boolean;
}

export const ConfirmationModal = ({ onCancel, onConfirm, isPending }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Modal
      title={formatMessage({ id: 'page.config.confirmation-modal.title' })}
      isOpen
      onClose={onCancel}
    >
      <div className={styles.content}>
        <Typography variant="ParagraphBodySmall" component="p">
          <FormattedMessage id="page.config.confirmation-modal.body" />
        </Typography>
        <div className={styles.buttons}>
          <Button variant="secondary" onClick={onCancel} size="medium">
            <FormattedMessage id="page.config.confirmation-modal.cancel" />
          </Button>
          <Button onClick={onConfirm} size="medium" loading={isPending}>
            <FormattedMessage id="page.config.confirmation-modal.confirm" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
