import { MobilityRuleName, MovabilityStatus } from 'types/wealth';

export const notificationHandling = (movability?: {
  result: MovabilityStatus;
  evaluatedRules: Array<{ rule: MobilityRuleName; result: MovabilityStatus }>;
}): { headline: string | null; reasons: string[] } | null => {
  if (!movability) {
    return {
      headline: null,
      reasons: ['page.pension.movability.not-movable.unknown-reason'],
    };
  }

  const reasons: string[] = [];
  let headline = null;
  const evaluations = movability.evaluatedRules.filter((e) => e.result === movability?.result);

  if (movability.result === MovabilityStatus.MOVABLE || evaluations.length === 0) {
    return null;
  }

  if (movability.result === MovabilityStatus.NOT_MOVABLE) {
    headline = 'page.pension.movability.not-movable.title';
    evaluations.forEach((e) => {
      if (e.rule === 'ACTIVE_PAYMENT') {
        reasons.push('page.pension.movability.not-movable.description.active-payments');
      } else if (e.rule === 'ACTIVE_PAYOUT') {
        reasons.push('page.pension.movability.not-movable.description.payout');
      } else if (e.rule === 'OLD_TRAD_PENSION') {
        reasons.push('page.pension.movability.not-movable.description.old-trad-pension');
      }
    });
  } else if (movability.result === MovabilityStatus.UNKNOWN) {
    headline = 'page.pension.movability.unknown.title';
    evaluations.forEach((e) => {
      if (e.rule === 'ACTIVE_PAYMENT') {
        reasons.push('page.pension.movability.unknown.description.active-payment');
      } else if (e.rule === 'ACTIVE_PAYOUT') {
        reasons.push('page.pension.movability.unknown.description.payout');
      } else if (e.rule === 'OLD_TRAD_PENSION') {
        reasons.push('page.pension.movability.unknown.description.old-trad-pension');
      }
    });
  }

  return {
    headline,
    reasons,
  };
};
