import { Typography } from '@insurely/ui';
import { useContext, useMemo } from 'react';

import { Page } from 'components/Page/Page';

import UserContext from 'contexts/user/UserContext';
import { useIntl } from 'translations';

import styles from './faq.module.css';
import { getQuestions } from './faqQuestions';

export default function FAQ() {
  const { productLine } = useContext(UserContext);
  const { formatMessage } = useIntl();

  const questions = useMemo(() => {
    const quests = getQuestions(formatMessage, productLine);
    return quests.map((question) => (
      <div key={question.question}>
        <Typography className={styles.subTitle} component="h2" variant="Headline-4">
          {question.question}
        </Typography>
        <Typography className={styles.paragraph} component="p" variant="ParagraphBody">
          {question.answer}
        </Typography>
      </div>
    ));
  }, [productLine, formatMessage]);

  return (
    <Page title="FAQ">
      <div style={{ margin: '0 auto 100px', maxWidth: 800 }}>
        <Typography className={styles.title} component="h1" variant="Headline-3">
          FAQ
        </Typography>
        <Typography className={styles.preamble} component="p" variant="ParagraphBody">
          {formatMessage({
            id: 'This FAQ aims to give you as a salesperson or customer contact support in answering questions that may come from customers. It is a collection of the questions we have historically received and examples of suggested answers.',
          })}
        </Typography>

        {questions}
      </div>
    </Page>
  );
}
