import { newRequestWithToken } from 'client/client';
import { Market } from 'types/v3';
import { InsuranceSubType, InsuranceType } from 'types/v3/Insurance';

interface ReportProblemBody {
  coverageCompany: string;
  insuranceName: string;
  insuranceSubType: InsuranceSubType;
  insuranceType: InsuranceType;
  market: Market;
  parameterName: string;
  userId: string;
  feedbackText: string;
  externalId?: string;
}

export const reportProblemForCell = (body: ReportProblemBody, clientId: string): Promise<void> =>
  newRequestWithToken({
    method: 'POST',
    url: '/coverage/feedback',
    data: { ...body, market: body.market.toUpperCase() },
    headers: { 'client-id': clientId },
  }).then(({ data }) => data);
