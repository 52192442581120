import { Color, Typography } from '@insurely/ui';

import React from 'react';

import { CancellationStatus as CancellationStatusEnum, DeliveryMethod } from 'client/types';
import { IntlShape, useIntl } from 'translations';

import styles from './CancellationStatus.module.css';

export const getStatusText = (
  status: CancellationStatusEnum,
  formatMessage: IntlShape['formatMessage'],
) => {
  switch (status) {
    case CancellationStatusEnum.CANCELED:
      return formatMessage({ id: 'Deleted' });
    case CancellationStatusEnum.TIMED_OUT:
      return formatMessage({ id: 'Timed out' });
    case CancellationStatusEnum.CLOSED:
      return formatMessage({ id: 'Signed' });
    case CancellationStatusEnum.DOCUMENT_ERROR:
      return formatMessage({ id: 'Not delivered' });
    case CancellationStatusEnum.REJECTED:
      return formatMessage({ id: 'Rejected' });
    case CancellationStatusEnum.PENDING:
      return formatMessage({ id: 'Waiting for signature' });
    case CancellationStatusEnum.CREATED:
      return formatMessage({ id: 'No status yet' });
    case CancellationStatusEnum.PREPARATION:
    default:
      return formatMessage({ id: 'Waiting for signature' });
  }
};

export const getStatusColor = (status: CancellationStatusEnum) => {
  switch (status) {
    case CancellationStatusEnum.CLOSED:
      return Color.grannySmithDark;
    case CancellationStatusEnum.PREPARATION:
    case CancellationStatusEnum.PENDING:
      return Color['yellow-1'];
    case CancellationStatusEnum.CREATED:
      return Color['grey-1'];
    default:
      return Color['red-1'];
  }
};

export const StatusDot = ({
  status,
  isDeliveryMethodAPI,
}: {
  status: CancellationStatusEnum;
  isDeliveryMethodAPI?: boolean;
}) => (
  <div
    className={isDeliveryMethodAPI ? styles.colorDotAPI : styles.colorDot}
    style={{ backgroundColor: getStatusColor(status) }}
  />
);

interface CancellationStatusProps {
  status: CancellationStatusEnum;
  deliveryMethod?: DeliveryMethod;
}

export const CancellationStatus: React.FC<CancellationStatusProps> = ({
  status,
  deliveryMethod,
}) => {
  const { formatMessage } = useIntl();
  const isDeliveryMethodAPI = deliveryMethod === DeliveryMethod.API;
  const statusText = getStatusText(status, formatMessage);

  return (
    <div className={isDeliveryMethodAPI ? styles.containerAPI : styles.container}>
      <StatusDot status={status} isDeliveryMethodAPI={isDeliveryMethodAPI} />
      {isDeliveryMethodAPI ? (
        status === CancellationStatusEnum.CREATED ? (
          <Typography
            component="p"
            variant="ParagraphBodySmall"
            className={styles.apiStatusTextContent}
          >
            <Typography component="span" variant="Label-2" className={styles.apiStatusText}>
              {statusText}
            </Typography>{' '}
            {formatMessage({
              id: 'will be updated when the power of attorney is signed or declined.',
            })}
          </Typography>
        ) : (
          <Typography component="p" variant="Label-2">
            {statusText}
          </Typography>
        )
      ) : (
        <Typography component="p" variant="Label-2">
          {statusText}
        </Typography>
      )}
    </div>
  );
};
