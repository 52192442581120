import { Plate, Typography } from '@insurely/ui';

import { useCompanies } from 'hooks/useCompanies';
import { useIntl } from 'translations';

import { snakeCaseToCamelCase, snakeCaseToKebabCase } from 'utils/formatters';

import { ProductLine } from 'utils/product-line';

import { FormValues } from '../../../type';

import styles from '../clientForm.module.css';

interface Props {
  values: FormValues;
}

const LabelledText = ({ text, value }: { text: string; value: string }) => (
  <div className={styles.labelledText}>
    <Typography variant="Label-3" component="p">
      {text}
    </Typography>
    <Typography variant="ParagraphCaption" component="p" style={{ color: 'var(--grey-1)' }}>
      {value || '-'}
    </Typography>
  </div>
);

export const Step3 = ({ values }: Props) => {
  const { formatMessage } = useIntl();
  const { getCompanyDisplayName } = useCompanies();

  return (
    <Plate className={styles.confirmationPlate}>
      <Typography variant="Label-1" component="h1">
        {values.clientName}
      </Typography>
      <div className={styles.item}>
        <LabelledText
          text={formatMessage({ id: 'page.config.client-form.field.productLine' })}
          value={formatMessage({
            id: `page.config.client-form.field.productLine.${values.productLine.toLowerCase()}`,
          })}
        />
        <LabelledText
          text={formatMessage({ id: 'page.config.client-form.field.minimumApiVersion' })}
          value={values.minimumApiVersion}
        />
      </div>
      <div className={styles.item}>
        <LabelledText
          text={formatMessage({ id: 'page.config.client-form.field.market' })}
          value={formatMessage({ id: `shared.market.${values.market.toLowerCase()}` })}
        />
        <LabelledText
          text={formatMessage({ id: 'page.config.client-form.field.language' })}
          value={formatMessage({ id: `shared.language.${values.language.toLowerCase()}` })}
        />
      </div>
      {values.productLine === ProductLine.INSURANCE ? (
        <>
          <div className={styles.item}>
            <LabelledText
              text={formatMessage({ id: 'page.config.client-form.field.insuranceTypes' })}
              value={values.insuranceTypes
                .map((t) => formatMessage({ id: snakeCaseToCamelCase(t) }))
                .join(', ')}
            />
          </div>
          <div className={styles.item}>
            <LabelledText
              text={formatMessage({ id: 'page.config.client-form.field.insuranceSubTypes' })}
              value={values.insuranceSubTypes
                .map((t) => formatMessage({ id: snakeCaseToCamelCase(t) }))
                .join(', ')}
            />
          </div>
        </>
      ) : (
        <div className={styles.item}>
          <LabelledText
            text={formatMessage({ id: 'page.config.client-form.field.wealthTypes' })}
            value={values.wealthTypes
              .map((p) => formatMessage({ id: snakeCaseToCamelCase(p) }))
              .join(', ')}
          />
        </div>
      )}
      <div className={styles.item}>
        <LabelledText
          text={formatMessage({ id: 'page.config.client-form.field.clientCompanies' })}
          value={values.clientCompanies
            .map((c) => getCompanyDisplayName(snakeCaseToKebabCase(c)))
            .join(', ')}
        />
      </div>
      <div className={styles.item}>
        <LabelledText
          text={formatMessage({ id: 'page.config.client-form.field.baseUrl' })}
          value={values.baseUrl}
        />
      </div>
      <div className={styles.item}>
        <LabelledText
          text={formatMessage({ id: 'page.config.client-form.field.getPolicyDocuments' })}
          value={formatMessage({
            id: `shared.boolean.${values.getPolicyDocuments.toString()}`,
          })}
        />
      </div>
      <div className={styles.item}>
        <LabelledText
          text={formatMessage({ id: 'page.config.client-form.field.logRetention' })}
          value={values.logRetention.toString()}
        />
        <LabelledText
          text={formatMessage({ id: 'page.config.client-form.field.allowAnonymousRetention' })}
          value={formatMessage({
            id: `shared.boolean.${values.allowAnonymousRetention.toString()}`,
          })}
        />
      </div>
      <div className={styles.item}>
        <LabelledText
          text={formatMessage({ id: 'page.config.client-form.field.hasDpa' })}
          value={formatMessage({ id: `shared.boolean.${values.hasDpa.toString()}` })}
        />
        <LabelledText
          text={formatMessage({ id: 'page.config.client-form.field.forceMtls' })}
          value={formatMessage({ id: `shared.boolean.${values.forceMtls.toString()}` })}
        />
      </div>
    </Plate>
  );
};
