import { Button, Icon, Loader, Modal, Notification, Plate, Typography } from '@insurely/ui';
import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useAsyncFn, useMount } from 'react-use';

import { ModifiedList } from 'components/ModifiedList/ModifiedList';
import { Page } from 'components/Page/Page';

import { ADMIN_PANEL_ROUTE } from 'constants/routes';
import { FormattedMessage, useIntl } from 'translations';

import { logError } from 'utils/datadog';

import { deleteLiveboard, getLiveboardById } from '../api';
import { Breadcrumbs } from '../comp/Breadcrumb/Breadcrumb';
import { ConfirmationModal } from '../comp/ConfirmationModal/ConfirmationModal';

import { AdminPanelTab } from '../config.types';
import { CreateLiveboardForm } from '../Liveboards/Form';

import styles from './liveboardConfig.module.css';

export const LiveboardConfig = () => {
  const { formatMessage } = useIntl();
  const { thoughtspotLiveboardId } = useParams() as { thoughtspotLiveboardId: string };
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [
    { value: liveboard, loading: isFetchingLiveboard, error: errorFetchingLiveboard },
    doFetchLiveboard,
  ] = useAsyncFn(() =>
    getLiveboardById({ thoughtspotLiveboardId }).catch((err) => {
      const nErr = new Error('Failed to fetch liveboard', { cause: err });
      logError(nErr, { thoughtspotLiveboardId });
      throw nErr;
    }),
  );

  const [{ loading: isDeletingLiveboard }, doDeleteLiveboard] = useAsyncFn(
    () =>
      deleteLiveboard({ thoughtspotLiveboardId }).catch((err) => {
        const nErr = new Error('Failed to delete liveboard', { cause: err });
        logError(nErr, { thoughtspotLiveboardId });
        throw nErr;
      }),
    [thoughtspotLiveboardId],
  );

  useMount(() => doFetchLiveboard());

  if (errorFetchingLiveboard) {
    return (
      <Page title="Liveboard">
        <Notification
          headline={formatMessage({ id: 'page.config.liveboard-config.error' })}
          status="error"
        />
      </Page>
    );
  }

  if (isFetchingLiveboard || !liveboard) {
    return (
      <Page title="Liveboard">
        <div>
          <Loader.Content />
        </div>
      </Page>
    );
  }

  return (
    <Page title="Liveboard">
      <Breadcrumbs
        crumbs={[
          {
            label: formatMessage({ id: 'page.config.liveboard-config.breadcrumb.liveboards' }),
            link: `/${ADMIN_PANEL_ROUTE}?tab=${AdminPanelTab.LIVEBOARDS}`,
          },
          { label: liveboard.liveboardName },
        ]}
      />
      <div className={styles.headline}>
        <Typography component="h1" variant="Headline-3">
          {liveboard.liveboardName}
        </Typography>
        <Typography component="p" variant="ParagraphBodySmall">
          {liveboard.description}
        </Typography>
      </div>
      <Plate className={styles.plate}>
        <ModifiedList
          rows={[
            {
              label: formatMessage({ id: 'page.config.liveboard-config.permissions' }),
              value: liveboard.requiredUserPermission,
            },
            {
              label: formatMessage({ id: 'page.config.liveboard-config.liveboard-id' }),
              value: liveboard.thoughtspotLiveboardId,
            },
          ]}
          title={liveboard.liveboardName}
          icon={<Icon name="bar-chart" size={28} />}
        />
        <div className={styles.buttonsContainer}>
          <Button variant="secondary" size="medium" onClick={() => setShowModal(true)}>
            <FormattedMessage id="page.config.liveboard-config.delete-button" />
          </Button>
          <Button size="medium" onClick={() => setIsEditing(true)}>
            <FormattedMessage id="page.config.liveboard-config.edit-button" />
          </Button>
        </div>
      </Plate>
      <Modal
        isOpen={isEditing}
        onClose={() => setIsEditing(false)}
        title={formatMessage({ id: 'page.config.liveboards.form.edit-liveboard' })}
        width={560}
      >
        <CreateLiveboardForm
          mode="edit"
          onSuccess={() => {
            doFetchLiveboard();
            setIsEditing(false);
          }}
          defaultValues={liveboard}
        />
      </Modal>
      {showModal && (
        <ConfirmationModal
          isPending={isDeletingLiveboard}
          onCancel={() => setShowModal(false)}
          onConfirm={() => {
            doDeleteLiveboard().then(() => {
              navigate(`/${ADMIN_PANEL_ROUTE}`);
            });
          }}
        />
      )}
    </Page>
  );
};
