import { useContext } from 'react';
import { useAsync } from 'react-use';

import AppContext from 'contexts/app/AppContext';
import { Language } from 'translations/types';
import { logError } from 'utils/datadog';

export const useTranslations = () => {
  const { language, setLanguage } = useContext(AppContext);

  const {
    value: messages,
    loading,
    error,
  } = useAsync(async (): Promise<Record<string, string>> => {
    const isValidLanguage = Object.values(Language).includes(language);

    if (!isValidLanguage) {
      const err = new Error(`Trying to fetch a language not supported: ${language}`);
      logError(err, { language });

      setLanguage(Language.EN);
      throw err;
    }

    const resp = await fetch(`https://translations.insurely.com/sst/latest/${language}.json`);

    if (resp.ok) {
      const translations = await resp.json();
      return translations;
    }

    const err = new Error(`Could not fetch translation for language ${language}`);
    logError(err, { language });

    // If we cannot fetch a language for whatever reason, we should try to fallback to English.
    // This will re-trigger a call
    if (language !== Language.EN) {
      setLanguage(Language.EN);
    }

    throw err;
  }, [language]);

  return { messages, loading, error };
};
