export const USER_ROUTE = '/';

export const LOGIN_ROUTE = 'login';
export const LOGOUT_ROUTE = 'logout';
export const LOGIN_MAGIC_LINK_ROUTE = 'magic-link';
export const ENABLE_ACCOUNT_ROUTE = 'enable-account';
export const REGISTER_ROUTE = 'register';
export const FORGOT_PASSWORD_ROUTE = 'reset-password';
export const SSO_LOGIN_ROUTE = 'sso';

export const OVERVIEW_ROUTE = 'overview';
export const SESSIONS_ROUTE = 'sessions';
export const COLLECTIONS_ROUTE = 'collections';
export const ANALYTICS_ROUTE = 'analytics';
export const COMPARISON_ROUTE = 'comparison';
export const CANCELLATIONS_ROUTE = 'cancellations';

export const FAQ_ROUTE = 'faq';
export const GET_STARTED_ROUTE = 'get-started';
export const CONTACT_ROUTE = 'contact';
export const SYSTEM_STATUS_ROUTE = 'system-status';
export const ADMIN_PANEL_ROUTE = 'administration';
export const CUSTOMERS_ROUTE = 'customers';
export const LIVEBOARDS_ROUTE = 'liveboards';
export const CLIENT_ROUTE = 'client';
export const USER_GROUP_ROUTE = 'user-group';
