import { useParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';

import { Market } from 'types/v3';
import { FinancialProductType } from 'types/wealth';
import { logError } from 'utils/datadog';

import { ProductLine } from 'utils/product-line';

import {
  assignCompaniesToClient,
  assignInsuranceSubTypesToClient,
  assignInsuranceTypesToClient,
  assignWealthTypesToClient,
  assignProductTypesToClient,
  createClient,
  createClientAllowedOrigins,
  createClientSstConfig,
  deleteClientAllowedOrigins,
  deleteClientSstConfig,
  getAllCompanies,
  getAllInsuranceSubTypes,
  getAllInsuranceTypes,
  getAllLanguages,
  getAllMarkets,
  getAllWealthTypes,
  getAllProductLines,
  removeCompanyFromClient,
  removeInsuranceSubTypeFromClient,
  removeInsuranceTypeFromClient,
  removeWealthTypeFromClient,
  removeProductTypeFromClient,
  updateClient,
} from '../../api';

import { FormValues, FormValuesStringArray } from './type';
import { computeProductType, generateBaseUrlLink } from './utils';

interface Props {
  onSuccess: () => void;
  market?: Market;
  productLine?: ProductLine;
}

export const useFormRequests = ({ onSuccess, market, productLine }: Props) => {
  const { customerId, clientId } = useParams() as { customerId: string; clientId: string };

  const [{ value: firstStepOptions, loading: isLoadingFirstStepOptions }, doFetchFirstStepOptions] =
    useAsyncFn(
      () =>
        Promise.all([getAllMarkets(), getAllLanguages(), getAllProductLines()]).catch((err) => {
          const nErr = new Error('Failed to fetch step 1 dropdown options', { cause: err });
          logError(nErr);
          throw nErr;
        }),
      [market, productLine],
    );

  const [
    { value: secondStepOptions, loading: isLoadingSecondStepOptions },
    doFetchSecondStepOptions,
  ] = useAsyncFn(
    () =>
      Promise.all([
        getAllInsuranceTypes(),
        getAllInsuranceSubTypes(),
        getAllWealthTypes(),
        getAllCompanies({ market, productLine }),
      ]).catch((err) => {
        const nErr = new Error('Failed to fetch step 2 dropdown options', { cause: err });
        logError(nErr);
        throw nErr;
      }),
    [market, productLine],
  );

  const [{ loading: isCreatingClient, error: errorCreatingClient }, doCreateClient] = useAsyncFn(
    async (config: FormValues) =>
      createClient({
        customerId,
        config: {
          allowAnonymousRetention: config.allowAnonymousRetention,
          clientName: config.clientName,
          filterOtherInsuredPerson: config.filterOtherInsuredPerson,
          forceMtls: config.forceMtls,
          getPolicyDocuments: config.getPolicyDocuments,
          hasDpa: config.hasDpa,
          isDisabled: false,
          language: config.language,
          logRetention: config.logRetention,
          market: config.market,
          minimumApiVersion: config.minimumApiVersion,
          configurationName: config.configurationName,
        },
      })
        .then(async (cId) => {
          const {
            insuranceTypes,
            insuranceSubTypes,
            clientCompanies,
            productLine: _productLine,
            wealthTypes,
          } = config;

          const productType = computeProductType({
            productLine: _productLine,
            productInterface: config.productInterface,
          });

          await Promise.all(
            [
              assignInsuranceTypesToClient({ clientId: cId, insuranceTypes }),
              assignInsuranceSubTypesToClient({ clientId: cId, insuranceSubTypes }),
              assignCompaniesToClient({ clientId: cId, clientCompanies }),
              assignProductTypesToClient({ clientId: cId, productTypes: [productType] }),
              assignWealthTypesToClient({ clientId: cId, wealthTypes }),
              config.productInterface === 'hub'
                ? createClientSstConfig({
                    clientId: cId,
                    conf: {
                      baseUrl: generateBaseUrlLink({
                        baseUrl: config.baseUrl,
                        customerId,
                        configurationName: config.configurationName ?? '',
                      }),
                      language: config.language,
                      logotype: config.logotype,
                    },
                  })
                : undefined,
              createClientAllowedOrigins({ clientId: cId, allowedOrigins: config.allowedOrigins }),
            ].filter(Boolean),
          );
          onSuccess();
        })
        .catch((err) => {
          const nErr = new Error('Failed to create client config', { cause: err });
          logError(nErr);
          throw nErr;
        }),
  );

  const [
    { loading: isUpdatingExtendedClient, error: errorUpdatingExtendedClient },
    doUpdateExtendedClient,
  ] = useAsyncFn(async ({ config, defC }: { config: FormValues; defC: FormValues }) => {
    await updateClient({
      clientId,
      config: {
        allowAnonymousRetention: config.allowAnonymousRetention,
        clientName: config.clientName,
        filterOtherInsuredPerson: config.filterOtherInsuredPerson,
        forceMtls: config.forceMtls,
        getPolicyDocuments: config.getPolicyDocuments,
        hasDpa: config.hasDpa,
        isDisabled: config.isDisabled,
        language: config.language,
        logRetention: config.logRetention,
        market: config.market,
        minimumApiVersion: config.minimumApiVersion,
        configurationName: config.configurationName,
      },
    }).catch((err) => {
      const nErr = new Error('Failed to update client config', { cause: err });
      logError(nErr);
      throw nErr;
    });

    const findDifferences = (property: FormValuesStringArray) => ({
      toDelete: defC[property].filter((item) => !(config[property] as string[]).includes(item)),
      toAdd: config[property].filter((item) => !(defC[property] as string[]).includes(item)),
    });

    const insuranceTypeDiff = findDifferences('insuranceTypes');
    const insuranceSubTypeDiff = findDifferences('insuranceSubTypes');
    const wealthTypeDiff = findDifferences('wealthTypes');
    const clientCompaniesDiff = findDifferences('clientCompanies');

    const productTypeDiff = {
      toAdd:
        config.productLine !== defC.productLine || config.productInterface !== defC.productInterface
          ? [
              computeProductType({
                productLine: config.productLine,
                productInterface: config.productInterface,
              }),
            ]
          : [],
      toDelete:
        config.productLine !== defC.productLine || config.productInterface !== defC.productInterface
          ? [
              computeProductType({
                productLine: defC.productLine,
                productInterface: defC.productInterface,
              }),
            ]
          : [],
    };

    const insuranceTypePromises = [
      insuranceTypeDiff.toAdd.length > 0 &&
        assignInsuranceTypesToClient({ clientId, insuranceTypes: insuranceTypeDiff.toAdd }),
      insuranceTypeDiff.toDelete.length > 0 &&
        insuranceTypeDiff.toDelete.map((d) =>
          removeInsuranceTypeFromClient({ clientId, insuranceType: d }).catch((err) => {
            if (err.response.status === 404) {
              return;
            }
            throw err;
          }),
        ),
    ];

    const insuranceSubTypePromises = [
      insuranceSubTypeDiff.toAdd.length > 0 &&
        assignInsuranceSubTypesToClient({
          clientId,
          insuranceSubTypes: insuranceSubTypeDiff.toAdd,
        }),
      insuranceSubTypeDiff.toDelete.length > 0 &&
        insuranceSubTypeDiff.toDelete.map((d) =>
          removeInsuranceSubTypeFromClient({ clientId, insuranceSubType: d }),
        ),
    ];

    const productTypePromises = [
      productTypeDiff.toAdd.length > 0 &&
        assignProductTypesToClient({
          clientId,
          productTypes: productTypeDiff.toAdd,
        }),
      productTypeDiff.toDelete.length > 0 &&
        productTypeDiff.toDelete.map((d) =>
          removeProductTypeFromClient({ clientId, productType: d }),
        ),
    ];

    const wealthTypePromises = [
      wealthTypeDiff.toAdd.length > 0 &&
        assignWealthTypesToClient({
          clientId,
          wealthTypes: wealthTypeDiff.toAdd as FinancialProductType[],
        }),
      wealthTypeDiff.toDelete.length > 0 &&
        wealthTypeDiff.toDelete.map((d: FinancialProductType) =>
          removeWealthTypeFromClient({ clientId, wealthType: d }),
        ),
    ];

    const clientCompaniesPromises = [
      clientCompaniesDiff.toAdd.length > 0 &&
        assignCompaniesToClient({
          clientId,
          clientCompanies: clientCompaniesDiff.toAdd,
        }),
      clientCompaniesDiff.toDelete.length > 0 &&
        clientCompaniesDiff.toDelete.map((d) =>
          removeCompanyFromClient({ clientId, clientCompany: d }),
        ),
    ];

    const sstConfigPromise =
      config.productInterface === 'hub'
        ? deleteClientSstConfig(clientId).then(() =>
            createClientSstConfig({
              clientId,
              conf: {
                baseUrl: config.baseUrl,
                language: config.language,
                logotype: config.logotype,
              },
            }),
          )
        : null;

    await Promise.all(
      [
        sstConfigPromise,
        deleteClientAllowedOrigins(clientId).then(() =>
          createClientAllowedOrigins({ clientId, allowedOrigins: config.allowedOrigins }),
        ),
        ...insuranceTypePromises,
        ...insuranceSubTypePromises,
        ...productTypePromises,
        ...wealthTypePromises,
        ...clientCompaniesPromises,
      ]
        .flat()
        .filter(Boolean),
    ).catch((err) => {
      const nErr = new Error('Failed to update client details', { cause: err });
      logError(nErr);
      throw nErr;
    });
    onSuccess();
  });

  return {
    createClient: { isCreatingClient, errorCreatingClient, doCreateClient },
    updateClient: { isUpdatingExtendedClient, errorUpdatingExtendedClient, doUpdateExtendedClient },
    formFirstStep: { isLoadingFirstStepOptions, firstStepOptions, doFetchFirstStepOptions },
    formSecondStep: { isLoadingSecondStepOptions, secondStepOptions, doFetchSecondStepOptions },
  };
};
