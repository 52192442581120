import { Avatar, CardWithIcon, Icon } from '@insurely/ui';
import IntlMessageFormat from 'intl-messageformat';
import { useContext } from 'react';

import { Link } from 'react-router-dom';

import { SESSIONS_ROUTE } from 'constants/routes';
import UserContext from 'contexts/user/UserContext';
import { useIntl } from 'translations';
import { Session } from 'types/v3';
import { upperCaseFirstLetter } from 'utils/formatters';
import { ProductLine } from 'utils/product-line';

interface SessionCardProps {
  session: Session;
}

export const SessionCard: React.FC<SessionCardProps> = ({ session }) => {
  const { formatMessage } = useIntl();
  const { productLine } = useContext(UserContext);

  const numberOfData =
    productLine === ProductLine.PENSION
      ? session.numberOfPensions
      : session.numberOfFilteredInsurances;

  const getIcon = (number: number) =>
    number > 0 ? <Avatar color="green" text={number.toString()} /> : number.toString();

  const getNumberOfDataCopy = (data: number) =>
    upperCaseFirstLetter(
      new IntlMessageFormat(
        `{data, plural,
      =0 {${
        productLine === ProductLine.PENSION
          ? formatMessage({ id: 'No pensions' })
          : formatMessage({ id: 'shared.no-insurances' })
      }}
      =1 {# ${
        productLine === ProductLine.PENSION
          ? formatMessage({ id: 'Pension' })
          : formatMessage({ id: 'Insurance' })
      }}
      other {# ${
        productLine === ProductLine.PENSION
          ? formatMessage({ id: 'Pensions' })
          : formatMessage({ id: 'Insurances' })
      }}
    }`,
      )
        .format({ data })
        .toString(),
    );

  return (
    <Link
      to={`/${SESSIONS_ROUTE}/${session.sessionId}`}
      style={{ display: 'block', width: '100%' }}
    >
      <CardWithIcon
        style={{ border: '1px solid var(--grey-3)' }}
        icon={getIcon(numberOfData)}
        // eslint-disable-next-line
        // @ts-ignore
        title={<span className="ph-no-capture">{session.freeText}</span>}
        subtitle={getNumberOfDataCopy(numberOfData)}
      >
        <Icon name="chevron-right" size={28} />
      </CardWithIcon>
    </Link>
  );
};
