import deepEqual from 'deep-equal';
import posthog from 'posthog-js';
import { useCallback, useState, useEffect, ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useAsync } from 'react-use';

import {
  fetchAvailableLiveboards,
  getCompanyStatus,
  getConfig,
  getSessions,
  getUser,
  getUserAttributes,
} from 'client/client';
import { LOGIN_ROUTE } from 'constants/routes';
import UserContext from 'contexts/user/UserContext';
import { useSelectedWorkspaceId } from 'hooks/useSelectedWorkspaceId';
import { getStoredAuthToken } from 'services/authToken';
import utils from 'services/utils';
import { Company, Config, Session, User as UserInterface, UserAttributes, Market } from 'types/v3';
import {
  setContext as setDatadogContext,
  setUser as setDatadogUser,
  setContextProperty as setDatadogContextProperty,
  logError,
} from 'utils/datadog';
import { formatMarket } from 'utils/formatters';
import { getUserId } from 'utils/posthog';
import { ProductLine, computeProductTypeFromConfig } from 'utils/product-line';

const CONFIG_INITIAL_STATE: Config = {
  cancellationEnabled: false,
  cancellationServiceEnabled: false,
  currency: 'SEK',
  market: Market.SE,
  retention: 90,
  smsServiceEnabled: false,
  advisoryParameters: [],
  viewCancellationAuthenticationMethod: 'STANDARD',
  newInsuranceNumberMandatory: false,
};

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [user, setUser] = useState<UserInterface | undefined>(undefined);
  const [config, setConfig] = useState<Config>(CONFIG_INITIAL_STATE);
  const [sessions, setSessions] = useState<Session[]>([]);
  const location = useLocation();
  const [loadingUser, setLoading] = useState(true);
  const [userAttributes, setUserAttributes] = useState<UserAttributes>({
    qrRequirementConfirmation: true,
  });
  const [productLine, setProductLine] = useState<ProductLine | undefined>(undefined);
  const { setSelectedWorkspaceId } = useSelectedWorkspaceId();

  const loadSessions = useCallback(
    async (contentType: string): Promise<Session[] | undefined> => {
      if (!contentType) return;

      try {
        const sessionResponse = await getSessions(contentType);
        const newData = !deepEqual(sessionResponse, sessions);
        if (newData) {
          setSessions(sessionResponse);
        }
        return sessionResponse;
      } catch (error) {
        const nErr = new Error('Error fetching sessions', { cause: error });
        logError(nErr, { contentType });
        return error;
      }
    },
    [sessions],
  );

  const loadConfig = useCallback(async () => {
    try {
      const configResponse = await getConfig();
      if (configResponse) {
        setConfig({ ...configResponse, market: formatMarket(configResponse.market) });
        const mode = computeProductTypeFromConfig(configResponse);
        setDatadogContext({
          productType: mode,
          market: configResponse.market,
          clientId:
            configResponse.insuranceConfig?.clientId ?? configResponse.pensionConfig?.clientId,
        });
        setProductLine(mode);
      }
      return configResponse;
    } catch (error) {
      const nErr = new Error('Error fetching config', { cause: error });
      logError(nErr);
      return error;
    }
  }, []);

  const loadUser = useCallback(async () => {
    try {
      const userResponse = await getUser();
      setUser(userResponse);
      setSelectedWorkspaceId(userResponse.selectedClientId);

      if (userResponse?.selectedClientId && posthog.__loaded) {
        posthog.identify(getUserId(userResponse.selectedClientId), {
          clientId: userResponse.selectedClientId,
          customerId: userResponse.customerId,
          userSessionId:
            typeof Storage !== 'undefined' ? window.localStorage.getItem('userSessionId') : '',
        });
        posthog.set_config({ disable_session_recording: false });
        setDatadogContextProperty('customerId', userResponse.customerId);
        setDatadogUser(userResponse.uuid);
      }
      const conf = await loadConfig();
      await loadSessions(conf?.pensionConfig ? 'pension' : 'insurance');
      setLoading(false);
    } catch (error) {
      if (error?.response?.status !== 401) {
        const nErr = new Error('Error fetching user', { cause: error });
        logError(nErr);
        throw nErr;
      } else if (error?.response?.status === 401 && getStoredAuthToken()) {
        // If we get a 401 from the endpoint using a authToken, this means that the token
        // does not grant us access to SST. Reasons could be:
        // - using a prod token is test (or vice versa)
        // - being logged in with an account that does not grant us access to SST
        window.location.assign(`/${LOGIN_ROUTE}`);
      }
    }
  }, [loadConfig, loadSessions, setSelectedWorkspaceId]);

  useEffect(() => {
    const getCompaniesStatus = async () => {
      const confItem = utils.getConfigItem(config);
      if (!user || !confItem) {
        return;
      }
      try {
        const companiesResponse = await getCompanyStatus(confItem.clientId);
        if (companiesResponse.length) {
          setCompanies(companiesResponse);
        }
      } catch (error) {
        const nErr = new Error('Error fetching company status', { cause: error });
        logError(nErr, { clientId: confItem.clientId });
        throw nErr;
      }
    };

    if (!companies.length) {
      getCompaniesStatus();
    }
  }, [location, companies, user, config]);

  useEffect(() => {
    const userAttributesRequest = async () => {
      if (user?.selectedClientId) {
        await getUserAttributes(user.selectedClientId)
          .then((resp) => {
            setUserAttributes((attributes) => ({
              ...attributes,
              qrRequirementConfirmation: resp.qrRequirementConfirmation,
            }));
          })
          .catch((err) => {
            const nErr = new Error('Failed to get user attributes', { cause: err });
            logError(nErr, {
              userId: user.uuid,
              clientId: user.selectedClientId,
              customerId: user.customerId,
            });
            throw nErr;
          });
      }
    };

    userAttributesRequest();
  }, [user?.customerId, user?.selectedClientId, user?.uuid]);

  useEffect(() => {
    if (!user) {
      loadUser();
    }
  }, [loadUser, user]);

  const { value: liveboards } = useAsync(() =>
    fetchAvailableLiveboards().catch((err) => {
      const nErr = new Error('Failed to fetch available liveboards', { cause: err });
      if (err.response.status !== 401) {
        logError(nErr);
      }
      throw nErr;
    }),
  );

  const userContextValue = useMemo(
    () => ({
      user,
      userAttributes,
      setUserAttributes,
      sessions,
      config,
      companies,
      loadSessions,
      reloadUser: loadUser,
      loadingUser,
      liveboards: liveboards || [],
      productLine,
    }),
    [
      companies,
      config,
      loadSessions,
      loadUser,
      loadingUser,
      userAttributes,
      sessions,
      user,
      liveboards,
      productLine,
    ],
  );

  return <UserContext.Provider value={userContextValue}>{children}</UserContext.Provider>;
};
