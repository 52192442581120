import { Loader, Typography } from '@insurely/ui';
import { useState } from 'react';

import { useAsync, useAsyncFn, useMount, useWindowSize } from 'react-use';

import { getAllAdvisors, searchCollections } from 'client/client';

import CollectionError from 'components/Notifications/CollectionError/CollectionError';
import EmptyState from 'components/Notifications/EmptyState';
import { Page } from 'components/Page/Page';
import { useIntl } from 'translations';

import { CollectionsTableData } from 'types/v3';

import { logError } from 'utils/datadog';

import styles from './collections.module.css';

import { CollectionsTable } from './comp/CollectionsTable/CollectionsTable';
import NewFilter from './Filter/Filter';
import { useStoreFilters } from './Filter/useStoreFilters';
import { FilterQuery } from './types';
import { createTableData, getRequestBody } from './utils';

export const Collections = () => {
  const { formatMessage } = useIntl();
  const { getFilters } = useStoreFilters();

  const [tableData, setTableData] = useState<CollectionsTableData>({
    rows: [],
    searchHasMoreData: false,
  });
  const { width } = useWindowSize();

  const maxWidth = width > 991 ? `${width - 304 - 16 * 4 * 2}px` : '100%';

  const { value: advisors, loading: isLoadingAdvisors } = useAsync(() =>
    getAllAdvisors().catch((err) => {
      const nErr = new Error('Error fetching advisors', { cause: err });
      logError(nErr);
      throw nErr;
    }),
  );

  const loadCollectionTable = async ({
    loadMore,
    filterQuery,
  }: {
    loadMore: boolean;
    filterQuery?: FilterQuery;
  }) => {
    const requestBody = getRequestBody(filterQuery);
    return searchCollections(requestBody).then((resp) => {
      const groupedData = createTableData(resp.collections);
      setTableData({
        rows: loadMore ? tableData.rows.concat(groupedData) : groupedData,
        searchHasMoreData: resp.searchHasMoreData,
      });
    });
  };

  const [
    { loading: isLoadingCollectionTable, error: errorLoadingCollectionTable },
    doFetchCollectionTable,
  ] = useAsyncFn(
    (filterQuery?: FilterQuery) => loadCollectionTable({ loadMore: false, filterQuery }),
    [tableData],
  );

  const [{ loading: isLoadingMoreItems, error: errorLoadingMoreItems }, doLoadMoreCollectionTable] =
    useAsyncFn(
      (filterQuery: FilterQuery) => loadCollectionTable({ loadMore: true, filterQuery }),
      [tableData],
    );

  const hasError = errorLoadingCollectionTable || errorLoadingMoreItems;

  useMount(() => {
    doFetchCollectionTable(getFilters());
  });

  function renderContent() {
    if (isLoadingAdvisors || isLoadingCollectionTable) {
      return (
        <div style={{ position: 'relative', minHeight: '200px' }}>
          <Loader.Content />
        </div>
      );
    }

    if (hasError) {
      return <CollectionError retry={doFetchCollectionTable} />;
    }

    if (tableData.rows.length === 0) {
      if (window.location.search !== '') {
        return (
          <EmptyState
            headline={formatMessage({ id: 'We did not find any collections' })}
            description={formatMessage({
              id: "Try changing your search filters to find what you're looking for",
            })}
          />
        );
      }
      return (
        <EmptyState
          headline={formatMessage({ id: 'No collections have been made yet' })}
          description={formatMessage({
            id: 'When policies have been downloaded through your digital feed, they will be displayed here.',
          })}
        />
      );
    }

    return (
      <CollectionsTable
        tableData={tableData}
        advisors={advisors ?? []}
        setTableData={setTableData}
        isLoadingMore={isLoadingMoreItems}
        onLoadMore={doLoadMoreCollectionTable}
      />
    );
  }

  return (
    <Page title={formatMessage({ id: 'Collections' })} maxWidth={maxWidth}>
      <Typography className={styles.title} component="h1" variant="Headline-3">
        {formatMessage({ id: 'Collections' })}
      </Typography>

      <NewFilter
        isLoading={isLoadingCollectionTable}
        getCollectionsTable={doFetchCollectionTable}
        advisors={advisors ?? []}
      />

      <div className={styles.marginTop8}>{renderContent()}</div>
    </Page>
  );
};
