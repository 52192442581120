import { Button } from '@insurely/ui';

import { useParams } from 'react-router-dom';

import { FormattedMessage } from 'translations';

import styles from '../actionBanner.module.css';
import { Target } from '../type';

interface Props {
  target: Target;
  onClick: (userGroupId: string) => void;
  isPending: boolean;
  disabled: boolean;
}

// Default removal action, extract into its own component if you need to make changes here
export const BatchRemoval = ({ target, disabled, onClick, isPending }: Props) => {
  const { userGroupId } = useParams() as {
    userGroupId: string;
  };

  return (
    <div className={styles.batchRemoval}>
      <FormattedMessage
        id={`page.config.action-banner.batch-${target.toLowerCase()}.remove-title`}
      />
      <Button
        onClick={() => onClick(userGroupId)}
        loading={isPending}
        size="medium"
        disabled={disabled}
        variant="secondary"
      >
        <FormattedMessage id="page.config.action-banner.batch.remove" />
      </Button>
    </div>
  );
};
