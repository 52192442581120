export const getStoredAuthToken = () =>
  (document.cookie.split(';').filter((cookie) => cookie.match(/authToken=/))[0] || '=').split(
    '=',
  )[1];

export const storeAuthToken = (token: string) => {
  const { hostname } = window.location;

  const domain = hostname !== 'localhost' ? hostname.slice(hostname.indexOf('.')) : hostname;

  document.cookie = `authToken=${token}; domain=${domain}; path=/;${
    ENV && ENV !== 'preview' ? ' secure' : ''
  }`;
};

export const removeStoredAuthToken = () => {
  const { hostname } = window.location;

  document.cookie = `authToken=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  document.cookie = `authToken=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${hostname}`;
  if (hostname !== 'localhost') {
    const domains = window.location.hostname.split('.');

    while (domains.length > 1) {
      document.cookie = `authToken=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=${`.${domains.join(
        '.',
      )}`}`;
      domains.shift();
    }
  }
};
