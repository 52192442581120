import { Typography } from '@insurely/ui';
import classNames from 'classnames';

import styles from './picker.module.css';

interface Props {
  label: string | React.ReactNode;
  left: { label: string; value: string };
  right: { label: string; value: string };
  selected: string;
  onChange: (val: string) => void;
  disabled?: boolean;
}

export const Picker = ({ label, left, right, selected, onChange, disabled }: Props) => (
  <div>
    {typeof label === 'string' ? (
      <Typography variant="Label-2" component="p">
        {label}
      </Typography>
    ) : (
      label
    )}
    <div className={styles.picker}>
      <button
        type="button"
        onClick={() => onChange(left.value)}
        disabled={disabled}
        className={classNames(styles.pickerItem, { [styles.active]: selected === left.value })}
      >
        <Typography variant="Label-3" component="p">
          {left.label}
        </Typography>
      </button>
      <button
        type="button"
        disabled={disabled}
        onClick={() => onChange(right.value)}
        className={classNames(styles.pickerItem, { [styles.active]: selected === right.value })}
      >
        <Typography variant="Label-3" component="p">
          {right.label}
        </Typography>
      </button>
    </div>
  </div>
);
