import { Color, Typography } from '@insurely/ui';

import { useIntl } from 'translations';

import { MovabilityStatus } from 'types/wealth';

import styles from './movableStatus.module.css';

interface MovableStatusProps {
  status: MovabilityStatus;
}

export const getStatusText = (status: MovabilityStatus) => {
  switch (status) {
    case MovabilityStatus.NOT_MOVABLE:
      return 'No';
    case MovabilityStatus.MOVABLE:
      return 'Yes';
    case MovabilityStatus.UNKNOWN:
      return 'Maybe';
    default:
      return 'Maybe';
  }
};

export const getStatusColor = (status: MovabilityStatus) => {
  switch (status) {
    case MovabilityStatus.NOT_MOVABLE:
      return Color['red-1'];
    case MovabilityStatus.MOVABLE:
      return Color.grannySmithDark;
    case MovabilityStatus.UNKNOWN:
    default:
      return Color['yellow-1'];
  }
};

export const StatusDot = ({ status }: MovableStatusProps) => (
  <div className={styles.colorDot} style={{ backgroundColor: getStatusColor(status) }} />
);

export const MovableStatus = ({ status }: MovableStatusProps) => {
  const { formatMessage } = useIntl();
  const statusText = formatMessage({ id: getStatusText(status) });

  return (
    <div className={styles.container}>
      <StatusDot status={status} />
      <Typography component="p" variant="Headline-6">
        {statusText}
      </Typography>
    </div>
  );
};
