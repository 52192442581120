import { Typography } from '@insurely/ui';

import React, { CSSProperties } from 'react';

import { useIntl } from 'translations';

import styles from './reloadInstructions.module.css';

const baseStyle: { [key: string]: CSSProperties } = {
  item: {
    position: 'relative',
    paddingLeft: '30px',
    marginTop: '8px',
  },
  bullet: {
    position: 'absolute',
    left: '5px',
    bottom: '0',
    marginRight: '10px',
  },
};

export default function ReloadInstructions() {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.container}>
      <Typography component="h3" variant="Headline-6">
        {formatMessage({ id: 'Mac:' })}
      </Typography>

      <ul className={styles.list}>
        <li style={baseStyle.item}>
          <div style={baseStyle.bullet}>&#8226;</div>
          <Typography component="p" variant="ParagraphBodySmall">
            {formatMessage({ id: 'Chrome/Firefox: press' })}
            <strong>{formatMessage({ id: 'command' })}</strong>
            {formatMessage({ id: 'and' })}
            <strong>{formatMessage({ id: 'shift' })}</strong>
            {formatMessage({ id: 'and then press' })}
            <strong>{formatMessage({ id: 'R' })}</strong>.
          </Typography>
        </li>

        <li style={baseStyle.item}>
          <div style={baseStyle.bullet}>&#8226;</div>
          <Typography component="p" variant="ParagraphBodySmall">
            {formatMessage({ id: 'Safari: press' })}
            <strong>{formatMessage({ id: 'command' })}</strong>
            {formatMessage({ id: 'and' })}
            <strong>{formatMessage({ id: 'option' })}</strong>
            {formatMessage({ id: 'and then press' })}
            <strong>{formatMessage({ id: 'R' })}</strong>.
          </Typography>
        </li>
      </ul>
      <Typography component="h3" variant="Headline-6">
        {formatMessage({ id: 'Windows:' })}
      </Typography>
      <ul>
        <li style={baseStyle.item}>
          <div style={baseStyle.bullet}>&#8226;</div>
          <Typography component="p" variant="ParagraphBodySmall">
            {formatMessage({ id: 'Press' })}
            <strong>{formatMessage({ id: 'Ctrl' })}</strong>
            {formatMessage({ id: 'and then press' })}
            <strong>{formatMessage({ id: 'F5' })}</strong>.
          </Typography>
        </li>
      </ul>
    </div>
  );
}
