import { Button, Loader, Notification, Checkbox, Typography } from '@insurely/ui';
import { useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useAsync, useAsyncFn } from 'react-use';

import { FormattedMessage, useIntl } from 'translations';

import { Liveboard } from 'types/liveboard';
import { logError } from 'utils/datadog';
import { Target } from 'views/Config/comp/ActionBanner/type';

import { ExtendedClientConfig, User } from 'views/Config/config.types';

import {
  addClientsToGroup,
  addLiveboardsToGroup,
  addUsersToGroup,
  getAllClientsOnCustomer,
  getAllLiveboardsForCustomer,
  getAllUsers,
} from '../../api';

import styles from './userGroupConfig.module.css';

export const ConnectToGroup = ({
  onSuccess,
  existingIds,
  target,
}: {
  onSuccess: () => void;
  existingIds: string[];
  target: Target;
}) => {
  const { customerId, userGroupId } = useParams() as { customerId: string; userGroupId: string };
  const { formatMessage } = useIntl();

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { loading: isFetching, value: options } = useAsync(() => {
    switch (target) {
      case Target.USERS:
        return getAllUsers(customerId).catch((err) => {
          const nErr = new Error('Failed to fetch all users for customer', { cause: err });
          logError(nErr, { customerId });
          throw nErr;
        });
      case Target.LIVEBOARDS:
        return getAllLiveboardsForCustomer({ customerId }).catch((err) => {
          const nErr = new Error('Failed to fetch all liveboards for customer', { cause: err });
          logError(nErr, { customerId });
          throw nErr;
        });
      case Target.CLIENTS:
        return getAllClientsOnCustomer(customerId).catch((err) => {
          const nErr = new Error('Failed to fetch all clients for customer', { cause: err });
          logError(nErr, { customerId });
          throw nErr;
        });
      case Target.PERMISSIONS:
      default:
        return Promise.resolve([]);
    }
  });

  const refinedOptions = useMemo(() => {
    if (!options) {
      return null;
    }
    switch (target) {
      case Target.USERS:
        return (options as User[])
          .filter((e) => !existingIds.includes(e.uuid))
          .map((u) => ({ label: u.email, value: u.uuid }));
      case Target.LIVEBOARDS:
        return (options as Liveboard[])
          .filter((e) => !existingIds.includes(e.thoughtspotLiveboardId))
          .map((e) => ({ label: e.description, value: e.thoughtspotLiveboardId }));
      case Target.CLIENTS:
        return (options as ExtendedClientConfig[])
          .filter((e) => !existingIds.includes(e.clientId))
          .map((e) => ({ label: e.clientName, value: e.clientId }));
      case Target.PERMISSIONS:
      default:
        return [];
    }
  }, [existingIds, options, target]);

  const [{ loading: isPerformingAction, error: errorPerformingAction }, doAddToGroup] =
    useAsyncFn(() => {
      switch (target) {
        case Target.USERS:
          return addUsersToGroup({ userGroupId, userIds: selectedIds });
        case Target.LIVEBOARDS:
          return addLiveboardsToGroup({ userGroupId, liveboardIds: selectedIds });
        case Target.CLIENTS:
          return Promise.all(
            selectedIds.map((id) => addClientsToGroup({ userGroupId, clientId: id })),
          );
        case Target.PERMISSIONS:
        default:
          return Promise.resolve();
      }
    }, [selectedIds]);

  return (
    <div className={styles.connectEntitiesContent}>
      {isFetching && (
        <div style={{ position: 'relative', height: '30px' }}>
          <Loader.Content size="small" />
        </div>
      )}
      <div className={styles.entitiesOptions}>
        {refinedOptions && refinedOptions.length === 0 && (
          <div>
            <Typography
              variant="ParagraphBodySmall"
              component="p"
              style={{ color: 'var(--grey-1)' }}
            >
              <FormattedMessage id="page.config.user-group-config.add-group.no-options" />
            </Typography>
          </div>
        )}
        {refinedOptions &&
          refinedOptions
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((o) => (
              <Checkbox
                key={o.label}
                checked={selectedIds.includes(o.value)}
                label={o.label}
                onCheckedChange={(checked) => {
                  setSelectedIds((cur) =>
                    checked ? [...cur, o.value] : cur.filter((c) => c !== o.value),
                  );
                }}
              />
            ))}
      </div>
      <div className={styles.connectEntitiesButton}>
        <Button
          variant="secondary"
          size="medium"
          fullWidth
          onClick={() => setSelectedIds([])}
          disabled={selectedIds.length === 0}
        >
          <FormattedMessage id="page.config.user-group-config.add-group.deselect-all" />
        </Button>
        <Button
          loading={isPerformingAction}
          fullWidth
          size="medium"
          onClick={() =>
            doAddToGroup().then(() => {
              setSelectedIds([]);
              onSuccess();
            })
          }
          disabled={selectedIds.length === 0}
        >
          <FormattedMessage id="page.config.user-group-config.add-group.connect" />
        </Button>
      </div>
      {errorPerformingAction && (
        <Notification
          headline={formatMessage({ id: 'page.config.user-group-config.add-group.error' })}
          status="error"
        />
      )}
    </div>
  );
};
