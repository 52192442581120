export type Wealth = {
  clientId: string;
  collectionId: string;
  financialProducts: FinancialProduct[];
  creationDate: string;
};

export type FinancialProduct = {
  id: string;
  collectionId: string;
  collectionDate: string;
  company: string;
  companyDisplayName: string;
  productName: string;
  productNumber: string;
  traditionallyManaged: boolean;
  holder: Holder;
  movability: Movability;
  fees: Fees;
  holdings: Holding[];
  payoutDetails: PayoutDetail[];
  type: FinancialProductType;
  insurance: { survivorsProtection: boolean; insuredPersonName: string };
  financialInformation: { currentValue: MonetaryValue; totalDeposits: MonetaryValue };
  startDate?: string;
  activePayout?: boolean;
  activePayment?: boolean;
  classification?: string;
};

export enum MovabilityStatus {
  MOVABLE = 'MOVABLE',
  NOT_MOVABLE = 'NOT_MOVABLE',
  UNKNOWN = 'UNKNOWN',
}

export type MobilityRuleName =
  | 'ACTIVE_PAYMENT'
  | 'ACTIVE_PAYOUT'
  | 'COMPATIBLE_PENSION_TYPE'
  | 'DEFAULT_RULE_NAME'
  | 'OLD_PENSION'
  | 'OLD_TRAD_PENSION'
  | 'TRAD_PENSION';

export type Movability = {
  result: MovabilityStatus;
  evaluatedRules: Array<{ rule: MobilityRuleName; result: MovabilityStatus }>;
};

export type Fees = {
  feesCollected: boolean;
  feeVariable?: number;
  feeFixed?: MonetaryValue;
  feesPreviousYear?: MonetaryValue;
};

export type Holder = {
  name?: string;
  organization?: { name: string; number: string };
  organizationSuggestions?: Array<{
    name: string;
    number: string;
    city: string;
    financialInformation: { turnover: MonetaryValue };
  }>;
};

export type FinancialProductType =
  | 'IPS_PENSION'
  | 'PRIVATE_PENSION'
  | 'OCCUPATIONAL_PENSION'
  | 'COLLECTIVE_OCCUPATIONAL_PENSION'
  | 'KF'
  | 'ISK'
  | 'CUSTODY_ACCOUNT'
  | 'UNKNOWN_INVESTMENT_TYPE'
  | 'UNKNOWN_PENSION_TYPE';

export type HoldingType = 'FUND' | 'EQUITY';

export type Holding = {
  type: HoldingType;
  isin: string;
  name: string;
  quantity: number;
  value: MonetaryValue;
  feeVariable: number;
};

export type PayoutDetail = {
  active: boolean;
  amount: MonetaryValue;
  lifelongPayout: boolean;
  period: { startDate: string; endDate: string; type: string };
};

export type MonetaryValue = {
  currency: string;
  amount: number;
};
