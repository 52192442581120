import { Config } from 'types/v3';

function addThousandSeparator(value: number | string | undefined) {
  return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '';
}

function getConfigItem(config: Config) {
  if (config.pensionConfig) {
    return config.pensionConfig;
  }
  if (config.insuranceConfig) {
    return config.insuranceConfig;
  }
  return null;
}

const Utils = {
  addThousandSeparator,
  getConfigItem,
};

export default Utils;
