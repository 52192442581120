import { useContext, useMemo, useState } from 'react';
import { useAsync, useAsyncFn, useDeepCompareEffect, useMount } from 'react-use';

import { InsuranceBody, InsuranceBodyOption } from 'components/ComparisonTable/types';
import UserContext from 'contexts/user/UserContext';
import Utils from 'services/utils';

import { InsuranceResponse } from 'types/insurance';
import { logError } from 'utils/datadog';
import {
  fetchAvailablePackageScopes,
  fetchEquivalentPackageScopes,
  getParameters,
} from 'views/Comparison/api';
import { usePackageVersions } from 'views/Comparison/usePackageVersions';

interface Props {
  insurance: InsuranceResponse;
}

export const useComparisonDetails = ({ insurance }: Props) => {
  const { config, user } = useContext(UserContext);
  const { clientId } = Utils.getConfigItem(config) || {};

  const [availableRightInsurances, setAvailableRightInsurances] = useState<InsuranceBodyOption[]>(
    [],
  );
  const [selectedRightInsurance, setSelectedRightInsurance] = useState<InsuranceBody | undefined>();
  const [selectedLeftInsurance, setSelectedLeftInsurance] = useState<InsuranceBody | undefined>();

  const { leftVersions, rightVersions } = usePackageVersions({
    insuranceSubType: insurance.insurance.insuranceSubType,
    insuranceType: insurance.insurance.insuranceType,
  });

  // CoverageInfo seems to sometimes be null so we will not perform requests when that
  // is the case
  const isCoveragePackageInfoFilled = useMemo(
    () =>
      !!insurance.coveragePackageInfo.insuranceType &&
      !!insurance.coveragePackageInfo.insuranceSubType &&
      !!insurance.coveragePackageInfo.packageName &&
      !!insurance.coveragePackageInfo.packageVersionId,
    [insurance.coveragePackageInfo],
  );

  const {
    doFetchLeftPackageVersions,
    leftPackageVersions,
    isFetchingLeftPackageVersions,
    errorFetchingLeftPackageVersions,
  } = leftVersions;
  const {
    doFetchRightPackageVersions,
    rightPackageVersions,
    isFetchingRightPackageVersions,
    errorFetchingRightPackageVersions,
  } = rightVersions;

  useAsync(async () => {
    const { insuranceCompany, insuranceType, insuranceSubType, packageName, packageVersionId } =
      insurance.coveragePackageInfo;

    const availableScopes = await fetchAvailablePackageScopes({
      clientId: clientId as string,
      collectionId: insurance.collectionId as string,
      insuranceId: insurance.insurance.externalId,
    });

    const targetCompany = availableScopes.filter(
      (p) => p.insuranceSubType === insurance.insurance.insuranceSubType,
    )[0]?.insuranceCompany;

    if (!targetCompany || !isCoveragePackageInfoFilled) {
      return Promise.resolve();
    }

    return fetchEquivalentPackageScopes({
      body: {
        coverageCompany: insuranceCompany,
        insuranceType,
        insuranceSubType,
        market: config.market,
        packageName,
        targetCompany,
        versionId: packageVersionId,
      },
      clientId: clientId as string,
    })
      .then(async (scopes) => {
        const available = scopes.filter((eS) =>
          availableScopes.some((p) => p.packageName === eS.packageName),
        );

        if (available.length === 0) {
          return Promise.resolve();
        }

        await doFetchRightPackageVersions({
          company: available[0].insuranceCompany,
          packageName: available[0].packageName,
        }).then((res) => {
          const availableMapped = available.map((pack) => ({
            insuranceCompany: pack.insuranceCompany,
            insuranceName: pack.packageName,
            packageName: pack.packageName,
            insuranceSubType: pack.insuranceSubType,
            insuranceType: pack.insuranceType,
          }));

          setAvailableRightInsurances(availableMapped);
          setSelectedRightInsurance({
            ...availableMapped[0],
            packageVersionId: res[0].versionId,
          });
          return res;
        });

        return scopes;
      })
      .catch((err) => {
        const nErr = new Error('Failed to fetch equivalent packages', { cause: err });
        logError(nErr, {
          ...insurance.coveragePackageInfo,
          targetCompany,
          externalId: insurance.insurance.externalId,
          advisoryParameters: config.advisoryParameters,
        });
        throw nErr;
      });
  }, [insurance, user, isCoveragePackageInfoFilled]);

  const handleGetParameters = (body: InsuranceBody) =>
    getParameters({
      insuranceName: body.packageName,
      insuranceType: body.insuranceType,
      insuranceSubType: body.insuranceSubType,
      insuranceCompany: body.insuranceCompany as string,
      versionId: body.packageVersionId,
      clientId: clientId ?? '',
    }).catch((err) => {
      const nErr = new Error('Failed to get parameters', { cause: err });
      logError(nErr, { ...body, clientId });
      throw nErr;
    });

  const [{ loading: isLoadingRight, value: right }, doFetchRightInsuranceParameters] =
    useAsyncFn(handleGetParameters);

  const [{ loading: isLoadingLeft, value: left }, doFetchLeftInsuranceParameters] =
    useAsyncFn(handleGetParameters);

  useDeepCompareEffect(() => {
    if (selectedRightInsurance) {
      doFetchRightInsuranceParameters(selectedRightInsurance);
    }
  }, [selectedRightInsurance]);

  useDeepCompareEffect(() => {
    if (
      selectedLeftInsurance?.packageVersionId === insurance.coveragePackageInfo.packageVersionId
    ) {
      setSelectedLeftInsurance(undefined);
    } else if (selectedLeftInsurance) {
      doFetchLeftInsuranceParameters(selectedLeftInsurance);
    }
  }, [selectedLeftInsurance]);

  useMount(() => {
    if (isCoveragePackageInfoFilled) {
      doFetchLeftPackageVersions({
        company: insurance.coveragePackageInfo.insuranceCompany,
        packageName: insurance.coveragePackageInfo.packageName,
      });
    }
  });

  const handleOnChangeSelectedRight = async (ins: InsuranceBody) => {
    if (ins.packageName !== selectedRightInsurance?.packageName) {
      await doFetchRightPackageVersions({
        company: ins.insuranceCompany as string,
        packageName: ins.packageName,
      }).then((res) => {
        setSelectedRightInsurance({ ...ins, packageVersionId: res[0].versionId });
        return res;
      });
    } else {
      setSelectedRightInsurance(ins);
    }
  };

  return {
    versions: {
      leftPackageVersions,
      rightPackageVersions,
      isFetching: isFetchingLeftPackageVersions || isFetchingRightPackageVersions,
      errorFetchingVersion: errorFetchingLeftPackageVersions || errorFetchingRightPackageVersions,
    },
    right: {
      selectedRightInsurance,
      setSelectedRightInsurance: handleOnChangeSelectedRight,
      availableRightInsurances,
      rightParameters: right,
      isLoadingRight,
    },
    left: { isLoadingLeft, leftParameters: left, setSelectedLeftInsurance, selectedLeftInsurance },
  };
};
