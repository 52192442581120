import { Button, Typography, Modal } from '@insurely/ui';
import { useState } from 'react';

// import { Overlay } from 'components/Overlay/Overlay';

import { useIntl } from 'translations';

import styles from './removeSessionModal.module.css';

interface RemoveSessionModalPRops {
  removeSession: () => void;
}

export const RemoveSessionModal: React.FC<RemoveSessionModalPRops> = ({ removeSession }) => {
  const { formatMessage } = useIntl();
  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <>
      <Button
        variant="secondary"
        size="small"
        className={styles.triggerButton}
        onClick={() => setShowConfirmation(true)}
      >
        {formatMessage({ id: 'Delete the session' })}
      </Button>

      <Modal isOpen={showConfirmation} onClose={() => setShowConfirmation(false)} width={500}>
        <div className={styles.container}>
          <Typography component="h3" variant="Headline-6" className={styles.headline}>
            {formatMessage({ id: 'Are you sure you want to delete the session?' })}
          </Typography>
          <Typography component="p" variant="ParagraphBodySmall" className={styles.paragraph}>
            {formatMessage({
              id: 'All insurance data in this session will disappear from the Sales Support Tool.',
            })}
          </Typography>
          <div className={styles.buttons}>
            <Button variant="primary" onClick={() => setShowConfirmation(false)}>
              {formatMessage({ id: 'Cancel' })}
            </Button>
            <Button variant="secondary" onClick={removeSession}>
              {formatMessage({ id: 'Delete the session' })}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
