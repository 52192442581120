import { Button, Modal, TextArea, Typography, Notification } from '@insurely/ui';
import { useContext, useState } from 'react';

import { useParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';

import { ModifiedList } from 'components/ModifiedList/ModifiedList';
import UserContext from 'contexts/user/UserContext';

import { useCompanies } from 'hooks/useCompanies';
import { FormattedMessage, useIntl } from 'translations';
import { InsuranceSubType, InsuranceType } from 'types/v3/Insurance';

import { logError } from 'utils/datadog';
import { upperCaseFirstLetter } from 'utils/formatters';

import { reportProblemForCell } from '../../api';

import styles from './reportProblemModal.module.css';

export type ReportModalMetadata = {
  insuranceName: string;
  company: string;
  parameter: string;
  parameterDisplayName: string;
  columnName: string;
  insuranceType: InsuranceType;
  insuranceSubType: InsuranceSubType;
  value: string;
};

const SuccessView = ({ onClose }: { onClose: () => void }) => (
  <div className={styles.successContent}>
    <div>
      <Typography variant="Label-1" component="p" className={styles.successTitle}>
        <FormattedMessage id="page.comparison.report-problem.modal.success-title" />
      </Typography>
      <Typography variant="ParagraphCaption" component="p">
        <FormattedMessage id="page.comparison.report-problem.modal.success-description" />
      </Typography>
    </div>
    <Button variant="secondary" onClick={onClose} size="medium" style={{ alignSelf: 'flex-end' }}>
      <FormattedMessage id="page.comparison.report-problem.modal.success-close" />
    </Button>
  </div>
);

export const ReportProblemModal = ({
  isOpen,
  onClose,
  metadata,
}: {
  isOpen: boolean;
  onClose: () => void;
  metadata: ReportModalMetadata;
}) => {
  const { formatMessage } = useIntl();
  const { user, config } = useContext(UserContext);
  const { externalId } = useParams();
  const { getCompanyDisplayName } = useCompanies();

  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const {
    insuranceName,
    parameter,
    columnName,
    value,
    insuranceSubType,
    insuranceType,
    company,
    parameterDisplayName,
  } = metadata;

  const [{ loading, error }, doSendProblemReport] = useAsyncFn(
    () =>
      reportProblemForCell(
        {
          coverageCompany: company,
          insuranceName,
          market: config.market,
          parameterName: parameter,
          externalId,
          feedbackText: `Column: ${columnName} - Value: ${value} - Message: ${message}`,
          userId: user?.uuid as string,
          insuranceType,
          insuranceSubType,
        },
        user?.selectedClientId as string,
      )
        .then(() => setIsSuccess(true))
        .catch((err) => {
          const nErr = new Error('Failed to report a coverage problem for a cell', { cause: err });
          logError(nErr, {
            coverageCompany: company,
            insuranceName,
            market: config.market,
            parameterName: parameter,
            externalId,
            userId: user?.uuid as string,
            insuranceType,
            insuranceSubType,
          });
          throw nErr;
        }),
    [metadata, message],
  );

  const getContent = () => {
    if (isSuccess) {
      return <SuccessView onClose={onClose} />;
    }

    return (
      <div className={styles.content}>
        <Typography variant="Label-1" component="span" className={styles.title}>
          <FormattedMessage id="page.comparison.report-problem.modal.title" />
        </Typography>
        <ModifiedList
          rows={[
            {
              label: formatMessage({ id: 'page.comparison.report-problem.modal.insurance' }),
              value: `${getCompanyDisplayName(company)}, ${upperCaseFirstLetter(insuranceName)}`,
            },
            {
              label: formatMessage({ id: 'page.comparison.report-problem.modal.parameter' }),
              value: parameterDisplayName,
            },
            { label: columnName, value },
          ]}
        />
        <TextArea
          aria-label="report-content"
          textAreaSize="small"
          value={message}
          rows={3}
          placeholder={formatMessage({
            id: 'page.comparison.report-problem.modal.input-placeholder',
          })}
          onChange={(e) => setMessage(e.currentTarget.value)}
        />
        <div className={styles.buttonsContainer}>
          <Button variant="secondary" size="medium" onClick={onClose}>
            <FormattedMessage id="page.comparison.report-problem.modal.cancel" />
          </Button>
          <Button
            variant="primary"
            size="medium"
            loading={loading}
            onClick={doSendProblemReport}
            disabled={message.trim().length === 0}
          >
            <FormattedMessage id="page.comparison.report-problem.modal.send" />
          </Button>
        </div>
        {error && (
          <Notification
            status="error"
            description={formatMessage({ id: 'page.comparison.report-problem.modal.error-send' })}
          />
        )}
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {getContent()}
    </Modal>
  );
};
