import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Card, Icon, Notification, TextInput, Typography } from '@insurely/ui';
import { useContext, useEffect, useMemo, useState } from 'react';

import { useForm } from 'react-hook-form';

import { Navigate, useNavigationType, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

import { createSession } from 'client/client';
import { Page } from 'components/Page/Page';
import { SYSTEM_STATUS_ROUTE, SESSIONS_ROUTE } from 'constants/routes';
import UserContext from 'contexts/user/UserContext';
import { useNavigateQueryParams } from 'hooks/useNavigateQueryParams';

import utils from 'services/utils';
import { FormattedMessage, IntlShape, useIntl } from 'translations';
import { useFormatting } from 'translations/useFormatting';
import { Session } from 'types/v3';

import { logError } from 'utils/datadog';
import { PostHogAction, PostHogCategory, postHogCapture } from 'utils/posthog';
import { ProductLine } from 'utils/product-line';

import { SessionCard } from './SessionCard/SessionCard';
import styles from './sessions.module.css';

interface FormValues {
  sessionName: string;
}

const schema = (formatMessage: IntlShape['formatMessage']) =>
  z.object({
    sessionName: z.string().min(1, { message: formatMessage({ id: 'Please enter a name' }) }),
  });

export const SessionsPage = () => {
  const { formatMessage } = useIntl();
  const { sessions, config, loadSessions, companies, productLine } = useContext(UserContext);
  const { formatRelativeDate } = useFormatting();
  const navigate = useNavigateQueryParams();

  const { clientId } = utils.getConfigItem(config) || {};
  const [sendingRequest, setSendingRequest] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>();

  const navigationType = useNavigationType();
  const [searchParams] = useSearchParams();

  const sessionsByDate = sessions.reduce(
    (acc, session) => {
      const date = session.creationDate;

      if (!acc[date]) {
        return { ...acc, [date]: [session] };
      }
      acc[date].push(session);
      return acc;
    },
    {} as { [key: string]: Session[] },
  );

  const nonFunctionalCompanies = companies.filter((comp) => !comp.functional);

  const formattedNonFunctionalCompanies = useMemo(() => {
    const names = nonFunctionalCompanies.map((c) => c.insuranceCompanyDisplayName);

    if (names.length === 1) {
      return names[0];
    }

    return `${names.slice(0, -1).join(', ')} ${formatMessage({
      id: 'page.sessions.system-status-warning.separator',
    })} ${names.slice(-1)}`;
  }, [nonFunctionalCompanies, formatMessage]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onTouched',
    resolver: zodResolver(schema(formatMessage)),
  });

  async function createNewSession({ sessionName }: FormValues) {
    setSendingRequest(true);
    postHogCapture(`tracking:${PostHogAction.CLICK}`, {
      action: PostHogAction.CLICK,
      category: PostHogCategory.GENERAL,
      object: 'createSession',
    });

    try {
      const response = await createSession({
        clientId: clientId ?? '',
        freeText: sessionName,
      });

      setSendingRequest(false);
      // Should just add the response (the new session) to the
      // context instead of loading all again
      await loadSessions(productLine === ProductLine.PENSION ? 'pension' : 'insurance');
      setRedirectUrl(`/${SESSIONS_ROUTE}/${response.sessionId}`);
    } catch (error) {
      const nErr = new Error('Error creating new session', { cause: error });
      logError(nErr, { clientId });
      throw nErr;
    }
  }

  useEffect(() => {
    if (navigationType === 'PUSH' && searchParams) window.scrollTo(0, 0);
  }, [navigationType, searchParams]);

  if (redirectUrl) {
    return <Navigate to={redirectUrl} />;
  }

  return (
    <Page title={formatMessage({ id: 'Sessions' })}>
      <div className={styles.container}>
        <Typography component="h1" variant="Headline-3">
          {formatMessage({ id: 'Sessions' })}
        </Typography>
        {nonFunctionalCompanies.length > 0 && (
          <Notification
            status="warning"
            headline={formatMessage(
              { id: 'page.sessions.system-status-warning.title' },
              { count: nonFunctionalCompanies.length, companies: formattedNonFunctionalCompanies },
            )}
          >
            <div className={styles.notificationCardContent}>
              <Typography variant="ParagraphCaption" component="p">
                <FormattedMessage
                  id="page.sessions.system-status-warning.description"
                  values={{ count: nonFunctionalCompanies.length }}
                />
              </Typography>
              <Button
                variant="secondary"
                size="small"
                onClick={() => navigate(`/${SYSTEM_STATUS_ROUTE}`)}
              >
                <FormattedMessage id="page.sessions.system-status-warning.link" />
              </Button>
            </div>
          </Notification>
        )}
        <Card className={styles.inputCard}>
          <form className={styles.form} onSubmit={handleSubmit(createNewSession)}>
            <TextInput
              {...register('sessionName')}
              style={{ maxWidth: '280px' }}
              label={formatMessage({ id: 'First and last name' })}
              type="text"
              error={!!errors.sessionName}
              helperText={errors.sessionName?.message}
              inputProps={{ autoComplete: 'off' }}
            />
            <Button
              variant="primary"
              size="large"
              type="submit"
              loading={sendingRequest}
              icon={<Icon name="arrow-right" size={20} />}
              iconPosition="right"
            >
              {formatMessage({ id: 'Create new session' })}
            </Button>
          </form>
        </Card>
        {Object.entries(sessionsByDate)
          .reverse()
          .map(([date, dateSessions]) => (
            <div key={date} className={styles.day}>
              <Typography className={styles.collectionDate} component="h2" variant="Headline-6">
                <strong>{formatRelativeDate(date)}</strong>
              </Typography>

              <div className={styles.sessions}>
                {dateSessions.reverse().map((session: Session) => (
                  <SessionCard key={session.sessionId} session={session} />
                ))}
              </div>
            </div>
          ))}
      </div>
    </Page>
  );
};
