import { redirect } from 'react-router-dom';

import { LOGIN_ROUTE } from 'constants/routes';
import { logout } from 'utils/logout';

export default function Logout() {
  logout();
  redirect(LOGIN_ROUTE);

  return null;
}
