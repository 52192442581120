import { zodResolver } from '@hookform/resolvers/zod';
import { Button, CompanyLogo, Divider, TextInput, Typography, Notification } from '@insurely/ui';

import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Link, useSearchParams } from 'react-router-dom';
import { useAsyncFn, useMount } from 'react-use';
import { z } from 'zod';

import { magicLinkLogin, sendLoginMagicLink } from 'client/client';
import { LOGIN_ROUTE, OVERVIEW_ROUTE, REGISTER_ROUTE } from 'constants/routes';

import { useNavigateQueryParams } from 'hooks/useNavigateQueryParams';
import { IntlShape, useIntl } from 'translations';
import { logError } from 'utils/datadog';

import LoginLoader from '../comp/LoginLoader';
import styles from '../login.module.css';

interface FormValues {
  email: string;
}

const schema = (formatMessage: IntlShape['formatMessage']) =>
  z.object({
    email: z.string().email({ message: formatMessage({ id: 'Please enter a valid e-mail' }) }),
  });

export const MagicLink: React.FC = () => {
  const [searchParams] = useSearchParams();

  const [magicLinkSent, setMagicLinkSent] = useState(false);

  const token = searchParams.get('token');

  const navigate = useNavigateQueryParams();
  const { formatMessage } = useIntl();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    trigger,
  } = useForm<FormValues>({
    mode: 'onTouched',
    delayError: 500,
    resolver: zodResolver(schema(formatMessage)),
  });

  const [{ error: errorLoginWithToken }, doLoginWithToken] = useAsyncFn(() =>
    magicLinkLogin(token)
      .then(() => navigate(`/${OVERVIEW_ROUTE}`, { cleanQueryParams: true }))
      .catch((error) => {
        const { data } = error.response;
        const nErr = new Error(data?.localizedMessage || data?.message, { cause: error });
        logError(nErr, { token });
        throw nErr;
      }),
  );

  const [{ loading: isSendingMagicLink, error: errorSendingMagicLink }, doSendMagicLink] =
    useAsyncFn(({ email }: FormValues) =>
      sendLoginMagicLink(email)
        .then(() => setMagicLinkSent(true))
        .catch((error) => {
          const { data } = error.response;
          const nErr = new Error(data?.localizedMessage || data?.message, { cause: error });
          if (error.response.status !== 401) {
            logError(nErr);
          }
          throw nErr;
        }),
    );

  useMount(() => {
    if (token) {
      doLoginWithToken();
    }
  });

  if (token && !errorLoginWithToken) {
    return <LoginLoader />;
  }

  const errorMessage = errorLoginWithToken?.message ?? errorSendingMagicLink?.message;

  return (
    <div className={styles.formContainer}>
      {magicLinkSent ? (
        <div className={styles.form}>
          <CompanyLogo company="se-demo" width="56px" />
          <div className={styles.header}>
            <Typography component="h1" variant="Headline-4" className={styles.signInTitle}>
              {formatMessage({ id: 'page.login.magic-link.confirmation.title' })}
            </Typography>
            <Typography component="p" variant="ParagraphBodySmall">
              {formatMessage(
                { id: 'page.login.magic-link.confirmation.description' },
                { email: getValues('email') },
              )}
            </Typography>
          </div>
        </div>
      ) : (
        <form className={styles.form} onSubmit={handleSubmit(doSendMagicLink)}>
          <CompanyLogo company="se-demo" width="56px" />
          <div className={styles.header}>
            <Typography component="h1" variant="Headline-4" className={styles.signInTitle}>
              {formatMessage({ id: 'page.login.magic-link.title' })}
            </Typography>
            <Typography component="p" variant="ParagraphBodySmall" className={styles.signInText}>
              {formatMessage({ id: 'page.login.magic-link.description' })}
            </Typography>
          </div>
          {errorMessage && (
            <Notification className={styles.notification} headline={errorMessage} status="error" />
          )}
          <TextInput
            {...register('email')}
            label={formatMessage({ id: 'shared.email' })}
            className={styles.formInput}
            error={!!errors.email}
            helperText={errors.email?.message}
            onBlur={() => {
              if (getValues('email')) {
                trigger('email');
              }
            }}
            autoFocus
          />
          <Button
            variant="primary"
            fullWidth
            style={{ display: 'flex', justifyContent: 'center' }}
            type="submit"
            loading={isSendingMagicLink}
            disabled={isSendingMagicLink}
          >
            {formatMessage({ id: 'Send link' })}
          </Button>
        </form>
      )}

      <div className={styles.secondaryLoginContainer}>
        <Divider />
        <Button variant="secondary" onClick={() => navigate(`/${LOGIN_ROUTE}`)} fullWidth>
          {formatMessage({ id: 'page.login.link.username-login' })}
        </Button>
        <Divider />
      </div>

      <div className={styles.linksContainer}>
        <Link to={`/${LOGIN_ROUTE}/${REGISTER_ROUTE}`}>
          {formatMessage({ id: 'page.login.link.create-account' })}
        </Link>
      </div>
    </div>
  );
};
