import { Button, Icon, IconButton, Loader, Modal, Plate, TextInput } from '@insurely/ui';
import { useMemo, useState } from 'react';

import { FormattedMessage, useIntl } from 'translations';

import { Liveboard } from 'types/liveboard';
import { Target } from 'views/Config/comp/ActionBanner/type';
import { TableTitle } from 'views/Config/comp/TableTitle/TableTitle';
import { MinimalClientConfig, User } from 'views/Config/config.types';

import { Action, ActionsBanner } from '../../comp/ActionBanner/ActionBanner';

import { ConnectToGroup } from './ConnectToGroup';
import styles from './userGroupConfig.module.css';

interface Props {
  loading: boolean;
  items?: User[] | MinimalClientConfig[] | Liveboard[];
  target: Target;
  refresh: () => void;
  children: ({
    filteredItems,
    handleOnSelectAll,
    handleOnSelect,
    selectedItems,
  }: {
    filteredItems: User[] | MinimalClientConfig[] | Liveboard[];
    handleOnSelectAll: () => void;
    handleOnSelect: (id: string) => void;
    selectedItems: string[];
  }) => React.ReactNode;
}

export const TabWrapper = ({ items, loading, target, refresh, children }: Props) => {
  const { formatMessage } = useIntl();

  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const stringifiedItems = useMemo(() => {
    if (!items) {
      return [];
    }
    switch (target) {
      case Target.USERS:
        return (items as User[]).map((u) => u.uuid);
      case Target.CLIENTS:
        return (items as MinimalClientConfig[]).map((c) => c.clientId);
      case Target.LIVEBOARDS:
        return (items as Liveboard[]).map((l) => l.thoughtspotLiveboardId);
      default:
        return [];
    }
  }, [items, target]);

  const handleOnSelectAll = () => {
    if (!items) {
      return;
    }
    setSelectedItems((cur) => (cur.length < items.length ? stringifiedItems : []));
  };

  const handleOnSelect = (id: string) => {
    setSelectedItems((cur) => (!cur.includes(id) ? [...cur, id] : cur.filter((u) => u !== id)));
  };

  const mappedActions = useMemo(() => {
    switch (target) {
      case Target.USERS:
        return [Action.USER_GROUP_USERS_REMOVE];
      case Target.CLIENTS:
        return [Action.CLIENTS_REMOVE];
      case Target.LIVEBOARDS:
        return [Action.LIVEBOARDS_REMOVE];
      default:
        return [];
    }
  }, [target]);

  const iconFromTarget = useMemo(() => {
    switch (target) {
      case Target.USERS:
        return 'user';
      case Target.CLIENTS:
        return 'settings';
      case Target.LIVEBOARDS:
      default:
        return 'bar-chart';
    }
  }, [target]);

  const filteredItems = useMemo(() => {
    if (!items) {
      return [];
    }
    switch (target) {
      case Target.USERS:
        return (items as User[]).filter((m) =>
          m.email.toLowerCase().includes(search.toLowerCase()),
        );
      case Target.CLIENTS:
        return (items as MinimalClientConfig[]).filter((c) =>
          c.clientName.toLowerCase().includes(search.toLowerCase()),
        );
      case Target.LIVEBOARDS:
        return (items as Liveboard[]).filter((l) =>
          l.liveboardName.toLowerCase().includes(search.toLowerCase()),
        );
      default:
        return [];
    }
  }, [items, search, target]);

  const renderContent = () => {
    if (loading) {
      return (
        <div style={{ position: 'relative', height: '30px' }}>
          <Loader.Content size="default" />
        </div>
      );
    }

    if (items) {
      return (
        <Plate>
          <div className={styles.headline}>
            <TableTitle
              icon={iconFromTarget}
              title={formatMessage({
                id: `page.config.user-group-config.tabs.${target.toLowerCase()}`,
              })}
            />
            <div className={styles.headlineActions}>
              <Button
                size="medium"
                onClick={() => setIsModalOpen(true)}
                icon={<Icon name="instant-connection" size={18} />}
              >
                <FormattedMessage
                  id={`page.config.user-group-config.members.add-${target.toLowerCase()}`}
                />
              </Button>
              <TextInput
                startAdornment={<Icon name="search" size={20} />}
                endAdornment={
                  search.length > 0 ? (
                    <IconButton
                      icon={<Icon name="close" size={20} />}
                      onClick={() => setSearch('')}
                    />
                  ) : undefined
                }
                textInputSize="extra-small"
                value={search}
                onChange={(e) => setSearch(e.currentTarget.value)}
                placeholder={formatMessage({
                  id: `page.config.user-group-config.${target.toLowerCase()}.search.placeholder`,
                })}
                label=""
              />
            </div>
          </div>
          <div style={{ overflow: 'auto' }}>
            {children({
              filteredItems,
              handleOnSelect,
              handleOnSelectAll,
              selectedItems,
            })}
          </div>
        </Plate>
      );
    }

    return null;
  };

  return (
    <>
      {selectedItems.length > 0 && (
        <div className={styles.actionBannerContainer}>
          <ActionsBanner
            target={target}
            targetIds={selectedItems}
            actions={mappedActions}
            onSuccess={() => {
              setSelectedItems([]);
              setIsModalOpen(false);
              refresh();
            }}
          />
        </div>
      )}
      {renderContent()}
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={formatMessage({
          id: `page.config.user-group-config.clients.add-${target.toLowerCase()}`,
        })}
        className={styles.modal}
      >
        <ConnectToGroup
          existingIds={stringifiedItems}
          onSuccess={() => {
            refresh();
            setIsModalOpen(false);
          }}
          target={target}
        />
      </Modal>
    </>
  );
};
