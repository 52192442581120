import { newRequestWithToken } from 'client/client';
import { Liveboard } from 'types/liveboard';
import { Customer, ExtendedClientConfig, SmsConfig } from 'views/Config/config.types';

export const getAllCustomers = async (): Promise<Customer[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: '/sst/setup/customer',
  });
  return res?.data.customers;
};

export const createNewCustomer = async ({
  customerName,
}: {
  customerName: string;
}): Promise<{ customerId: string }> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: '/sst/setup/customer',
    data: { customerName },
  });
  return res?.data;
};

export const getCustomer = async (
  customerId: string,
): Promise<Pick<Customer, 'customerName' | 'customerId'>> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/customer/${customerId}`,
  });
  return res?.data.customers[0];
};

export const updateCustomer = async ({
  customerId,
  customerName,
}: {
  customerId: string;
  customerName: string;
}) => {
  const res = await newRequestWithToken({
    method: 'PUT',
    url: `/sst/setup/customer/${customerId}`,
    data: { customerName },
  });
  return res?.data;
};

export const getCustomerEmails = async (customerId: string): Promise<string[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/customer/${customerId}/emails`,
  });
  return res?.data?.customerEmails;
};

export const createCustomerEmails = async ({
  customerId,
  emails,
}: {
  customerId: string;
  emails: string[];
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/setup/customer/${customerId}/emails`,
    data: { customerEmails: emails },
  });
  return res?.data;
};

export const deleteCustomerEmails = async ({
  customerId,
  email,
}: {
  customerId: string;
  email: string;
}): Promise<string[]> => {
  const res = await newRequestWithToken({
    method: 'DELETE',
    url: `/sst/setup/customer/${customerId}/emails/${email}`,
  });
  return res?.data?.customerEmails;
};

export const getCustomerSmsConfig = async (customerId: string): Promise<SmsConfig> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/customer/${customerId}/sst-sms-config`,
  });
  return res?.data;
};

export const updateCustomerSmsConfig = async ({
  customerId,
  smsConfig,
}: {
  customerId: string;
  smsConfig: SmsConfig;
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'PUT',
    url: `/sst/setup/customer/${customerId}/sst-sms-config`,
    data: smsConfig,
  });
  return res?.data;
};

export const createCustomerSmsConfig = async ({
  customerId,
  smsConfig,
}: {
  customerId: string;
  smsConfig: SmsConfig;
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/setup/customer/${customerId}/sst-sms-config`,
    data: smsConfig,
  });
  return res?.data;
};

export const deleteCustomerSmsConfig = async (customerId: string): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'DELETE',
    url: `/sst/setup/customer/${customerId}/sst-sms-config`,
  });
  return res?.data;
};

export const assignLiveboardToCustomer = async ({
  customerId,
  thoughtspotLiveboardIds,
}: {
  customerId: string;
  thoughtspotLiveboardIds: string[];
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/setup/customer/${customerId}/liveboards`,
    data: thoughtspotLiveboardIds,
  });
  return res?.data;
};

export const removeLiveboardFromCustomer = async ({
  customerId,
  thoughtspotLiveboardId,
}: {
  customerId: string;
  thoughtspotLiveboardId: string;
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'DELETE',
    url: `/sst/setup/customer/${customerId}/liveboards/${thoughtspotLiveboardId}`,
  });
  return res?.data;
};

export const getAllClientsOnCustomer = async (
  customerId: string,
): Promise<ExtendedClientConfig[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/customer/${customerId}/client-configs`,
  });
  return res?.data.clientConfigs;
};

export const getAllLiveboardsForCustomer = async ({
  customerId,
}: {
  customerId: string;
}): Promise<Liveboard[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/customer/${customerId}/liveboards`,
  });
  return res?.data;
};
