import { SSTCollectionResponse, Permissions, CountryCode, CollectionStatus } from 'types/v3';

export interface LoginResponse {
  clientId: string;
  creationDate: string;
  customerId: string;
  email: string;
  enabled: boolean;
  permissions: Permissions[];
}

export interface RegisterResponse {
  creationDate: string;
  customerId: string;
  email: string;
  enabled: boolean;
  permissions: Permissions[];
}

// Verify these types
export interface EnableAccountResponse {
  uuid: string;
  clientId: string;
  customerId: string;
  email: string;
  enabled: boolean;
  permissions: Permissions[];
  creationDate: string;
}

export interface SSTCollectionData {
  clientId: string;
  collections: number;
  day: number;
  email: string;
  exports: number;
  hour: number;
  insurances: number;
  month: number;
  sessions: number;
  week: number;
  year: number;
}

export interface SSTCompanyData {
  clientId: string;
  collections: number;
  day: number;
  email: string;
  hour: number;
  insuranceCompany: string;
  insuranceCompanyDisplayName: string;
  insurances: number;
  month: number;
  week: number;
  year: number;
}

export interface SSTSessionData {
  clientId: string;
  completedSessions: number;
  day: number;
  email: string;
  hour: number;
  month: number;
  sessions: number;
  week: number;
  year: number;
}

export interface CancellationDocments {
  collectionId: string;
  creationDate: number;
  documentId: string;
  documentStatus: CancellationStatus;
  insuranceId: string;
  signedAndSealed: false;
  terminationDate: string;
  updatedDate: number;
}

export interface OldCancellationResponse {
  cancellationDocuments: CancellationDocments[];
}

export enum CancellationStatus {
  PREPARATION = 'PREPARATION',
  PENDING = 'PENDING',
  CREATED = 'CREATED',
  CLOSED = 'CLOSED',
  CANCELED = 'CANCELED',
  TIMED_OUT = 'TIMED_OUT',
  REJECTED = 'REJECTED',
  DOCUMENT_ERROR = 'DOCUMENT_ERROR',
}

export enum SmsStatus {
  // SUCCESS
  DELIVERED = 'DELIVERED',
  SENT = 'SENT',
  // ERROR
  FAILED = 'FAILED',
  UNDELIVERED = 'UNDELIVERED',
  UNKNOWN = 'UNKNOWN',
  // LOADING
  REQUESTED = 'REQUESTED',
  ACCEPTED = 'ACCEPTED',
  QUEUED = 'QUEUED',
  SENDING = 'SENDING',
}

export enum AauthenticationMethod {
  BANK_ID = 'BANK_ID',
}

export enum DeliveryMethod {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  API = 'API',
}

export enum CancellationSort {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
}

export interface Email {
  value: string;
}

export interface PhoneNumber {
  countryCode: CountryCode;
  number: string;
}

interface DeliveryMethodSms {
  deliveryMethod: DeliveryMethod.SMS;
  phoneNumber: PhoneNumber;
}

interface DeliveryMethodEmail {
  deliveryMethod: DeliveryMethod.EMAIL;
  email: Email;
}
interface DeliveryMethodAPI {
  deliveryMethod: DeliveryMethod.API;
}

export type DeliveryMethodInUse = DeliveryMethodSms | DeliveryMethodEmail | DeliveryMethodAPI;

export interface PagingMetadata {
  next: string | null;
  previous: string | null;
  pageSize: number;
}

export interface SortingMetadata {
  currentSort: CancellationSort;
  availableSorts: CancellationSort[];
}

export interface Insurance {
  collectionId: string;
  externalId: string;
  newInsuranceNumber: string;
  terminationDate: string;
}

export interface Cancellation {
  createdAt: string;
  documentUrl: string;
  updatedAt: string;
  id: string;
  isSignedAndSealed: boolean;
  status: CancellationStatus;
  email?: string;
  phoneNumber?: string;
  insurances: Insurance[];
  insurance: Insurance;
  agent: { id: number; email: string };
}

export interface CancellationsResponse {
  cancellations: Cancellation[];
  pagingMetadata: PagingMetadata;
  sortingMetadata: SortingMetadata;
}

export interface CancellationConfig {
  defaultAuthenticationMethod: AauthenticationMethod;
  defaultDeliveryMethod: DeliveryMethod;
}

export interface CreateCancellationRequestBase {
  authenticationMethod: AauthenticationMethod;
  collectionId: string;
  authorEmail: Email;
  insurances: { externalId: string }[];
  personalNumber?: string;
  newInsuranceNumber?: string;
  terminationDate?: string;
}

export type CancellationRequest = CreateCancellationRequestBase & DeliveryMethodInUse;

export interface CompareParameter {
  parameterDescription: string;
  parameterDisplayName: string;
  parameterGroup: string;
  parameterGroupOrder: number;
  parameterName: string;
  value: 'true' | 'false' | 'possible';
}

export interface CreatedCancellation {
  collectionId: string;
  documentId: string;
  externalId: string;
}

export interface CreateCancellationResponse {
  cancelledInsurances: CreatedCancellation[];
}

export interface Invitation {
  creationDate: number;
  customerId: string;
  insurelyId: string;
  phoneNumber: string;
  sessionId: string;
  status: SmsStatus;
}

export interface InvitationRequest {
  sessionId: string;
  phoneNumber: PhoneNumber;
  communicationConsent: boolean;
}

export interface Collection {
  collectionId: string;
  creationDate: number;
  insuranceCompany: string;
  sessionId: string;
  status: string;
}

export interface CollectionStatusChange {
  status: CollectionStatus;
  updated: number;
}

export interface CollectionStatusUpdatesResponse {
  changes: CollectionStatusChange[];
}

export interface TermsStatus {
  date: string;
  exists: boolean;
  insuranceCompany: string;
  type: string;
}

export interface GetCollectionsRequest {
  limit: number;
  personalNumber: string;
  companies: Array<string>;
  beforeDate: string;
  afterDate: string;
  clientIds: Array<string>;
  excludeEmpty: boolean;
}

export interface SearchCollectionsRequest {
  limit: number;
  personalNumber: string;
  companies: Array<string>;
  beforeDate: string;
  afterDate: string;
  clientIds: Array<string>;
  excludeEmpty: boolean;
  externalReference: string;
}

export interface SearchCollectionsResponse {
  collections: SSTCollectionResponse[];
  searchHasMoreData: boolean;
}

export interface CreateSessionResponse {
  advisoryUserUuid: string;
  creationDate: string;
  sessionId: string;
  freeText: string;
  numberOfInsurances: number;
  numberOfFilteredInsurances: number;
  numberOfPensions: number;
}
