import { Button } from '@insurely/ui';
import deepEqual from 'deep-equal';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useForm, UseFormSetValue } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { useAsync } from 'react-use';

import { fetchCompaniesForClient, getCompanyClients } from 'client/client';
import UserContext from 'contexts/user/UserContext';
import { useDebouncedEffect } from 'hooks/useDebouncedEffect';
import { FormattedMessage, useIntl } from 'translations';
import { Advisor, SSTClientResponse } from 'types/v3';

import { logError } from 'utils/datadog';
import { PostHogAction, postHogCapture, PostHogCategory } from 'utils/posthog';
import { ProductLine } from 'utils/product-line';
import { transformFilterQueryToUrl } from 'views/Collections/utils';

import { FilterOptions, FilterQuery } from '../types';

import styles from './filter.module.css';
import FilterDropdown from './FilterDropdown/FilterDropdown';

import FilterPill from './FilterPill/FilterPill';
import { useStoreFilters } from './useStoreFilters';

const EMPTY_FILTERS = {
  assignee: '',
  clientIds: [],
  selectedCompanies: [],
  afterDate: '',
  personalNumber: '',
  contactPhone: '',
  contactEmail: '',
  externalReference: '',
  showEmpty: false,
};

interface FilterBoxProps {
  isLoading: boolean;
  getCollectionsTable: (filterQuery?: FilterQuery) => void;
  advisors: Advisor[];
}

const RenderPill = ({
  id,
  value,
  setValue,
  optionsBySection,
}: {
  id: keyof FilterQuery;
  value: string | string[];
  setValue: UseFormSetValue<FilterQuery>;
  optionsBySection: FilterOptions;
}) => {
  const { formatMessage } = useIntl();

  const { clients, advisors, companies } = optionsBySection;

  const valueLabel = useCallback(
    (v: string) => {
      switch (id) {
        case 'selectedCompanies':
          return companies.find((c) => c.value === v)?.label ?? v;
        case 'clientIds':
          return clients.find((c) => c.value === v)?.label ?? v;
        case 'assignee':
          return advisors.find((a) => a.value === v)?.label ?? v;
        default:
          return v;
      }
    },
    [id, companies, clients, advisors],
  );

  const renderText = useCallback(
    (v: string) => (
      <FormattedMessage
        id="page.collections.filters.pill"
        values={{
          value: valueLabel(v),
          descriptor: formatMessage({ id: `page.collections.filters.${id}` }),
        }}
      />
    ),
    [id, formatMessage, valueLabel],
  );

  if (Array.isArray(value)) {
    return value.map((v) => (
      <FilterPill
        text={renderText(v)}
        onClick={() =>
          setValue(
            id,
            value.filter((_v) => _v !== v),
          )
        }
        key={v}
      />
    ));
  }

  if (typeof value === 'boolean') {
    return (
      <FilterPill
        text={
          <strong>
            <FormattedMessage id={`page.collections.filters.${id}`} />
          </strong>
        }
        onClick={() => setValue(id, '')}
      />
    );
  }

  return <FilterPill text={renderText(value)} onClick={() => setValue(id, '')} />;
};

export default function FilterBox({ isLoading, getCollectionsTable, advisors }: FilterBoxProps) {
  const [, setSearchParams] = useSearchParams();
  const { productLine } = useContext(UserContext);
  const { setFilters, getFilters } = useStoreFilters();

  const [searchText, setSearchText] = useState('');
  const { register, handleSubmit, control, watch, setValue, reset } = useForm<FilterQuery>({
    mode: 'onSubmit',
    delayError: 500,
    defaultValues: getFilters(),
  });

  const { value: clients } = useAsync(
    () =>
      getCompanyClients()
        .then((resp) =>
          productLine === ProductLine.PENSION
            ? resp.filter((item) => item.productType.includes('PENSION'))
            : resp,
        )
        .catch((err) => {
          const nErr = new Error('Failed to fetch company clients', { cause: err });
          logError(nErr, { productLine });
          throw nErr;
        }) as Promise<SSTClientResponse[]>,
  );

  const handleSearch = (filters: FilterQuery) => {
    setSearchParams(transformFilterQueryToUrl(filters), { replace: true });
    setFilters(filters);
    postHogCapture('Search collections', {
      action: PostHogAction.FETCH,
      category: PostHogCategory.COLLECTIONS,
      metadata: {
        selectedCompanies: filters.selectedCompanies,
        afterDate: filters.afterDate,
        clientIds: filters.clientIds,
        showEmpty: filters.showEmpty,
        externalReference: filters.externalReference,
        advisor: filters.assignee,
        contactPhone: filters.contactPhone,
        contactEmail: filters.contactEmail,
      },
    });

    return getCollectionsTable(filters);
  };

  const { value: availableCompaniesForClient } = useAsync(fetchCompaniesForClient);

  const optionsBySection = useMemo(
    () =>
      ({
        advisors: advisors.map((a) => ({ label: a.email, value: a.userUuid })),
        clients: clients?.map((c) => ({ label: c.clientName, value: c.clientId })) ?? [],
        companies:
          availableCompaniesForClient?.map((c) => ({
            label: c.companyDisplayName,
            value: c.companyName,
          })) ?? [],
      }) satisfies FilterOptions,
    [advisors, clients, availableCompaniesForClient],
  );

  const filterList = Object.entries(watch()).filter(([, v]) =>
    Array.isArray(v) ? v.length > 0 : !!v,
  );

  useDebouncedEffect({
    delay: 600,
    deps: [watch()],
    effect: () => handleSearch(watch()),
  });

  return (
    <div>
      <form className={styles.filterContainer} onSubmit={handleSubmit(handleSearch)}>
        <FilterDropdown
          options={optionsBySection}
          form={{ register, control, watch, setValue }}
          searchText={searchText}
          setSearchText={setSearchText}
        />
        <div className={styles.buttonsContainer}>
          <Button className={styles.searchButton} type="submit" disabled={isLoading}>
            <FormattedMessage id="page.collections.search" />
          </Button>
          <Button
            variant="secondary"
            type="button"
            disabled={deepEqual(watch(), EMPTY_FILTERS)}
            className={styles.resetButton}
            onClick={() => {
              reset(EMPTY_FILTERS);
              handleSearch(EMPTY_FILTERS);
              setSearchText('');
            }}
          >
            <FormattedMessage id="page.collections.filter.reset" />
          </Button>
        </div>
      </form>
      {filterList.length > 0 && (
        <div className={styles.filterPills}>
          {filterList.map(([key, value]) => (
            <RenderPill
              key={key}
              id={key as keyof FilterQuery}
              value={value}
              setValue={setValue}
              optionsBySection={optionsBySection}
            />
          ))}
        </div>
      )}
    </div>
  );
}
