import { isToday, isYesterday } from 'date-fns';
import { useContext } from 'react';

import UserContext from 'contexts/user/UserContext';

import { Market } from 'types/v3';

import { marketToCountryAreaCode } from 'utils/formatters';

import { useIntl } from './messages';

const PHONE_NUMBER_FORMAT: Record<Market, string> = {
  se: 'xxx xxx xx xx',
  fr: 'xx xx xx xx xx',
  dk: 'xx xx xx xx',
  ee: 'xxxx xxxx',
};

const getLocaleFromMarket = (market: Market) => {
  switch (market) {
    case Market.SE:
      return 'sv';
    case Market.DK:
      return 'da';
    case Market.FR:
      return 'fr';
    case Market.EE: // Estonia uses English only for now
    default:
      return 'en';
  }
};

export const useFormatting = () => {
  const { config } = useContext(UserContext);
  const { formatMessage } = useIntl();

  const locale = getLocaleFromMarket(config.market);

  const formatCurrency = (amount: number) =>
    Intl.NumberFormat(locale, {
      currency: config.currency,
      style: 'currency',
      notation: 'standard',
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
      ...(config.currency
        ? {
            style: 'currency',
            currency: config.currency,
          }
        : {}),
    }).format(amount);

  const formatDate = (date: string | Date | number) =>
    Intl.DateTimeFormat(locale, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(new Date(date));

  const formatRelativeDate = (date: string | Date | number) => {
    const d = new Date(date);

    if (isToday(d)) {
      return formatMessage({ id: 'Today' });
    }

    if (isYesterday(d)) {
      return formatMessage({ id: 'Yesterday' });
    }

    return formatDate(date);
  };

  const formatTime = (date: string | Date | number) =>
    Intl.DateTimeFormat(locale, { hour: '2-digit', minute: '2-digit', hour12: false }).format(
      new Date(date),
    );

  const formatDateTime = (date: string | Date | number) =>
    Intl.DateTimeFormat(locale, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    }).format(new Date(date));

  const formatNumber = (number: number) => Intl.NumberFormat(locale).format(number);

  const formatPhoneNumber = (phoneNumber: string) => {
    const formatter = PHONE_NUMBER_FORMAT[config.market];
    const countryCode = marketToCountryAreaCode(config.market);

    const regex = new RegExp(`^((00|\\+)${countryCode}|0)`, 'g');

    let sanitzedNumber = phoneNumber.replace(regex, '');

    if ([Market.SE, Market.FR].includes(config.market)) {
      sanitzedNumber = `0${sanitzedNumber}`;
    }

    let result = '';
    [...formatter].forEach((char) => {
      result += char === 'x' ? sanitzedNumber[0] : ' ';
      if (char === 'x') {
        sanitzedNumber = sanitzedNumber.slice(1);
      }
    });

    // If somehow the phone number is not at the expected format, we append the rest so
    // it does not appear truncated
    result += sanitzedNumber;

    return result;
  };

  const formatTimeAgo = (inputDate: string | Date | number): string => {
    const now = new Date();
    const date = new Date(inputDate);
    const diff = now.getTime() - date.getTime();

    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 60) {
      return formatMessage({ id: 'shared.minutes-ago' }, { count: minutes });
    }
    if (hours < 24) {
      return formatMessage({ id: 'shared.hours-ago' }, { count: hours });
    }
    if (days === 1) {
      return formatMessage({ id: 'Yesterday' });
    }
    return formatDate(date);
  };

  return {
    formatCurrency,
    formatDate,
    formatRelativeDate,
    formatTime,
    formatNumber,
    formatPhoneNumber,
    formatDateTime,
    formatTimeAgo,
  };
};
