import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Loader, Notification, Stepper } from '@insurely/ui';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useMount } from 'react-use';

import { FormattedMessage, useIntl } from 'translations';

import { ProductLine } from 'utils/product-line';
import { BaseClientConfig } from 'views/Config/config.types';

import { FormValues } from '../../type';
import { useFormRequests } from '../../useFormRequests';

import styles from './clientForm.module.css';
import { Step1 } from './Steps/Step1';
import { Step2 } from './Steps/Step2';
import { Step3 } from './Steps/Step3';
import { useFormResolver } from './useFormResolver';

interface Props {
  onClose: () => void;
  onSuccess: () => void;
  mode: 'creation' | 'edit';
  defaultValues?: FormValues;
  existingClients: BaseClientConfig[];
}

const DEFAULT_FORM = {
  clientName: '',
  configurationName: '',
  minimumApiVersion: '',
  baseUrl: '',
  logRetention: 14,
  clientCompanies: [],
  insuranceTypes: [],
  insuranceSubTypes: [],
  allowedOrigins: [],
  wealthTypes: [],
  allowAnonymousRetention: false,
  filterOtherInsuredPerson: false,
  forceMtls: false,
  getPolicyDocuments: false,
  hasDpa: false,
  isDisabled: false,
  productLine: ProductLine.INSURANCE,
  productInterface: 'hub',
  logotype: '',
} satisfies Omit<FormValues, 'language' | 'market'>;

export const ClientForm = ({ onClose, defaultValues, onSuccess, mode, existingClients }: Props) => {
  const { formatMessage } = useIntl();

  const formRef = useRef<HTMLDivElement>(null);
  const [step, setStep] = useState(0);

  const { resolver } = useFormResolver({ step, existingClients });

  const {
    register,
    control,
    handleSubmit,
    watch,
    resetField,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: defaultValues ?? DEFAULT_FORM,
    resolver: zodResolver(resolver),
  });

  const market = watch('market');
  const productLine = watch('productLine');

  const { formFirstStep, formSecondStep, createClient, updateClient } = useFormRequests({
    onSuccess,
    market,
    productLine,
  });

  const { doFetchFirstStepOptions, firstStepOptions, isLoadingFirstStepOptions } = formFirstStep;
  const { doFetchSecondStepOptions, secondStepOptions, isLoadingSecondStepOptions } =
    formSecondStep;
  const { isCreatingClient, errorCreatingClient, doCreateClient } = createClient;
  const { isUpdatingExtendedClient, errorUpdatingExtendedClient, doUpdateExtendedClient } =
    updateClient;

  useMount(() => {
    doFetchFirstStepOptions();
  });

  useEffect(() => {
    formRef.current?.scrollTo({ top: 0 });
  }, [step]);

  useEffect(() => {
    doFetchSecondStepOptions();
  }, [doFetchSecondStepOptions, market, productLine]);

  const handleOnSubmit = (data: FormValues) => {
    if (step < 2) {
      setStep((cur) => cur + 1);
    } else if (mode === 'creation') {
      doCreateClient(data);
    } else if (mode === 'edit' && defaultValues) {
      doUpdateExtendedClient({ config: data, defC: defaultValues });
    }
  };

  const handleOnBack = () => {
    if (step === 0) {
      onClose();
      return;
    }
    setStep((cur) => cur - 1);
  };

  const isClientNameExisting =
    existingClients.some((c) => c.clientName === watch('clientName')) &&
    watch('clientName').length > 0;

  const renderStep = () => {
    if (!firstStepOptions) {
      return null;
    }

    switch (step) {
      case 0: {
        const [markets, languages] = firstStepOptions;

        return (
          <Step1
            formUtils={{ control, register, errors, watch, resetField }}
            markets={markets}
            languages={languages}
            metadata={{ isClientNameExisting, mode }}
          />
        );
      }
      case 1: {
        if (!secondStepOptions || isLoadingSecondStepOptions) {
          return null;
        }
        const [insuranceTypes, insuranceSubTypes, wealthTypes, companies] = secondStepOptions;

        return (
          <Step2
            companies={companies}
            insuranceSubTypes={insuranceSubTypes}
            insuranceTypes={insuranceTypes}
            wealthTypes={wealthTypes}
            formUtils={{ errors, control, register, watch, resetField, setValue }}
            metadata={{ mode }}
          />
        );
      }
      case 2:
        return <Step3 values={getValues()} />;
      default:
        return null;
    }
  };

  return (
    <form className={styles.wrapper} onSubmit={handleSubmit(handleOnSubmit)}>
      <div className={styles.wrapStepper}>
        <Stepper
          steps={[
            {
              label: formatMessage({ id: 'page.config.client-form.step.type' }),
              value: 'workspace',
            },
            {
              label: formatMessage({ id: 'page.config.client-form.step.configuration' }),
              value: 'configuration',
            },
            {
              label: formatMessage({ id: `page.config.client-form.step.confirm.${mode}` }),
              value: 'confirm',
            },
          ]}
          currentStep={step}
        />
      </div>
      {((isLoadingFirstStepOptions && step === 0) ||
        (isLoadingSecondStepOptions && step === 1)) && (
        <div className={styles.loaderWrapper}>
          <Loader.Content />
        </div>
      )}
      <div ref={formRef} className={styles.formContent}>
        {renderStep()}
      </div>
      <div className={styles.buttons}>
        <Button onClick={handleOnBack} variant="secondary">
          {step === 0 ? (
            <FormattedMessage id="page.config.client-form.cancel" />
          ) : (
            <FormattedMessage id="page.config.client-form.back" />
          )}
        </Button>
        <Button type="submit" loading={isCreatingClient || isUpdatingExtendedClient}>
          {step === 2 ? (
            <FormattedMessage id={`page.config.client-form.save.${mode}`} />
          ) : (
            <FormattedMessage id="page.config.client-form.next" />
          )}
        </Button>
      </div>
      {(!!errorCreatingClient || !!errorUpdatingExtendedClient) && (
        <Notification
          headline={formatMessage({ id: 'page.config.client-form.error' })}
          status="error"
        />
      )}
    </form>
  );
};
