import { Notification } from '@insurely/ui';
import { useSearchParams } from 'react-router-dom';

import { useAsyncFn, useMount } from 'react-use';

import { enableAccount } from 'client/client';
import { OVERVIEW_ROUTE } from 'constants/routes';

import { useNavigateQueryParams } from 'hooks/useNavigateQueryParams';
import { logError } from 'utils/datadog';

import LoginLoader from '../comp/LoginLoader';

import styles from '../login.module.css';

export const EnableAccount = () => {
  const navigate = useNavigateQueryParams();
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  const [{ loading, error }, doEnableAccount] = useAsyncFn((t: string) =>
    enableAccount(t)
      .then(() => navigate(`/${OVERVIEW_ROUTE}`, { cleanQueryParams: true }))
      .catch((err) => {
        const { data } = err.response;
        const nErr = new Error(data?.localizedMessage || data?.message, { cause: err });

        logError(nErr, { token: t });

        throw nErr;
      }),
  );

  useMount(() => {
    if (token) {
      doEnableAccount(token);
    }
  });

  return (
    <>
      {loading && <LoginLoader />}

      <div className={styles.formContainer}>
        {!!error && (
          <Notification className={styles.notification} headline={error.message} status="error" />
        )}
      </div>
    </>
  );
};
