import { LiveboardEmbed, Action } from '@thoughtspot/visual-embed-sdk/react';

import React, { useContext } from 'react';

import UserContext from 'contexts/user/UserContext';

import { logError } from 'utils/datadog';

export const Board = React.memo(
  ({ liveboardId, onLoad }: { liveboardId: string; onLoad: () => void }) => {
    const { user } = useContext(UserContext);

    return (
      <LiveboardEmbed
        usePrerenderedIfAvailable
        frameParams={{ width: '100%', loading: 'lazy' }}
        fullHeight
        liveboardId={liveboardId}
        visibleActions={[Action.Download, Action.DownloadAsXlsx]}
        onLoad={onLoad}
        locale="en-GB"
        onError={(e) => {
          const nErr = new Error('Error in Thoughtspot', { cause: e });
          logError(nErr, {
            liveboardId,
            clientId: user?.selectedClientId,
            customerId: user?.customerId,
          });
        }}
      />
    );
  },
);
