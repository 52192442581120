import { Button, Typography, Modal } from '@insurely/ui';

import { useIntl } from 'translations';

import styles from './deleteModal.module.css';

interface DeleteModalProps {
  deleteCancellation: () => void;
  onHide: () => void;
  isOpen: boolean;
}

export const DeleteModal = ({ deleteCancellation, onHide, isOpen }: DeleteModalProps) => {
  const { formatMessage } = useIntl();
  return (
    <Modal isOpen={isOpen} onClose={onHide}>
      <Typography component="h3" variant="Headline-7">
        {formatMessage({ id: 'Do you want to delete the power of attorney?' })}
      </Typography>
      <Typography component="p" variant="ParagraphBodySmall" className={styles.paragraph}>
        {formatMessage({
          id: 'You will not be able to undo this action and the power of attorney will be invalid.',
        })}
      </Typography>
      <div className={styles.buttonContainer}>
        <Button variant="primary" onClick={onHide}>
          {formatMessage({ id: 'Cancel' })}
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            deleteCancellation();
            onHide();
          }}
        >
          {formatMessage({ id: 'Delete power of attorney' })}
        </Button>
      </div>
    </Modal>
  );
};
