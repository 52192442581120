import { Typography } from '@insurely/ui';

import { useIntl } from 'translations';

import { Parameter } from 'types/insurance';

import { BoolCell, DefaultCell, NotValueCell, NumberCell } from './comp/Cells/Cells';
import { ColumnType, Filters } from './types';
import { applyFiltering, highlightRowCellDifference, sortByGroupAndParameterGroup } from './utils';

interface Props {
  left: {
    parameters: Parameter[];
    columns: ColumnType[];
    company: string;
  };
  right?: {
    parameters: Parameter[];
    columns: ColumnType[];
    company: string;
  };
  filters: Filters;
  onOpenReportProblemModal: ({
    parameter,
    value,
    columnName,
    side,
  }: {
    parameter: string;
    value: string;
    columnName: string;
    side: number;
  }) => void;
}

export const useComparisonParameters = ({
  left,
  right,
  filters,
  onOpenReportProblemModal,
}: Props) => {
  const { formatMessage } = useIntl();

  const rowsWithDifference = highlightRowCellDifference({
    left: left.parameters,
    right: right?.parameters ?? [],
    columns: left.columns.filter((c) => (right?.columns ?? []).includes(c)),
  });

  const valueToCell = (item: Parameter, column: ColumnType, index: number) => {
    const incidence = rowsWithDifference.find(
      (r) => r.rowName === item.parameterName && r.column === column,
    )?.incidence;

    // Getting the proper highlight only if it is match the corresponding side
    // Index 0 = left insurance and index 1 = right insurance
    const highlighting =
      incidence === 'neutral'
        ? incidence
        : (index === 0 && incidence === 'left') || (index === 1 && incidence === 'right')
          ? 'win'
          : null;

    switch (column) {
      case 'protection':
        return <DefaultCell item={item} searchHighlight={filters.search} />;
      case 'included':
        return (
          <BoolCell
            item={item}
            highlighting={highlighting}
            onClickReportModal={(value: string) =>
              onOpenReportProblemModal({
                parameter: item.parameterName,
                value,
                columnName: formatMessage({ id: 'page.comparison.column.included' }),
                side: index,
              })
            }
          />
        );
      case 'deductible':
        return (
          <NumberCell
            displayValue={item.parameterDeductible?.displayValue}
            type={item.parameterDeductible?.deductibleType}
            description={item.parameterDeductible?.description}
            highlighting={highlighting}
            onClickReportModal={(value: string) =>
              onOpenReportProblemModal({
                parameter: item.parameterName,
                value,
                columnName: formatMessage({ id: 'page.comparison.column.deductible' }),
                side: index,
              })
            }
          />
        );
      case 'compensation':
        return (
          <NumberCell
            displayValue={item.parameterLimit?.displayValue}
            type={item.parameterLimit?.limitType}
            description={item.parameterLimit?.description}
            highlighting={highlighting}
            onClickReportModal={(value: string) =>
              onOpenReportProblemModal({
                parameter: item.parameterName,
                value,
                columnName: formatMessage({ id: 'page.comparison.column.compensation' }),
                side: index,
              })
            }
          />
        );
      default:
        return <NotValueCell />;
    }
  };

  const itemToRow = (items: Array<{ content: Parameter | undefined; columns: ColumnType[] }>) => {
    const firstCell = items.find((e) => e.content !== undefined)?.content as Parameter;

    return [
      [valueToCell(firstCell, 'protection', -1)],
      ...items.map((item, index) => {
        if (item.content) {
          return item.columns.map((c) => valueToCell(item.content as Parameter, c, index));
        }
        return item.columns.map(() => <NotValueCell />);
      }),
    ];
  };

  const { filteredLeft, filteredRight } = applyFiltering({
    left: left.parameters,
    right: right?.parameters ?? [],
    filters,
  });

  // Merge both lists, order parameter by name, groupOrder then order. This gives
  // a list of all possible parameter names that we can later try to get for each list.
  // This makes the handling of a missing parameter in a list easy to do.
  const uniqueParameterNames = [
    ...new Set(
      [...filteredLeft, ...filteredRight]
        .map((e) => ({
          parameterDisplayName: e.parameterDisplayName,
          parameterName: e.parameterName,
          parameterGroupOrder: e.parameterGroupOrder,
          parameterOrder: e.parameterOrder,
        }))
        .filter((e) => {
          const search = filters.search
            ? e.parameterDisplayName.toLowerCase().includes(filters.search.toLowerCase())
            : true;
          const existLeft =
            filteredLeft.length === 0 ||
            filteredLeft.some((l) => l.parameterName === e.parameterName);
          // If there is no parameter for the right insurance, we assume that there is no right insurance so
          // we won't try to find the parameter
          const existRight =
            filteredRight.length === 0 ||
            filteredRight.some((r) => r.parameterName === e.parameterName);
          // Filters out the parameter if it does not exist for both right and left insurances
          // Ignored if right insurance is not provided
          const existForBothRightAndLeft = existLeft && existRight;

          return search && existForBothRightAndLeft;
        })
        .sort((a, b) => a.parameterDisplayName.localeCompare(b.parameterDisplayName))
        .sort(sortByGroupAndParameterGroup)
        .map((e) => e.parameterName),
    ),
  ];

  const computeGroupedRows = () =>
    uniqueParameterNames
      .reduce(
        (acc, parameterName) => {
          const leftItem = left.parameters.find((e) => e.parameterName === parameterName);
          const rightItem = right
            ? right.parameters.find((e) => e.parameterName === parameterName)
            : null;
          const parameterGroup = (leftItem?.parameterGroup ?? rightItem?.parameterGroup) as string;

          const groupKeyIndex = acc.findIndex((a) => a.title === parameterGroup);
          // undefined = the list of parameter exist but not that parameter in it
          // null = the list of parameter does not exist (table only show one column)
          const items = [
            { content: leftItem, columns: left.columns },
            { content: rightItem, columns: right?.columns },
          ].filter((e) => e.content !== null) as Array<{
            content: Parameter | undefined;
            columns: ColumnType[];
          }>;

          if (groupKeyIndex === -1) {
            acc.push({
              title: parameterGroup,
              rows: [itemToRow(items)],
            });
          } else {
            acc[groupKeyIndex].rows.push(itemToRow(items));
          }

          return acc;
        },
        [] as Array<{ title: string; rows: React.ReactNode[][][] }>,
      )
      .map((rows) => ({
        title: (
          <div>
            <Typography variant="Headline-8" component="p">
              {rows.title}
            </Typography>
          </div>
        ),
        rows: rows.rows,
      }));

  return {
    groupedRows: computeGroupedRows(),
  };
};
