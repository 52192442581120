import { useMemo } from 'react';

import { z } from 'zod';

import { useIntl } from 'translations';

import { Language } from 'translations/types';
import { Market } from 'types/v3';
import { ProductLine } from 'utils/product-line';
import { BaseClientConfig } from 'views/Config/config.types';

interface Props {
  step: number;
  existingClients: BaseClientConfig[];
}

export const useFormResolver = ({ step, existingClients }: Props) => {
  const { formatMessage } = useIntl();

  const missingField = formatMessage({ id: 'page.config.client-form.field-error' });

  const step1Resolver = z.object({
    clientName: z.string().min(1, { message: missingField }),
    configurationName: z
      .string()
      .min(1, { message: missingField })
      .regex(/^[a-z0-9]+(-[a-z0-9]+)*$/, {
        message: formatMessage({ id: 'page.config.client-form.field-error.configurationName' }),
      })
      .refine((data) => !existingClients.some((c) => c.configurationName === data), {
        message: formatMessage({
          id: 'page.config.client-form.field-error.configurationName.existing',
        }),
      }),
    productLine: z.nativeEnum(ProductLine),
    productInterface: z.string(),
    market: z.nativeEnum(Market, { message: missingField }),
    language: z.nativeEnum(Language, { message: missingField }),
    minimumApiVersion: z.string().min(1, { message: missingField }),
    logotype: z.string(),
  });

  const step2Resolver = z.object({
    insuranceTypes: z.array(z.string()),
    insuranceSubTypes: z.array(z.string()),
    clientCompanies: z.array(z.string()),
    wealthTypes: z.array(z.string()),
    productInterface: z.string(),
    baseUrl: z.string(),
    logRetention: z
      .any()
      .refine(
        (val) =>
          !Number.isNaN(parseFloat(val)) && Number.isFinite(Number(val)) && parseFloat(val) >= 1,
        { message: missingField },
      ),
    allowedOrigins: z.array(z.string()),
    allowAnonymousRetention: z.boolean(),
    getPolicyDocuments: z.boolean(),
    forceMtls: z.boolean(),
    hasDpa: z.boolean(),
    isDisabled: z.boolean(),
  });

  const computeResolver = useMemo(() => {
    switch (step) {
      case 0:
        return step1Resolver;
      case 1:
        return step2Resolver.refine(
          (data) => (data.productInterface === 'hub' ? !!data.baseUrl : true),
          {
            message: missingField,
            path: ['baseUrl'],
          },
        );
      case 2:
      default:
        return step1Resolver.merge(step2Resolver);
    }
  }, [step, step1Resolver, step2Resolver, missingField]);

  return { resolver: computeResolver };
};
