import { Config } from 'types/v3';

export enum ProductLine {
  INSURANCE = 'insurance',
  PENSION = 'pension',
}

export const computeProductTypeFromConfig = (config: Config) => {
  if (config.insuranceConfig) {
    return ProductLine.INSURANCE;
  }
  if (config.pensionConfig) {
    return ProductLine.PENSION;
  }
  return undefined;
};
