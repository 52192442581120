import {
  DefaultExpandableSection,
  Link,
  IconDownload,
  Typography,
  Plate,
  Icon,
} from '@insurely/ui';
import { useContext, useMemo } from 'react';

import { Page } from 'components/Page/Page';

import UserContext from 'contexts/user/UserContext';
import { FormattedMessage, useIntl } from 'translations';

import { downloadFile } from 'utils/downloadFile';

import { getQuestions } from '../FAQ/faqQuestions';

import styles from './getStarted.module.css';

export function GetStarted() {
  const { productLine } = useContext(UserContext);
  const { formatMessage } = useIntl();

  const questions = useMemo(
    () => getQuestions(formatMessage, productLine),
    [productLine, formatMessage],
  );

  const downloadGetStartedGuide = async () => {
    const response = await fetch(
      'https://assets.insurely.com/guides/Insurely_TipsTrix_SST_Broschyr.pdf',
    );
    const blob = await response.blob();

    downloadFile({ data: blob, name: 'Insurely_TipsTrix_SST_Broschyr' });
  };

  return (
    <Page title={formatMessage({ id: 'page.get-started.title' })}>
      <div className={styles.container}>
        <div>
          <Typography component="h1" variant="Headline-3">
            <FormattedMessage id="page.get-started.title" />
          </Typography>
          <Typography className={styles.preamble} component="p" variant="ParagraphBody">
            <FormattedMessage id="page.get-started.description" />
          </Typography>
        </div>
        <Plate className={styles.plate}>
          <Typography className={styles.subTitle} component="h2" variant="Headline-5">
            <FormattedMessage id="page.get-started.about-tool.title" />
          </Typography>
          <Typography className={styles.paragraph} component="p" variant="ParagraphBodySmall">
            <FormattedMessage id="page.get-started.about-tool.content" />
          </Typography>
          <Typography className={styles.paragraph} component="p" variant="ParagraphBodySmall">
            <FormattedMessage id="page.get-started.about-tool.list-header" />
          </Typography>
          <ul>
            {[
              formatMessage({ id: 'page.get-started.about-tool.name' }),
              formatMessage({
                id: 'page.get-started.about-tool.insurance-amount',
              }),
              formatMessage({
                id: 'page.get-started.about-tool.insurance-object',
              }),
              formatMessage({
                id: 'page.get-started.about-tool.price',
              }),
              formatMessage({
                id: 'page.get-started.about-tool.coverage',
              }),
            ].map((item) => (
              <li key={item} className={styles.informationListItem}>
                <Icon name="success" size={20} color="var(--green)" />
                <Typography component="p" variant="ParagraphBodySmall">
                  {item}
                </Typography>
              </li>
            ))}
          </ul>
        </Plate>
        <Plate className={styles.plate}>
          <Typography className={styles.subTitle} component="h2" variant="Headline-5">
            <FormattedMessage id="page.get-started.tips.title" />
          </Typography>
          <Typography className={styles.paragraph} component="p" variant="ParagraphBodySmall">
            <FormattedMessage id="page.get-started.tips.content" />
          </Typography>

          <Link
            href="https://assets.insurely.com/guides/Insurely_TipsTrix_SST_Broschyr.pdf"
            target="_blank"
            startIcon={IconDownload}
            linkProps={{
              download: true,
              onClick: (e) => {
                e.preventDefault();
                downloadGetStartedGuide();
              },
            }}
          >
            <FormattedMessage id="page.get-started.tips.download-pdf" />
          </Link>
        </Plate>
        <Plate className={styles.plate}>
          <Typography className={styles.subTitle} component="h2" variant="Headline-5">
            <FormattedMessage id="page.get-started.faq.title" />
          </Typography>

          {questions.map((question) => (
            <DefaultExpandableSection key={question.question} title={question.question}>
              {question.answer}
            </DefaultExpandableSection>
          ))}
        </Plate>
      </div>
    </Page>
  );
}
