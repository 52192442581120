import { Button, Icon, Typography } from '@insurely/ui';

import classNames from 'classnames';

import styles from './infoBox.module.css';

interface InfoBoxProps {
  title: string;
  content: React.ReactNode;
  button: { label: string; onClick: () => void };
  fullWidth?: boolean;
  maxWidth?: number;
  className?: string;
}

export const InfoBox = ({
  title,
  content,
  button,
  fullWidth,
  maxWidth,
  className,
}: InfoBoxProps) => (
  <div
    className={classNames(styles.wrapper, { [styles.fullWidth]: fullWidth }, className)}
    style={{ maxWidth }}
  >
    <Icon name="info" size={28} />
    <div className={styles.contentContainer}>
      <Typography component="h4" variant="Headline-8">
        {title}
      </Typography>
      {content}
      <Button
        variant="secondary"
        size="small"
        style={{ marginTop: 'var(--space-2)' }}
        onClick={button.onClick}
      >
        {button.label}
      </Button>
    </div>
  </div>
);
