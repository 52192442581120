import { useToastContext } from '@insurely/ui';
import { compareDesc } from 'date-fns';
import { useContext } from 'react';
import { useAsyncFn } from 'react-use';

import UserContext from 'contexts/user/UserContext';
import Utils from 'services/utils';

import { useIntl } from 'translations';
import { InsuranceSubType, InsuranceType } from 'types/v3/Insurance';
import { logError } from 'utils/datadog';

import { fetchPackageVersions } from './api';
import { getInsuranceTypeFromSubType } from './utils';

interface Props {
  insuranceSubType: InsuranceSubType | null;
  insuranceType?: InsuranceType;
}

export const usePackageVersions = ({ insuranceSubType, insuranceType }: Props) => {
  const { config } = useContext(UserContext);
  const { clientId } = Utils.getConfigItem(config) || {};
  const { dispatch } = useToastContext();
  const { formatMessage } = useIntl();

  const handleFetchPackageVersions = ({
    company,
    packageName,
  }: {
    company: string;
    packageName: string;
  }) => {
    if (!insuranceSubType) {
      const nErr = new Error('Failed to fetch package versions due to no insuranceSubType');
      logError(nErr, { company, packageName, insuranceType });
      return Promise.reject(nErr);
    }
    return fetchPackageVersions({
      clientId: clientId as string,
      body: {
        coverageCompany: company,
        market: config.market,
        insuranceType: insuranceType ?? getInsuranceTypeFromSubType(insuranceSubType),
        insuranceSubType,
        packageName,
      },
    })
      .then((res) => {
        if (res.length === 0) {
          const err = new Error('Received no package versions while it should at least be one');
          logError(err, {
            company,
            market: config.market,
            insuranceType: insuranceType ?? getInsuranceTypeFromSubType(insuranceSubType),
            insuranceSubType,
            packageName,
          });
          dispatch({
            type: 'ADD_TOAST',
            payload: {
              type: 'error',
              id: 'error-fetching-package-version',
              message: formatMessage({ id: 'page.comparison.table.error-fetching-packages' }),
            },
          });
          throw err;
        }
        return res.sort((a, b) => compareDesc(a.termsDate, b.termsDate));
      })
      .catch((err) => {
        const nErr = new Error('Failed to fetch package versions', { cause: err });
        logError(nErr, { company, packageName, insuranceSubType, insuranceType });
        throw nErr;
      });
  };

  const [
    {
      value: leftPackageVersions,
      loading: isFetchingLeftPackageVersions,
      error: errorFetchingLeftPackageVersions,
    },
    doFetchLeftPackageVersions,
  ] = useAsyncFn(handleFetchPackageVersions, [insuranceSubType]);

  const [
    {
      value: rightPackageVersions,
      loading: isFetchingRightPackageVersions,
      error: errorFetchingRightPackageVersions,
    },
    doFetchRightPackageVersions,
  ] = useAsyncFn(handleFetchPackageVersions, [insuranceSubType]);

  return {
    leftVersions: {
      leftPackageVersions,
      isFetchingLeftPackageVersions,
      doFetchLeftPackageVersions,
      errorFetchingLeftPackageVersions,
    },
    rightVersions: {
      rightPackageVersions,
      isFetchingRightPackageVersions,
      doFetchRightPackageVersions,
      errorFetchingRightPackageVersions,
    },
  };
};
