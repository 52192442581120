import { Button, Card, Loader, Typography } from '@insurely/ui';
import classNames from 'classnames';
import { useMemo, useState } from 'react';

import { useAsync, useAsyncFn } from 'react-use';

import { FormattedMessage, useIntl } from 'translations';

import { logError } from 'utils/datadog';

import { deleteRecordSaleAnswer, getRecordSaleAnswer, updateRecordSale } from '../../../../api';

import styles from './saleCard.module.css';

interface Props {
  collectionId: string;
  insuranceId: string;
}

export const SaleCard = ({ collectionId, insuranceId }: Props) => {
  const { formatMessage } = useIntl();

  const [insuranceSold, setInsuranceSold] = useState<boolean | undefined>(undefined);

  const [{ loading: submittingAnswer, error: errorSubmittingAnswer }, doSubmitAnswer] = useAsyncFn(
    (value: boolean) =>
      updateRecordSale({ insuranceSold: value, collectionId, insuranceId })
        .then(() => setInsuranceSold(value))
        .catch((err) => {
          const nErr = new Error('Failed to record a sale', { cause: err });
          logError(nErr, { collectionId, insuranceId });
          throw nErr;
        }),
  );

  const [{ loading: removingAnswer, error: errorRemovingAnswer }, doRemoveAnswer] = useAsyncFn(() =>
    deleteRecordSaleAnswer({ collectionId, insuranceId })
      .then(() => setInsuranceSold(undefined))
      .catch((err) => {
        const nErr = new Error('Failed to delete a sale', { cause: err });
        logError(nErr, { collectionId, insuranceId });
        throw nErr;
      }),
  );

  const { loading: fetchingInitialAnswer } = useAsync(() =>
    getRecordSaleAnswer({ collectionId, insuranceId })
      .then((data) => setInsuranceSold(data.insuranceSold))
      .catch((err) => {
        const nErr = new Error('Failed to get a sale', { cause: err });
        if (err.response.status !== 404) {
          logError(nErr, { collectionId, insuranceId });
        }
        throw nErr;
      }),
  );

  const answerReaction = insuranceSold === true ? '🎉' : null;

  const title = useMemo(() => {
    switch (insuranceSold) {
      case true:
        return formatMessage({ id: 'page.insurance.record-sale.title.has-sold' });
      case false:
        return formatMessage({ id: 'page.insurance.record-sale.title.has-not-sold' });
      default:
        return formatMessage({ id: 'page.insurance.record-sale.title.question' });
    }
  }, [insuranceSold, formatMessage]);

  const renderContent = () => {
    if (fetchingInitialAnswer || submittingAnswer || removingAnswer) {
      return (
        <div className={styles.spinnerWrapper}>
          <Loader.Content size="small" />
        </div>
      );
    }

    return (
      <>
        <div className={styles.questionTitle}>
          {answerReaction && (
            <Typography variant="Label-2" component="p">
              {answerReaction}
            </Typography>
          )}
          <Typography variant="Label-2" component="p">
            {title}
          </Typography>
        </div>
        <div className={classNames(styles.buttons, styles.hideOnPrint)}>
          {insuranceSold === undefined ? (
            <>
              <Button variant="tertiary" onClick={() => doSubmitAnswer(true)}>
                <FormattedMessage id="page.insurance.record-sale.yes" />
              </Button>
              <Button variant="tertiary" onClick={() => doSubmitAnswer(false)}>
                <FormattedMessage id="page.insurance.record-sale.no" />
              </Button>
            </>
          ) : (
            <Button variant="tertiary" onClick={() => doRemoveAnswer()}>
              <FormattedMessage id="page.insurance.record-sale.remove-sale" />
            </Button>
          )}
        </div>
      </>
    );
  };

  return (
    <Card
      className={classNames(styles.card, { [styles.hideOnPrint]: insuranceSold === undefined })}
    >
      {renderContent()}
      {(!!errorSubmittingAnswer || !!errorRemovingAnswer) && (
        <Typography variant="Label-3" component="p" style={{ color: 'var(--colorError)' }}>
          <FormattedMessage id="page.insurance.record-sale.error" />
        </Typography>
      )}
    </Card>
  );
};
