import { Checkbox, StatusText, Typography } from '@insurely/ui';
import classNames from 'classnames';
import { useContext, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { ADMIN_PANEL_ROUTE, CUSTOMERS_ROUTE } from 'constants/routes';
import UserContext from 'contexts/user/UserContext';
import tableStyles from 'styles/table.module.css';
import { FormattedMessage, useIntl } from 'translations';

import { Permissions } from 'types/v3';
import { snakeCaseToCamelCase } from 'utils/formatters';
import { Target } from 'views/Config/comp/ActionBanner/type';

import { TagsGroup } from 'views/Config/comp/TagsGroup/TagsGroup';
import { MinimalClientConfig } from 'views/Config/config.types';

import configStyles from '../../config.module.css';

import { TabWrapper } from './TabWrapper';
import styles from './userGroupConfig.module.css';

export const ClientsContent = ({
  clients,
  loading,
  onRefresh,
}: {
  loading: boolean;
  clients?: MinimalClientConfig[];
  onRefresh: () => void;
}) => {
  const { formatMessage } = useIntl();
  const { customerId } = useParams() as { customerId: string; userGroupId: string };
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const isInsurelyAdmin = useMemo(
    () => user?.permissions.includes(Permissions.INSURELY_ADMIN),
    [user?.permissions],
  );

  return (
    <TabWrapper loading={loading} refresh={onRefresh} items={clients} target={Target.CLIENTS}>
      {({ filteredItems, handleOnSelectAll, handleOnSelect, selectedItems }) => (
        <table className={tableStyles.table}>
          <thead>
            <tr className={classNames(tableStyles.head, configStyles.tableHeader)}>
              <th style={{ width: '5%' }}>
                <Checkbox
                  aria-label="checkbox-user"
                  checked={selectedItems.length > 0}
                  partial={selectedItems.length > 0 && clients?.length !== selectedItems.length}
                  onCheckedChange={handleOnSelectAll}
                  disabled={clients?.length === 0}
                />
              </th>
              <th aria-labelledby="client name" style={{ width: '40%' }}>
                <FormattedMessage id="page.config.user-group-config.clients.column.name" />
              </th>
              <th aria-labelledby="product type" style={{ width: '40%' }}>
                <FormattedMessage id="page.config.user-group-config.clients.column.product-type" />
              </th>
              <th aria-labelledby="status" style={{ width: '15%' }}>
                <FormattedMessage id="page.config.user-group-config.clients.column.status" />
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredItems.length === 0 && (
              <tr>
                <td colSpan={4} aria-label="empty-row-cell">
                  <div className={configStyles.emptyRow}>
                    <Typography variant="ParagraphBodySmall" component="p">
                      <FormattedMessage id="page.config.user-group-config.clients.empty-table" />
                    </Typography>
                  </div>
                </td>
              </tr>
            )}
            {(filteredItems as MinimalClientConfig[])
              .sort((a, b) => a.clientName.localeCompare(b.clientName))
              .map((c) => (
                <tr
                  className={classNames(tableStyles.row, configStyles.tableRow, {
                    [styles.notClickableRow]: !isInsurelyAdmin,
                  })}
                  key={c.clientId}
                  tabIndex={0}
                  onClick={() => {
                    if (isInsurelyAdmin) {
                      navigate(
                        `/${ADMIN_PANEL_ROUTE}/${CUSTOMERS_ROUTE}/${customerId}/client/${c.clientId}`,
                      );
                    }
                  }}
                >
                  {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
                  <td aria-label="user-select" onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                      aria-label="checkbox-user"
                      checked={selectedItems.some((u) => c.clientId === u)}
                      onCheckedChange={() => handleOnSelect(c.clientId)}
                    />
                  </td>
                  <td>
                    <Typography component="p" variant="ParagraphCaption">
                      {c.clientName}
                    </Typography>
                  </td>
                  <td aria-label="product-types">
                    <Typography component="p" variant="ParagraphCaption">
                      <TagsGroup
                        tags={c.productTypes.map((p) =>
                          formatMessage({ id: `shared.productType.${snakeCaseToCamelCase(p)}` }),
                        )}
                      />
                    </Typography>
                  </td>
                  {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
                  <td aria-label="actions" onClick={(e) => e.stopPropagation()}>
                    <StatusText
                      color={c.disabled ? 'var(--red-1)' : 'var(--green)'}
                      text={formatMessage({
                        id: `page.config.customer-config.users.${c.disabled ? 'inactive' : 'active'}`,
                      })}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </TabWrapper>
  );
};
