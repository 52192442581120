import { InsuranceSubType, InsuranceType } from 'types/v3/Insurance';

import { InsurancePackage } from './api';

export const INSURANCE_SUB_TYPES = [
  // VEHICLE_INSURANCE
  'carInsurance',
  'boatInsurance',
  'trailerInsurance',
  'mopedInsurance',
  'motorcycleInsurance',
  'motorhomeInsurance',
  'caravanInsurance',
  'otherVehicleInsurance',

  // PERSON_INSURANCE
  'childInsurance',
  'pregnancyInsurance',
  'healthInsurance',
  'accidentInsurance',
  'travelInsurance',
  'lifeInsurance',

  // HOUSECONTENT_INSURANCE
  'condoInsurance',
  'villaInsurance',
  'cottageInsurance',
  'farmInsurance',

  // ANIMAL_INSURANCE
  'catInsurance',
  'dogInsurance',
  'otherAnimalInsurance',
  'horseInsurance',

  // GROUP_INSURANCE
  'groupInsurance',

  // INCOME_INSURANCE
  'incomeInsurance',
  'sicknessInsurance',
  'loanInsurance',
];

export const INSURANCE_TYPES = [
  'animalInsurance',
  'vehicleInsurance',
  'houseContentInsurance',
  'personInsurance',
  'incomeInsurance',
  'groupInsurance',
  'generalInsurance',
];

// Method used temporarily to map an insurance sub type to a type
export const getInsuranceTypeFromSubType = (subType: InsuranceSubType): InsuranceType => {
  switch (subType) {
    case 'carInsurance':
    case 'boatInsurance':
    case 'trailerInsurance':
    case 'mopedInsurance':
    case 'motorcycleInsurance':
    case 'motorhomeInsurance':
    case 'caravanInsurance':
    case 'otherVehicleInsurance':
      return 'vehicleInsurance';

    case 'childInsurance':
    case 'pregnancyInsurance':
    case 'healthInsurance':
    case 'accidentInsurance':
    case 'travelInsurance':
    case 'lifeInsurance':
      return 'personInsurance';

    case 'condoInsurance':
    case 'villaInsurance':
    case 'cottageInsurance':
    case 'farmInsurance':
      return 'houseContentInsurance';

    case 'catInsurance':
    case 'dogInsurance':
    case 'otherAnimalInsurance':
    case 'horseInsurance':
      return 'animalInsurance';

    case 'groupInsurance':
      return 'groupInsurance';

    case 'incomeInsurance':
    case 'sicknessInsurance':
    case 'loanInsurance':
      return 'incomeInsurance';

    default:
      return 'generalInsurance';
  }
};

export const findFirstPackageName = ({
  packages,
  insuranceSubType,
  insuranceCompany,
}: {
  packages: InsurancePackage[];
  insuranceSubType: InsuranceSubType;
  insuranceCompany: string;
}) =>
  packages.find(
    (i) => i.insuranceSubType === insuranceSubType && i.insuranceCompany === insuranceCompany,
  )?.packageName;

export const findPackageNames = ({
  packages,
  insuranceSubType,
  insuranceCompany,
}: {
  packages: InsurancePackage[];
  insuranceSubType: InsuranceSubType;
  insuranceCompany: string;
}) =>
  packages.filter(
    (i) => i.insuranceSubType === insuranceSubType && i.insuranceCompany === insuranceCompany,
  );

export const companiesForSubType = ({
  packages,
  insuranceSubType,
}: {
  packages: InsurancePackage[];
  insuranceSubType: InsuranceSubType;
}) =>
  packages.filter((i) => i.insuranceSubType === insuranceSubType).map((i) => i.insuranceCompany);
