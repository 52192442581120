import { Loader, Typography } from '@insurely/ui';
import { useContext, useEffect, useState } from 'react';

import { useAsync, useAsyncFn } from 'react-use';

import { ComparisonTable } from 'components/ComparisonTable/ComparisonTable';
import { InsuranceBody, InsuranceBodyOption } from 'components/ComparisonTable/types';
import { Page } from 'components/Page/Page';
import UserContext from 'contexts/user/UserContext';
import Utils from 'services/utils';
import { FormattedMessage, useIntl } from 'translations';
import { InsuranceSubType } from 'types/v3/Insurance';

import { logError } from 'utils/datadog';

import { PostHogAction, PostHogCategory, postHogCapture } from 'utils/posthog';

import { fetchCoveragePackages, getParameters } from './api';
import { CompaniesPicker } from './comp/CompaniesPicker/CompaniesPicker';
import { ErrorBanner } from './comp/ErrorBanner/ErrorBanner';
import styles from './comparisonPage.module.css';
import { usePackageVersions } from './usePackageVersions';
import { findFirstPackageName, findPackageNames, getInsuranceTypeFromSubType } from './utils';

export const ComparisonPage = () => {
  const { config, user } = useContext(UserContext);
  const { clientId } = Utils.getConfigItem(config) || {};
  const { formatMessage } = useIntl();

  // Selectors values
  const [selectedInsuranceSubType, setSelectedInsuranceSubType] = useState<InsuranceSubType | null>(
    null,
  );

  // Comparison table values
  const [comparedCompany1, setComparedCompany1] = useState<InsuranceBody | null>(null);
  const [comparedCompany2, setComparedCompany2] = useState<InsuranceBody | null>(null);
  const [comparedInsuranceSubType, setComparedInsuranceSubType] = useState<InsuranceSubType | null>(
    null,
  );

  const { leftVersions, rightVersions } = usePackageVersions({
    insuranceSubType: selectedInsuranceSubType,
  });

  const {
    loading: isLoadingCoveragePackages,
    value: coveragePackages,
    error: errorLoadingCoveragePackages,
  } = useAsync(() =>
    fetchCoveragePackages({
      market: config.market,
      clientId: user?.selectedClientId as string,
    }).catch((err) => {
      const nErr = new Error('Failed to fetch coverage packages', { cause: err });
      logError(nErr, { market: config.market, clientId: user?.selectedClientId });
      throw nErr;
    }),
  );

  const fetchParameters = (body: InsuranceBody) =>
    getParameters({
      insuranceName: body.packageName,
      insuranceType: body.insuranceType,
      insuranceSubType: body.insuranceSubType,
      insuranceCompany: body.insuranceCompany as string,
      versionId: body.packageVersionId,
      clientId: clientId ?? '',
    }).catch((err) => {
      const nErr = new Error('Failed to get parameters', { cause: err });
      logError(nErr, { ...body });
      throw nErr;
    });

  const [
    { loading: isLoadingRight, value: right, error: errorRight },
    doFetchRightInsuranceParameters,
  ] = useAsyncFn(fetchParameters, [clientId]);

  const [
    { loading: isLoadingLeft, value: left, error: errorLeft },
    doFetchLeftInsuranceParameters,
  ] = useAsyncFn(fetchParameters, [clientId]);

  const formatInsuranceBody = (
    company: string,
    packageName: string,
    packageVersionId: string,
  ): InsuranceBody | null => {
    if (!selectedInsuranceSubType) {
      return null;
    }
    const insuranceType = getInsuranceTypeFromSubType(selectedInsuranceSubType);

    return {
      insuranceCompany: company,
      packageName,
      insuranceSubType: selectedInsuranceSubType,
      insuranceType,
      packageVersionId,
    };
  };

  const handleOnSetComparedLeft = (insuranceCompany: string, packageName?: string) => {
    if (!selectedInsuranceSubType) {
      return;
    }
    const leftPackageName =
      packageName ??
      findFirstPackageName({
        packages: coveragePackages || [],
        insuranceSubType: selectedInsuranceSubType,
        insuranceCompany,
      }) ??
      '';

    leftVersions
      .doFetchLeftPackageVersions({ company: insuranceCompany, packageName: leftPackageName })
      .then((res) => {
        setComparedCompany1(
          formatInsuranceBody(insuranceCompany, leftPackageName, res[0].versionId),
        );
        return res;
      });
  };

  const handleOnSetComparedRight = (insuranceCompany: string, packageName?: string) => {
    if (!selectedInsuranceSubType) {
      return;
    }

    const rightPackageName =
      packageName ??
      findFirstPackageName({
        packages: coveragePackages || [],
        insuranceSubType: selectedInsuranceSubType,
        insuranceCompany,
      }) ??
      '';

    rightVersions
      .doFetchRightPackageVersions({ company: insuranceCompany, packageName: rightPackageName })
      .then((res) => {
        setComparedCompany2(
          formatInsuranceBody(insuranceCompany, rightPackageName, res[0].versionId),
        );
        return res;
      });
  };

  useEffect(() => {
    if (comparedCompany1) {
      doFetchLeftInsuranceParameters(comparedCompany1);
    }
  }, [comparedCompany1, doFetchLeftInsuranceParameters]);

  useEffect(() => {
    if (comparedCompany2) {
      doFetchRightInsuranceParameters(comparedCompany2);
    }
  }, [comparedCompany2, doFetchRightInsuranceParameters]);

  const handleOnClickCompare = ({
    insuranceSubType,
    selectedCompany1,
    selectedCompany2,
  }: {
    insuranceSubType: InsuranceSubType;
    selectedCompany1: string;
    selectedCompany2: string;
  }) => {
    postHogCapture(`tracking:${PostHogAction.CLICK}`, {
      action: PostHogAction.CLICK,
      category: PostHogCategory.COMPARISON,
      object: 'compareInsurances',
    });

    setComparedInsuranceSubType(insuranceSubType);

    handleOnSetComparedLeft(selectedCompany1);
    handleOnSetComparedRight(selectedCompany2);
  };

  const computeInsuranceOptions = (company: string): InsuranceBodyOption[] => {
    if (!comparedInsuranceSubType) {
      return [];
    }
    return findPackageNames({
      packages: coveragePackages || [],
      insuranceSubType: comparedInsuranceSubType,
      insuranceCompany: company,
    }).map((e) => ({
      insuranceName: e.packageName,
      packageName: e.packageName,
      insuranceSubType: e.insuranceSubType,
      insuranceType: e.insuranceType,
      insuranceCompany: e.insuranceCompany,
    }));
  };

  const shouldShowAnyContent = comparedCompany1 && comparedCompany2;

  const shouldShowErrorBanner =
    !isLoadingLeft &&
    !isLoadingRight &&
    ((right && right.length === 0) || (left && left.length === 0) || !!errorLeft || !!errorRight);

  const shouldShowSpinner = isLoadingRight && isLoadingLeft;

  const shouldShowTable = !!right && !!left && right.length > 0 && left.length > 0;

  return (
    <Page title={formatMessage({ id: 'Comparison link' })}>
      <div className={styles.title}>
        <Typography variant="Headline-3" component="h3">
          {formatMessage({ id: 'Compare protection, deductibles and compensation' })}
        </Typography>
        <Typography variant="ParagraphBody" component="p">
          {formatMessage({
            id: 'Below you can compare different insurance products with each other.',
          })}
        </Typography>
      </div>
      {isLoadingCoveragePackages && (
        <div style={{ position: 'relative' }}>
          <Loader.Content size="default" />
        </div>
      )}
      {errorLoadingCoveragePackages && (
        <div style={{ color: 'var(--red-1)' }}>
          <Typography component="span" variant="Label-2">
            <FormattedMessage id="page.comparison.error-fetch-packages" />
          </Typography>
        </div>
      )}
      {!isLoadingCoveragePackages && !errorLoadingCoveragePackages && (
        <CompaniesPicker
          coveragePackages={coveragePackages ?? []}
          isLoading={isLoadingLeft || isLoadingRight}
          onCompare={handleOnClickCompare}
          selectedInsuranceSubType={selectedInsuranceSubType}
          setSelectedInsuranceSubType={setSelectedInsuranceSubType}
        />
      )}
      {shouldShowAnyContent && (
        <>
          <div className={styles.dividerContainer}>
            <div className={styles.divider} />
          </div>
          {shouldShowErrorBanner && selectedInsuranceSubType && (
            <ErrorBanner
              insuranceSubType={selectedInsuranceSubType}
              company1={!left || left.length === 0 ? comparedCompany1.insuranceCompany : undefined}
              company2={
                !right || right.length === 0 ? comparedCompany2.insuranceCompany : undefined
              }
            />
          )}
          <div className={styles.tableContainer}>
            {shouldShowSpinner && <Loader.Content size="default" />}
            {shouldShowTable && (
              <ComparisonTable
                leftInsurance={{
                  isLoading: isLoadingLeft,
                  insuranceOptions: computeInsuranceOptions(
                    comparedCompany1.insuranceCompany as string,
                  ),
                  versionOptions: leftVersions.leftPackageVersions ?? [],
                  parameters: left,
                  value: comparedCompany1,
                  onChange: (ins) => {
                    if (ins.packageName !== comparedCompany1.packageName) {
                      handleOnSetComparedLeft(ins.insuranceCompany as string, ins.packageName);
                    } else {
                      setComparedCompany1(ins);
                    }
                  },
                }}
                rightInsurance={{
                  isLoading: isLoadingRight,
                  insuranceOptions: computeInsuranceOptions(
                    comparedCompany2.insuranceCompany as string,
                  ),
                  versionOptions: rightVersions.rightPackageVersions ?? [],
                  parameters: right,
                  value: comparedCompany2,
                  onChange: (ins) => {
                    if (ins.packageName !== comparedCompany2.packageName) {
                      handleOnSetComparedRight(ins.insuranceCompany as string, ins.packageName);
                    } else {
                      setComparedCompany2(ins);
                    }
                  },
                }}
              />
            )}
          </div>
        </>
      )}
    </Page>
  );
};
