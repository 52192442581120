import { useContext, useEffect } from 'react';
import { useLocalStorage } from 'react-use';

import UserContext from 'contexts/user/UserContext';

export const SELECTED_WORKSPACE_ID_KEY = 'selectedWorkspaceId';

export const useSelectedWorkspaceId = () => {
  const { user } = useContext(UserContext);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useLocalStorage<string | undefined>(
    SELECTED_WORKSPACE_ID_KEY,
    undefined,
  );

  useEffect(() => {
    if (user?.hubClients && !user?.hubClients.some((hC) => hC.clientId === selectedWorkspaceId)) {
      setSelectedWorkspaceId(user.selectedClientId);
    }
  }, [selectedWorkspaceId, setSelectedWorkspaceId, user?.hubClients, user?.selectedClientId]);

  return { selectedWorkspaceId, setSelectedWorkspaceId };
};
