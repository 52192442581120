import { Language } from 'translations/types';

import { InsuranceSubType, InsuranceType } from './Insurance';

export enum Permissions {
  READ_CUSTOMER_CLIENTS = 'READ_CUSTOMER_CLIENTS',
  INSURELY_ADMIN = 'INSURELY_ADMIN',
  READ_COLLECTION_STATISTICS = 'READ_COLLECTION_STATISTICS',
  READ_TEAM_STATISTICS = 'READ_TEAM_STATISTICS',
  READ_USER_JOURNEY_STATISTICS = 'READ_USER_JOURNEY_STATISTICS',
  READ_ALL_CANCELLATIONS = 'READ_ALL_CANCELLATIONS',
}

export interface SSTParameter {
  id: number;
  clientId: string;
  insuranceCompany: string;
  insuranceName: string;
  insuranceSubType: InsuranceSubType;
  insuranceType: InsuranceType;
}

export enum Market {
  DK = 'dk',
  SE = 'se',
  FR = 'fr',
  EE = 'ee',
}

export enum CountryCode {
  FR = 'FR',
  DK = 'DK',
  SE = 'SE',
  EE = 'EE',
}

export enum CountryAreaCode {
  FR = '33',
  DK = '45',
  SE = '46',
  EE = '372',
}

export interface ConfigItem {
  clientId: string;
  baseUrl: string;
  logotype: string;
  language: Language;
}

export interface Config {
  advisoryParameters: SSTParameter[];
  cancellationEnabled: boolean;
  cancellationServiceEnabled: boolean;
  currency: string;
  retention: number;
  market: Market;
  smsServiceEnabled: boolean;
  viewCancellationAuthenticationMethod: 'STANDARD' | 'BANK_ID';
  insuranceConfig?: ConfigItem;
  pensionConfig?: ConfigItem;
  newInsuranceNumberMandatory: boolean;
}

export interface User {
  selectedClientId: string;
  creationDate: string;
  customerId: string;
  email: string;
  enabled: boolean;
  permissions: Permissions[];
  uuid: string;
  hubClients: Array<{ clientId: string; clientName: string }>;
}

export interface Advisor {
  email: string;
  permissions: Permissions[];
  userUuid: string;
}

export interface UserAttributes {
  qrRequirementConfirmation: boolean;
}

export interface CollectionStartedResponse {
  collectionId: string;
  creationDate: number;
  insuranceCompany: string;
  sessionId: string;
  status: CollectionStatus;
  numberOfInsurances: number;
  retained: boolean;
  numberOfFilteredInsurances: number;
}

export interface SSTCollectionResponse {
  clientId: string;
  clientName: string;
  collectionId: string;
  creationDate: string;
  incomplete: boolean;
  insuranceCompany: string;
  numberOfFilteredInsurances: number;
  numberOfInsurances: number;
  personalNumber: string;
  sessionId?: string;
  state?: string;
  status: string;
  viewed?: string;
  externalReference?: string;
  assignee?: string;
}

export interface CollectionInfoResponse {
  clientId: string;
  collectionId: string;
  consumerId: string;
  creationDate: string;
  insuranceCompany: string;
  numberOfFilteredInsurances: number;
  numberOfInsurances: number;
  status: CollectionStatus;
  externalReference?: string;
  retained: boolean;
  contactEmail?: string;
  contactPhone?: string;
  marketingConsent?: boolean;
}

export interface CollectionsTableRow extends SSTCollectionResponse {
  insuranceCompanyList: string[];
  externalReferenceList: string[];
  collectionIds: string[];
  assignee: string;
  rowState: string;
  rowIsViewed: boolean;
}

export interface CollectionsTableData {
  rows: CollectionsTableRow[];
  searchHasMoreData: boolean;
}

export interface SSTClientResponse {
  clientId: string;
  clientName: string;
  productType: string;
}

export interface Session {
  advisoryUserUuid: string;
  collectionStartedResponses: CollectionStartedResponse[];
  creationDate: string;
  freeText: string;
  numberOfInsurances: number;
  numberOfFilteredInsurances: number;
  numberOfPensions: number;
  sessionId: string;
  type: 'insurance' | 'pension';
}

export enum CollectionStatus {
  // LOADING
  LOGIN = 'LOGIN',
  COLLECTING = 'COLLECTING',
  // SUCCESS
  COMPLETED = 'COMPLETED',
  COMPLETED_PARTIAL = 'COMPLETED PARTIAL',
  // ERROR
  COMPLETED_EMPTY = 'COMPLETED EMPTY',
  FAILED = 'FAILED',
  WAITING_FOR_AUTHENTICATION = 'WAITING FOR AUTHENTICATION',
  AUTHENTICATION_CANCELLED = 'AUTHENTICATION CANCELLED',
  AUTHENTICATION_CONFLICT = 'AUTHENTICATION CONFLICT',
  AUTHENTICATION_MISMATCH = 'AUTHENTICATION MISMATCH',
  INCORRECT_CREDENTIALS = 'INCORRECT CREDENTIALS',
  CUSTOMER_ENROLLMENT_REQUIRED = 'CUSTOMER ENROLLMENT REQUIRED',
  CONTACT_FORM = 'CONTACT FORM',
  KYC_FORM = 'KYC FORM',
  FAILED_PDF_PARSE = 'FAILED PDF PARSE',
  FAILED_PDF_USER_INPUT = 'FAILED PDF USER INPUT',
  REJECTED_INCORRECT_LOGIN_METHOD = 'REJECTED INCORRECT LOGIN METHOD',
  // HIDE
  RUNNING = 'RUNNING',
  TWO_FACTOR_PENDING = 'TWO FACTOR PENDING',
  CONTACT_FORM_PENDING = 'CONTACT FORM PENDING',
  COLLECTION_COMPLETED = 'COLLECTION COMPLETED',
}

export enum LoginMethod {
  SWEDISH_MOBILE_BANKID_ANY_DEVICE = 'SWEDISH_MOBILE_BANKID_ANY_DEVICE',
  SWEDISH_MOBILE_BANKID_ANY_DEVICE_TEST = 'SWEDISH_MOBILE_BANKID_ANY_DEVICE_TEST',
  SWEDISH_MOBILE_BANKID_OTHER_DEVICE = 'SWEDISH_MOBILE_BANKID_OTHER_DEVICE',
  SWEDISH_MOBILE_BANKID_OTHER_DEVICE_MOCK = 'SWEDISH_MOBILE_BANKID_OTHER_DEVICE_MOCK',
  SWEDISH_MOBILE_BANKID_SAME_DEVICE_CLIENT_SIDE_AUTHENTICATION = 'SWEDISH_MOBILE_BANKID_SAME_DEVICE_CLIENT_SIDE_AUTHENTICATION',
  SWEDISH_MOBILE_BANKID_SAME_DEVICE = 'SWEDISH_MOBILE_BANKID_SAME_DEVICE',
  SWEDISH_SECURITY_TOKEN = 'SWEDISH_SECURITY_TOKEN',
  ESTONIAN_SMART_ID = 'ESTONIAN_SMART_ID',
  ESTONIAN_SMART_ID_USERNAME = 'ESTONIAN_SMART_ID_USERNAME',
  ESTONIAN_MOBIL_ID = 'ESTONIAN_MOBIIL_ID',
  DANISH_NEMID = 'DANISH_NEMID',
  DANISH_MITID = 'DANISH_MITID',
  UK_DOB_USERNAME_PASSWORD = 'UK_DOB_USERNAME_PASSWORD',
  UK_USERNAME_POSTCODE = 'UK_USERNAME_POSTCODE',
  UK_LASTNAME_MEMBERSHIP_NUMBER = 'UK_LASTNAME_MEMBERSHIP_NUMBER',
  USERNAME_AND_PASSWORD = 'USERNAME_AND_PASSWORD',
}

export interface Company {
  functional: boolean;
  insuranceCompany: string;
  insuranceCompanyDisplayName: string;
  loginMethods: LoginMethod[];
  nonFunctionalLoginMethods: LoginMethod[];
  status: string;
}

export enum CollectionState {
  NOT_SET = 'NOT_SET',
  CONTACTED = 'CONTACTED',
}
