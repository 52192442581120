import { LOGIN_ROUTE } from 'constants/routes';
import { removeStoredAuthToken } from 'services/authToken';

export const logout = () => {
  removeStoredAuthToken();
  if (typeof Storage !== 'undefined') {
    window.localStorage.removeItem('userSessionId');
  }
  window.location.assign(`/${LOGIN_ROUTE}`);
};
