import { IntlShape } from 'translations';
import { ProductLine } from 'utils/product-line';

export const getQuestions = (
  formatMessage: IntlShape['formatMessage'],
  productLine?: ProductLine,
) => [
  {
    question: formatMessage({ id: 'page.faq.how-does-it-work.title' }),
    answer: formatMessage({ id: `page.faq.how-does-it-work.content.${productLine}` }),
  },
  {
    question: formatMessage({ id: 'page.faq.what-happens-to-data.title' }),
    answer: formatMessage({ id: `page.faq.what-happens-to-data.content.${productLine}` }),
  },
  {
    question: formatMessage({ id: 'page.faq.safe-to-use.title' }),
    answer: formatMessage({ id: `page.faq.safe-to-use.content.${productLine}` }),
  },
  {
    question: formatMessage({ id: 'page.faq.signing-bank-id.title' }),
    answer: formatMessage({ id: `page.faq.signing-bank-id.content.${productLine}` }),
  },
  {
    question: formatMessage({ id: 'page.faq.information-collect.title' }),
    answer: formatMessage({ id: `page.faq.information-collect.content.${productLine}` }),
  },
  {
    question: formatMessage({ id: 'page.faq.who-are-we.title' }),
    answer: formatMessage(
      {
        id: `page.faq.who-are-we.content.${productLine}`,
      },
      {
        link: (
          <a target="_blank" href="https://insurely.com" rel="noreferrer">
            insurely.com
          </a>
        ),
      },
    ),
  },
];
