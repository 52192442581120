import { Checkbox, Icon, IconButton, Plate, StatusText, TextInput, Typography } from '@insurely/ui';

import classNames from 'classnames';
import { useMemo, useState } from 'react';

import tableStyles from 'styles/table.module.css';
import { FormattedMessage, useIntl } from 'translations';

import { Action, ActionsBanner } from 'views/Config/comp/ActionBanner/ActionBanner';
import { Target } from 'views/Config/comp/ActionBanner/type';

import { PermissionMapper } from 'views/Config/comp/PermissionMapper/PermissionMapper';
import { TableTitle } from 'views/Config/comp/TableTitle/TableTitle';
import { TagsGroup } from 'views/Config/comp/TagsGroup/TagsGroup';
import { User, UserGroup } from 'views/Config/config.types';

import configStyles from '../../config.module.css';

import styles from './users.module.css';

interface Props {
  users?: User[];
  onRefresh: () => void;
}

const UserGroupTags = ({ groups }: { groups: UserGroup[] }) => {
  if (groups.length === 0) {
    return (
      <Typography variant="Label-3" component="p" style={{ color: 'var(--grey-2)' }}>
        <FormattedMessage id="page.config.customer-config.users.no-teams" />
      </Typography>
    );
  }

  return <TagsGroup tags={groups.map((g) => g.name)} />;
};

const UsersContent = ({
  title,
  users,
  selectedUsers,
  onSelectUser,
  handleOnSelectAllUsers,
  showEmpty,
}: {
  title: string;
  users: User[];
  selectedUsers: string[];
  onSelectUser: (id: string) => void;
  handleOnSelectAllUsers: () => void;
  showEmpty?: boolean;
}) => {
  const { formatMessage } = useIntl();
  const [search, setSearch] = useState('');

  const filteredUsers = useMemo(
    () => users.filter((u) => u.email.toLowerCase().includes(search.toLowerCase())),
    [search, users],
  );

  if (!showEmpty && users.length === 0) {
    return null;
  }

  return (
    <Plate>
      <div className={styles.headline}>
        <TableTitle icon="user" title={title} />
        <TextInput
          startAdornment={<Icon name="search" size={20} />}
          endAdornment={
            search.length > 0 ? (
              <IconButton icon={<Icon name="close" size={20} />} onClick={() => setSearch('')} />
            ) : undefined
          }
          textInputSize="extra-small"
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
          placeholder={formatMessage({
            id: 'page.config.customer-config.users.search.placeholder',
          })}
          label=""
        />
      </div>
      <div style={{ overflow: 'auto' }}>
        <table className={tableStyles.table}>
          <thead>
            <tr className={classNames(tableStyles.head, configStyles.tableHeader)}>
              <th style={{ width: '5%' }}>
                <Checkbox
                  aria-label="checkbox-user"
                  checked={users.some((u) => selectedUsers.includes(u.uuid))}
                  partial={!users.every((u) => selectedUsers.includes(u.uuid))}
                  onCheckedChange={handleOnSelectAllUsers}
                  disabled={users.length === 0}
                />
              </th>
              <th aria-labelledby="customer name" style={{ width: '30%' }}>
                <Typography variant="Label-3" component="p">
                  <FormattedMessage id="page.config.customer-config.users.column.email" />
                </Typography>
              </th>
              <th aria-labelledby="customer id" style={{ width: '25%' }}>
                <Typography variant="Label-3" component="p">
                  <FormattedMessage id="page.config.customer-config.users.column.permissions" />
                </Typography>
              </th>
              <th aria-labelledby="user groups" style={{ width: '30%' }}>
                <Typography variant="Label-3" component="p">
                  <FormattedMessage id="page.config.customer-config.users.column.user-groups" />
                </Typography>
              </th>
              <th aria-labelledby="status" style={{ width: '10%' }}>
                <Typography variant="Label-3" component="p">
                  <FormattedMessage id="page.config.customer-config.users.column.status" />
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.length === 0 && (
              <tr>
                <td aria-label="empty-table" colSpan={5}>
                  <div className={configStyles.emptyRow}>
                    <Typography component="p" variant="ParagraphBodySmall">
                      <FormattedMessage id="page.config.customer-config.users.no-users" />
                    </Typography>
                  </div>
                </td>
              </tr>
            )}
            {filteredUsers
              ?.sort((a, b) => a.email.localeCompare(b.email))
              ?.map((user) => (
                <tr
                  className={classNames(
                    tableStyles.row,
                    configStyles.tableRow,
                    styles.notClickable,
                  )}
                  key={user.uuid}
                  tabIndex={0}
                >
                  <td aria-label="user-select">
                    <Checkbox
                      aria-label="checkbox-user"
                      checked={selectedUsers.some((u) => user.uuid === u)}
                      onCheckedChange={() => onSelectUser(user.uuid)}
                    />
                  </td>
                  <td>
                    <Typography component="p" variant="ParagraphCaption">
                      {user.email}
                    </Typography>
                  </td>
                  <td>{user.permissions && <PermissionMapper permissions={user.permissions} />}</td>
                  <td aria-label="teams">
                    <UserGroupTags groups={user.userGroups} />
                  </td>
                  <td aria-label="status">
                    <StatusText
                      color={user.enabled ? 'var(--green)' : 'var(--red-1)'}
                      text={formatMessage({
                        id: `page.config.customer-config.users.${user.enabled ? 'active' : 'inactive'}`,
                      })}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Plate>
  );
};

export default function Users({ users, onRefresh }: Props) {
  const { formatMessage } = useIntl();

  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  const splitUsers = useMemo(
    () => ({
      unassignedUsers: users ? users.filter((u) => u.userGroups.length === 0) : [],
      assignedUsers: users ? users.filter((u) => u.userGroups.length > 0) : [],
    }),
    [users],
  );

  const handleOnSelectUser = (userId: string) => {
    setSelectedUsers((cur) =>
      !cur.includes(userId) ? [...cur, userId] : cur.filter((u) => u !== userId),
    );
  };

  const handleOnSelectAllUsers = (bUsers: User[]) => {
    if (bUsers.every((u) => selectedUsers.includes(u.uuid))) {
      setSelectedUsers((cur) => cur.filter((c) => !bUsers.some((u) => u.uuid === c)));
    } else {
      setSelectedUsers((cur) => [...new Set([...cur, ...bUsers.map((u) => u.uuid)])]);
    }
  };

  return (
    <div className={styles.pageContainer}>
      {selectedUsers.length > 0 && (
        <div className={styles.actionBannerContainer}>
          <ActionsBanner
            target={Target.USERS}
            targetIds={selectedUsers}
            actions={[Action.USERS_ACTIONS, Action.USER_GROUP_MGMT, Action.USERS_PERMISSIONS]}
            onSuccess={onRefresh}
            onClose={() => setSelectedUsers([])}
          />
        </div>
      )}
      {users && (
        <>
          <UsersContent
            title={formatMessage({
              id: 'page.config.customer-config.users.table-header.unassigned-users',
            })}
            users={splitUsers.unassignedUsers}
            selectedUsers={selectedUsers}
            onSelectUser={handleOnSelectUser}
            handleOnSelectAllUsers={() => handleOnSelectAllUsers(splitUsers.unassignedUsers)}
          />
          <UsersContent
            title={formatMessage({
              id: 'page.config.customer-config.users.table-header.all-users',
            })}
            users={splitUsers.assignedUsers}
            selectedUsers={selectedUsers}
            onSelectUser={handleOnSelectUser}
            handleOnSelectAllUsers={() => handleOnSelectAllUsers(splitUsers.assignedUsers)}
            showEmpty
          />
        </>
      )}
    </div>
  );
}
