import { Button, Icon, IconButton, Typography } from '@insurely/ui';

import classNames from 'classnames';
import { useState } from 'react';

import { downloadCancellationPdf } from 'client/client';
import { Cancellation } from 'client/types';

import { useNavigateQueryParams } from 'hooks/useNavigateQueryParams';
import styles from 'styles/table.module.css';
import { useIntl } from 'translations';

import { useFormatting } from 'translations/useFormatting';
import { logError } from 'utils/datadog';
import { downloadFile } from 'utils/downloadFile';
import { getNameFromEmail } from 'utils/formatters';
import { PostHogAction, PostHogCategory, postHogCapture } from 'utils/posthog';
import {
  StatusDot,
  getStatusText,
} from 'views/InsurancePage/CancellationView/comp/CancellationStatus/CancellationStatus';

import { ExtendedCancellation } from '../types';

type ColumnName =
  | 'download'
  | 'consumer'
  | 'company'
  | 'insuranceType'
  | 'status'
  | 'newInsuranceNumber'
  | 'createdAt'
  | 'agent'
  | 'goTo';

type Column = {
  id: ColumnName;
  title: string;
  width?: string;
  position?: 'left' | 'right';
};

interface Props {
  cancellations: Array<ExtendedCancellation>;
  loadMoreCancellations: () => Promise<ExtendedCancellation[]>;
  canLoadMore: boolean;
  loading: boolean;
}

export const CancellationTable = ({
  cancellations,
  loadMoreCancellations,
  canLoadMore,
  loading,
}: Props) => {
  const { formatMessage } = useIntl();
  const { formatDate } = useFormatting();
  const navigate = useNavigateQueryParams();

  const [pdfDownloadId, setPdfDownloadId] = useState<string[]>([]);

  const columns: Column[] = [
    { id: 'download', title: '', width: '5%' },
    { id: 'company', title: formatMessage({ id: 'Company' }), width: '15%' },
    { id: 'consumer', title: formatMessage({ id: 'Customer' }), width: '15%' },
    { id: 'status', title: formatMessage({ id: 'Status' }), width: '15%' },
    {
      id: 'newInsuranceNumber',
      title: formatMessage({ id: 'New insurance number' }),
      width: '15%',
    },
    { id: 'createdAt', title: formatMessage({ id: 'Created' }), width: '15%', position: 'right' },
    { id: 'agent', title: formatMessage({ id: 'Case manager' }), width: '15%' },
    { id: 'goTo', title: '', width: '5%' },
  ];

  const handleDownloadCancellationPdf = async (id: string) => {
    setPdfDownloadId((ids) => [...ids, id]);
    postHogCapture(`tracking:${PostHogAction.VIEW}`, {
      action: PostHogAction.VIEW,
      category: PostHogCategory.CANCELLATION,
      object: 'downloadCancellationPdf',
    });

    try {
      const cancellationPdf = await downloadCancellationPdf(id);
      downloadFile({ data: cancellationPdf, id, name: `${formatMessage({ id: 'Cancel' })} ` });
    } catch (error) {
      const nErr = new Error('Error downloading cancellation PDF', { cause: error });
      logError(nErr, { pdfId: id });
      throw nErr;
    }
    setPdfDownloadId((ids) => ids.filter((c) => c !== id));
  };

  const handleOnRowClick = (cancellation: Cancellation) => {
    navigate(
      `/cancellations/${cancellation.insurance.collectionId}/insurance/${cancellation.insurance.externalId}`,
    );
  };

  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.head}>
            {columns.map((column) => (
              <th key={column.id} style={{ width: column.width, textAlign: column.position }}>
                {column.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {cancellations.map((cancellation) => (
            <tr
              className={classNames(styles.row, { [styles.rowDisabled]: !cancellation.retained })}
              key={`${cancellation.id}-${cancellation.updatedAt}`}
              tabIndex={0}
              onClick={() => handleOnRowClick(cancellation)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleOnRowClick(cancellation);
                }
              }}
            >
              <td aria-label="download">
                <div className={styles.iconCell}>
                  <IconButton
                    loading={pdfDownloadId.includes(cancellation.id)}
                    disabled={pdfDownloadId.includes(cancellation.id) || !cancellation.retained}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDownloadCancellationPdf(cancellation.id);
                    }}
                    icon={<Icon name="download" size={20} />}
                  />
                </div>
              </td>
              <td>
                <Typography
                  component="p"
                  variant="ParagraphCaption"
                  className={!cancellation.retained && styles.disabledCell}
                >
                  {cancellation.retained
                    ? cancellation.insuranceCompany
                    : formatMessage({ id: 'Expired information' })}
                </Typography>
              </td>
              <td className="ph-no-capture">
                <Typography
                  component="p"
                  variant="ParagraphCaption"
                  className={!cancellation.retained && styles.disabledCell}
                >
                  {cancellation.consumerId ?? '-'}
                </Typography>
              </td>
              <td>
                <div className={styles.statusCell}>
                  <StatusDot status={cancellation.status} />
                  <Typography
                    component="p"
                    variant="ParagraphCaption"
                    className={!cancellation.retained && styles.disabledCell}
                  >
                    {getStatusText(cancellation.status, formatMessage)}
                  </Typography>
                </div>
              </td>
              <td className="ph-no-capture">
                <Typography
                  component="p"
                  variant="ParagraphCaption"
                  className={!cancellation.retained && styles.disabledCell}
                >
                  {cancellation.insurance.newInsuranceNumber || '-'}
                </Typography>
              </td>
              <td>
                <div className={styles.numberCell}>
                  <Typography
                    component="p"
                    variant="ParagraphCaption"
                    className={!cancellation.retained && styles.disabledCell}
                  >
                    {formatDate(cancellation.createdAt)}
                  </Typography>
                </div>
              </td>
              <td className="ph-no-capture">
                <Typography
                  component="p"
                  variant="ParagraphCaption"
                  className={!cancellation.retained && styles.disabledCell}
                >
                  {getNameFromEmail(cancellation.agent.email)}
                </Typography>
              </td>
              <td aria-label="goto">
                {cancellation.retained && (
                  <div className={styles.iconCell}>
                    <Icon name="chevron-right" size={20} />
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {canLoadMore && (
        <div className={styles.viewMore}>
          <Button disabled={loading} loading={loading} onClick={loadMoreCancellations}>
            {formatMessage({ id: 'shared.show-more' })}
          </Button>
        </div>
      )}
    </div>
  );
};
