import { NavigateOptions, To, useLocation, useNavigate } from 'react-router-dom';

/** This hooks just use the navigate method but keeps the query params */
export const useNavigateQueryParams = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  return (to: To | number, options?: NavigateOptions & { cleanQueryParams: boolean }) =>
    typeof to === 'number'
      ? navigate(to)
      : navigate(`${to}${options?.cleanQueryParams === true ? '' : search}`, options);
};
