import { newRequestWithToken } from 'client/client';
import { Liveboard } from 'types/liveboard';
import { ThoughtspotUser } from 'views/Config/config.types';

export const getAllLiveboards = async (): Promise<Liveboard[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/liveboard`,
  });
  return res?.data;
};

export const getLiveboardById = async ({
  thoughtspotLiveboardId,
}: {
  thoughtspotLiveboardId: string;
}): Promise<Liveboard> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/liveboard/${thoughtspotLiveboardId}`,
  });
  return res?.data;
};

export const createLiveboard = async (body: Liveboard): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/setup/liveboard`,
    data: body,
  });
  return res?.data;
};

export const deleteLiveboard = async ({
  thoughtspotLiveboardId,
}: {
  thoughtspotLiveboardId: string;
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'DELETE',
    url: `/sst/setup/liveboard/${thoughtspotLiveboardId}`,
  });
  return res?.data;
};

export const updateLiveboard = async ({
  thoughtspotLiveboardId,
  body,
}: {
  thoughtspotLiveboardId: string;
  body: Liveboard;
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'PUT',
    url: `/sst/setup/liveboard/${thoughtspotLiveboardId}`,
    data: body,
  });
  return res?.data;
};

export const getAllThoughtspotUsers = async (): Promise<ThoughtspotUser[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/thoughtspot/users`,
  });
  return res?.data;
};

export const createThoughtspotUser = async ({
  customerId,
  thoughtspotUsername,
}: {
  customerId: string;
  thoughtspotUsername: string;
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/setup/thoughtspot/customer/${customerId}/user`,
    data: thoughtspotUsername,
    headers: { 'Content-Type': 'text/plain' },
  });
  return res?.data;
};
