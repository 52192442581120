/* eslint-disable max-lines */

import { Market } from 'types/v3';
import { AllInsurancesKeys, InsuranceSubType, InsuranceType } from 'types/v3/Insurance';

type Row = {
  key: AllInsurancesKeys | 'addons' | 'address';
  copyKey: string;
  markets: Market[];
  insuranceTypes: InsuranceType[];
  subTypes: InsuranceSubType[];
};

export const insuranceHolderKeys: Row[] = [
  {
    key: 'insuranceHolderName',
    copyKey: 'shared.name',
    markets: [],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'insuranceHolderGender',
    copyKey: 'shared.gender',
    markets: [Market.FR],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'insuranceHolderEmail',
    copyKey: 'shared.email',
    markets: [Market.FR],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'insuranceHolderPhoneNumber',
    copyKey: 'shared.phone-number',
    markets: [Market.FR],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'insuranceHolderDateOfBirth',
    copyKey: 'shared.date-of-birth',
    markets: [Market.FR],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'address',
    copyKey: 'shared.address',
    markets: [],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'insuranceHolderCompanyCode',
    copyKey: 'insurance.company-code',
    markets: [Market.FR],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'insuranceHolderOccupation',
    copyKey: 'shared.occupation',
    markets: [Market.FR],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'insuranceHolderPersonalCode',
    copyKey: 'insurance.personal-code',
    markets: [Market.FR],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'otherInsuranceHolder',
    copyKey: 'shared.other-insurance-holder',
    markets: [],
    insuranceTypes: [],
    subTypes: [],
  },
];

export const insuranceDetailsKeys: Row[] = [
  {
    key: 'insuranceName',
    copyKey: 'shared.insurance-name',
    markets: [],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'insuranceNumber',
    copyKey: 'shared.insurance-number',
    markets: [],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'startDate',
    copyKey: 'shared.start-date',
    markets: [],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'insuranceObjectStreetAddress',
    copyKey: 'shared.insurance-object-street-address',
    markets: [],
    insuranceTypes: ['houseContentInsurance'],
    subTypes: [],
  },
  {
    key: 'insuranceObjectPostalCode',
    copyKey: 'shared.insurance-object-postal-code',
    markets: [],
    insuranceTypes: ['houseContentInsurance'],
    subTypes: [],
  },
  {
    key: 'insuranceObjectCity',
    copyKey: 'shared.insurance-object-city',
    markets: [],
    insuranceTypes: ['houseContentInsurance'],
    subTypes: [],
  },
  {
    key: 'insuranceObjectPropertyCode',
    copyKey: 'shared.insurance-object-property-code',
    markets: [],
    insuranceTypes: ['houseContentInsurance'],
    subTypes: ['villaInsurance', 'farmInsurance'],
  },
  {
    key: 'insuredAmount',
    copyKey: 'shared.insurance-amount',
    markets: [],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'insuredAmountBaseMedicalDisability',
    copyKey: 'shared.insurance-amount-base-medical-disability',
    markets: [],
    insuranceTypes: ['personInsurance'],
    subTypes: ['accidentInsurance', 'childInsurance', 'pregnancyInsurance'],
  },
  {
    key: 'insuredAmountBaseEconomicalDisability',
    copyKey: 'shared.insurance-amount-base-economical-disability',
    markets: [],
    insuranceTypes: ['personInsurance'],
    subTypes: ['accidentInsurance', 'childInsurance'],
  },
  {
    key: 'insuredAmountMaxMedicalDisability',
    copyKey: 'shared.insurance-amount-max-medical-disability',
    markets: [],
    insuranceTypes: ['personInsurance'],
    subTypes: ['accidentInsurance', 'childInsurance'],
  },
  {
    key: 'insuredAmountLife',
    copyKey: 'shared.insured-amount-life',
    markets: [],
    insuranceTypes: ['personInsurance'],
    subTypes: ['accidentInsurance', 'childInsurance', 'lifeInsurance'],
  },
  {
    key: 'insuredMovablesAmount',
    copyKey: 'shared.insured-movables-amount',
    markets: [],
    insuranceTypes: ['houseContentInsurance'],
    subTypes: [],
  },
  {
    key: 'veterinaryCareAmount',
    copyKey: 'shared.veterinary-care-amount',
    markets: [],
    insuranceTypes: ['animalInsurance'],
    subTypes: [],
  },
  {
    key: 'veterinaryCareAmountRemaining',
    copyKey: 'shared.veterinary-care-amount-remaining',
    markets: [Market.FR],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'veterinaryCareCovered',
    copyKey: 'shared.veterinary-care-covered',
    markets: [Market.FR],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'animalBreed',
    copyKey: 'shared.animal-breed',
    markets: [],
    insuranceTypes: ['animalInsurance'],
    subTypes: [],
  },
  {
    key: 'animalGender',
    copyKey: 'shared.animal-gender',
    markets: [Market.FR],
    insuranceTypes: ['animalInsurance'],
    subTypes: [],
  },
  {
    key: 'dateOfBirth',
    copyKey: 'shared.date-of-birth',
    markets: [],
    insuranceTypes: ['animalInsurance'],
    subTypes: [],
  },
  {
    key: 'chipNumber',
    copyKey: 'shared.chip-number',
    markets: [],
    insuranceTypes: ['animalInsurance'],
    subTypes: [],
  },
  {
    key: 'registrationNo',
    copyKey: 'shared.registration-no',
    markets: [],
    insuranceTypes: ['vehicleInsurance'],
    subTypes: [],
  },
  {
    key: 'model',
    copyKey: 'shared.model',
    markets: [],
    insuranceTypes: ['vehicleInsurance'],
    subTypes: [],
  },
  {
    key: 'livingArea',
    copyKey: 'shared.living-area',
    markets: [],
    insuranceTypes: ['houseContentInsurance'],
    subTypes: [],
  },
  {
    key: 'numberOfResidents',
    copyKey: 'shared.number-of-residents',
    markets: [],
    insuranceTypes: ['houseContentInsurance'],
    subTypes: [],
  },
  {
    key: 'numberOfAdults',
    copyKey: 'shared.number-of-adults',
    markets: [Market.FR],
    insuranceTypes: ['houseContentInsurance'],
    subTypes: [],
  },
  {
    key: 'numberOfChildren',
    copyKey: 'shared.number-of-children',
    markets: [Market.FR],
    insuranceTypes: ['houseContentInsurance'],
    subTypes: [],
  },
  {
    key: 'numberOfBathrooms',
    copyKey: 'shared.number-of-bathrooms',
    markets: [],
    insuranceTypes: ['houseContentInsurance'],
    subTypes: ['villaInsurance'],
  },
  {
    key: 'numberOfFloors',
    copyKey: 'shared.number-of-floors',
    markets: [Market.FR],
    insuranceTypes: ['houseContentInsurance'],
    subTypes: [],
  },
  {
    key: 'numberOfRooms',
    copyKey: 'shared.number-of-rooms',
    markets: [Market.FR],
    insuranceTypes: ['houseContentInsurance'],
    subTypes: [],
  },
  {
    key: 'numberOfToilets',
    copyKey: 'shared.number-of-toilets',
    markets: [Market.FR],
    insuranceTypes: ['houseContentInsurance'],
    subTypes: [],
  },
  {
    key: 'maxMileage',
    copyKey: 'shared.max-mileage',
    markets: [],
    insuranceTypes: ['vehicleInsurance'],
    subTypes: ['carInsurance'],
  },
  {
    key: 'youngDriver',
    copyKey: 'shared.young-driver',
    markets: [Market.FR],
    insuranceTypes: ['vehicleInsurance'],
    subTypes: [],
  },
  {
    key: 'youngDriverAge',
    copyKey: 'shared.young-driver-age',
    markets: [Market.FR],
    insuranceTypes: ['vehicleInsurance'],
    subTypes: [],
  },
  {
    key: 'claims',
    copyKey: 'shared.insurance.claims',
    markets: [Market.FR],
    insuranceTypes: [],
    subTypes: [],
  },
  // {
  //   key: 'vehicleOwnership',
  //   copyKey: 'shared.vehicle-ownership',
  //   markets: [Market.FR],
  //   insuranceTypes: ['vehicleInsurance'],
  //   subTypes: [],
  // },
  // {
  //   key: 'additionalAmenities',
  //   copyKey: 'shared.additional-amenities',
  //   markets: [Market.FR],
  //   insuranceTypes: [],
  //   subTypes: [],
  // },
  // {
  //   key: 'additionalBuildings',
  //   copyKey: 'shared.additional-buildings',
  //   markets: [],
  //   insuranceTypes: ['houseContentInsurance'],
  //   subTypes: ['villaInsurance'],
  // },
  {
    key: 'ancillaryArea',
    copyKey: 'shared.ancillary-area',
    markets: [],
    insuranceTypes: ['houseContentInsurance'],
    subTypes: ['villaInsurance'],
  },
  {
    key: 'animalName',
    copyKey: 'shared.animal-name',
    markets: [],
    insuranceTypes: ['animalInsurance'],
    subTypes: ['catInsurance', 'dogInsurance'],
  },
  {
    key: 'animalPurchasePrice',
    copyKey: 'shared.animal-purchase-price',
    markets: [Market.FR],
    insuranceTypes: ['animalInsurance'],
    subTypes: [],
  },
  {
    key: 'brand',
    copyKey: 'shared.brand',
    markets: [],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'coming',
    copyKey: 'shared.coming',
    markets: [Market.FR],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'connectedAlarmDiscount',
    copyKey: 'shared.connected-alarm-discount',
    markets: [Market.FR],
    insuranceTypes: ['houseContentInsurance'],
    subTypes: [],
  },
  {
    key: 'constructionYear',
    copyKey: 'shared.construction-year',
    markets: [],
    insuranceTypes: ['houseContentInsurance'],
    subTypes: ['villaInsurance'],
  },
  {
    key: 'farmProperties',
    copyKey: 'shared.farm-properties',
    markets: [],
    insuranceTypes: ['houseContentInsurance'],
    subTypes: [],
  },
  {
    key: 'floor',
    copyKey: 'shared.floor',
    markets: [],
    insuranceTypes: ['houseContentInsurance'],
    subTypes: [],
  },
  {
    key: 'hasBasement',
    copyKey: 'shared.has-basement',
    markets: [],
    insuranceTypes: ['houseContentInsurance'],
    subTypes: [],
  },
  {
    key: 'insuranceBeneficiaryCode',
    copyKey: 'shared.insurance-beneficiary-code',
    markets: [Market.FR],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'insuranceBeneficiaryName',
    copyKey: 'shared.insurance-beneficiary-name',
    markets: [Market.FR],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'insuranceInsuredPersonCode',
    copyKey: 'shared.insured-person-code',
    markets: [Market.FR],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'insuranceInsuredPersonName',
    copyKey: 'shared.insured-person-name',
    markets: [Market.FR],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'insuranceLevel',
    copyKey: 'shared.insurance-level',
    markets: [],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'insurancePreviouslyRefused',
    copyKey: 'shared.previously-refused-insurance',
    markets: [Market.FR],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'insuredMonthlyAmount',
    copyKey: 'shared.insured-monthly-amount',
    markets: [],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'insuredPersonName',
    copyKey: 'shared.insured-person-name',
    markets: [],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'insuredValuablesAmount',
    copyKey: 'shared.insured-valuables-amount',
    markets: [],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'numberOfDaysUntilFirstPayout',
    copyKey: 'shared.number-of-days-until-first-payout',
    markets: [Market.FR],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'numberOfDaysUntilLastPayout',
    copyKey: 'shared.number-of-days-until-last-payout',
    markets: [Market.FR],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'ownershipStatus',
    copyKey: 'shared.ownership-status',
    markets: [Market.FR],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'professionalUsage',
    copyKey: 'shared.professional-usage',
    markets: [Market.FR],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'purchaseYear',
    copyKey: 'shared.purchase-year',
    markets: [],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'qualifyingPeriodDays',
    copyKey: 'shared.qualifying-period-days',
    markets: [Market.FR],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'rental',
    copyKey: 'shared.rental',
    markets: [],
    insuranceTypes: ['houseContentInsurance'],
    subTypes: ['villaInsurance'],
  },
  {
    key: 'residentialStatus',
    copyKey: 'shared.residential-status',
    markets: [],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'securityDoorGridDiscount',
    copyKey: 'shared.security-door-grid-discount',
    markets: [],
    insuranceTypes: ['houseContentInsurance'],
    subTypes: [],
  },
  {
    key: 'usagePurposes',
    copyKey: 'shared.usage-purposes',
    markets: [Market.FR],
    insuranceTypes: ['vehicleInsurance'],
    subTypes: [],
  },
  {
    key: 'usedForWork',
    copyKey: 'shared.used-for-work',
    markets: [Market.FR],
    insuranceTypes: ['vehicleInsurance'],
    subTypes: [],
  },
  {
    key: 'parking',
    copyKey: 'shared.parking',
    markets: [Market.FR],
    insuranceTypes: ['vehicleInsurance'],
    subTypes: [],
  },
  {
    key: 'bonusMalus',
    copyKey: 'shared.bonusMalus',
    markets: [Market.FR],
    insuranceTypes: ['vehicleInsurance'],
    subTypes: ['carInsurance'],
  },
  {
    key: 'addons',
    copyKey: 'shared.addons',
    markets: [],
    insuranceTypes: [],
    subTypes: [],
  },
];

export const paymentPricesKeys: Row[] = [
  {
    key: 'premiumAmountYearRounded',
    copyKey: 'shared.monthly-premium',
    markets: [],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'premiumAmountYearRounded',
    copyKey: 'shared.anual-premium',
    markets: [],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'discountAmount',
    copyKey: 'shared.discount',
    markets: [],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'discountPercentage',
    copyKey: 'shared.discount-percentage',
    markets: [],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'premiumFrequency',
    copyKey: 'shared.payment-frequency',
    markets: [],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'paymentMethod',
    copyKey: 'shared.payment-method',
    markets: [],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'renewalDate',
    copyKey: 'shared.renewal-date',
    markets: [Market.SE, Market.DK, Market.EE],
    insuranceTypes: [],
    subTypes: [],
  },
  {
    key: 'endDate',
    copyKey: 'shared.endDate',
    markets: [Market.FR],
    insuranceTypes: [],
    subTypes: [],
  },
];
