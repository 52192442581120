import { Insurance } from 'types/v3/Insurance';
import { upperCaseEveryFirstLetter } from 'utils/formatters';

export const getDisplayName = (insurance: Insurance) => {
  if (insurance.insuranceSubType === 'pregnancyInsurance') {
    return insurance.insuranceHolderName
      ? upperCaseEveryFirstLetter(insurance.insuranceHolderName)
      : '- - -';
  }
  if (insurance.insuranceSubType === 'otherVehicleInsurance') {
    return insurance.insuranceName;
  }
  if ('registrationNo' in insurance && insurance.registrationNo) {
    return insurance.registrationNo;
  }
  if (insurance.insuranceType === 'personInsurance') {
    return upperCaseEveryFirstLetter(insurance.insuranceHolderName || insurance.insuranceName);
  }
  if ('animalName' in insurance) {
    return upperCaseEveryFirstLetter(insurance.animalName);
  }
  return insurance.insuranceName || '- - -';
};
