import { Dropdown, Toggle, TextInput, Typography } from '@insurely/ui';
import { useRef } from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';

import { useParams } from 'react-router-dom';

import { useCompanies } from 'hooks/useCompanies';
import { useIntl } from 'translations';

import { InsuranceSubType, InsuranceType } from 'types/v3/Insurance';

import { FinancialProductType } from 'types/wealth';
import { snakeCaseToCamelCase, snakeCaseToKebabCase } from 'utils/formatters';
import { ProductLine } from 'utils/product-line';
import { AddToList } from 'views/Config/comp/AddToList/AddToList';
import { ExtendedClientConfig } from 'views/Config/config.types';

import { FormValues } from '../../../type';

import { generateBaseUrlLink } from '../../../utils';
import styles from '../clientForm.module.css';
import { TitleWithDescription } from '../TitleWithDescription/TitleWithDescription';

export const Step2 = ({
  insuranceTypes,
  insuranceSubTypes,
  companies,
  wealthTypes,
  formUtils,
  metadata,
}: {
  insuranceTypes: InsuranceType[];
  insuranceSubTypes: InsuranceSubType[];
  companies: string[];
  wealthTypes: FinancialProductType[];
  formUtils: {
    register: UseFormRegister<FormValues>;
    control: Control<FormValues, unknown>;
    watch: UseFormWatch<FormValues>;
    errors: FieldErrors<FormValues>;
    resetField: UseFormResetField<FormValues>;
    setValue: UseFormSetValue<FormValues>;
  };
  metadata: { mode: 'creation' | 'edit' };
}) => {
  const { customerId } = useParams() as { customerId: string };
  const { getCompanyDisplayName } = useCompanies();
  const { formatMessage } = useIntl();
  const refs = useRef<{ [key: string]: HTMLButtonElement | null }>({});

  const { register, control, watch, errors, resetField, setValue } = formUtils;
  const { mode } = metadata;

  const dropdownOptions = (
    field: keyof ExtendedClientConfig,
  ): Array<{ label: string; value: string }> => {
    switch (field) {
      case 'insuranceTypes':
        return insuranceTypes.map((t) => ({
          label: formatMessage({ id: snakeCaseToCamelCase(t) }),
          value: t,
        }));
      case 'insuranceSubTypes':
        return insuranceSubTypes.map((t) => ({
          label: formatMessage({ id: snakeCaseToCamelCase(t) }),
          value: t,
        }));
      case 'clientCompanies':
        return companies.map((c) => ({
          label: getCompanyDisplayName(snakeCaseToKebabCase(c)),
          value: c,
        }));
      case 'wealthTypes':
        return wealthTypes.map((p) => ({
          label: formatMessage({ id: snakeCaseToCamelCase(p) }),
          value: p,
        }));
      default:
        return [];
    }
  };

  const renderField = (field: keyof FormValues) => {
    switch (field) {
      case 'insuranceTypes':
      case 'insuranceSubTypes':
      case 'clientCompanies':
      case 'wealthTypes':
        return (
          <Controller
            control={control}
            name={field}
            render={({ field: { onChange, value } }) => (
              <div className={styles.dropdownContainer}>
                <TitleWithDescription
                  title={formatMessage({ id: `page.config.client-form.field.${field}` })}
                  description={formatMessage({
                    id: `page.config.client-form.field.${field}.description`,
                  })}
                />
                <Dropdown
                  size="small"
                  multiSelect
                  ref={(_ref) => {
                    refs.current[field] = _ref;
                  }}
                  placeholder={formatMessage({
                    id: `page.config.client-form.field.${field}.placeholder`,
                  })}
                  multiSelectLabels={{
                    all: formatMessage({ id: 'page.config.client-form.field.all' }),
                    selected: (count: number) =>
                      count === 1
                        ? dropdownOptions(field).find((f) => f.value === watch(field)[0])?.label ??
                          ''
                        : formatMessage(
                            { id: 'page.config.client-form.field.selected' },
                            { count },
                          ),
                  }}
                  primaryCallback={{
                    label: formatMessage({ id: 'page.config.client-form.field.done' }),
                    action: () => refs.current[field]?.click(),
                  }}
                  secondaryCallback={{
                    label:
                      watch(field).length === 0
                        ? formatMessage({ id: 'page.config.client-form.field.select-all' })
                        : formatMessage({ id: 'page.config.client-form.field.deselect-all' }),
                    action: () => {
                      if (watch(field).length === 0) {
                        setValue(
                          field,
                          dropdownOptions(field).map((o) => o.value),
                        );
                      } else {
                        resetField(field, { defaultValue: [] });
                      }
                    },
                  }}
                  fullWidth
                  options={dropdownOptions(field).sort((a, b) => a.label.localeCompare(b.label))}
                  value={value}
                  onChange={onChange}
                />
              </div>
            )}
          />
        );
      case 'allowAnonymousRetention':
      case 'hasDpa':
      case 'forceMtls':
      case 'getPolicyDocuments':
        return (
          <Controller
            control={control}
            name={field}
            render={({ field: { onChange, value } }) => (
              <div className={styles.switcherWrapper}>
                <TitleWithDescription
                  title={formatMessage({ id: `page.config.client-form.field.${field}` })}
                  description={formatMessage({
                    id: `page.config.client-form.field.${field}.description`,
                  })}
                />
                <Toggle checked={value} onCheckedChange={onChange} />
              </div>
            )}
          />
        );
      case 'logRetention':
      case 'baseUrl':
        return (
          <div>
            <TextInput
              type={field === 'logRetention' ? 'number' : 'text'}
              label={
                <TitleWithDescription
                  title={formatMessage({ id: `page.config.client-form.field.${field}` })}
                  description={formatMessage({
                    id: `page.config.client-form.field.${field}.description`,
                  })}
                />
              }
              placeholder={formatMessage({
                id: `page.config.client-form.field.${field}.placeholder`,
              })}
              textInputSize="small"
              error={!!errors[field]}
              helperText={errors[field]?.message}
              {...register(field)}
            />
            {field === 'baseUrl' && mode === 'creation' && (
              <Typography
                variant="ParagraphCaption"
                component="p"
                style={{ color: 'var(--grey-1)', marginTop: 'var(--space-2)' }}
              >
                {generateBaseUrlLink({
                  baseUrl: watch('baseUrl'),
                  configurationName: watch('configurationName') ?? '',
                  customerId,
                })}
              </Typography>
            )}
          </div>
        );
      case 'allowedOrigins':
        return (
          <Controller
            control={control}
            name={field}
            render={({ field: { onChange, value } }) => (
              <AddToList
                title={
                  <TitleWithDescription
                    title={formatMessage({ id: `page.config.client-form.field.${field}` })}
                    description={formatMessage({
                      id: `page.config.client-form.field.${field}.description`,
                    })}
                  />
                }
                values={value}
                onChange={onChange}
                placeholder={formatMessage({
                  id: `page.config.client-form.field.${field}.placeholder`,
                })}
              />
            )}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {watch('productLine') === ProductLine.INSURANCE ? (
        <div className={styles.singleLineElements}>
          {renderField('insuranceTypes')}
          {renderField('insuranceSubTypes')}
        </div>
      ) : (
        renderField('wealthTypes')
      )}
      <div className={styles.singleLineElements}>
        {renderField('clientCompanies')}
        {renderField('logRetention')}
      </div>
      {watch('productInterface') === 'hub' && renderField('baseUrl')}
      {renderField('allowedOrigins')}
      {renderField('allowAnonymousRetention')}
      {renderField('getPolicyDocuments')}
      {renderField('hasDpa')}
      {renderField('forceMtls')}
    </>
  );
};
