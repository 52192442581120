import { ColumnTwo, TableTwo, Typography } from '@insurely/ui';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { getCancellationEventLog } from 'client/client';
import { Cancellation } from 'client/types';
import { useIntl } from 'translations';
import { useFormatting } from 'translations/useFormatting';
import { InsuranceResponse } from 'types/insurance';
import { logError } from 'utils/datadog';
import { getNameFromEmail } from 'utils/formatters';

import styles from './eventLog.module.css';

interface EventProps {
  agent: string;
  created: string;
  event: string;
}

interface EventLogProps {
  insurance: InsuranceResponse;
  cancellation?: Cancellation;
}

enum EventStatus {
  SENT = 'SENT',
  REMINDER = 'REMINDER',
  SIGNED = 'SIGNED',
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
  CREATED = 'CREATED',
}

const TableItems = ({
  currentItems,
  clientInfoColumns,
}: {
  currentItems: EventProps[];
  clientInfoColumns: ColumnTwo<string>[];
}) => {
  const tableRows = currentItems.map((i) => ({
    values: {
      date: i.created,
      activity: i.event,
      advisory: i.agent ?? '',
    },
    id: i.created,
  }));

  return <TableTwo columns={clientInfoColumns} rows={tableRows} />;
};

export const EventLog = ({ insurance, cancellation }: EventLogProps) => {
  const [events, setEvents] = useState<EventProps[]>();
  const { formatMessage } = useIntl();
  const { formatDateTime } = useFormatting();

  useEffect(() => {
    async function fetchEventLog() {
      if (insurance.collectionId) {
        const eventLogCancellations = await getCancellationEventLog(
          insurance.collectionId,
          insurance.insurance.externalId,
        ).catch((err) => {
          const nErr = new Error('Fail to fetch cancellation event log', { cause: err });
          logError(nErr);
          throw nErr;
        });
        setEvents(eventLogCancellations);
      }
    }

    fetchEventLog();
  }, [cancellation, insurance.collectionId, insurance.insurance.externalId]);

  const getStatusText = useCallback(
    (status: EventStatus) => {
      switch (status) {
        case EventStatus.SENT:
          return formatMessage({ id: 'Power of attorney sent' });
        case EventStatus.CREATED:
          return formatMessage({ id: 'Power of attorney created' });
        case EventStatus.REMINDER:
          return formatMessage({ id: 'PoA reminder sent' });
        case EventStatus.SIGNED:
          return formatMessage({ id: 'Power of attorney signed' });
        case EventStatus.CANCELLED:
          return formatMessage({ id: 'Power of attorney cancelled' });
        case EventStatus.REJECTED:
          return formatMessage({ id: 'Power of attorney rejected' });
        default:
          return formatMessage({ id: 'Unknown' });
      }
    },
    [formatMessage],
  );

  const clientInfoColumns = useMemo<ColumnTwo<string>[]>(
    () => [
      {
        id: 'date',
        title: formatMessage({ id: 'Date' }),
        canBeSorted: true,
        renderCell: (date: string) =>
          // TODO: The 'Z' added here should come from backend
          formatDateTime(new Date(date)),
      },
      {
        id: 'activity',
        title: formatMessage({ id: 'Activity' }),
        canBeSorted: true,
        renderCell: (value: EventStatus) => getStatusText(value),
      },
      {
        id: 'advisory',
        title: formatMessage({ id: 'Case manager' }),
        canBeSorted: true,
        renderCell: (email: string) => (
          <span className="ph-no-capture">{getNameFromEmail(email)}</span>
        ),
      },
    ],
    [formatDateTime, formatMessage, getStatusText],
  );

  if (!events || events.length <= 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Typography component="h3" variant="Headline-6" className={styles.title}>
        {formatMessage({ id: 'History' })}
      </Typography>
      <TableItems currentItems={events} clientInfoColumns={clientInfoColumns} />
    </div>
  );
};
