import { Typography } from '@insurely/ui';

import styles from './tagsGroup.module.css';

interface Props {
  tags: string[];
}

export const TagsGroup = ({ tags }: Props) => (
  <div className={styles.container}>
    {tags.map((t) => (
      <div className={styles.tag}>
        <Typography variant="Label-3" component="p">
          {t}
        </Typography>
      </div>
    ))}
  </div>
);
