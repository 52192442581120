import { createContext } from 'react';

import { CollectionStatusChange, Invitation } from 'client/types';

import { InsuranceResponse } from 'types/insurance';
import { CollectionStartedResponse, Session } from 'types/v3';
import { FinancialProduct } from 'types/wealth';

export type CollectionsWithStatuses = CollectionStartedResponse & {
  statuses: CollectionStatusChange[];
};

export interface SessionContextInterface {
  session?: Session;
  loadSession: () => Promise<void>;
  collections: CollectionsWithStatuses[];
  updateCollections: (collections: CollectionsWithStatuses[]) => void;
  items: FinancialProduct[] | InsuranceResponse[];
  smsInvites: Invitation[];
  loadSmsInvites: () => void;
  loadingSessionData: boolean;
  errorFetchingSessionData: boolean;
}

const SessionContext = createContext<SessionContextInterface>({} as SessionContextInterface);

export default SessionContext;
