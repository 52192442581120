import { Button, Typography, Modal } from '@insurely/ui';

import { useIntl } from 'translations';

import styles from './recentReminderModal.module.css';

interface RecentReminderModalProps {
  sendReminder: () => void;
  onHide: () => void;
  isOpen: boolean;
}

export const RecentReminderModal = ({ sendReminder, onHide, isOpen }: RecentReminderModalProps) => {
  const { formatMessage } = useIntl();
  return (
    <Modal isOpen={isOpen} onClose={onHide}>
      <Typography component="h3" variant="Headline-7">
        {formatMessage({ id: 'Do you want to remind again?' })}
      </Typography>
      <Typography component="p" variant="ParagraphBodySmall" className={styles.paragraph}>
        {formatMessage({ id: 'You have sent a reminder once earlier today.' })}
      </Typography>
      <div className={styles.buttonContainer}>
        <Button variant="primary" onClick={onHide}>
          {formatMessage({ id: 'Cancel' })}
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            sendReminder();
            onHide();
          }}
        >
          {formatMessage({ id: 'Send reminder' })}
        </Button>
      </div>
    </Modal>
  );
};
