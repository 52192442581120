import { Button, Modal, Typography } from '@insurely/ui';

import { FormattedMessage, useIntl } from 'translations';

import styles from './legend.module.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ColorCodeLine = ({
  colorLeft,
  colorRight,
  text,
}: {
  colorLeft: string;
  colorRight: string;
  text: React.ReactNode;
}) => (
  <div className={styles.tileBlock}>
    <div className={styles.tiles}>
      <div className={styles.tile} style={{ backgroundColor: colorLeft }} />
      <Typography variant="Label-3" component="p">
        <FormattedMessage id="shared.word.versus" />
      </Typography>
      <div className={styles.tile} style={{ backgroundColor: colorRight }} />
    </div>
    <Typography variant="ParagraphCaption" component="p">
      {text}
    </Typography>
  </div>
);

export const Legend = ({ isOpen, onClose }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({ id: 'page.comparison.table.legend.title' })}
      width={550}
    >
      <div className={styles.container}>
        <Typography variant="ParagraphCaption" component="p">
          <FormattedMessage id="page.comparison.table.legend.description" />
        </Typography>
        <Typography variant="Label-3" component="p">
          <FormattedMessage id="page.comparison.table.legend.color-codes.title" />
        </Typography>
        <div className={styles.tilesContainer}>
          <ColorCodeLine
            colorLeft="var(--mint-1)"
            colorRight="var(--white)"
            text={<FormattedMessage id="page.comparison.table.legend.color-codes.winner" />}
          />
          <ColorCodeLine
            colorLeft="var(--beige-1)"
            colorRight="var(--beige-1)"
            text={<FormattedMessage id="page.comparison.table.legend.color-codes.undetermined" />}
          />
          <ColorCodeLine
            colorLeft="var(--white)"
            colorRight="var(--white)"
            text={<FormattedMessage id="page.comparison.table.legend.color-codes.equal" />}
          />
        </div>
        <Typography variant="Label-3" component="p">
          <FormattedMessage id="page.comparison.table.legend.report-error.title" />
        </Typography>
        <Typography variant="ParagraphCaption" component="p">
          <FormattedMessage id="page.comparison.table.legend.report-error.description" />
        </Typography>
        <div className={styles.buttonContainer}>
          <Button onClick={onClose}>
            <FormattedMessage id="page.comparison.table.legend.done" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
