import { Button, Checkbox, TextArea } from '@insurely/ui';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';

import UserContext from 'contexts/user/UserContext';
import { FormattedMessage, useIntl } from 'translations';

import styles from './sendLink.module.css';

export default function SendViaEmail({ sessionLink }: { sessionLink: string }) {
  const [consent, setConsent] = useState(false);
  const [consentError, setConsentError] = useState('');
  const { formatMessage } = useIntl();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const shadowDivRef = useRef<HTMLDivElement>(null);
  const [rows, setRows] = useState<number>(1);
  const [height, setHeight] = useState<number | null>(null);
  const { config } = useContext(UserContext);

  const defaultValue = formatMessage(
    {
      id: config.insuranceConfig
        ? 'page.session.send-collection-link.default-message-insurance'
        : 'page.session.send-collection-link.default-message-pension',
    },
    { url: `\n${sessionLink}\n` },
  );

  const [value, setValue] = useState<string>(defaultValue);

  function handleCopy(action: 'copy' | 'email-client') {
    if (!consent) {
      setConsentError(
        formatMessage({
          id: 'page.session.send-collection-link.customer-approval-error',
        }),
      );
    } else if (action === 'copy') {
      navigator.clipboard.writeText(textAreaRef?.current?.value || '');
    } else if (action === 'email-client' && textAreaRef.current) {
      textAreaRef.current.select();
      const body = encodeURIComponent(textAreaRef.current.value);
      window.location.href = `mailto:?body=${body}`;
    }
  }

  function handleChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setValue(event.target.value);
  }

  useEffect(() => {
    if (!textAreaRef || !textAreaRef.current) return;
    if (shadowDivRef.current) {
      // Copy textarea styles to shadow div for accurate measurement
      const textareaStyles = window.getComputedStyle(textAreaRef.current!);
      shadowDivRef.current.style.width = textareaStyles.width;
      shadowDivRef.current.style.font = textareaStyles.font;
      shadowDivRef.current.style.padding = textareaStyles.padding;
      shadowDivRef.current.style.lineHeight = textareaStyles.lineHeight;
      shadowDivRef.current.innerText = value;
      const newHeight =
        shadowDivRef.current.scrollHeight +
        parseInt(textareaStyles.paddingTop, 10) +
        parseInt(textareaStyles.paddingBottom, 10);
      setHeight(newHeight);
      setRows(newHeight / 30);
    }
  }, [value]);

  return (
    <div className={styles.textAreaWrapper}>
      <TextArea
        ref={textAreaRef}
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        rows={rows}
        label={formatMessage({ id: 'page.session.send-collection-link.email-headline' })}
        textAreaSize="default"
        className={styles.textArea}
        style={{ height: height ? `${height}px` : undefined }}
      />
      <div ref={shadowDivRef} className={styles.shadowDiv} />

      <Checkbox
        className={styles.checkbox}
        label={formatMessage({
          id: 'page.session.send-collection-link.customer-approval',
        })}
        checked={consent}
        onCheckedChange={() => {
          setConsent(!consent);
          setConsentError('');
        }}
        error={!!consentError}
        helperText={consentError}
      />

      <div className={styles.buttons}>
        <Button variant="secondary" type="button" onClick={() => handleCopy('copy')}>
          <FormattedMessage id="page.session.send-collection-link.copy-message-btn" />
        </Button>
        <Button variant="primary" type="button" onClick={() => handleCopy('email-client')}>
          <FormattedMessage id="page.session.send-collection-link.send-from-client-btn" />
        </Button>
      </div>
    </div>
  );
}
