import { newRequestWithToken } from 'client/client';

export const getRecordSaleAnswer = async ({
  collectionId,
  insuranceId,
}: {
  collectionId: string;
  insuranceId: string;
}): Promise<{ insuranceSold: boolean }> => {
  const res = await newRequestWithToken({
    url: `/sst/insurance-sold/${collectionId}/${insuranceId}`,
  });

  return res.data;
};

export const deleteRecordSaleAnswer = async ({
  collectionId,
  insuranceId,
}: {
  collectionId: string;
  insuranceId: string;
}) => {
  const res = await newRequestWithToken({
    url: `/sst/insurance-sold/${collectionId}/${insuranceId}`,
    method: 'DELETE',
  });

  return res.data;
};

export const updateRecordSale = async ({
  insuranceSold,
  collectionId,
  insuranceId,
}: {
  insuranceSold: boolean;
  collectionId: string;
  insuranceId: string;
}) => {
  const res = await newRequestWithToken({
    url: `/sst/insurance-sold/${collectionId}/${insuranceId}`,
    method: 'PUT',
    data: { insuranceSold },
  });

  return res.data;
};
