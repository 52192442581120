import { InsuranceResponse } from 'types/insurance';

export const getInsurancesLists = (insurances?: InsuranceResponse[]) => {
  const lists = [];

  if (insurances) {
    const home = insurances.filter((ins) => /house/.test(ins.insurance.insuranceType));
    const car = insurances.filter((ins) => /vehicle/.test(ins.insurance.insuranceType));
    const person = insurances.filter((ins) => /person/.test(ins.insurance.insuranceType));
    const animal = insurances.filter((ins) => /animal/.test(ins.insurance.insuranceType));

    const other = insurances.filter(
      (ins) => [...home, ...car, ...person, ...animal].indexOf(ins) === -1,
    );

    if (home.length) lists.push(home);
    if (car.length) lists.push(car);
    if (person.length) lists.push(person);
    if (animal.length) lists.push(animal);
    if (other.length) lists.push(other);
  }

  return lists;
};
