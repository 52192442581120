import { useContext, useEffect, useState } from 'react';

import { getCollectionStatuses } from 'client/client';
import { CollectionEndStatuses } from 'constants/collection';
import SessionContext from 'contexts/session/SessionContext';
import { logError } from 'utils/datadog';

export const useCollectionsStatusPoll = () => {
  const { collections, updateCollections, loadSession } = useContext(SessionContext);
  const [runningCollections, setRunningCollections] = useState<string[]>([]);

  useEffect(() => {
    const nonFinishedCollections = collections
      .filter((collection) => {
        const includesEndStatus =
          collection.statuses.filter((status) => CollectionEndStatuses.includes(status.status))
            .length > 0;
        return !includesEndStatus;
      })
      .map((collection) => collection.collectionId);

    setRunningCollections(nonFinishedCollections);
  }, [collections]);

  useEffect(() => {
    const interval = setInterval(() => {
      runningCollections.map(async (runningCollectionId) => {
        const { changes } = await getCollectionStatuses(runningCollectionId).catch((err) => {
          const nErr = new Error('Fail to fetch collection statuses', { cause: err });
          logError(nErr, { collectionId: runningCollectionId });
          throw nErr;
        });
        const statuses = changes.filter((st) => st.status !== 'RUNNING');
        const collectionsToUpdate = collections.map((collection) => {
          if (collection.collectionId === runningCollectionId) {
            return {
              ...collection,
              statuses,
            };
          }
          return collection;
        });

        updateCollections(collectionsToUpdate);

        const status = statuses?.at(-1)?.status;

        if (status && CollectionEndStatuses.includes(status)) {
          await loadSession().catch((err) => {
            const nErr = new Error('Fail to load session', { cause: err });
            logError(nErr);
          });
        }
      });
    }, 5000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runningCollections]);
};
