import { Card, Icon, TextInput, Typography } from '@insurely/ui';
import { Control, FieldErrorsImpl, UseFormRegister } from 'react-hook-form';

import { Cancellation, DeliveryMethod } from 'client/types';
import { useIntl } from 'translations';

import { CountryCode } from 'types/v3';

import cancellationsViewStyles from '../../cancellationView.module.css';
import { FormValues } from '../../utils';

import styles from './inputfields.module.css';

export const InputFields = ({
  deliveryMethod,
  cancellation,
  register,
  errors,
}: {
  deliveryMethod: DeliveryMethod;
  control: Control<FormValues, unknown>;
  cancellation?: Cancellation;
  register: UseFormRegister<FormValues>;
  errors: Partial<
    FieldErrorsImpl<{
      externalId: string;
      deliveryMethod: NonNullable<DeliveryMethod>;
      receiver: string;
      countryCode: CountryCode;
      terminationDate: string;
      newInsuranceNumber: string;
      personalNumber: string;
    }>
  >;
}) => {
  const { formatMessage } = useIntl();

  if (deliveryMethod === DeliveryMethod.EMAIL) {
    return (
      <TextInput
        label={formatMessage({ id: 'shared.email' })}
        {...register('receiver')}
        error={!!errors.receiver}
        helperText={errors.receiver?.message}
        className={cancellation ? cancellationsViewStyles.input : styles.email}
        textLine={!!cancellation}
      />
    );
  }
  if (deliveryMethod === DeliveryMethod.SMS) {
    return (
      <div className={styles.mobileContainer}>
        <TextInput
          label={formatMessage({ id: 'shared.cell-phone-number' })}
          {...register('receiver')}
          error={!!errors.receiver}
          helperText={errors.receiver?.message}
        />
      </div>
    );
  }
  if (deliveryMethod === DeliveryMethod.API) {
    return (
      <Card className={styles.manualLinkInfoCard}>
        <span style={{ marginTop: '-8px' }}>
          <Icon name="info" aria-hidden size={28} color="var(--midnight)" />
        </span>
        <Typography component="p" variant="ParagraphCaption">
          {formatMessage({
            id: 'After you have clicked on "Create power of attorney" a link will appear that you can send to the customer manually.',
          })}
        </Typography>
      </Card>
    );
  }
  return null;
};
