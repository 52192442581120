import { zodResolver } from '@hookform/resolvers/zod';
import {
  Button,
  Icon,
  IconButton,
  Loader,
  Modal,
  Plate,
  TextInput,
  Typography,
} from '@insurely/ui';

import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { z } from 'zod';

import { ADMIN_PANEL_ROUTE, CUSTOMERS_ROUTE } from 'constants/routes';
import tableStyles from 'styles/table.module.css';
import { FormattedMessage, useIntl } from 'translations';
import { logError } from 'utils/datadog';
import { snakeCaseToCamelCase } from 'utils/formatters';

import { createNewCustomer } from '../api';
import { TableTitle } from '../comp/TableTitle/TableTitle';
import { TagsGroup } from '../comp/TagsGroup/TagsGroup';
import configStyles from '../config.module.css';
import { Customer } from '../config.types';

import styles from './configPages.module.css';

interface Props {
  customers?: Customer[];
  isFetchingCustomers: boolean;
}

interface FormValues {
  customerName: string;
}

const AddCustomerForm = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [{ loading: creatingCustomer }, doCreateNewCustomer] = useAsyncFn(
    (body: { customerName: string }) =>
      createNewCustomer(body)
        .then((resp) => navigate(`${CUSTOMERS_ROUTE}/${resp.customerId}`))
        .catch((err) => {
          const nErr = new Error('Error creating customer', { cause: err });
          logError(nErr);
          throw nErr;
        }),
    [navigate],
  );

  const handleCreateNewCustomer = (data: FormValues) => {
    doCreateNewCustomer({ customerName: data.customerName });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(
      z.object({
        customerName: z
          .string()
          .min(1, { message: formatMessage({ id: 'page.config.customers.create.form-error' }) }),
      }),
    ),
  });

  return (
    <form className={styles.form} onSubmit={handleSubmit(handleCreateNewCustomer)}>
      <TextInput
        id="customerName"
        aria-label="Customer name"
        {...register('customerName')}
        label={formatMessage({
          id: 'page.config.customers.create-new.name-label',
        })}
        error={!!errors.customerName}
        helperText={errors.customerName?.message}
        autoFocus
      />
      <Button
        type="submit"
        variant="primary"
        className={styles.formButton}
        loading={creatingCustomer}
        disabled={creatingCustomer}
      >
        <FormattedMessage id="page.config.customers.create-new-customer" />
      </Button>
    </form>
  );
};

export const Customers = ({ customers, isFetchingCustomers }: Props) => {
  const [addCustomer, setAddCustomer] = useState<boolean>(false);
  const [search, setSearch] = useState('');

  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const filteredCustomer = useMemo(() => {
    if (!customers) {
      return [];
    }
    return customers.filter(
      (c) =>
        c.customerName.toLowerCase().includes(search.toLowerCase()) ||
        c.customerId.includes(search.toLowerCase()),
    );
  }, [search, customers]);

  if (isFetchingCustomers) {
    return (
      <div style={{ position: 'relative', height: '30px' }}>
        <Loader.Content />
      </div>
    );
  }

  return (
    <Plate>
      <div className={styles.tableHeadline}>
        <TableTitle icon="company" title={formatMessage({ id: 'page.config.customers-title' })} />
        <div className={styles.headlineActions}>
          <Button
            variant="primary"
            size="medium"
            onClick={() => setAddCustomer(true)}
            icon={<Icon name="add-document" size={20} />}
          >
            <FormattedMessage id="page.config.customers.create-new-customer" />
          </Button>
          <TextInput
            startAdornment={<Icon name="search" size={20} />}
            endAdornment={
              search.length > 0 ? (
                <IconButton icon={<Icon name="close" size={20} />} onClick={() => setSearch('')} />
              ) : undefined
            }
            textInputSize="extra-small"
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
            placeholder={formatMessage({ id: 'page.config.customers.search.placeholder' })}
            label=""
          />
        </div>
      </div>
      <table className={tableStyles.table}>
        <thead>
          <tr className={classNames(tableStyles.head, configStyles.tableHeader)}>
            <th aria-labelledby="customer name" style={{ width: '40%' }}>
              <Typography component="p" variant="Label-3">
                <FormattedMessage id="page.config.customers.column.customer-name" />
              </Typography>
            </th>
            <th aria-labelledby="products" style={{ width: '45%' }}>
              <Typography component="p" variant="Label-3">
                <FormattedMessage id="page.config.customers.column.product-types" />
              </Typography>
            </th>
            <th aria-labelledby="workspaces" style={{ width: '5%', textAlign: 'center' }}>
              <Typography component="p" variant="Label-3">
                <FormattedMessage id="page.config.customers.column.clients" />
              </Typography>
            </th>
            <th aria-labelledby="teams" style={{ width: '5%', textAlign: 'center' }}>
              <Typography component="p" variant="Label-3">
                <FormattedMessage id="page.config.customers.column.user-groups" />
              </Typography>
            </th>
            <th aria-labelledby="users" style={{ width: '5%', textAlign: 'center' }}>
              <Typography component="p" variant="Label-3">
                <FormattedMessage id="page.config.customers.column.users" />
              </Typography>
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredCustomer.length === 0 && (
            <tr>
              <td colSpan={2} aria-label="no-elements">
                <div className={configStyles.emptyRow}>
                  <Typography variant="ParagraphBodySmall" component="p">
                    <FormattedMessage
                      id={
                        filteredCustomer.length === 0 && search
                          ? 'page.config.customers.empty-table-after-search'
                          : 'page.config.customers.empty-table'
                      }
                    />
                  </Typography>
                </div>
              </td>
            </tr>
          )}
          {filteredCustomer
            .sort((a, b) => a.customerName.localeCompare(b.customerName))
            .map((customer) => (
              <tr
                className={classNames(tableStyles.row, configStyles.tableRow)}
                key={customer.customerId}
                tabIndex={0}
                onClick={() =>
                  navigate(`/${ADMIN_PANEL_ROUTE}/${CUSTOMERS_ROUTE}/${customer.customerId}`)
                }
              >
                <td>
                  <Typography component="p" variant="ParagraphCaption">
                    {customer.customerName}
                  </Typography>
                </td>
                <td aria-label="tags">
                  <TagsGroup
                    tags={customer.productTypes.map((p) =>
                      formatMessage({ id: `shared.productType.${snakeCaseToCamelCase(p)}` }),
                    )}
                  />
                </td>
                <td className={styles.centerAligned}>
                  <Typography component="p" variant="Label-3">
                    {customer.numberOfClients}
                  </Typography>
                </td>
                <td className={styles.centerAligned}>
                  <Typography component="p" variant="Label-3">
                    {customer.numberOfUserGroups}
                  </Typography>
                </td>
                <td className={styles.centerAligned}>
                  <Typography component="p" variant="Label-3">
                    {customer.numberOfUsers}
                  </Typography>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Modal
        isOpen={addCustomer}
        onClose={() => setAddCustomer(false)}
        title={formatMessage({ id: 'page.config.customers.create-new-customer' })}
        width={560}
      >
        <AddCustomerForm />
      </Modal>
    </Plate>
  );
};
