import { Tabs, Typography } from '@insurely/ui';
import { useMemo } from 'react';

import { useAsync, useAsyncFn, useMount } from 'react-use';

import { Page } from 'components/Page/Page';
import { FormattedMessage, useIntl } from 'translations';

import { logError } from 'utils/datadog';

import { getAllLiveboards, getAllCustomers } from '../api';
import { AdminPanelTab as Tab } from '../config.types';
import { Customers } from '../Customers/Customers';

import { Liveboards } from '../Liveboards/Liveboards';

import { useQueryParamTab } from '../useQueryParamTab';

import styles from './adminPanel.module.css';

export const AdminPanel = () => {
  const { formatMessage } = useIntl();

  const { tab: activeTab, setTab: setActiveTab } = useQueryParamTab<Tab>(Tab.CUSTOMERS);

  const [{ loading: isFetchingLiveboards, value: liveboards }, doFetchLiveboards] =
    useAsyncFn(getAllLiveboards);

  const { loading: isFetchingCustomers, value: customers } = useAsync(async () => {
    const response = await getAllCustomers().catch((err) => {
      const nErr = new Error('Failed to fetch all customers', { cause: err });
      logError(nErr);
      throw nErr;
    });
    return response;
  });

  useMount(() => {
    if (!Object.keys(Tab).some((t) => t === activeTab)) {
      setActiveTab(Tab.CUSTOMERS, true);
    }
    Promise.all([doFetchLiveboards()]);
  });

  const renderTab = useMemo(() => {
    switch (activeTab) {
      case Tab.CUSTOMERS:
        return <Customers customers={customers} isFetchingCustomers={isFetchingCustomers} />;
      case Tab.LIVEBOARDS:
        return (
          <Liveboards
            liveboards={liveboards}
            isFetchingLiveboards={isFetchingLiveboards}
            onRefresh={doFetchLiveboards}
          />
        );
      default:
        return null;
    }
  }, [
    activeTab,
    customers,
    doFetchLiveboards,
    isFetchingCustomers,
    isFetchingLiveboards,
    liveboards,
  ]);

  return (
    <Page title={formatMessage({ id: 'page.config.title' })}>
      <Typography component="h1" variant="Headline-3" className={styles.headline}>
        <FormattedMessage id="page.config.title" />
      </Typography>
      <div className={styles.tabsWrapper}>
        <Tabs
          items={Object.values(Tab).map((t) => ({
            label: formatMessage({ id: `page.config.${t.toLowerCase()}-title` }),
            value: t,
          }))}
          activeTab={activeTab}
          onChange={(t: Tab) => setActiveTab(t)}
        />
        {renderTab}
      </div>
    </Page>
  );
};
