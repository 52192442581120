import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Modal, Notification, TextArea, TextInput } from '@insurely/ui';
import { useForm } from 'react-hook-form';

import { useParams } from 'react-router-dom';
import { useAsyncFn } from 'react-use';
import { z } from 'zod';

import { FormattedMessage, useIntl } from 'translations';

import { logError } from 'utils/datadog';

import { SmsConfig } from 'views/Config/config.types';

import {
  createCustomerSmsConfig,
  deleteCustomerSmsConfig,
  updateCustomerSmsConfig,
} from '../../../../api';

import styles from './editSmsConfigModal.module.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  smsConfig?: SmsConfig;
  onSuccess: () => void;
}

export const EditSmsConfigModal = ({ isOpen, onClose, smsConfig, onSuccess }: Props) => {
  const { formatMessage } = useIntl();
  const { customerId } = useParams() as { customerId: string };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SmsConfig>({
    defaultValues: smsConfig,
    resolver: zodResolver(
      z.object({
        senderId: z
          .string()
          .regex(/^[a-zA-Z0-9]{1,11}$/, {
            message: formatMessage({
              id: 'page.config.customer-config.edit-sms-config.invalid-field',
            }),
          })
          .min(1, {
            message: formatMessage({
              id: 'page.config.customer-config.edit-sms-config.missing-field',
            }),
          }),
        smsTemplate: z.string().min(1, {
          message: formatMessage({
            id: 'page.config.customer-config.edit-sms-config.missing-field',
          }),
        }),
      }),
    ),
  });

  const handleError = (err: Error, msg: string) => {
    const nErr = new Error(msg, { cause: err });
    logError(nErr, { customerId });
    throw nErr;
  };

  const [
    { loading: isCreatingOrUpdatingConfig, error: errorCreatingOrUpdatingConfig },
    doCreateOrUpdateSmsConfig,
  ] = useAsyncFn(
    (body: SmsConfig) => {
      if (smsConfig) {
        return updateCustomerSmsConfig({ customerId, smsConfig: body })
          .then(onSuccess)
          .catch((err) => handleError(err, 'Failed to update sms config for customer'));
      }
      return createCustomerSmsConfig({ customerId, smsConfig: body })
        .then(onSuccess)
        .catch((err) => handleError(err, 'Failed to create sms config for customer'));
    },
    [customerId, smsConfig],
  );

  const [{ loading: isDeletingSmsConfig, error: errorDeletingConfig }, doDeleteSmsConfig] =
    useAsyncFn(() =>
      deleteCustomerSmsConfig(customerId)
        .then(onSuccess)
        .catch((err) => handleError(err, 'Failed to delete sms config for customer')),
    );

  const handleOnSubmit = (values: SmsConfig) => {
    doCreateOrUpdateSmsConfig(values);
  };

  const hasError = !!errorCreatingOrUpdatingConfig || !!errorDeletingConfig;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={formatMessage({ id: 'page.config.customer-config.edit-sms-config.title' })}
      width={560}
    >
      <form className={styles.form} onSubmit={handleSubmit(handleOnSubmit)}>
        <TextInput
          label={formatMessage({
            id: 'page.config.customer-config.edit-sms-config.field.sender-id',
          })}
          error={!!errors.senderId}
          helperText={errors.senderId?.message}
          {...register('senderId')}
        />
        <TextArea
          rows={3}
          label={formatMessage({
            id: 'page.config.customer-config.edit-sms-config.field.template',
          })}
          maxLength={500}
          error={!!errors.smsTemplate}
          helperText={errors.smsTemplate?.message}
          {...register('smsTemplate')}
        />
        <div className={styles.buttons}>
          <Button
            variant="secondary"
            onClick={() => doDeleteSmsConfig()}
            loading={isDeletingSmsConfig}
          >
            <FormattedMessage id="page.config.customer-config.edit-sms-config.delete" />
          </Button>
          <div className={styles.actionButtons}>
            <Button variant="secondary" onClick={onClose}>
              <FormattedMessage id="page.config.customer-config.edit-sms-config.cancel" />
            </Button>
            <Button type="submit" loading={isCreatingOrUpdatingConfig}>
              <FormattedMessage id="page.config.customer-config.edit-sms-config.save" />
            </Button>
          </div>
        </div>
        {hasError && (
          <Notification
            status="error"
            headline={formatMessage({ id: 'page.config.customer-config.edit-sms-config.error' })}
          />
        )}
      </form>
    </Modal>
  );
};
