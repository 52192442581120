import { Typography, useToastContext } from '@insurely/ui';

import { v4 as uuid } from 'uuid';

import { useIntl } from 'translations';

import styles from './externalReferenceTag.module.css';

export const ExternalReferenceTag = ({ externalReference }: { externalReference: string }) => {
  const { dispatch } = useToastContext();
  const { formatMessage } = useIntl();

  const copyExternalReference = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    navigator.clipboard.writeText(externalReference);
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        id: uuid(),
        type: 'success',
        message: formatMessage({ id: 'The Reference ID is copied' }),
      },
    });
  };

  return (
    <button type="button" className={styles.tag} onClick={copyExternalReference}>
      <div className={styles.textContainer}>
        <Typography component="p" variant="ParagraphTiny" className={styles.text}>
          {externalReference}
        </Typography>
      </div>
    </button>
  );
};
