import { differenceInDays } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { useAsyncFn, useMount } from 'react-use';

import { getCancellations, getCollectionsInfo } from 'client/client';
import { Cancellation, CancellationStatus } from 'client/types';
import UserContext from 'contexts/user/UserContext';

import { useCompanies } from 'hooks/useCompanies';

import { logError } from 'utils/datadog';

import { ExtendedCancellation } from './types';

export const useCancellations = () => {
  const { config } = useContext(UserContext);
  const { getCompanyDisplayName } = useCompanies();
  const [nextElementsQuery, setNextElementsQuery] = useState<string | undefined>();
  const [cancellations, setCancellations] = useState<ExtendedCancellation[]>([]);
  const [firstTimeLoading, setFirstTimeLoading] = useState(true);
  const [errorLoadingCollectionInfo, setErrorLoadingCollectionInfo] = useState(false);

  const filterOutCancellations = (cancellation: Cancellation) => {
    const isProperStatus = ![
      CancellationStatus.CANCELED,
      CancellationStatus.DOCUMENT_ERROR,
      CancellationStatus.TIMED_OUT,
    ].includes(cancellation.status);

    const isWithinRetention =
      differenceInDays(new Date(cancellation.createdAt), Date.now()) <= config.retention;

    return isProperStatus && isWithinRetention;
  };

  const extractQueryParams = (url: string | null) =>
    url && url.indexOf('?') !== -1 ? url.slice(url.indexOf('?')) : undefined;

  const [{ loading, error, value }, doFetchCancellations] = useAsyncFn(async () => {
    const respCancellations = await getCancellations(nextElementsQuery).catch((err) => {
      const nErr = new Error('Fail to get cancellations', { cause: err });
      logError(nErr, { query: nextElementsQuery });
      throw nErr;
    });

    const filteredCancellations = respCancellations.cancellations.filter(filterOutCancellations);

    if (filteredCancellations.length === 0) {
      setFirstTimeLoading(false);
      return [];
    }

    const uniqueCollectionIds = [
      ...new Set(filteredCancellations.map((c) => c.insurance.collectionId)),
    ];

    try {
      const respCollections = await getCollectionsInfo(uniqueCollectionIds).catch((err) => {
        const nErr = new Error('Fail to get collections infos', { cause: err });
        logError(nErr, { collectionIds: uniqueCollectionIds });
        throw nErr;
      });
      setNextElementsQuery(extractQueryParams(respCancellations.pagingMetadata.next));
      setFirstTimeLoading(false);

      return filteredCancellations.map((c) => {
        const collectionFromId = respCollections?.find(
          (col) => col.collectionId === c.insurance.collectionId,
        );

        return {
          ...c,
          consumerId: collectionFromId?.consumerId,
          insuranceCompany: getCompanyDisplayName(collectionFromId?.insuranceCompany ?? ''),
          retained: collectionFromId?.retained ?? false,
        };
      });
    } catch {
      setErrorLoadingCollectionInfo(true);
      setFirstTimeLoading(false);
      return filteredCancellations.map((c) => ({
        ...c,
        consumerId: '',
        insuranceCompany: '',
        retained: false,
      }));
    }
  }, [nextElementsQuery]);

  useEffect(() => {
    if (value) {
      setCancellations((cur) => [...cur, ...value]);
    }
  }, [value]);

  useMount(() => {
    doFetchCancellations();
  });

  return {
    cancellations,
    firstTimeLoading,
    loading,
    error,
    canLoadMore: !!nextElementsQuery,
    loadMoreCancellations: doFetchCancellations,
    errorLoadingCollectionInfo,
  };
};
