import { Checkbox, Icon, Typography } from '@insurely/ui';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';

import { FormattedMessage } from 'translations';

import { FilterFormUtils, FilterQuery } from '../../types';

import styles from './filterDropdown.module.css';

type FilterSectionProps = {
  searchText: string;
  section: 'advisors' | 'clients' | 'companies';
  options: { label: string; value: string }[];
  form: FilterFormUtils;
};

export function FilterDropdownSection({ searchText, section, options, form }: FilterSectionProps) {
  const filteredOptions = searchText
    ? options.filter((option) => option.label?.toLowerCase()?.includes(searchText.toLowerCase()))
    : options;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!searchText && filteredOptions.length > 0);
  }, [filteredOptions.length, searchText]);

  const noEntries = filteredOptions.length === 0;

  const fieldFromSection = useMemo(() => {
    switch (section) {
      case 'advisors':
        return 'assignee';
      case 'clients':
        return 'clientIds';
      case 'companies':
      default:
        return 'selectedCompanies';
    }
  }, [section]) satisfies keyof FilterQuery;

  return (
    <div className={styles.rowContainer}>
      <button
        className={classNames(styles.rowHeader, {
          [styles.disabled]: noEntries,
        })}
        type="button"
        onClick={() => {
          setOpen(!open);
        }}
        disabled={noEntries}
        aria-label={section}
      >
        <Typography component="span" variant="Label-2" className={styles.title}>
          <FormattedMessage id={`page.collections.filters.${fieldFromSection}`} />
        </Typography>
        <div className={classNames(styles.chevron, { [styles.chevronOpen]: open })}>
          <Icon
            name="chevron-down"
            color={noEntries ? 'var(--grey-2)' : 'var(--midnight)'}
            size={24}
          />
        </div>
      </button>
      {open && (
        <ul className={styles.checkboxList}>
          {filteredOptions
            .sort((a, b) => a.label.localeCompare(b.label))
            .map((opt) => (
              <li key={`filter-${section}-${opt.value}`}>
                <Controller
                  control={form.control}
                  name={fieldFromSection}
                  render={({ field: { onChange, value } }) => (
                    <Checkbox
                      label={opt.label}
                      checked={value.includes(opt.value)}
                      onCheckedChange={(checked) => {
                        if (checked) {
                          onChange(Array.isArray(value) ? [...value, opt.value] : opt.value);
                        } else {
                          onChange(
                            Array.isArray(value) ? value.filter((v) => v !== opt.value) : '',
                          );
                        }
                      }}
                    />
                  )}
                />
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}
