import {
  CompanyLogo,
  Icon,
  Link,
  Menu,
  MenuLink,
  Select,
  Typography,
  useToastContext,
} from '@insurely/ui';

import React, { useContext, useMemo, useState } from 'react';
import { NavLink, useLocation, useSearchParams } from 'react-router-dom';
import { useAsyncFn, useWindowSize } from 'react-use';

import { changeUserWorkspace } from 'client/client';
import { CUSTOMER_DA, CUSTOMER_II, CUSTOMER_S, CUSTOMER_SP, CUSTOMER_T } from 'constants/customers';
import {
  OVERVIEW_ROUTE,
  SESSIONS_ROUTE,
  COLLECTIONS_ROUTE,
  CANCELLATIONS_ROUTE,
  COMPARISON_ROUTE,
  ADMIN_PANEL_ROUTE,
} from 'constants/routes';
import AppContext from 'contexts/app/AppContext';
import UserContext from 'contexts/user/UserContext';
import { useSelectedWorkspaceId } from 'hooks/useSelectedWorkspaceId';
import utils from 'services/utils';
import { useIntl, Language } from 'translations';
import { Permissions } from 'types/v3';

import { logError } from 'utils/datadog';
import { ProductLine } from 'utils/product-line';

import { AnalysisMenu } from './comp/AnalysisMenu/AnalysisMenu';
import { LanguageSwitch } from './comp/LanguageSwitch';
import { Profile } from './comp/Profile/Profile';
import { SupportMenu } from './comp/SupportMenu/SupportMenu';
import styles from './sideNav.module.css';

interface RouteItem {
  route: string;
  label: string;
  icon: React.ReactElement;
  isBeta?: boolean;
}

const PRIVACY_POLICY_LINKS = {
  [Language.EN]: 'https://www.insurely.com/privacy-policy',
  [Language.SV]: 'https://www.insurely.com/sv/privacy-policy',
  [Language.DA]: 'https://www.insurely.com/da/privacy-policy',
  [Language.FR]: 'https://www.insurely.com/fr/privacy-policy',
};

const LinkItem = ({ link, onClick }: { link: RouteItem; onClick: () => void }) => {
  const { search } = useLocation();

  return (
    <NavLink key={link.route} end={false} to={`${link.route}${search}`} onClick={onClick}>
      {({ isActive }) => (
        <MenuLink isActive={isActive}>
          {link.icon}
          <div className={styles.linkContent}>
            <Typography component="span" variant="ParagraphBodySmall">
              {link.label}
            </Typography>
            {link.isBeta && (
              <Typography component="span" variant="Label-4" className={styles.betaTag}>
                Beta
              </Typography>
            )}
          </div>
        </MenuLink>
      )}
    </NavLink>
  );
};

export const SideNav = () => {
  const { config, user, productLine } = useContext(UserContext);
  const { language } = useContext(AppContext);
  const { formatMessage } = useIntl();
  const { width } = useWindowSize();
  const [searchParams] = useSearchParams();
  const { dispatch } = useToastContext();

  const { selectedWorkspaceId, setSelectedWorkspaceId } = useSelectedWorkspaceId();
  const [menuOpen, setMenuOpen] = useState(false);

  const isDebug = !!searchParams.get('debug');

  const [{ loading: isSwitchWorkspace }, doSwitchWorkspace] = useAsyncFn((workspaceId: string) =>
    changeUserWorkspace(workspaceId)
      .then(() => {
        setSelectedWorkspaceId(workspaceId);
        window.location.assign(`/${OVERVIEW_ROUTE}`);
      })
      .catch((err) => {
        dispatch({
          type: 'ADD_TOAST',
          payload: {
            id: 'workspace-switch-fail-toast',
            message: formatMessage({ id: 'shared.navigation.switching-workspace.error' }),
            type: 'error',
          },
        });
        const nErr = new Error('Failed to switch workspace for user', { cause: err });
        logError(nErr, { workspaceId, userId: user?.uuid });
        throw nErr;
      }),
  );

  const workspaceOptions = useMemo(
    () => user?.hubClients.map((w) => ({ label: w.clientName, value: w.clientId })) ?? [],
    [user?.hubClients],
  );

  function havePermission(permission: Permissions) {
    return user?.permissions?.includes(permission);
  }

  const routes: RouteItem[] = [
    {
      route: OVERVIEW_ROUTE,
      label: formatMessage({ id: 'shared.navigation.overview' }),
      icon: <Icon name="house" aria-hidden size={28} />,
    },
    {
      route: SESSIONS_ROUTE,
      label: formatMessage({ id: 'shared.navigation.sessions' }),
      icon: <Icon name="document" aria-hidden size={28} />,
    },
  ];

  if (config.cancellationEnabled && productLine !== ProductLine.PENSION) {
    routes.push({
      route: CANCELLATIONS_ROUTE,
      label: formatMessage({ id: 'shared.navigation.cancellations' }),
      icon: <Icon name="compare-arrows" aria-hidden size={28} />,
    });
  }

  if (havePermission(Permissions.READ_CUSTOMER_CLIENTS)) {
    routes.push({
      route: COLLECTIONS_ROUTE,
      label: formatMessage({ id: `shared.navigation.collections.${productLine}` }),
      icon: <Icon name="award" aria-hidden size={28} />,
    });
  }

  if (
    isDebug ||
    [CUSTOMER_S, CUSTOMER_SP, CUSTOMER_T, CUSTOMER_II, CUSTOMER_DA].includes(user?.customerId ?? '')
  ) {
    routes.push({
      route: COMPARISON_ROUTE,
      label: formatMessage({ id: 'shared.navigation.comparison' }),
      icon: <Icon name="globe" aria-hidden size={28} />,
      isBeta: true,
    });
  }

  const menuBottomRoutes = [];

  if (havePermission(Permissions.INSURELY_ADMIN)) {
    menuBottomRoutes.unshift({
      route: ADMIN_PANEL_ROUTE,
      label: formatMessage({ id: 'shared.navigation.administration' }),
      icon: <Icon name="settings" aria-hidden size={28} />,
    });
  }

  const logoTypeComponent = useMemo(() => {
    const logotype = utils.getConfigItem(config)?.logotype;

    if (!logotype) {
      return null;
    }

    return (
      <div
        className={styles.logo}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: logotype }}
      />
    );
  }, [config]);

  const handleOnChangeWorkspaceId = (workspaceId: string) => {
    if (workspaceId !== selectedWorkspaceId) {
      doSwitchWorkspace(workspaceId);
    }
  };

  return (
    <Menu
      desktopOnly
      hideMenuButton={width > 990}
      stickyMenuButton
      isOpen={width > 990 || menuOpen}
      onOpen={() => setMenuOpen(!menuOpen)}
      logo={logoTypeComponent}
      className={styles.menu}
    >
      {workspaceOptions.length > 1 && (
        <div className={styles.workspaceSwitcher}>
          <Select
            id="workspace-switch"
            onChange={handleOnChangeWorkspaceId}
            options={workspaceOptions}
            label={formatMessage({ id: 'shared.navigation.workspaces' })}
            value={user?.selectedClientId ?? ''}
            fullWidth
            variant="small"
            disabled={isSwitchWorkspace}
          />
        </div>
      )}
      {routes.map((link) => (
        <LinkItem key={link.route} link={link} onClick={() => setMenuOpen(false)} />
      ))}
      <AnalysisMenu onCloseMenu={() => setMenuOpen(false)} />
      <SupportMenu onClick={() => setMenuOpen(false)} />
      <div className={styles.bottomLinks}>
        {isDebug && <LanguageSwitch />}
        {menuBottomRoutes.map((link) => (
          <LinkItem key={link.route} link={link} onClick={() => setMenuOpen(false)} />
        ))}

        <Profile />

        <div className={styles.policyAndLogo}>
          <Link
            className={styles.policyLink}
            title={formatMessage({ id: 'shared.navigation.policy-link' })}
            target="_blank"
            href={PRIVACY_POLICY_LINKS[language]}
          >
            {formatMessage({ id: 'shared.navigation.policy-link' })}
          </Link>
          <CompanyLogo company="insurely" className={styles.insurelyLogo} width="80px" />
        </div>
      </div>
    </Menu>
  );
};
