import React from 'react';
import ContentLoader from 'react-content-loader';

interface SkeletonTextProps extends Omit<React.HTMLProps<HTMLDivElement>, 'width' | 'height'> {
  width: number;
  height: number;
}

export const SkeletonText = ({ width, height, ...restProps }: SkeletonTextProps) => (
  <div {...restProps}>
    <ContentLoader
      speed={6}
      width={width}
      height={height}
      backgroundColor="var(--grey-4)"
      foregroundColor="var(--grey-3)"
    >
      <rect rx="4" width={width} height={height} />
    </ContentLoader>
  </div>
);
