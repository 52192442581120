import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Checkbox, TextInput } from '@insurely/ui';
import { useContext, useMemo } from 'react';

import { Controller, useForm } from 'react-hook-form';

import { useAsyncFn } from 'react-use';
import { z } from 'zod';

import { sendSmsInvite } from 'client/client';
import { InvitationRequest } from 'client/types';
import SessionContext from 'contexts/session/SessionContext';
import UserContext from 'contexts/user/UserContext';
import { FormattedMessage, useIntl } from 'translations';

import { CountryAreaCode } from 'types/v3';

import { logError } from 'utils/datadog';
import { marketToCountryCode } from 'utils/formatters';
import { PostHogAction, PostHogCategory, postHogCapture } from 'utils/posthog';
import { phoneNumberValidationPerMarket } from 'utils/validators';

import styles from './sendLink.module.css';

interface FormValues {
  phoneNumber: string;
  communicationConsent: boolean;
}

export default function SendViaSMS({ sessionId }: { sessionId: string }) {
  const { formatMessage } = useIntl();
  const { config } = useContext(UserContext);
  const { loadSmsInvites } = useContext(SessionContext);

  const phoneNumberRegex = phoneNumberValidationPerMarket(config.market);

  const countryCode = useMemo(() => marketToCountryCode(config.market), [config.market]);

  const schema = () =>
    z.object({
      phoneNumber: z
        .string()
        .min(1, formatMessage({ id: 'page.session.sms-form.phone-number.error' }))
        .regex(phoneNumberRegex, formatMessage({ id: 'page.session.sms-form.phone-number.error' })),
      communicationConsent: z.literal(true, {
        errorMap: () => ({
          message: formatMessage({
            id: 'page.session.sms-form.customer-approval.error',
          }),
        }),
      }),
    });

  const {
    register,
    handleSubmit,
    resetField,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onTouched',
    resolver: zodResolver(schema()),
  });

  const [{ loading: sendingSmsInvite }, doSendSmsInvite] = useAsyncFn((body: InvitationRequest) =>
    sendSmsInvite(body)
      .catch((err) => {
        const nErr = new Error('Error sending SMS invite', { cause: err });
        logError(nErr, { sessionId: body.sessionId });
        throw nErr;
      })
      .then(() => {
        postHogCapture(`tracking:${PostHogAction.CLICK}`, {
          action: PostHogAction.CLICK,
          category: PostHogCategory.GENERAL,
          object: 'sendSMSLink',
        });

        resetField('phoneNumber');
        resetField('communicationConsent');
      })
      .finally(() => {
        loadSmsInvites();
      }),
  );

  const handleOnSendSmsInvite = (data: FormValues) => {
    const countryCodeRegex = new RegExp(`(^(\\+?|00)${CountryAreaCode[countryCode]}){1}|(^0+)`);

    const parsedData: InvitationRequest = {
      sessionId,
      phoneNumber: {
        countryCode,
        number: data.phoneNumber.replace(countryCodeRegex, ''),
      },
      communicationConsent: data.communicationConsent,
    };

    doSendSmsInvite(parsedData);
  };
  return (
    <form className={styles.form} onSubmit={handleSubmit(handleOnSendSmsInvite)}>
      <div className={styles.mobileContainer}>
        <TextInput
          label={formatMessage({
            id: 'page.session.sms-form.phone-number.label',
          })}
          {...register('phoneNumber')}
          aria-label="Phone number"
          error={!!errors.phoneNumber}
          helperText={errors.phoneNumber?.message}
          id="phoneNumber"
        />
      </div>
      <Controller
        control={control}
        name="communicationConsent"
        render={({ field: { onChange, value } }) => (
          <Checkbox
            label={formatMessage({
              id: 'page.session.send-collection-link.customer-approval',
            })}
            checked={value || false}
            onCheckedChange={() => onChange(!value)}
            error={!!errors.communicationConsent}
            helperText={errors.communicationConsent?.message}
            className={styles.checkbox}
          />
        )}
      />
      <div className={styles.buttons}>
        <Button
          variant="primary"
          type="submit"
          loading={sendingSmsInvite}
          disabled={sendingSmsInvite}
        >
          <FormattedMessage id="page.session.sms-form.send-button" />
        </Button>
      </div>
    </form>
  );
}
