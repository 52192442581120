import { ReactNode, useMemo } from 'react';

import { useLocalStorage } from 'react-use';

import { LANGUAGE_LOCAL_STORAGE_KEY } from 'constants/local-storage';
import { Language } from 'translations/types';

import AppContext from './AppContext';

export const AppContextProvider = ({ children }: { children: ReactNode }) => {
  const [language, setLanguage] = useLocalStorage<Language>(
    LANGUAGE_LOCAL_STORAGE_KEY,
    Language.EN,
  );

  const appContextValue = useMemo(
    () => ({ language: language ?? Language.EN, setLanguage }),
    [language, setLanguage],
  );

  return <AppContext.Provider value={appContextValue}>{children}</AppContext.Provider>;
};
