export const computeBoardNameKey = (name: string) => {
  switch (name) {
    case 'collection-statistics':
      return 'Collection statistics';
    case 'team-performance':
      return 'Team performance';
    case 'user-journey':
      return 'User journey';
    default:
      return null;
  }
};
