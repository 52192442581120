import { Icon, IconName, Typography } from '@insurely/ui';

import styles from './tableTitle.module.css';

interface Props {
  icon: IconName;
  title: string;
}

export const TableTitle = ({ icon, title }: Props) => (
  <div className={styles.wrapper}>
    <div className={styles.iconWrapper}>
      <Icon name={icon} size={24} />
    </div>
    <Typography variant="Headline-7" component="p">
      {title}
    </Typography>
  </div>
);
