import { useCallback, useContext, useMemo } from 'react';

import UserContext from 'contexts/user/UserContext';
import { Market } from 'types/v3';

export const COMPANIES_PER_MARKET: {
  [market in Market]: Array<{ id: string; displayName: string }>;
} = {
  [Market.SE]: [
    { id: 'se-agria', displayName: 'Agria djurförsäkring' },
    { id: 'se-alecta', displayName: 'Alecta' },
    { id: 'se-amf', displayName: 'AMF' },
    { id: 'se-avanza', displayName: 'Avanza' },
    { id: 'se-bliwa', displayName: 'Bliwa' },
    { id: 'se-danske-bank', displayName: 'Danske Bank' },
    { id: 'se-dina', displayName: 'Dina' },
    { id: 'se-euroaccident', displayName: 'Euroaccident' },
    { id: 'se-evidentlife', displayName: 'Evident Life' },
    { id: 'se-folksam', displayName: 'Folksam' },
    { id: 'se-futur', displayName: 'Futur' },
    { id: 'se-gjensidige', displayName: 'Gjensidige' },
    { id: 'se-handelsbanken', displayName: 'Handelsbanken' },
    { id: 'se-hedvig', displayName: 'Hedvig' },
    { id: 'se-ica', displayName: 'ICA Försäkring' },
    { id: 'se-ica-bank', displayName: 'ICA Bank' },
    { id: 'se-if', displayName: 'If' },
    { id: 'se-if-mercedes', displayName: 'If - Mercecdes' },
    { id: 'se-kpa', displayName: 'KPA' },
    { id: 'se-lansforsakringar', displayName: 'Länsförsäkringar' },
    { id: 'se-lassie', displayName: 'Lassie' },
    { id: 'se-leaky', displayName: 'Leaky - Only internal testing' },
    { id: 'se-lf-alvsborg', displayName: 'Länsförsäkringar Älvsborg' },
    { id: 'se-lf-bergslagen', displayName: 'Länsförsäkringar Bergslagen' },
    { id: 'se-lf-blekinge', displayName: 'Länsförsäkringar Blekinge' },
    { id: 'se-lf-dalarna', displayName: 'Länsförsäkringar Dalarna' },
    { id: 'se-lf-goteborg-bohuslan', displayName: 'Länsförsäkringar Göteborg Bohuslän' },
    { id: 'se-lf-goinge-kristianstad', displayName: 'Länsförsäkringar Göinge Kristianstad' },
    { id: 'se-lf-gotland', displayName: 'Länsförsäkringar Gotland' },
    { id: 'se-lf-gavleborg', displayName: 'Länsförsäkringar Gävleborg' },
    { id: 'se-lf-halland', displayName: 'Länsförsäkringar Halland' },
    { id: 'se-lf-jamtland', displayName: 'Länsförsäkringar Jämtland' },
    { id: 'se-lf-jonkoping', displayName: 'Länsförsäkringar Jönköping' },
    { id: 'se-lf-norrbotten', displayName: 'Länsförsäkringar Norrbotten' },
    { id: 'se-lf-ostgota', displayName: 'Länsförsäkringar Östgöta' },
    { id: 'se-lf-kalmar', displayName: 'Länsförsäkringar Kalmar' },
    { id: 'se-lf-kronoberg', displayName: 'Länsförsäkringar Kronoberg' },
    { id: 'se-lf-stockholm', displayName: 'Länsförsäkringar Stockholm' },
    { id: 'se-lf-skane', displayName: 'Länsförsäkringar Skåne' },
    { id: 'se-lf-skaraborg', displayName: 'Länsförsäkringar Skaraborg' },
    { id: 'se-lf-sormland', displayName: 'Länsförsäkringar Sörmland' },
    { id: 'se-lf-uppsala', displayName: 'Länsförsäkringar Uppsala' },
    { id: 'se-lf-varmland', displayName: 'Länsförsäkringar Värmland' },
    { id: 'se-lf-vasterbotten', displayName: 'Länsförsäkringar Västerbotten' },
    { id: 'se-lf-vasternorrland', displayName: 'Länsförsäkringar Västernorrland' },
    { id: 'se-manypets', displayName: 'ManyPets' },
    { id: 'se-moderna', displayName: 'Moderna' },
    { id: 'se-movestic', displayName: 'Movestic / JustInCase' },
    { id: 'se-nordea', displayName: 'Nordea' },
    { id: 'se-nordnet', displayName: 'Nordnet' },
    { id: 'se-paydrive', displayName: 'Paydrive' },
    { id: 'se-seb', displayName: 'SEB' },
    { id: 'se-seb-bank', displayName: 'SEB Bank' },
    { id: 'se-sensor', displayName: 'Sensor' },
    { id: 'se-skandia', displayName: 'Skandia' },
    { id: 'se-soderberg-partners', displayName: 'Söderberg & Partners' },
    { id: 'se-spp', displayName: 'SPP' },
    { id: 'se-svedea', displayName: 'Svedea' },
    { id: 'se-sveland', displayName: 'Sveland Djurförsäkringar' },
    { id: 'se-svenska-fribrevsbolaget', displayName: 'Svenska Fribrevsbolaget' },
    { id: 'se-swedbank', displayName: 'Swedbank' },
    { id: 'se-trekronor', displayName: 'Swedbank / Trekronor' },
    { id: 'se-trygg-hansa', displayName: 'Trygg-Hansa' },
    { id: 'se-trygghansa', displayName: 'Trygg-Hansa' },
    { id: 'se-volvia', displayName: 'Volvia' },
    { id: 'se-watercircles', displayName: 'Watercircles' },
  ],
  [Market.FR]: [
    { id: 'fr-acheel', displayName: 'Acheel' },
    { id: 'fr-allianz', displayName: 'Allianz' },
    { id: 'fr-animauxsante', displayName: 'Animaux Santé' },
    { id: 'fr-assur-opoil', displayName: "Assur O'Poil" },
    { id: 'fr-axa', displayName: 'AXA' },
    { id: 'fr-banque-populaire', displayName: 'Banque populaire' },
    { id: 'fr-bred', displayName: 'Bred' },
    { id: 'fr-bulle-bleue', displayName: 'Bulle Bleue' },
    { id: 'fr-caisse-depargne', displayName: "Caisse D'épargne" },
    { id: 'fr-cardif', displayName: 'BNP Paribas Cardif' },
    { id: 'fr-credit-agricole', displayName: 'Crédit Agricole' },
    { id: 'fr-credit-mutuel', displayName: 'Crédit Mutuel' },
    { id: 'fr-dalma', displayName: 'Dalma' },
    { id: 'fr-direct-assurance', displayName: 'Direct Assurance' },
    { id: 'fr-eca', displayName: 'ECA' },
    { id: 'fr-generali', displayName: 'Generali' },
    { id: 'fr-gmf', displayName: 'GMF' },
    { id: 'fr-groupama', displayName: 'Groupama' },
    { id: 'fr-kozoo', displayName: 'Kozoo' },
    { id: 'fr-lolivier', displayName: "L'Olivier" },
    { id: 'fr-luko', displayName: 'Luko' },
    { id: 'fr-maaf', displayName: 'MAAF' },
    { id: 'fr-macif', displayName: 'Macif' },
    { id: 'fr-maif', displayName: 'MAIF' },
    { id: 'fr-matmut', displayName: 'Matmut' },
    { id: 'fr-mma', displayName: 'MMA' },
    { id: 'fr-santevet', displayName: 'SantéVet' },
  ],
  [Market.DK]: [
    { id: 'dk-almbrand', displayName: 'Alm. Brand' },
    { id: 'dk-alka', displayName: 'Alka' },
    { id: 'dk-bauta', displayName: 'Bauta' },
    { id: 'dk-codan', displayName: 'Codan' },
    { id: 'dk-fdm', displayName: 'FDM' },
    { id: 'dk-gfforsikring', displayName: 'GF Forsikring' },
    { id: 'dk-gjensidige', displayName: 'Gjensidige' },
    { id: 'dk-ida', displayName: 'IDA Forsikring' },
    { id: 'dk-if', displayName: 'If' },
    { id: 'dk-lb', displayName: 'LB Forsikring' },
    { id: 'dk-lbforsikringpfa', displayName: 'LB Forsikring PFA' },
    { id: 'dk-runa', displayName: 'Runa' },
    { id: 'dk-tjm', displayName: 'TJM Forsikring' },
    { id: 'dk-topdanmark', displayName: 'Topdanmark' },
    { id: 'dk-tryg', displayName: 'Tryg' },
  ],
  [Market.EE]: [
    { id: 'ee-bta', displayName: 'BTA' },
    { id: 'ee-ergo', displayName: 'Ergo' },
    { id: 'ee-gjensidige', displayName: 'Gjensidige' },
    { id: 'ee-if', displayName: 'If' },
    { id: 'ee-lhv', displayName: 'LHV' },
    { id: 'ee-pzu', displayName: 'PZU' },
    { id: 'ee-salva', displayName: 'Salva' },
    { id: 'ee-seesam', displayName: 'Seesam' },
    { id: 'ee-swedbank', displayName: 'Swedbank' },
  ],
};

export const useCompanies = () => {
  const { config } = useContext(UserContext);

  const getCompanyDisplayName = useCallback((id: string) => {
    if (id.includes('demo')) {
      return 'Demo company';
    }
    return (
      Object.values(COMPANIES_PER_MARKET)
        .flat()
        .find((c) => c.id === id)?.displayName ?? id
    );
  }, []);

  const companiesPerMarket = useMemo(() => COMPANIES_PER_MARKET[config.market], [config.market]);

  // Some companies like LF have subsidiraries per region. In order to properly identify it
  // we use this method. It can be used to display the proper logo for instance.
  const getPrimaryCompanyFromSubsidiary = useCallback((name: string) => {
    if (name.includes('se-lf')) {
      return 'se-lansforsakringar';
    }
    return name;
  }, []);

  return {
    getCompanyDisplayName,
    getPrimaryCompanyFromSubsidiary,
    companiesPerMarket,
  };
};
