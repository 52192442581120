import { Button, Loader, Plate, Typography } from '@insurely/ui';

import { useContext, useMemo, useState } from 'react';

import UserContext from 'contexts/user/UserContext';
import { FormattedMessage, useIntl } from 'translations';

import { Liveboard } from 'types/liveboard';
import { Permissions } from 'types/v3';

import { TableTitle } from 'views/Config/comp/TableTitle/TableTitle';
import { BaseClientConfig, SmsConfig, User, UserGroup } from 'views/Config/config.types';

import { EditEmailsModal } from './comp/EditEmailsModal/EditEmailsModal';
import { EditLiveboardsModal } from './comp/EditLiveboardsModal/EditLiveboardsModal';
import { EditSmsConfigModal } from './comp/EditSmsConfigModal/EditSmsConfigModal';
import styles from './overview.module.css';

type Source = 'clients' | 'user-groups' | 'users' | 'liveboards' | 'emails' | 'sms-config';

interface Props {
  onAccess: (tab: Source) => void;
  entities: {
    users?: User[];
    clients?: BaseClientConfig[];
    userGroups?: UserGroup[];
    liveboards?: Liveboard[];
    emails?: string[];
    smsConfig?: SmsConfig;
  };
  isFetchingEntities: boolean;
  refreshers: {
    refreshEmails: () => void;
    refreshSmsConfig: () => void;
    refreshLiveboards: () => void;
  };
}

const PlateWrapper = ({
  items,
  source,
  onAccess,
  showCount = true,
  children,
}: {
  items: string[];
  source: Source;
  onAccess?: () => void;
  showCount?: boolean;
  children: React.ReactNode;
}) => {
  const { formatMessage } = useIntl();

  const icon = useMemo(() => {
    switch (source) {
      case 'clients':
        return 'settings';
      case 'user-groups':
        return 'personal';
      case 'users':
        return 'user';
      case 'emails':
        return 'globe';
      case 'sms-config':
        return 'broken-device';
      case 'liveboards':
      default:
        return 'bar-chart';
    }
  }, [source]);

  return (
    <Plate className={styles.plate}>
      <div>
        <div className={styles.titleWrapper}>
          <TableTitle
            title={formatMessage(
              { id: `page.config.customer-config.overview.${source}` },
              { count: showCount ? items.length : 0 },
            )}
            icon={icon}
          />
        </div>
        {children}
      </div>
      {onAccess && (
        <div className={styles.buttonContainer}>
          <Button size="small" variant="secondary" onClick={onAccess}>
            <FormattedMessage id={`page.config.customer-config.overview.access-${source}`} />
          </Button>
        </div>
      )}
    </Plate>
  );
};

const OverviewPlate = ({
  items,
  source,
  onAccess,
}: {
  items?: string[];
  source: Source;
  onAccess?: () => void;
}) => (
  <PlateWrapper items={items ?? []} source={source} onAccess={onAccess}>
    <div className={styles.plateItems}>
      {!items && (
        <div className={styles.loaderWrapper}>
          <Loader.Content />
        </div>
      )}
      {items?.length === 0 && (
        <Typography variant="ParagraphCaption" component="p" style={{ color: 'var(--grey-1)' }}>
          <FormattedMessage id={`page.config.customer-config.overview.empty-${source}`} />
        </Typography>
      )}
      {items
        ?.sort((a, b) => a.localeCompare(b))
        .map((c) => (
          <div key={c}>
            <Typography variant="ParagraphBodySmall" component="p">
              {c || '-'}
            </Typography>
          </div>
        ))}
    </div>
  </PlateWrapper>
);

const SmsConfigPlate = ({
  smsConfig,
  onAccess,
}: {
  smsConfig?: SmsConfig;
  onAccess: () => void;
}) => (
  <PlateWrapper
    items={smsConfig ? [smsConfig?.senderId] : []}
    showCount={false}
    source="sms-config"
    onAccess={onAccess}
  >
    <div className={styles.smsConfigPlate}>
      {smsConfig ? (
        <div className={styles.smsConfigContent}>
          <div>
            <Typography variant="Label-2" component="p">
              <FormattedMessage id="page.config.customer-config.edit-sms-config.field.sender-id" />
            </Typography>
            <Typography variant="ParagraphBodySmall" component="p">
              {smsConfig?.senderId}
            </Typography>
          </div>
          <div>
            <Typography variant="Label-2" component="p">
              <FormattedMessage id="page.config.customer-config.edit-sms-config.field.template" />
            </Typography>
            <Typography variant="ParagraphBodySmall" component="p">
              {smsConfig?.smsTemplate}
            </Typography>
          </div>
        </div>
      ) : (
        <Typography variant="ParagraphCaption" component="p" style={{ color: 'var(--grey-1)' }}>
          <FormattedMessage id="page.config.customer-config.overview.empty-sms-config" />
        </Typography>
      )}
    </div>
  </PlateWrapper>
);

export const Overview = ({ onAccess, entities, isFetchingEntities, refreshers }: Props) => {
  const { user } = useContext(UserContext);

  const [modalState, setModalState] = useState({
    emails: false,
    smsConfig: false,
    liveboards: false,
  });
  const isInsurelyAdmin = useMemo(
    () => user?.permissions.includes(Permissions.INSURELY_ADMIN),
    [user?.permissions],
  );

  const { users, clients, userGroups, liveboards, emails, smsConfig } = entities;

  const { refreshEmails, refreshSmsConfig, refreshLiveboards } = refreshers;

  if (isFetchingEntities) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader.Content />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <OverviewPlate
        items={clients?.map((c) => c.clientName)}
        source="clients"
        onAccess={() => onAccess('clients')}
      />
      <OverviewPlate
        items={userGroups?.map((u) => u.name)}
        source="user-groups"
        onAccess={() => onAccess('user-groups')}
      />
      <OverviewPlate
        items={users?.map((u) => u.email)}
        source="users"
        onAccess={() => onAccess('users')}
      />
      <OverviewPlate
        items={liveboards?.map((l) => l.description)}
        source="liveboards"
        onAccess={
          isInsurelyAdmin ? () => setModalState((cur) => ({ ...cur, liveboards: true })) : undefined
        }
      />
      <OverviewPlate
        items={emails}
        source="emails"
        onAccess={
          isInsurelyAdmin ? () => setModalState((cur) => ({ ...cur, emails: true })) : undefined
        }
      />
      <SmsConfigPlate
        smsConfig={smsConfig}
        onAccess={() => setModalState((cur) => ({ ...cur, smsConfig: true }))}
      />
      {modalState.emails && (
        <EditEmailsModal
          isOpen={modalState.emails}
          onClose={() => setModalState((cur) => ({ ...cur, emails: false }))}
          emails={emails ?? []}
          onSuccess={() => {
            setModalState((cur) => ({ ...cur, emails: false }));
            refreshEmails();
          }}
        />
      )}
      {modalState.smsConfig && (
        <EditSmsConfigModal
          isOpen={modalState.smsConfig}
          onClose={() => setModalState((cur) => ({ ...cur, smsConfig: false }))}
          smsConfig={smsConfig}
          onSuccess={() => {
            setModalState((cur) => ({ ...cur, smsConfig: false }));
            refreshSmsConfig();
          }}
        />
      )}
      {modalState.liveboards && (
        <EditLiveboardsModal
          isOpen={modalState.liveboards}
          liveboards={liveboards ?? []}
          onClose={() => setModalState((cur) => ({ ...cur, liveboards: false }))}
          onSuccess={() => {
            setModalState((cur) => ({ ...cur, liveboards: false }));
            refreshLiveboards();
          }}
        />
      )}
    </div>
  );
};
