import { Route, Routes } from 'react-router-dom';

import {
  REGISTER_ROUTE,
  ENABLE_ACCOUNT_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  LOGIN_MAGIC_LINK_ROUTE,
  SSO_LOGIN_ROUTE,
} from 'constants/routes';

import { EnableAccount } from './EnableAccount/EnableAccount';
import { ForgotPassword } from './ForgotPassword/ForgotPassword';
import { Login } from './Login';
import styles from './login.module.css';
import { MagicLink } from './MagicLink/MagicLink';
import { Register } from './Register/Register';
import { SSO } from './SSO/SSO';

export const LoginPages = () => (
  <div className={styles.container}>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path={SSO_LOGIN_ROUTE} element={<SSO />} />
      <Route path={LOGIN_MAGIC_LINK_ROUTE} element={<MagicLink />} />
      <Route path={REGISTER_ROUTE} element={<Register />} />
      <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPassword />} />
      <Route path={ENABLE_ACCOUNT_ROUTE} element={<EnableAccount />} />
    </Routes>
  </div>
);
