import { zodResolver } from '@hookform/resolvers/zod';
import { CompanyLogo, Button, TextInput, Typography, Notification } from '@insurely/ui';
import { useForm } from 'react-hook-form';

import { useAsyncFn } from 'react-use';
import { z } from 'zod';

import { changePassword } from 'client/client';
import { IntlShape, useIntl } from 'translations';

import { logError } from 'utils/datadog';

import styles from '../login.module.css';

interface FormValues {
  email: string;
  password: string;
  repeatPassword: string;
}

const schema = (formatMessage: IntlShape['formatMessage']) =>
  z
    .object({
      password: z.string().min(1, { message: formatMessage({ id: 'Please enter a password' }) }),
      repeatPassword: z
        .string()
        .min(1, { message: formatMessage({ id: 'page.login.please-repeat-password' }) }),
    })
    .refine((data) => data.password.length >= 8, {
      message: formatMessage({ id: 'page.login.password-validation' }),
      path: ['password'],
    })
    .refine((data) => data.password === data.repeatPassword, {
      message: formatMessage({ id: 'The passwords needs to match' }),
      path: ['repeatPassword'],
    });

interface ResetPassWordProps {
  onChangeSuccess: () => void;
  token: string;
}

export default function ResetPassword({ onChangeSuccess, token }: ResetPassWordProps) {
  const { formatMessage } = useIntl();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    trigger,
    getValues,
  } = useForm<FormValues>({
    mode: 'onTouched',
    delayError: 500,
    resolver: zodResolver(schema(formatMessage)),
    defaultValues: {
      password: '',
    },
  });

  const [{ loading: isChangingPassword, error: errorChangingPassword }, doChangePassword] =
    useAsyncFn(
      ({ password, repeatPassword }: { password: string; repeatPassword: string }) =>
        changePassword({ token, password, repeatPassword })
          .then(() => onChangeSuccess())
          .catch((err) => {
            const { data } = err.response;
            const nErr = new Error(data?.localizedMessage || data?.message, { cause: err });

            logError(nErr);
            throw nErr;
          }),
      [token],
    );

  return (
    <form className={styles.form} onSubmit={handleSubmit(doChangePassword)}>
      <CompanyLogo company="se-demo" width="56px" aria-label="Insurely" />
      <Typography component="h1" variant="Headline-4" className={styles.signInTitle}>
        {formatMessage({ id: 'Set new password' })}
      </Typography>

      {errorChangingPassword && (
        <Notification
          className={styles.notification}
          headline={errorChangingPassword.message}
          status="error"
        />
      )}

      <TextInput
        {...register('password')}
        label={formatMessage({ id: 'page.login.password' })}
        type="password"
        className={styles.formInput}
        error={!!errors.password}
        helperText={
          watch('password').length < 8 && !errors.password?.message
            ? formatMessage({ id: 'page.login.password-validation' })
            : errors.password?.message
        }
        onBlur={() => {
          if (getValues('password')) {
            trigger('password');
          }
        }}
        autoFocus
      />
      <TextInput
        {...register('repeatPassword')}
        label={formatMessage({ id: 'page.login.repeat-password' })}
        type="password"
        className={styles.formInput}
        error={!!errors.repeatPassword}
        helperText={errors.repeatPassword?.message}
        onBlur={() => {
          if (getValues('repeatPassword')) {
            trigger('repeatPassword');
          }
        }}
      />
      <Button
        variant="primary"
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
        type="submit"
        loading={isChangingPassword}
        disabled={isChangingPassword}
      >
        {formatMessage({ id: 'Save new password' })}
      </Button>
    </form>
  );
}
