import { Typography } from '@insurely/ui';

import { FormattedMessage, useIntl } from 'translations';

import { useFormatting } from 'translations/useFormatting';
import { FinancialProduct } from 'types/wealth';
import { groupByDate } from 'utils/insurance';

import { PensionCard } from './comp/PensionCard/PensionCard';
import styles from './pensionListing.module.css';

export const PensionListing = ({ pensions }: { pensions: FinancialProduct[] }) => {
  const { formatMessage } = useIntl();
  const { formatDateTime } = useFormatting();
  const groupedItems = groupByDate(pensions);

  return (
    <div className={styles.wrapper}>
      <Typography component="h2" variant="Headline-5" className={styles.title}>
        {formatMessage({ id: 'Collected pensions' })}
      </Typography>

      <div className={styles.section}>
        {Object.keys(groupedItems)
          .reverse()
          .map((date) => (
            <div key={date}>
              <Typography component="p" variant="ParagraphCaption">
                <FormattedMessage
                  id="page.pension-listing.collect-at"
                  values={{ date: formatDateTime(date) }}
                />
              </Typography>
              {groupedItems[date].map((pen: FinancialProduct) => (
                <PensionCard key={pen.id} pension={pen} />
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};
