import { Button, Loader, Plate, Typography } from '@insurely/ui';
import { useMemo } from 'react';

import { FormattedMessage, useIntl } from 'translations';

import { Liveboard } from 'types/liveboard';
import { TableTitle } from 'views/Config/comp/TableTitle/TableTitle';

import { MinimalClientConfig, User } from 'views/Config/config.types';

import styles from './userGroupConfig.module.css';

type Source = 'users' | 'liveboards' | 'clients';

interface Props {
  entities: {
    clients?: MinimalClientConfig[];
    liveboards?: Liveboard[];
    users?: User[];
  };
  isFetchingEntities: boolean;
  onAccess: (tab: Source) => void;
}

const OverviewPlate = ({
  items,
  source,
  onAccess,
}: {
  items: string[];
  source: Source;
  onAccess?: () => void;
}) => {
  const { formatMessage } = useIntl();

  const icon = useMemo(() => {
    switch (source) {
      case 'clients':
        return 'settings';
      case 'users':
        return 'user';
      case 'liveboards':
      default:
        return 'bar-chart';
    }
  }, [source]);

  return (
    <Plate className={styles.overviewPlate}>
      <div>
        <div className={styles.overviewPlateTitle}>
          <TableTitle
            title={formatMessage(
              { id: `page.config.user-group-config.overview.${source}` },
              { count: items.length },
            )}
            icon={icon}
          />
        </div>
        <div className={styles.plateItems}>
          {items.length === 0 && (
            <Typography variant="ParagraphCaption" component="p" style={{ color: 'var(--grey-1)' }}>
              <FormattedMessage id={`page.config.user-group-config.overview.empty-${source}`} />
            </Typography>
          )}
          {items
            .sort((a, b) => a.localeCompare(b))
            .map((c) => (
              <div key={c}>
                <Typography variant="ParagraphBodySmall" component="p">
                  {c || '-'}
                </Typography>
              </div>
            ))}
        </div>
      </div>
      {onAccess && (
        <div className={styles.accessButtonContainer}>
          <Button size="small" variant="secondary" onClick={onAccess}>
            <FormattedMessage id={`page.config.user-group-config.overview.access-${source}`} />
          </Button>
        </div>
      )}
    </Plate>
  );
};

export const UserGroupOverview = ({ entities, isFetchingEntities, onAccess }: Props) => {
  const { users, clients, liveboards } = entities;

  if (isFetchingEntities || !users || !clients || !liveboards) {
    return (
      <div style={{ height: '30px', position: 'relative' }}>
        <Loader.Content />
      </div>
    );
  }

  return (
    <div className={styles.overviewContainer}>
      <OverviewPlate
        items={clients.map((c) => c.clientName)}
        source="clients"
        onAccess={() => onAccess('clients')}
      />
      <OverviewPlate
        items={users.map((u) => u.email)}
        source="users"
        onAccess={() => onAccess('users')}
      />
      <OverviewPlate
        items={liveboards.map((l) => l.description)}
        source="liveboards"
        onAccess={() => onAccess('liveboards')}
      />
    </div>
  );
};
