import { CollectionStatus } from 'types/v3';

export const CollectionEndStatuses: CollectionStatus[] = [
  CollectionStatus.COMPLETED,
  CollectionStatus.COMPLETED_PARTIAL,
  CollectionStatus.COMPLETED_EMPTY,
  CollectionStatus.FAILED,
  CollectionStatus.WAITING_FOR_AUTHENTICATION,
  CollectionStatus.AUTHENTICATION_CANCELLED,
  CollectionStatus.AUTHENTICATION_CONFLICT,
  CollectionStatus.INCORRECT_CREDENTIALS,
  CollectionStatus.CUSTOMER_ENROLLMENT_REQUIRED,
  CollectionStatus.CONTACT_FORM,
  CollectionStatus.KYC_FORM,
  CollectionStatus.REJECTED_INCORRECT_LOGIN_METHOD,
];
