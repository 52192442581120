import { Icon, Notification } from '@insurely/ui';
import classNames from 'classnames';

import styles from './index.module.css';

interface EmptyStateProps {
  headline: string;
  description: string;
  className?: string;
}

const EmptyState = ({ headline, description, className }: EmptyStateProps) => (
  <Notification
    className={classNames(styles.notification, className)}
    headline={headline}
    description={description}
    status="info"
    customIcon={
      <div className={styles.iconWrapper}>
        <Icon name="no-file" aria-hidden size={40} />
      </div>
    }
  />
);

export default EmptyState;
