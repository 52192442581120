import { ProductLine } from 'utils/product-line';
import { ProductType } from 'views/Config/config.types';

import { ProductInterface } from './type';

export const computeProductType = ({
  productLine,
  productInterface,
}: {
  productLine: ProductLine;
  productInterface: ProductInterface;
}) => {
  if (productLine === ProductLine.INSURANCE && productInterface === 'hub') {
    return ProductType.INSIGHTS;
  }
  if (productLine === ProductLine.INSURANCE && productInterface === 'api') {
    return ProductType.SALES_OPTIMIZATION;
  }
  if (productLine === ProductLine.PENSION && productInterface === 'hub') {
    return ProductType.SST_PENSION;
  }
  if (productLine === ProductLine.PENSION && productInterface === 'api') {
    return ProductType.PENSION;
  }
  return ProductType.INSIGHTS;
};

export const computeProductLine = (productType: ProductType) => {
  switch (productType) {
    case ProductType.SST_PENSION:
    case ProductType.PENSION:
      return ProductLine.PENSION;
    case ProductType.INSIGHTS:
    case ProductType.SALES_OPTIMIZATION:
    default:
      return ProductLine.INSURANCE;
  }
};

export const computeTarget = (productType: ProductType): ProductInterface => {
  switch (productType) {
    case ProductType.SST_PENSION:
    case ProductType.INSIGHTS:
      return 'hub';
    case ProductType.PENSION:
    case ProductType.CHECKOUT:
    default:
      return 'api';
  }
};

export const generateBaseUrlLink = ({
  baseUrl,
  customerId,
  configurationName,
}: {
  baseUrl: string;
  customerId: string;
  configurationName: string;
}) => `${baseUrl}?customerId=${customerId}&configName=${configurationName}&sessionId=`;
