import { newRequestWithToken } from 'client/client';

import { Language } from 'translations';
import { Market, Permissions } from 'types/v3';
import { InsuranceSubType, InsuranceType } from 'types/v3/Insurance';
import { FinancialProductType } from 'types/wealth';

import { formatMarket } from 'utils/formatters';

import { ProductLine } from 'utils/product-line';

import { User } from '../config.types';

export const getAllPermissions = async (): Promise<Permissions[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/permissions`,
  });
  return res?.data;
};

export const getAllUsers = async (customerId: string): Promise<User[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/customer/${customerId}/users`,
  });
  return res?.data?.users;
};

export const getAllCompanies = async (props?: {
  market?: Market;
  productLine?: ProductLine;
}): Promise<string[]> => {
  const { market, productLine } = props || {};

  const params = [
    market && `market=${market.toUpperCase()}`,
    productLine && `productLine=${productLine.toUpperCase()}`,
  ]
    .filter(Boolean)
    .join('&');

  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/companies?${params}`,
  });
  return res?.data;
};

export const getAllProductLines = async (): Promise<ProductLine[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/product-lines`,
  });
  return res?.data;
};

export const getAllInsuranceTypes = async (): Promise<InsuranceType[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/insurance-types`,
  });
  return res?.data;
};

export const getAllInsuranceSubTypes = async (): Promise<InsuranceSubType[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/insurance-sub-types`,
  });
  return res?.data;
};

export const getAllMarkets = async (): Promise<Market[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/markets`,
  });
  return res?.data.map(formatMarket);
};

export const getAllLanguages = async (): Promise<Language[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/languages`,
  });
  return res?.data;
};

export const getAllProductTypes = async (): Promise<string[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/product-types`,
  });
  return res?.data;
};

export const getAllWealthTypes = async (): Promise<FinancialProductType[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/wealth-types`,
  });
  return res?.data;
};
