export const dashboardCustomizations = {
  style: {
    customCSS: {
      variables: {
        '--ts-var-root-color': '#213123',
        '--ts-var-button--secondary-background': '#F7F5FF',
        '--ts-var-button--secondary--hover-background': '#F7F5FF',
        '--ts-var-root-background': 'transparent',
        '--ts-var-button--primary--font-family': 'Insurely Sans',
        '--ts-var-root-font-family': 'Insurely Sans',
        '--ts-var-viz-border-radius': '8px',
        '--ts-var-viz-box-shadow': 'none',
        '--ts-var-button--primary-background': '#213123',
        '--ts-var-button--primary-color': '#FFFFFF',
        '--ts-var-button--primary--hover-background': '#213123',
      },
      rules_UNSTABLE: {
        '.pinboard-header-module__headerContainer': {
          display: 'none !important',
        },
        '.pinboard-header-module__pinboardLbEmbedHeader': {
          padding: '0',
        },
        '.pinboard-content-module__tile': {
          'box-shadow': 'none',
          border: '1px solid #E1E3E2',
        },
        '.chip-base-module__filter': {
          border: '1px solid #213123',
          'background-color': 'transparent',
        },
        '.chip-base-module__filter:hover': {
          border: '1px solid #213123',
          'background-image': 'linear-gradient(rgb(0 0 0/8%) 0 0)',
          'background-color': 'transparent',
        },
        '.button-module__backgroundLight.button-module__secondary': {
          border: '1px solid #213123',
          'background-color': 'transparent',
        },
        '.button-module__backgroundLight.button-module__secondary:hover': {
          border: '1px solid #213123',
          'background-image': 'linear-gradient(rgb(0 0 0/8%) 0 0)',
          'background-color': 'transparent',
        },
        '.tab-module__tabHorizontal .tab-module__headerItem:after': {
          'background-color': '#00884f',
        },
        '.tab-module__tabHorizontal .tab-module__headerItem.tab-module__headerItemSelected .tab-module__headerText':
          {
            color: '#00884f',
          },
        '.tab-module__tabHorizontal .tab-module__headerItem:hover .tab-module__headerText': {
          color: '#1d232f',
        },
        'input[type=checkbox].checkbox-module__nativeCheckbox:checked+.checkbox-module__customCheckbox':
          {
            color: '#00884f',
            'border-color': '#00884f',
          },
        '.pivot-table .dx-pivotgrid .dx-area-description-cell.dx-pivotgrid-background, .pivot-table .dx-pivotgrid .dx-column-header, .pivot-table .dx-pivotgrid .dx-data-header':
          {
            'background-color': '#f8f4f1',
          },
        '.dx-pivotgrid .dx-total': {
          'background-color': '#f8f4f1',
        },
        '.dx-pivotgrid .dx-area-tree-view td.dx-white-space-column': {
          'background-color': '#f8f4f1',
        },
        '.change-analysis-modal-module__legendContainer__outliersIndicator': {
          'background-color': '#00884f',
        },
        '.segment-control-module__segmentedControl .segment-control-module__segmentedItem.segment-control-module__selected .segment-control-module__innerText':
          {
            color: '#213123',
          },
        '.select-module__select .select-module__selectOpen.select-module__selectHead': {
          'border-color': '#213123',
        },
        '[data-radiant-v2] .input-base-module__container.input-base-module__hasFocus': {
          'border-color': '#213123',
        },
        '.date-picker-module__button.date-picker-module__textButton': {
          color: '#213123',
        },
        '.react-datepicker__day.react-datepicker__day--selected': {
          color: '#213123',
        },
        '.pinboard-tab-module__tabNameContainer .pinboard-tab-module__tabNameEditor.pinboard-tab-module__tabHighlightOnSelected':
          {
            color: '#00884F',
          },
        '.pinboard-tab-module__tabContainer.pinboard-tab-module__tabSelected': {
          'border-bottom-color': '#00884F',
        },
        '.toggle-module__toggleCheckbox:checked+.toggle-module__toggleSwitchBackground .toggle-module__toggleSwitchMarker':
          {
            'background-color': '#00884F',
            'border-color': '#00884F',
          },
        '.toggle-module__toggleCheckbox:checked+.toggle-module__toggleSwitchBackground': {
          'background-color': '#DAF4DD',
        },
      },
    },
  },
};
