import { Button, Icon, IconButton, TextInput, Typography } from '@insurely/ui';
import { useState } from 'react';

import { FormattedMessage } from 'translations';

import styles from './addToList.module.css';

interface Props {
  values: string[];
  onChange: (val: string[]) => void;
  title: string | React.ReactNode;
  placeholder?: string;
}

export const AddToList = ({ values, onChange, title, placeholder }: Props) => {
  const [input, setInput] = useState('');

  const handleOnAdd = () => {
    if (input.trim().length > 0) {
      onChange([...values, input]);
      setInput('');
    }
  };

  const handleOnRemove = (r: string) => {
    onChange(values.filter((v) => v !== r));
  };

  return (
    <div>
      {typeof title === 'string' ? (
        <Typography variant="Label-2" component="p">
          {title}
        </Typography>
      ) : (
        title
      )}
      <div className={styles.input}>
        <TextInput
          textInputSize="small"
          label=""
          placeholder={placeholder}
          value={input}
          onChange={(e) => setInput(e.currentTarget.value)}
        />
        <Button onClick={handleOnAdd} disabled={input.trim().length === 0}>
          <FormattedMessage id="page.config.add-to-list.add" />
        </Button>
      </div>
      <div className={styles.container}>
        {values.map((v) => (
          <div key={v} className={styles.item}>
            <Typography variant="ParagraphCaption" component="p" style={{ color: 'var(--green)' }}>
              {v}
            </Typography>
            <IconButton onClick={() => handleOnRemove(v)} icon={<Icon name="close" size={14} />} />
          </div>
        ))}
      </div>
    </div>
  );
};
