import { newRequest, newRequestWithToken } from 'client/client';
import { PackageVersion } from 'components/ComparisonTable/types';
import { Parameter } from 'types/insurance';
import { Market } from 'types/v3';
import { InsuranceSubType, InsuranceType } from 'types/v3/Insurance';

export type InsurancePackage = {
  insuranceType: InsuranceType;
  insuranceSubType: InsuranceSubType;
  insuranceCompany: string;
  packageName: string;
  market: Market;
  termsDate: string;
};

export const fetchCoveragePackages = async ({
  market,
  clientId,
}: {
  market: Market;
  clientId: string;
}): Promise<InsurancePackage[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/coverage/package-scopes?market=${market}`,
    headers: { 'client-id': clientId },
  });
  return res?.data?.packageScopes;
};

export const fetchPackageVersions = async ({
  body,
  clientId,
}: {
  body: {
    market: Market;
    insuranceType: InsuranceType;
    insuranceSubType: InsuranceSubType;
    coverageCompany: string;
    packageName: string;
  };
  clientId: string;
}): Promise<PackageVersion[]> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/coverage/package-versions`,
    headers: { 'Insurely-Version': '2023-03-15', 'client-id': clientId },
    data: body,
  });
  return res?.data?.versions.reverse();
};

export const fetchEquivalentPackageScopes = async ({
  body,
  clientId,
}: {
  body: {
    market: Market;
    insuranceType: InsuranceType;
    insuranceSubType: InsuranceSubType;
    coverageCompany: string;
    packageName: string;
    versionId: string;
    targetCompany: string;
  };
  clientId: string;
}): Promise<InsurancePackage[]> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/coverage/equivalent-package-scopes`,
    headers: { 'Insurely-Version': '2023-03-15', 'client-id': clientId },
    data: {
      packageScope: {
        market: body.market,
        insuranceType: body.insuranceType,
        insuranceSubType: body.insuranceSubType,
        coverageCompany: body.coverageCompany,
        packageName: body.packageName,
      },
      coveragePolicyData: {
        residentialStatus: null,
        ownershipStatus: null,
        region: null,
        otherInsuranceHolder: null,
        versionId: body.versionId,
      },
      targetCompany: body.targetCompany,
    },
  });

  return res?.data?.packageScopes
    .map((s: { packageScope: InsurancePackage }) => s.packageScope)
    .flat();
};

export const getParameters = async ({
  clientId,
  insuranceCompany,
  insuranceName,
  insuranceSubType,
  insuranceType,
  versionId,
}: {
  insuranceType: string;
  insuranceSubType: string;
  insuranceName: string;
  insuranceCompany: string;
  versionId: string;
  clientId: string;
}): Promise<Parameter[]> => {
  const res = await newRequest({
    method: 'POST',
    url: `/insurance-collection/get-parameters`,
    headers: { 'client-id': clientId, 'Insurely-Version': '2023-03-15' },
    data: {
      insuranceCompany,
      insuranceName,
      insuranceSubType,
      insuranceType,
      coveragePolicyData: {
        residentialStatus: null,
        ownershipStatus: null,
        region: null,
        otherInsuranceHolder: null,
        versionId,
      },
    },
  });
  return res?.data;
};

export const fetchAvailablePackageScopes = async ({
  collectionId,
  insuranceId,
  clientId,
}: {
  collectionId: string;
  insuranceId: string;
  clientId: string;
}): Promise<InsurancePackage[]> => {
  const res = await newRequestWithToken({
    url: `/coverage/advisory/scopes/${collectionId}/${insuranceId}`,
    headers: { 'client-id': clientId },
  });

  return res?.data?.packageScopes;
};
