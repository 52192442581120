import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Loader, Modal, MultiSelect, Notification, TextInput } from '@insurely/ui';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useParams } from 'react-router-dom';
import { useAsync, useAsyncFn } from 'react-use';

import { z } from 'zod';

import { FormattedMessage, useIntl } from 'translations';

import { Liveboard } from 'types/liveboard';

import {
  assignLiveboardToCustomer,
  createThoughtspotUser,
  getAllLiveboards,
  getAllThoughtspotUsers,
  removeLiveboardFromCustomer,
} from '../../../../api';

import styles from './editLiveboardsModal.module.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  liveboards: Liveboard[];
  onSuccess: () => void;
}

interface FormValues {
  selectedLiveboards: string[];
  thoughtspotUsername: string;
}

export const EditLiveboardsModal = ({ isOpen, onClose, liveboards, onSuccess }: Props) => {
  const { formatMessage } = useIntl();
  const { customerId } = useParams() as { customerId: string };

  const { value: thoughtspotUsers } = useAsync(getAllThoughtspotUsers);

  const isMissingFromThoughtspot = thoughtspotUsers
    ? !thoughtspotUsers.some((t) => t.customerId === customerId)
    : false;

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useForm<FormValues>({
    defaultValues: {
      selectedLiveboards: liveboards.map((l) => l.thoughtspotLiveboardId),
      thoughtspotUsername: '',
    },
    resolver: zodResolver(
      z.object({
        selectedLiveboards: z.array(z.string()),
        thoughtspotUsername: isMissingFromThoughtspot
          ? z.string().regex(/^[a-zA-Z]+(?:_[a-zA-Z]+)+$/, {
              message: formatMessage({
                id: 'page.config.customer-config.edit-liveboards.thoughtspot-user-helper',
              }),
            })
          : z.string().optional(),
      }),
    ),
  });

  const {
    value: allLiveboards,
    loading: isFetchingAllLiveboards,
    error: errorFetchingAllLiveboards,
  } = useAsync(getAllLiveboards);

  useEffect(() => {
    const thoughtSpotUsername = thoughtspotUsers?.find(
      (u) => u.customerId === customerId,
    )?.thoughtspotUserName;

    if (thoughtSpotUsername) {
      setValue('thoughtspotUsername', thoughtSpotUsername);
    }
  }, [customerId, setValue, thoughtspotUsers]);

  const [{ loading: isUpdatingLiveboards }, doUpdateLiveboardsForCustomers] = useAsyncFn(
    ({ thoughtspotUsername, selectedLiveboards }: FormValues) => {
      const toAdd = selectedLiveboards.filter(
        (l) => !liveboards.some((_l) => _l.thoughtspotLiveboardId === l),
      );
      const toRemove = liveboards
        .filter((l) => !selectedLiveboards.includes(l.thoughtspotLiveboardId))
        .map((l) => l.thoughtspotLiveboardId);

      const removePromises = toRemove.map((r) =>
        removeLiveboardFromCustomer({ customerId, thoughtspotLiveboardId: r }),
      );

      return Promise.all(
        [
          ...removePromises,
          toAdd.length > 0 &&
            assignLiveboardToCustomer({ customerId, thoughtspotLiveboardIds: toAdd }),
          isMissingFromThoughtspot &&
            createThoughtspotUser({
              customerId,
              thoughtspotUsername,
            }),
        ].filter(Boolean),
      ).then(onSuccess);
    },
    [thoughtspotUsers],
  );

  const handleOnSubmit = (data: FormValues) => {
    doUpdateLiveboardsForCustomers(data);
  };

  const handleSelectedLiveboards = (count: number) => {
    if (count === 1) {
      return (
        allLiveboards?.find((l) => l.thoughtspotLiveboardId === watch('selectedLiveboards')[0])
          ?.description ?? ''
      );
    }
    return formatMessage(
      { id: 'page.config.customer-config.edit-liveboards.some-selected' },
      { count },
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      width={560}
      title={formatMessage({ id: 'page.config.customer-config.edit-liveboards.title' })}
    >
      <form className={styles.content} onSubmit={handleSubmit(handleOnSubmit)}>
        {isFetchingAllLiveboards && (
          <div className={styles.loaderWrapper}>
            <Loader.Content />
          </div>
        )}
        {!!errorFetchingAllLiveboards && (
          <Notification
            status="error"
            headline={formatMessage({
              id: 'page.config.customer-config.edit-liveboards.error-all-liveboards',
            })}
          />
        )}
        {allLiveboards && (
          <Controller
            name="selectedLiveboards"
            control={control}
            render={({ field: { onChange, value } }) => (
              <MultiSelect
                id="liveboards"
                options={allLiveboards
                  .map((l) => ({ label: l.description, value: l.thoughtspotLiveboardId }))
                  .sort((a, b) => a.label.localeCompare(b.label))}
                label={formatMessage({
                  id: 'page.config.customer-config.edit-liveboards.connected-liveboards',
                })}
                value={value}
                fullWidth
                search={{
                  noResultText: formatMessage({
                    id: 'page.config.customer-config.edit-liveboards.no-results',
                  }),
                }}
                renderValue={(values) => {
                  if (values.length === 0) {
                    return null;
                  }
                  if (values.length === allLiveboards.length) {
                    return formatMessage({ id: 'page.config.customer-config.edit-liveboards.all' });
                  }
                  return handleSelectedLiveboards(values.length);
                }}
                onChange={onChange}
              />
            )}
          />
        )}
        <TextInput
          label={formatMessage({
            id: 'page.config.customer-config.edit-liveboards.thoughtspot-username',
          })}
          {...register('thoughtspotUsername')}
          error={!!errors.thoughtspotUsername}
          helperText={errors.thoughtspotUsername?.message}
          disabled={!isMissingFromThoughtspot}
        />
        <div className={styles.buttons}>
          <Button variant="secondary" onClick={onClose}>
            <FormattedMessage id="page.config.customer-config.edit-liveboards.cancel" />
          </Button>
          <Button type="submit" loading={isUpdatingLiveboards}>
            <FormattedMessage id="page.config.customer-config.edit-liveboards.save" />
          </Button>
        </div>
      </form>
    </Modal>
  );
};
