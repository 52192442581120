import { Button, CompanyLogo, Icon, Loader, Notification, Typography } from '@insurely/ui';

import { useSearchParams } from 'react-router-dom';
import { useAsyncFn, useMount } from 'react-use';

import { LOGIN_ROUTE, OVERVIEW_ROUTE } from 'constants/routes';

import { useNavigateQueryParams } from 'hooks/useNavigateQueryParams';
import { FormattedMessage, useIntl } from 'translations';

import { validateSSOLogin } from '../api';
import styles from '../login.module.css';

export const SSO = () => {
  const navigate = useNavigateQueryParams();
  const [searchParams] = useSearchParams();
  const [errorCode] = searchParams.getAll('errorCode');
  const { formatMessage } = useIntl();

  const [{ error, loading }, doValidateSsoLogin] = useAsyncFn((token) =>
    validateSSOLogin(token).then(() => navigate(`/${OVERVIEW_ROUTE}`, { cleanQueryParams: true })),
  );

  useMount(() => {
    const token = searchParams.get('token');

    doValidateSsoLogin(token);
  });

  return (
    <div className={styles.formContainer}>
      <div className={styles.form}>
        <CompanyLogo company="se-demo" width="56px" aria-label="Insurely" />
        <Typography component="h1" variant="Headline-4" className={styles.signInTitle}>
          <FormattedMessage id="page.login.sso.title" />
        </Typography>

        {loading && !errorCode && (
          <div className={styles.loader}>
            <Loader.Content size="default" />
          </div>
        )}

        {(!!error || !!errorCode) && (
          <>
            <Notification
              headline={formatMessage({ id: 'page.login.sso.error-login' })}
              status="error"
              className={styles.notification}
            />
            <Button
              variant="secondary"
              onClick={() => navigate(`/${LOGIN_ROUTE}`)}
              icon={<Icon name="arrow-left" size={20} />}
              iconPosition="left"
            >
              <FormattedMessage id="shared.go-back" />
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
