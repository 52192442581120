import { createContext } from 'react';

import { Liveboard } from 'types/liveboard';

import { Company, Config, Session, User as UserInterface, UserAttributes, Market } from 'types/v3';
import { ProductLine } from 'utils/product-line';

interface UserContextInterface {
  user?: UserInterface;
  userAttributes: UserAttributes;
  setUserAttributes?: (userAttributes: UserAttributes) => void;
  sessions: Session[];
  companies: Company[];
  config: Config;
  reloadUser: () => void;
  loadSessions: (contentType: string) => Promise<Session[] | undefined>;
  loadingUser: boolean;
  liveboards: Liveboard[];
  productLine?: ProductLine;
}

export const initialUserContext: UserContextInterface = {
  user: undefined,
  userAttributes: { qrRequirementConfirmation: true },
  sessions: [],
  companies: [],
  config: {
    cancellationEnabled: false,
    cancellationServiceEnabled: false,
    currency: 'SEK',
    market: Market.SE,
    retention: 90,
    smsServiceEnabled: false,
    advisoryParameters: [],
    viewCancellationAuthenticationMethod: 'STANDARD',
    newInsuranceNumberMandatory: false,
  },
  reloadUser: () => {}, // eslint-disable-line
  loadSessions: () => Promise.resolve([]),
  loadingUser: false,
  liveboards: [],
  productLine: undefined,
};

const UserContext = createContext<UserContextInterface>(initialUserContext);

export default UserContext;
