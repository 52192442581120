import { useToastContext } from '@insurely/ui';
import { useState } from 'react';

import { UseFormResetField } from 'react-hook-form';

import { v4 as uuid } from 'uuid';

import {
  createCancellation,
  deleteCancellation,
  sendCancellationReminder,
  updateCollectionStatus,
} from 'client/client';
import {
  Cancellation,
  Collection,
  CreateCancellationRequestBase,
  DeliveryMethodInUse,
} from 'client/types';
import { useIntl } from 'translations';

import { InsuranceResponse } from 'types/insurance';
import { Config, User } from 'types/v3';
import { logError } from 'utils/datadog';

import { PostHogAction, PostHogCategory, postHogCapture } from 'utils/posthog';

import {
  FormValues,
  getFormattedSubmitData,
  getHasRecentReminder,
  updateRecentReminders,
} from '../utils';

export const useCancellationEvents = ({
  collection,
  cancellation,
  config,
  insurance,
  user,
  requirePersonalNumber,
  resetField,
  fetchCancellation,
  setCreatingNewCancellation,
}: {
  collection: Collection;
  cancellation?: Cancellation;
  config: Config;
  insurance: InsuranceResponse;
  user?: User;
  requirePersonalNumber: boolean;
  resetField: UseFormResetField<FormValues>;
  fetchCancellation: (id: string) => Promise<void>;
  setCreatingNewCancellation: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { formatMessage } = useIntl();
  const { dispatch } = useToastContext();
  const [sendingRequest, setSendingRequest] = useState(false);
  const [showErrorCard, setShowErrorCard] = useState(false);
  const [showReminderModal, setShowReminderModal] = useState(false);

  const handleOnSubmit = async (data: FormValues) => {
    setSendingRequest(true);
    const formattedData = getFormattedSubmitData({
      config,
      data,
      insurance,
      user,
      requirePersonalNumber,
    });

    try {
      // Make sure the status for the collection related to this insurance have a end status.
      // Some colelctions will have the COLLECTING status even though it's finished due to a bug
      // in the data collections service
      if (collection.status === 'COLLECTING') {
        await updateCollectionStatus(collection.sessionId, collection.collectionId).catch((err) => {
          const nErr = new Error('Failed to update collection status', { cause: err });
          logError(nErr, {
            sessionId: collection.sessionId,
            collectionId: collection.collectionId,
          });
          throw nErr;
        });
      }

      const res = await createCancellation(
        formattedData as CreateCancellationRequestBase & DeliveryMethodInUse,
      ).catch((err) => {
        const nErr = new Error('Failed to create cancellation', { cause: err });
        logError(nErr, {
          sessionId: collection.sessionId,
          collectionId: collection.collectionId,
          authenticationMethod: formattedData.authenticationMethod,
          deliveryMethod: formattedData.deliveryMethod,
        });
        throw nErr;
      });
      if (res?.cancelledInsurances?.[0]?.documentId) {
        await fetchCancellation(res?.cancelledInsurances?.[0]?.documentId).catch((err) => {
          const nErr = new Error('Failed to fetch cancellation', { cause: err });
          logError(nErr, {
            sessionId: collection.sessionId,
            collectionId: collection.collectionId,
          });
          throw nErr;
        });
      }
      setCreatingNewCancellation(false);
      setSendingRequest(false);
      dispatch({
        type: 'ADD_TOAST',
        payload: {
          id: uuid(),
          type: 'success',
          message: formatMessage({ id: 'Power of attorney sent.' }),
        },
      });
    } catch (error) {
      setSendingRequest(false);
      setShowErrorCard(true);
    }
  };

  const sendReminder = async () => {
    if (cancellation) {
      setSendingRequest(true);

      postHogCapture(`tracking:${PostHogAction.CLICK}`, {
        action: PostHogAction.CLICK,
        category: PostHogCategory.CANCELLATION,
        object: 'sendReminder',
      });

      try {
        await sendCancellationReminder(cancellation?.id);
        updateRecentReminders(cancellation.id);
        setSendingRequest(false);
        dispatch({
          type: 'ADD_TOAST',
          payload: {
            id: uuid(),
            type: 'success',
            message: formatMessage({ id: 'Reminder sent.' }),
          },
        });
      } catch (error) {
        setSendingRequest(false);
        logError(new Error('Error sending cancellation reminder', { cause: error }), {
          cancellationId: cancellation.id,
        });
      }
    }
  };

  const handleSendReminder = () => {
    if (!cancellation) return;
    const hasRecentReminder = getHasRecentReminder(cancellation.id);
    if (hasRecentReminder) {
      setShowReminderModal(true);
    } else {
      sendReminder();
    }
  };

  const handleDeleteCancellation = async () => {
    if (cancellation) {
      setSendingRequest(true);
      postHogCapture(`tracking:${PostHogAction.CLICK}`, {
        action: PostHogAction.CLICK,
        category: PostHogCategory.CANCELLATION,
        object: 'deleteCancellation',
      });

      try {
        await deleteCancellation(cancellation.id);
        setCreatingNewCancellation(true);
        setSendingRequest(false);
        resetField('receiver');
        resetField('newInsuranceNumber');
        resetField('terminationDate');
      } catch (error) {
        setSendingRequest(false);
        logError(new Error('Error deleting cancellation', { cause: error }), {
          cancellationId: cancellation.id,
        });
      }
    }
  };

  return {
    sendingRequest,
    showErrorCard,
    showReminderModal,
    sendReminder,
    handleOnSubmit,
    handleSendReminder,
    handleDeleteCancellation,
    setShowErrorCard,
    setShowReminderModal,
  };
};
