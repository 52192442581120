import { newRequestWithToken } from 'client/client';
import { InsuranceSubType, InsuranceType } from 'types/v3/Insurance';
import { FinancialProductType } from 'types/wealth';
import { formatMarket } from 'utils/formatters';
import {
  BaseClientConfig,
  ClientConfigApi,
  ClientSSTConfig,
  ExtendedClientConfig,
  ProductType,
} from 'views/Config/config.types';

export const createClient = async ({
  customerId,
  config,
}: {
  customerId: string;
  config: ClientConfigApi;
}): Promise<string> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/setup/customer/${customerId}/client-configs`,
    data: { ...config, market: config.market.toUpperCase() },
  });
  return res?.data.clientId;
};

export const updateClient = async ({
  clientId,
  config,
}: {
  clientId: string;
  config: ClientConfigApi;
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'PUT',
    url: `/sst/setup/client-config/${clientId}`,
    data: { ...config, market: config.market.toUpperCase() },
  });
  return res?.data;
};

export const getClientInsuranceTypes = async (clientId: string): Promise<InsuranceType[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/client-config/${clientId}/insurance-types`,
  });
  return res?.data.insuranceTypes;
};

export const assignInsuranceTypesToClient = async ({
  clientId,
  insuranceTypes,
}: {
  clientId: string;
  insuranceTypes: string[];
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/setup/client-config/${clientId}/insurance-types`,
    data: { insuranceTypes },
  });
  return res?.data;
};

export const removeInsuranceTypeFromClient = async ({
  clientId,
  insuranceType,
}: {
  clientId: string;
  insuranceType: string;
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'DELETE',
    url: `/sst/setup/client-config/${clientId}/insurance-types/${insuranceType}`,
  });
  return res?.data;
};

export const getClientInsuranceSubTypes = async (clientId: string): Promise<InsuranceSubType[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/client-config/${clientId}/insurance-sub-types`,
  });
  return res?.data.insuranceSubTypes;
};

export const assignInsuranceSubTypesToClient = async ({
  clientId,
  insuranceSubTypes,
}: {
  clientId: string;
  insuranceSubTypes: string[];
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/setup/client-config/${clientId}/insurance-sub-types`,
    data: { insuranceSubTypes },
  });
  return res?.data;
};

export const removeInsuranceSubTypeFromClient = async ({
  clientId,
  insuranceSubType,
}: {
  clientId: string;
  insuranceSubType: string;
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'DELETE',
    url: `/sst/setup/client-config/${clientId}/insurance-sub-types/${insuranceSubType}`,
  });
  return res?.data;
};

export const getClientCompanies = async (clientId: string): Promise<string[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/client-config/${clientId}/companies`,
  });
  return res?.data.clientCompanies;
};

export const assignCompaniesToClient = async ({
  clientId,
  clientCompanies,
}: {
  clientId: string;
  clientCompanies: string[];
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/setup/client-config/${clientId}/companies`,
    data: { clientCompanies },
  });
  return res?.data;
};

export const removeCompanyFromClient = async ({
  clientId,
  clientCompany,
}: {
  clientId: string;
  clientCompany: string;
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'DELETE',
    url: `/sst/setup/client-config/${clientId}/companies/${clientCompany}`,
  });
  return res?.data;
};

export const getClientProductTypes = async (clientId: string): Promise<ProductType[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/client-config/${clientId}/product-types`,
  });
  return res?.data.productTypes;
};

export const assignProductTypesToClient = async ({
  clientId,
  productTypes,
}: {
  clientId: string;
  productTypes: string[];
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/setup/client-config/${clientId}/product-types`,
    data: { productTypes },
  });
  return res?.data;
};

export const removeProductTypeFromClient = async ({
  clientId,
  productType,
}: {
  clientId: string;
  productType: string;
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'DELETE',
    url: `/sst/setup/client-config/${clientId}/product-types/${productType}`,
  });
  return res?.data;
};

export const getClientWealthTypes = async (clientId: string): Promise<FinancialProductType[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/client-config/${clientId}/wealth-types`,
  });
  return res?.data.wealthTypes;
};

export const assignWealthTypesToClient = async ({
  clientId,
  wealthTypes,
}: {
  clientId: string;
  wealthTypes: FinancialProductType[];
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/setup/client-config/${clientId}/wealth-types`,
    data: { wealthTypes },
  });
  return res?.data;
};

export const removeWealthTypeFromClient = async ({
  clientId,
  wealthType,
}: {
  clientId: string;
  wealthType: FinancialProductType;
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'DELETE',
    url: `/sst/setup/client-config/${clientId}/wealth-types/${wealthType}`,
  });
  return res?.data;
};

export const getClientConfig = async (clientId: string): Promise<BaseClientConfig> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/client-config/${clientId}`,
  });
  return { ...res?.data, market: formatMarket(res.data.market) };
};

export const getClientSstConfig = async (clientId: string): Promise<ClientSSTConfig> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/client-config/${clientId}/sst-config`,
  });
  return res?.data;
};

export const createClientSstConfig = async ({
  clientId,
  conf,
}: {
  clientId: string;
  conf: ClientSSTConfig;
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/setup/client-config/${clientId}/sst-config`,
    data: conf,
  });
  return res?.data;
};

export const deleteClientSstConfig = async (clientId: string): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'DELETE',
    url: `/sst/setup/client-config/${clientId}/sst-config`,
  });
  return res?.data;
};

export const getClientAllowedOrigins = async (clientId: string): Promise<string[]> => {
  const res = await newRequestWithToken({
    method: 'GET',
    url: `/sst/setup/client-config/${clientId}/allowed-origins`,
  });
  return res?.data.clientAllowedOrigin;
};

export const createClientAllowedOrigins = async ({
  clientId,
  allowedOrigins,
}: {
  clientId: string;
  allowedOrigins: string[];
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/setup/client-config/${clientId}/allowed-origins`,
    data: { clientAllowedOrigins: allowedOrigins },
  });
  return res?.data;
};

export const deleteClientAllowedOrigins = async (clientId: string): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'DELETE',
    url: `/sst/setup/client-config/${clientId}/allowed-origins`,
  });
  return res?.data;
};

export const createClientApiKey = async (clientId: string): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/setup/client-config/${clientId}/api-keys`,
  });
  return res?.data;
};

export const deleteClientApiKey = async ({
  clientId,
  apiKey,
}: {
  clientId: string;
  apiKey: string;
}): Promise<void> => {
  const res = await newRequestWithToken({
    method: 'DELETE',
    url: `/sst/setup/client-config/${clientId}/api-keys/${apiKey}`,
  });
  return res?.data;
};

export const getExtendedClientConfig = async (clientId: string): Promise<ExtendedClientConfig> => {
  const res = await Promise.allSettled([
    getClientConfig(clientId),
    getClientInsuranceTypes(clientId),
    getClientInsuranceSubTypes(clientId),
    getClientProductTypes(clientId),
    getClientWealthTypes(clientId),
    getClientCompanies(clientId),
    getClientSstConfig(clientId),
    getClientAllowedOrigins(clientId),
  ]);

  const [
    baseClientConfig,
    insuranceTypes,
    insuranceSubTypes,
    productTypes,
    wealthTypes,
    clientCompanies,
    sstConfig,
    allowedOrigins,
  ] = res;

  if (baseClientConfig.status === 'rejected') {
    throw new Error('Failed to fetch base client config');
  }

  return {
    ...baseClientConfig.value,
    insuranceTypes: insuranceTypes.status === 'fulfilled' ? insuranceTypes.value : [],
    insuranceSubTypes: insuranceSubTypes.status === 'fulfilled' ? insuranceSubTypes.value : [],
    productTypes: productTypes.status === 'fulfilled' ? productTypes.value : [],
    wealthTypes: wealthTypes.status === 'fulfilled' ? wealthTypes.value : [],
    clientCompanies: clientCompanies.status === 'fulfilled' ? clientCompanies.value : [],
    baseUrl: sstConfig.status === 'fulfilled' ? sstConfig.value.baseUrl : '',
    logotype: sstConfig.status === 'fulfilled' ? sstConfig.value.logotype : '',
    allowedOrigins: allowedOrigins.status === 'fulfilled' ? allowedOrigins.value : [],
  };
};
