import { Button, Icon, Notification, Plate, Typography } from '@insurely/ui';
import { AxiosError, isAxiosError } from 'axios';
import { useRef, useState } from 'react';

import { useParams } from 'react-router-dom';

import { FormattedMessage, useIntl } from 'translations';

import { Permissions } from 'types/v3';
import { ConfirmationModal } from 'views/Config/comp/ConfirmationModal/ConfirmationModal';

import styles from './actionBanner.module.css';
import { BatchDeletion } from './Actions/BatchDeletion';
import { BatchRemoval } from './Actions/BatchRemoval';
import { PermissionMgmt } from './Actions/PermissionMgmt';
import { UserGroupMgmt } from './Actions/UserGroupMgmt';
import { UsersActionsType, UsersActions } from './Actions/UsersActions';
import { Target } from './type';
import { useRequests } from './useRequests';

export enum Action {
  USERS_ACTIONS = 'USERS-ACTIONS', // Perform actions on users (disable/enable)
  USER_GROUP_MGMT = 'USER-GROUP_MGMT', // Add/Remove users from user-group
  USERS_PERMISSIONS = 'USERS-PERMISSIONS', // Add/Remove permissions on users
  USER_GROUP_USERS_REMOVE = 'USER-GROUP-USERS-REMOVE', // Remove only users from user-group
  CLIENTS_REMOVE = 'CLIENTS-REMOVE', // Remove only clients from user-group
  LIVEBOARDS_REMOVE = 'LIVEBOARDS-REMOVE', // Remove only liveboards from user-group
  LIVEBOARDS_DELETE = 'LIVEBOARDS-DELETE', // Delete liveboards (admin only)
}

interface Props {
  targetIds: string[];
  actions: Action[];
  onSuccess?: () => void;
  onClose?: () => void;
  target: Target;
}

export const ActionsBanner = ({ target, actions, targetIds, onSuccess, onClose }: Props) => {
  const { formatMessage } = useIntl();
  const { customerId } = useParams() as { customerId: string };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [successText, setSuccessText] = useState<string | null>(null);
  const [showSuccessText, setShowSuccessText] = useState(false);
  const pendingAction = useRef<() => Promise<unknown>>();

  const {
    addUsersInGroup,
    editUsers,
    removeUsersFromGroup,
    removeClientsFromGroup,
    removeLiveboardsFromGroup,
    addPermissionFromUser,
    removePermissionFromUser,
    deleteLiveboards,
  } = useRequests({
    ids: targetIds,
  });

  const { isAddingUsers, errorAddingUsers, doAddToUserGroup } = addUsersInGroup;
  const { isRemovingUsers, errorRemovingUsers, doRemoveFromUserGroup } = removeUsersFromGroup;
  const { isEditingUsers, errorEditingUsers, doEditUsers } = editUsers;
  const { isRemovingClients, errorRemovingClients, doRemoveClients } = removeClientsFromGroup;
  const { isRemovingLiveboards, errorRemovingLiveboards, doRemoveLiveboards } =
    removeLiveboardsFromGroup;
  const { isAddingPermission, errorAddingPermission, doAddPermission } = addPermissionFromUser;
  const { isRemovingPermission, errorRemovingPermission, doRemovePermission } =
    removePermissionFromUser;
  const { isDeletingLiveboards, errorDeletingLiveboards, doDeleteLiveboards } = deleteLiveboards;

  const isPendingAction =
    isAddingUsers ||
    isRemovingUsers ||
    isEditingUsers ||
    isRemovingClients ||
    isRemovingLiveboards ||
    isAddingPermission ||
    isRemovingPermission ||
    isDeletingLiveboards;

  const isErrorOnAction =
    !!errorAddingUsers ||
    !!errorRemovingUsers ||
    !!errorEditingUsers ||
    !!errorRemovingClients ||
    !!errorRemovingLiveboards ||
    !!errorAddingPermission ||
    !!errorRemovingPermission ||
    !!errorDeletingLiveboards;

  const isActionDisabled = targetIds.length === 0;

  const handleSetSuccessText = (origin: string) => {
    let message = null;
    switch (origin) {
      case 'users-to-group':
        message = formatMessage({ id: 'page.config.action-banner.add-users-to-group.success' });
        break;
      case 'users-from-group':
        message = formatMessage({
          id: 'page.config.action-banner.remove-users-from-group.success',
        });
        break;
      case 'clients-from-group':
        message = formatMessage({
          id: 'page.config.action-banner.remove-client-from-group.success',
        });
        break;
      case 'liveboards-from-group':
        message = formatMessage({
          id: 'page.config.action-banner.remove-liveboard-from-group.success',
        });
        break;
      case 'users-enabled':
        message = formatMessage({ id: 'page.config.action-banner.enable-user.success' });
        break;
      case 'users-disabled':
        message = formatMessage({ id: 'page.config.action-banner.disable-user.success' });
        break;
      case 'permissions-to-user':
        message = formatMessage({ id: 'page.config.action-banner.add-permission-to-user.success' });
        break;
      case 'permissions-from-user':
        message = formatMessage({
          id: 'page.config.action-banner.remove-permission-to-user.success',
        });
        break;
      case 'liveboards-delete':
        message = formatMessage({ id: 'page.config.action-banner.delete-liveboards.success' });
        break;
      default:
        break;
    }
    setSuccessText(message);
  };

  const handleOnSuccess = () => {
    setShowSuccessText(true);
    onSuccess?.();

    setTimeout(() => {
      setSuccessText(null);
      setShowSuccessText(false);
    }, 3000);
  };

  const handlePostPromise = (data: AxiosError | unknown) => {
    pendingAction.current = undefined;
    setShowConfirmationModal(false);
    if (!isAxiosError(data)) {
      handleOnSuccess();
    }
  };

  const handleOnAddUsersToUserGroup = (userGroupIds: string[]) => {
    handleSetSuccessText('users-to-group');
    doAddToUserGroup(userGroupIds).then(handlePostPromise);
  };

  const handleOnRemoveUsersFromUserGroup = (userGroupIds: string[]) => {
    handleSetSuccessText('users-from-group');
    pendingAction.current = () => doRemoveFromUserGroup(userGroupIds).then(handlePostPromise);
    setShowConfirmationModal(true);
  };

  const handleOnRemoveClientsFromUserGroup = (userGroupId: string) => {
    handleSetSuccessText('clients-from-group');
    pendingAction.current = () => doRemoveClients(userGroupId).then(handlePostPromise);
    setShowConfirmationModal(true);
  };

  const handleOnRemoveLiveboardsFromUserGroup = (userGroupId: string) => {
    handleSetSuccessText('liveboards-from-group');
    pendingAction.current = () => doRemoveLiveboards(userGroupId).then(handlePostPromise);
    setShowConfirmationModal(true);
  };

  const handlePerformUserAction = (action: UsersActionsType) => {
    if (action === UsersActionsType.ENABLE) {
      handleSetSuccessText('users-enabled');
      doEditUsers({ enabled: true, customerId }).then(handlePostPromise);
    } else {
      handleSetSuccessText('users-disabled');
      setShowConfirmationModal(true);
      pendingAction.current = () =>
        doEditUsers({ enabled: false, customerId }).then(handlePostPromise);
    }
  };

  const handleOnAddPermissionsToUser = (permissions: Permissions[]) => {
    handleSetSuccessText('permissions-to-user');
    doAddPermission(permissions).then(handleOnSuccess);
  };

  const handleOnRemovePermissionsFromUser = (permissions: Permissions[]) => {
    handleSetSuccessText('permissions-from-user');
    pendingAction.current = () => doRemovePermission(permissions).then(handlePostPromise);
    setShowConfirmationModal(true);
  };

  const handleOnDeleteLiveboards = () => {
    handleSetSuccessText('liveboards-delete');
    pendingAction.current = () => doDeleteLiveboards().then(handlePostPromise);
    setShowConfirmationModal(true);
  };

  const renderAction = (action: Action) => {
    switch (action) {
      case Action.USERS_ACTIONS:
        return (
          <UsersActions
            disabled={isActionDisabled}
            isPending={isEditingUsers}
            onClick={handlePerformUserAction}
          />
        );
      case Action.USER_GROUP_MGMT:
        return (
          <UserGroupMgmt
            addCallback={{
              onAdd: handleOnAddUsersToUserGroup,
              loading: isAddingUsers,
              disabled: isRemovingUsers,
            }}
            removeCallback={{
              onRemove: handleOnRemoveUsersFromUserGroup,
              loading: isRemovingUsers,
              disabled: isAddingUsers,
            }}
          />
        );
      case Action.USER_GROUP_USERS_REMOVE:
        return (
          <BatchRemoval
            target={target}
            onClick={(userGroupId) => handleOnRemoveUsersFromUserGroup([userGroupId])}
            isPending={isRemovingUsers}
            disabled={isActionDisabled}
          />
        );
      case Action.CLIENTS_REMOVE:
        return (
          <BatchRemoval
            target={target}
            onClick={handleOnRemoveClientsFromUserGroup}
            isPending={isRemovingClients}
            disabled={isActionDisabled}
          />
        );
      case Action.LIVEBOARDS_REMOVE:
        return (
          <BatchRemoval
            target={target}
            onClick={handleOnRemoveLiveboardsFromUserGroup}
            isPending={isRemovingLiveboards}
            disabled={isActionDisabled}
          />
        );
      case Action.LIVEBOARDS_DELETE:
        return (
          <BatchDeletion
            target={target}
            onClick={handleOnDeleteLiveboards}
            isPending={isDeletingLiveboards}
            disabled={isActionDisabled}
          />
        );
      case Action.USERS_PERMISSIONS:
        return (
          <PermissionMgmt
            addCallback={{
              onAdd: handleOnAddPermissionsToUser,
              loading: isAddingPermission,
              disabled: isRemovingPermission,
            }}
            removeCallback={{
              onRemove: handleOnRemovePermissionsFromUser,
              loading: isRemovingPermission,
              disabled: isAddingPermission,
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Plate className={styles.plate}>
      <div className={styles.headline}>
        {showSuccessText && successText ? (
          <div className={styles.successHeadline}>
            <Icon name="success" size={20} color="var(--green)" />
            <Typography variant="Label-3" component="p">
              {successText}
            </Typography>
          </div>
        ) : (
          <Typography variant="Label-3" component="p">
            <FormattedMessage id="page.config.action-banner.title" /> ({targetIds.length})
          </Typography>
        )}
        {onClose && (
          <Button size="medium" onClick={onClose}>
            <FormattedMessage id="page.config.action-banner.done" />
          </Button>
        )}
      </div>
      <div className={styles.content}>{actions.map(renderAction)}</div>
      {isErrorOnAction && (
        <div className={styles.actionBanner}>
          <Notification
            status="error"
            headline={formatMessage({ id: 'page.config.action-banner.error' })}
          />
        </div>
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          onCancel={() => {
            setShowConfirmationModal(false);
            pendingAction.current = undefined;
          }}
          onConfirm={() => {
            if (pendingAction.current) {
              pendingAction.current();
            }
          }}
          isPending={isPendingAction}
        />
      )}
    </Plate>
  );
};
