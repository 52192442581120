import { Card, Icon, Typography } from '@insurely/ui';

import { useCompanies } from 'hooks/useCompanies';
import { useIntl } from 'translations';
import { InsuranceSubType } from 'types/v3/Insurance';

import styles from './errorBanner.module.css';

interface Props {
  insuranceSubType: InsuranceSubType;
  company1?: string;
  company2?: string;
}

export const ErrorBanner = ({ company1, company2, insuranceSubType }: Props) => {
  const { getCompanyDisplayName } = useCompanies();
  const { formatMessage } = useIntl();

  const companyNames = [company1, company2]
    .filter(Boolean)
    .map((id) => getCompanyDisplayName(id as string))
    .join(` ${formatMessage({ id: 'and' })} `);

  return (
    <Card flat className={styles.card}>
      <div className={styles.iconBackground}>
        <Icon name="no-file" size={36} />
      </div>
      <div className={styles.content}>
        <Typography variant="Headline-5" component="h5">
          {formatMessage(
            { id: 'We could not find any {insuranceSubType} at {companyName}' },
            {
              insuranceSubType: formatMessage({ id: insuranceSubType }),
              companyName: companyNames,
            },
          )}
        </Typography>
        <Typography variant="ParagraphBodySmall" component="p">
          {formatMessage({ id: 'Try changing the insurance type or insurance companies' })}
        </Typography>
      </div>
    </Card>
  );
};
