import { Loader, Typography } from '@insurely/ui';
import classNames from 'classnames';

import tableStyles from 'styles/table.module.css';
import { FormattedMessage } from 'translations';

import { Permissions } from 'types/v3';

import configStyles from '../../config.module.css';

import styles from './userGroupConfig.module.css';

export const PermissionsContent = ({
  permissions,
  loading,
}: {
  loading: boolean;
  permissions?: Permissions[];
}) => {
  if (loading) {
    return (
      <div style={{ position: 'relative', height: '30px' }}>
        <Loader.Content size="default" />
      </div>
    );
  }

  return (
    <div className={tableStyles.tableContainer}>
      <table className={tableStyles.table}>
        <thead>
          <tr className={tableStyles.head}>
            <th aria-labelledby="customer name" style={{ paddingLeft: 'var(--space-4)' }}>
              <FormattedMessage id="page.config.user-group-config.permissions.column.name" />
            </th>
            <th
              aria-labelledby="actions"
              style={{ paddingRight: 'var(--space-4)', textAlign: 'right' }}
            >
              <FormattedMessage id="page.config.user-group-config.permissions.column.actions" />
            </th>
          </tr>
        </thead>
        <tbody>
          {(!permissions || permissions?.length === 0) && (
            <tr>
              <td colSpan={2} aria-label="empty-row-cell">
                <div className={configStyles.emptyRow}>
                  <Typography variant="ParagraphBodySmall" component="p">
                    <FormattedMessage id="page.config.user-group-config.permissions.empty-table" />
                  </Typography>
                </div>
              </td>
            </tr>
          )}
          {permissions
            ?.sort((a, b) => a.localeCompare(b))
            ?.map((p) => (
              <tr
                className={classNames(tableStyles.row, styles.notClickableRow)}
                key={p}
                tabIndex={0}
              >
                <td style={{ paddingLeft: 'var(--space-4)' }}>
                  <Typography component="p" variant="ParagraphCaption">
                    {p}
                  </Typography>
                </td>
                <td aria-label="actions">
                  <div />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
