import { Divider, Loader, Notification, Typography } from '@insurely/ui';

import { useContext } from 'react';

import { useParams } from 'react-router-dom';

import { findAndRemoveDuplicates } from 'components/EventLog/utils';
import { InsuranceListing } from 'components/InsuranceListing/InsuranceListing';
import CollectionError from 'components/Notifications/CollectionError/CollectionError';
import { Page } from 'components/Page/Page';
import { PensionListing } from 'components/PensionListing/PensionListing';
import SessionContext from 'contexts/session/SessionContext';
import UserContext from 'contexts/user/UserContext';
import utils from 'services/utils';
import { FormattedMessage, useIntl } from 'translations';
import { InsuranceResponse } from 'types/insurance';

import { LoginMethod } from 'types/v3';
import { FinancialProduct } from 'types/wealth';
import { ProductLine } from 'utils/product-line';

import { Notifications } from './comp/Notifications/Notifications';
import { SendCollectionLink } from './comp/SendCollectionLink/SendCollectionLink';
import { SessionEvents } from './comp/SessionEvents/SessionEvents';

import { SessionInfo } from './comp/SessionInfo/SessionInfo';

import styles from './sessionPage.module.css';

export type pensionCollection = { pensions: FinancialProduct[]; collectionId: string };
export type insuranceCollection = { insurances: InsuranceResponse[]; collectionId: string };

export const SessionPage = () => {
  const { formatMessage } = useIntl();
  const { sessionId } = useParams() as {
    sessionId: string;
  };
  const { config, loadSessions, productLine, companies } = useContext(UserContext);
  const { collections } = useContext(SessionContext);
  const { baseUrl } = utils.getConfigItem(config) || {};
  const { session, items, smsInvites, loadingSessionData, errorFetchingSessionData } =
    useContext(SessionContext);

  const QRCompanies = companies.filter(
    (comp) =>
      comp.functional &&
      comp.nonFunctionalLoginMethods.includes(LoginMethod.SWEDISH_MOBILE_BANKID_SAME_DEVICE),
  );

  const pageTitle = formatMessage({
    id: productLine === ProductLine.PENSION ? 'Pensions' : 'Insurances',
  });

  const noSession = !session && !loadingSessionData;

  if (loadingSessionData || !session) {
    return (
      <Page title={pageTitle} goback>
        <Typography component="h1" variant="Headline-3">
          {noSession ? formatMessage({ id: 'Could not find the session' }) : session?.freeText}
        </Typography>
        {errorFetchingSessionData ? <CollectionError /> : <Loader.Content />}
      </Page>
    );
  }

  const { filteredItems, updatedCollections } = findAndRemoveDuplicates(items, collections);

  return (
    <Page title={pageTitle} goback className={styles.page}>
      <Typography component="h1" variant="Headline-3">
        {session.freeText}
      </Typography>

      {QRCompanies.length > 0 && (
        <Notification
          status="warning"
          headline={formatMessage({ id: 'page.session.bankid-info.headline' })}
          className={styles.bankIdNotification}
          expandableDescription
        >
          <FormattedMessage
            id="page.session.bankid-info.description"
            values={{
              companies: (
                <ul className={styles.listInText}>
                  {QRCompanies.map((c) => (
                    <li key={c.insuranceCompanyDisplayName}>{c.insuranceCompanyDisplayName}</li>
                  ))}
                </ul>
              ),
            }}
          />
        </Notification>
      )}

      <Notifications sessionId={sessionId} smsInvites={smsInvites} />

      {filteredItems.length > 0 && productLine === ProductLine.INSURANCE && (
        <InsuranceListing insurances={filteredItems as InsuranceResponse[]} />
      )}
      {filteredItems.length > 0 && productLine === ProductLine.PENSION && (
        <PensionListing pensions={filteredItems as FinancialProduct[]} />
      )}

      <Divider />

      <SessionEvents smsInvites={smsInvites} collections={updatedCollections} />

      <SendCollectionLink sessionId={sessionId} sessionLink={`${baseUrl}${sessionId}`} />

      <SessionInfo
        sessionId={sessionId}
        sessionLink={`${baseUrl}${sessionId}`}
        loadSessions={loadSessions}
      />
    </Page>
  );
};
