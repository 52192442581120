import { Card, CompanyLogo, StatusText, Typography } from '@insurely/ui';
import classNames from 'classnames';
import { isFuture, isPast } from 'date-fns';

import { useContext } from 'react';

import UserContext from 'contexts/user/UserContext';
import { FormattedMessage, useIntl } from 'translations';

import { useFormatting } from 'translations/useFormatting';
import { InsuranceResponse } from 'types/insurance';

import { expiresSoon, getDisplayName, getDisplayType } from 'utils/insurance';

import styles from './insuranceCard.module.css';

interface InsuranceCardProps {
  insurance: InsuranceResponse;
  className?: string;
}

export const InsuranceCard = ({ insurance, className }: InsuranceCardProps) => {
  const { formatMessage } = useIntl();
  const { formatCurrency, formatDate } = useFormatting();
  const { config } = useContext(UserContext);

  const {
    insurance: {
      employerPaid,
      premiumAmountYearRounded,
      renewalDate,
      startDate,
      insuranceCompany,
      otherInsuranceHolder,
    },
  } = insurance;

  const getMonthlyPayment = () => {
    if (employerPaid) {
      return (
        <Typography component="p" variant="ParagraphTiny" style={{ color: 'var(--grey-1)' }}>
          <FormattedMessage id="Employer paid" />
        </Typography>
      );
    }
    if (!premiumAmountYearRounded) {
      return (
        <Typography component="p" variant="ParagraphTiny" style={{ color: 'var(--grey-1)' }}>
          <FormattedMessage id="UNKNOWN_PREMIUM_FREQUENCY" />
        </Typography>
      );
    }

    return (
      <Typography component="p" variant="Headline-7">
        <FormattedMessage
          id="shared.amount-per-month"
          values={{ amount: formatCurrency(Math.ceil(premiumAmountYearRounded / 12)) }}
        />
      </Typography>
    );
  };

  const getDateText = () => {
    if (renewalDate && isPast(renewalDate)) {
      return formatMessage({ id: 'Expired {date}' }, { date: formatDate(renewalDate) });
    }
    if (startDate && isFuture(startDate)) {
      return formatMessage({ id: 'Active from {date}' }, { date: formatDate(startDate) });
    }
    if (renewalDate) {
      return formatMessage({ id: 'Renewal date {date}' }, { date: formatDate(renewalDate) });
    }
    return formatMessage({ id: 'Renewal date is not available' });
  };

  const printStatus = () => {
    let color = 'green';
    let text = formatMessage({ id: 'Active' });

    if (startDate && isFuture(startDate)) {
      color = 'yellow';
      text = formatMessage({ id: 'Will soon be active' });
    }

    if (expiresSoon(insurance.insurance)) {
      color = 'yellow';
      text = formatMessage({ id: 'Will expire soon' });
    }

    if (insurance.insurance.renewalDate && isPast(insurance.insurance.renewalDate)) {
      color = 'red';
      text = formatMessage({ id: 'Has expired' });
    }

    return <StatusText color={color} text={text} />;
  };

  function getInsuranceDetail() {
    const displayName = getDisplayName(insurance.insurance);
    const {
      insurance: { insuranceType },
    } = insurance;

    if (displayName === '- - -') {
      if (insuranceType === 'houseContentInsurance') {
        return formatMessage({ id: 'Address is not available' });
      }
      if (['personInsurance', 'incomeInsurance'].includes(insuranceType)) {
        return formatMessage({ id: 'Name is not available' });
      }
      if (insuranceType === 'vehicleInsurance') {
        return formatMessage({ id: 'Registration number is not available' });
      }
    }
    return displayName;
  }

  return (
    <Card className={classNames(styles.card, className)}>
      <div className={styles.top}>
        <div style={{ marginBottom: 'var(--space-6)' }}>
          <CompanyLogo company={insuranceCompany} width="36px" />
          {getMonthlyPayment()}
        </div>

        <Typography component="p" variant="Label-2">
          {getDisplayType(insurance.insurance, formatMessage, config.market)}
        </Typography>

        <Typography component="p" variant="ParagraphCaption" className="ph-no-capture">
          {getInsuranceDetail()}
        </Typography>
      </div>

      <div className={styles.bottom}>
        <div>
          <Typography component="p" variant="ParagraphCaption" className={styles.expireDate}>
            {getDateText()}
          </Typography>
          {printStatus()}
        </div>
        {otherInsuranceHolder && (
          <Typography
            component="p"
            variant="ParagraphCaption"
            className={styles.otherInsuranceHolder}
          >
            {formatMessage({ id: 'Other insurance holder' })}
          </Typography>
        )}
      </div>
    </Card>
  );
};
