import { CountryAreaCode, CountryCode, Market } from 'types/v3';

export function cleanName(string: string) {
  // Remove everything that is not: unicode character,
  // space( ), comma(,), dot(.), apostrophe('), dash(-) and plus(+)
  return string.replace(/[^\p{L}\p{N} ,.'+-]/gu, '');
}

export function cleanNumber(value: string) {
  // Remove everything that is not number
  return value.replace(/[^0-9]/g, '');
}

export function upperCaseFirstLetter(string?: string) {
  if (!string) return '';
  return cleanName(string).charAt(0).toUpperCase() + cleanName(string).toLowerCase().slice(1);
}

export function upperCaseEveryFirstLetter(string?: string) {
  if (!string) return '';
  return cleanName(string)
    .toLowerCase()
    .split(/[\s-]+/) // split with either ' ' or '-'
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export const getNameFromEmail = (email: string) => {
  const name = email.split('@')[0];
  return upperCaseEveryFirstLetter(name.replace(/[-.+]/g, ' '));
};

export const snakeCaseToCamelCase = (input: string) =>
  input.toLowerCase().replace(/(_\w)/g, (match) => match[1].toUpperCase());

export const snakeCaseToKebabCase = (input: string) => input.toLowerCase().replaceAll('_', '-');

// TODO: Removes this method and all its usage when the BE sends back the market in lowercase everywhere
export const formatMarket = (market: string) => market.toLowerCase() as Market;

export const marketToCountryAreaCode = (market: Market): CountryAreaCode => {
  switch (market) {
    case Market.DK:
      return CountryAreaCode.DK;
    case Market.FR:
      return CountryAreaCode.FR;
    case Market.EE:
      return CountryAreaCode.EE;
    case Market.SE:
    default:
      return CountryAreaCode.SE;
  }
};

export const marketToCountryCode = (market: Market): CountryCode => {
  switch (market) {
    case Market.DK:
      return CountryCode.DK;
    case Market.FR:
      return CountryCode.FR;
    case Market.EE:
      return CountryCode.EE;
    case Market.SE:
    default:
      return CountryCode.SE;
  }
};
