import { Plate, TextInput, Toggle, Typography } from '@insurely/ui';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useClickAway } from 'react-use';

import { FormattedMessage, useIntl } from 'translations';

import { FilterFormUtils, FilterOptions, FilterQuery } from '../../types';
import FilterPill from '../FilterPill/FilterPill';
import { CollectionsParamOptions } from '../filterTypes';

import styles from './filterDropdown.module.css';
import { FilterDropdownSection } from './FilterDropdownSection';

type FilterDropdownProps = {
  searchText: string;
  setSearchText: (v: string) => void;
  options: FilterOptions;
  form: FilterFormUtils;
};

const FILTER_STRING_FIELDS = [
  'personalNumber',
  'externalReference',
  'contactEmail',
  'contactPhone',
] satisfies Array<keyof FilterQuery>;

export default function FilterDropdown({
  searchText,
  setSearchText,
  options,
  form,
}: FilterDropdownProps) {
  const { formatMessage } = useIntl();

  const filterDiv = useRef<HTMLDivElement | null>(null);
  const [filterDropdownOpen, setFilterDropdownOpen] = useState(false);

  useClickAway(filterDiv, () => setFilterDropdownOpen(false));

  return (
    <div className={styles.filterBox} ref={filterDiv}>
      <TextInput
        className={styles.textField}
        textInputSize="small"
        label={formatMessage({ id: 'page.collections.filters.title' })}
        placeholder={formatMessage({ id: 'page.collections.filters.placeholder' })}
        value={searchText}
        onChange={(event) => setSearchText(event.currentTarget.value)}
        onFocus={() => setFilterDropdownOpen(true)}
        aria-label="collection-filter"
        inputProps={{ autoComplete: 'off' }}
      />
      <Plate
        className={classNames(styles.filterDropdown, {
          [styles.filterDropdownOpen]: filterDropdownOpen,
        })}
      >
        {searchText && (
          <div className={styles.searchPillWrapper}>
            {FILTER_STRING_FIELDS.map((f) => (
              <FilterPill
                key={f}
                text={
                  <FormattedMessage
                    id="page.collections.filters.pill"
                    values={{
                      value: searchText,
                      descriptor: formatMessage({ id: `page.collections.filters.${f}` }),
                    }}
                  />
                }
                onClick={() => {
                  form.setValue(f, searchText);
                  setSearchText('');
                  setFilterDropdownOpen(false);
                }}
                add
              />
            ))}
          </div>
        )}
        <div className={styles.dropdownSectionWrapper}>
          {(Object.keys(options) as (keyof CollectionsParamOptions)[]).map((section) => (
            <FilterDropdownSection
              key={`filter-${section}`}
              searchText={searchText}
              options={options[section]}
              section={section}
              form={form}
            />
          ))}
          <Controller
            control={form.control}
            name="showEmpty"
            render={({ field: { onChange, value } }) => (
              <div className={styles.checkboxShowEmpty}>
                <Typography variant="Label-2" component="p">
                  <FormattedMessage id="page.collections.filters.showEmpty" />
                </Typography>
                <Toggle checked={value} onCheckedChange={onChange} />
              </div>
            )}
          />
        </div>
      </Plate>
    </div>
  );
}
