import { compareAsc, isAfter, isBefore, isSameDay, isValid, subDays } from 'date-fns';

import { InsuranceResponse } from 'types/insurance';
import { Market } from 'types/v3';
import {
  Insurance,
  PersonInsurance,
  PremiumFrequency,
  PremiumFrequencyNumbers,
} from 'types/v3/Insurance';

import { FinancialProduct } from 'types/wealth';

import { upperCaseEveryFirstLetter, upperCaseFirstLetter } from './formatters';

function lowerCaseCar(string: string) {
  if (string === 'VW' || string === 'BMW') return string;
  if (string) return upperCaseFirstLetter(string);
  return '';
}

export const getDisplayName = (insurance: Insurance) => {
  if (insurance.insuranceSubType === 'pregnancyInsurance') {
    return insurance.insuranceHolderName
      ? upperCaseEveryFirstLetter(insurance.insuranceHolderName)
      : '- - -';
  }
  if (insurance.insuranceSubType === 'childInsurance') {
    const personInsurance = insurance as PersonInsurance;
    return personInsurance.insuredPersonName
      ? upperCaseEveryFirstLetter(personInsurance.insuredPersonName)
      : '- - -';
  }
  if (insurance.insuranceType === 'houseContentInsurance') {
    return (
      insurance.insuranceObjectStreetAddress ||
      insurance.insuranceHolderStreetAddress ||
      insurance.insuranceName ||
      '- - -'
    );
  }
  if ('brand' in insurance && insurance.brand) {
    return `${insurance.brand !== null ? `${lowerCaseCar(insurance.brand)} - ` : ''} ${
      insurance.registrationNo
    }`;
  }
  if (insurance.insuranceSubType === 'otherVehicleInsurance') {
    return insurance.insuranceName;
  }
  if ('model' in insurance && insurance.model) {
    return insurance.model;
  }
  if ('registrationNo' in insurance && insurance.registrationNo) {
    return insurance.registrationNo;
  }
  if (
    insurance.insuranceType === 'personInsurance' ||
    insurance.insuranceType === 'incomeInsurance'
  ) {
    return upperCaseEveryFirstLetter(insurance.insuranceHolderName || insurance.insuranceName);
  }
  if ('animalName' in insurance) {
    return upperCaseEveryFirstLetter(insurance.animalName);
  }
  return insurance.insuranceName || '- - -';
};

type FormatMessageFunction = (options: { id: string }) => string;

export const getDisplayType = (
  insurance: Insurance | null,
  formatMessage: FormatMessageFunction,
  market: Market,
) => {
  if (insurance?.insuranceSubType === 'accidentInsurance' && market === Market.SE) {
    if (insurance.insuranceName.toLowerCase().includes('sjuk')) {
      return 'Sjuk- och Olycksfall';
    }
    return 'Olycksfall';
  }
  if (insurance) {
    return formatMessage({ id: insurance.insuranceSubType.replace('Insurance', '') });
  }
  return null;
};

export const expiresSoon = ({ startDate, renewalDate }: Insurance) => {
  const validStartDate = isValid(new Date(startDate || ''));
  const validRenewalDate = isValid(new Date(renewalDate || ''));
  const startAndEndIsValidDate =
    validStartDate && startDate && validRenewalDate && renewalDate
      ? !isSameDay(new Date(startDate), new Date(renewalDate)) &&
        isBefore(new Date(startDate), new Date(renewalDate))
      : false;
  if (!startAndEndIsValidDate || !startDate || !renewalDate) {
    return false;
  }
  return (
    isAfter(new Date(renewalDate), new Date()) &&
    isBefore(subDays(new Date(renewalDate), 30), new Date())
  );
};

export const calculateInsuranceTotals = (insurances: Insurance[]) => {
  const premiumAmountYearRounded = insurances.reduce((tot: number, ins: Insurance) => {
    if (!ins.employerPaid && ins.premiumAmountYearRounded) {
      return tot + ins.premiumAmountYearRounded;
    }
    return tot;
  }, 0);

  return { premiumAmountYearRounded };
};

export const mapPremiumFrequency = (num: PremiumFrequencyNumbers) => {
  if (Object.keys(PremiumFrequency).includes(num.toString())) {
    const premiumFrequencyString = num.toString() as keyof typeof PremiumFrequency;
    return PremiumFrequency[premiumFrequencyString];
  }
  return 'UNKNOWN_PREMIUM_FREQUENCY';
};

export const downloadFile = (exportResponse: Blob, id: string, name: string) => {
  const blob = new Blob([exportResponse], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${name} ${id}.pdf`;
  link.click();
};

export const groupByDate = (items: FinancialProduct[] | InsuranceResponse[]) => {
  const groupedItems: Record<string, Array<FinancialProduct | InsuranceResponse>> = {};
  items.forEach((item) => {
    if (!item) {
      return;
    }

    const collectionDate = item?.collectionDate;
    if (!collectionDate) {
      return;
    }

    if (!groupedItems[collectionDate]) {
      groupedItems[collectionDate] = [];
    }

    groupedItems[collectionDate].push(item);

    if ('displayType' in item) {
      (groupedItems[collectionDate] as InsuranceResponse[]).sort((a, b) =>
        compareAsc(a.insurance.startDate || '', b.insurance.startDate || ''),
      );
    } else {
      (groupedItems[collectionDate] as FinancialProduct[]).sort((a, b) =>
        compareAsc(a.startDate || '', b.startDate || ''),
      );
    }
  });

  return groupedItems;
};
