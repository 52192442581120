import { newRequestWithToken } from 'client/client';
import { Permissions } from 'types/v3';

export const removePermissionForUser = async ({
  userId,
  permission,
}: {
  userId: string;
  permission: Permissions;
}) => {
  const res = await newRequestWithToken({
    method: 'DELETE',
    url: `/sst/setup/user/${userId}/permissions/${permission}`,
  });
  return res?.data;
};

export const addPermissionsForUser = async ({
  userId,
  permissions,
}: {
  userId: string;
  permissions: Permissions[];
}) => {
  const res = await newRequestWithToken({
    method: 'POST',
    url: `/sst/setup/user/${userId}/permissions`,
    data: { permissions },
  });
  return res?.data;
};

export const updateUser = async ({
  userId,
  userBody,
}: {
  userId: string;
  userBody: { enabled: boolean; customerId: string };
}) => {
  const res = await newRequestWithToken({
    method: 'PUT',
    url: `/sst/setup/user/${userId}`,
    data: userBody,
  });
  return res?.data;
};
