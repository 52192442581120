import { Typography } from '@insurely/ui';

import { useIntl } from 'translations';

import { useFormatting } from 'translations/useFormatting';

import styles from './pensionCardTooltip.module.css';

interface PensionCardTooltipProps {
  startDate?: string;
  activePayment: boolean | undefined;
  activePayout: boolean | undefined;
}

export const PensionCardTooltip: React.FC<PensionCardTooltipProps> = ({
  startDate,
  activePayment,
  activePayout,
}) => {
  const { formatMessage } = useIntl();
  const { formatDate } = useFormatting();

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <Typography component="p" variant="ParagraphTiny" className={styles.name}>
          {formatMessage({ id: 'Start date' })}:
        </Typography>
        <Typography component="p" variant="ParagraphTiny" className={styles.value}>
          {startDate ? formatDate(startDate) : '-'}
        </Typography>
      </div>

      <div className={styles.row}>
        <Typography component="p" variant="ParagraphTiny" className={styles.name}>
          {formatMessage({ id: 'Active payments' })}:
        </Typography>
        <Typography component="p" variant="ParagraphTiny" className={styles.value}>
          {activePayment == null
            ? '-'
            : activePayment
              ? formatMessage({ id: 'Yes' })
              : formatMessage({ id: 'No' })}
        </Typography>
      </div>
      <div className={styles.row}>
        <Typography component="p" variant="ParagraphTiny" className={styles.name}>
          {formatMessage({ id: 'Active payouts' })}:
        </Typography>
        <Typography component="p" variant="ParagraphTiny" className={styles.value}>
          {activePayout == null
            ? '-'
            : activePayout
              ? formatMessage({ id: 'Yes' })
              : formatMessage({ id: 'No' })}
        </Typography>
      </div>
      <div className={styles.row} style={{ marginTop: 'var(--space-2)' }}>
        <Typography component="p" variant="ParagraphTiny" className={styles.name}>
          *{formatMessage({ id: 'shared.movability-disclaimer' })}
        </Typography>
      </div>
    </div>
  );
};
