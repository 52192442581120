import { useEffect, useState } from 'react';
import { useAsyncFn, usePrevious } from 'react-use';

import { checkIfTermsExist } from 'client/client';
import { InsuranceSubType } from 'types/v3/Insurance';
import { logError } from 'utils/datadog';

interface Props {
  name: string;
  company: string;
  subType: InsuranceSubType;
  startDate?: string;
}

export const useInsuranceTermLink = (props: Props) => {
  const previousInsurance = usePrevious(props);
  const [isExactTerms, setIsExactTerms] = useState(false);

  const { name, company, subType, startDate } = props;

  const baseUrl = `${API_URL}${!ENV ? '/api' : ''}`;

  const [{ value: linkUrl, loading: isLoadingLinkUrl, error: errorLoadingLink }, doFetchLink] =
    useAsyncFn(async () => {
      try {
        const termsStatus = await checkIfTermsExist(company as string, subType, startDate);
        if (termsStatus.exists) {
          setIsExactTerms(true);
          return `${baseUrl}/insurance-terms/terms/${company}/${subType}/${startDate || ''}`;
        }

        const termDateAgnosticStatus = await checkIfTermsExist(
          company as string,
          subType,
          undefined,
        );

        if (termDateAgnosticStatus.exists) {
          setIsExactTerms(false);
          return `${baseUrl}/insurance-terms/terms/${company}/${subType}`;
        }
        return null;
      } catch (error) {
        const nErr = new Error('Error fetching terms', { cause: error });
        logError(nErr, { company, insuranceSubType: subType, startDate });
        throw nErr;
      }
    }, [props]);

  useEffect(() => {
    if (
      previousInsurance?.name !== name ||
      previousInsurance?.company !== company ||
      previousInsurance?.startDate !== startDate
    ) {
      doFetchLink();
    }
  }, [doFetchLink, previousInsurance, name, company, startDate]);

  return {
    linkUrl,
    isExactTerms,
    isLoadingLinkUrl,
    errorLoadingLink,
    doFetchLink,
  };
};
